/* eslint-disable @typescript-eslint/naming-convention */
import { useContext, createContext, useState } from 'react';

type DeepStateContextType = {
  deepState: Record<string, any>;
  getDeepState: <T>(entity: string) => T;
  setDeepState: (entity: string, value: any) => void;
  deleteDeepState: (entity: string) => void;
};

const DeepStateContext = createContext<DeepStateContextType>({
  deepState: {},
  getDeepState: function <T>(entity: string): T {
    return {} as T;
  },
  setDeepState: () => {},
  deleteDeepState: () => {},
});

export default function useDeepState(): DeepStateContextType {
  const context = useContext<DeepStateContextType>(DeepStateContext);

  return context;
}

export function DeepStateProvider({
  children,
  initialState = {},
}: {
  children: React.ReactNode;
  initialState?: Record<string, any>;
}) {
  const [deepState, _setDeepState] = useState(initialState);

  const getDeepState = function <T>(entity: string): T {
    return deepState[entity];
  };
  const setDeepState = (entity: string, value: any) =>
    _setDeepState({ ...deepState, [entity]: value });
  const deleteDeepState = (entity: string) => {
    const { [entity]: _, ...rest } = deepState;
    _setDeepState(rest);
  };

  return (
    <DeepStateContext.Provider
      value={{ deepState, getDeepState, setDeepState, deleteDeepState }}
    >
      {children}
    </DeepStateContext.Provider>
  );
}
