/*
eslint-disable @typescript-eslint/no-throw-literal
*/

import { processError } from '../utils';
import axios from 'axios';

export default async function uploadWorld(
  worldId: string,
  worldName: string,
  file: File,
  {
    upload_url,
    world_url,
  }: {
    upload_url: string;
    world_url: string;
  },
  setProgress: (progress: number) => void,
  signal: AbortSignal
): Promise<string | undefined> {
  try {
    const uploadResult = await axios.put(upload_url, file, {
      headers: {
        /*'content-type': file.type,*/
      },
      timeout: 0,
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        delete headers['Content-Type'];
        return data;
      },
      onUploadProgress: (progressEvent) => {
        if (!progressEvent || !progressEvent.total) return;
        const fileProgress = (progressEvent.loaded * 100) / progressEvent.total;

        setProgress(Math.floor(fileProgress));
      },
      signal,
    });

    if (typeof uploadResult === 'undefined') throw `Upload failed.`;

    const result = await axios.get('/world/upload/packages/finish', {
      params: {
        url: world_url,
        world_id: worldId,
      },
      signal,
    });

    if (result.status !== 200) throw result;
    if (result.data.code !== 0) throw result.data.message;
    setProgress(100);

    return `Uploaded ${worldName}`;
  } catch (e: any) {
    if (e.toString() === 'CanceledError: canceled') return 'Upload canceled.';
    Promise.reject(processError(e) ?? "Couldn't upload asset.");
  }
}
