import { useCallback, useEffect, useState } from 'react';
import { useUser } from '../../../../hook/useUser';
import { useNavigate, useParams } from 'react-router-dom';
import useMutation from '../../../../hook/useMutation';
import useModal from '../../../../hook/useModal';
import usePageConfig from '../../../../hook/usePageConfig';

import { getWorld } from '../api';
import styles from './index.module.css';
import commonStyles from '../../../common.module.css';
import { processError } from '../../../../utils';
import axios from 'axios';
import { toast } from 'react-hot-toast'; // eslint-disable-line
import {
  deleteWorld,
  getWorldImages,
  getWorldLinks,
  updateWorldImages,
  updateWorldLinks,
} from '../../../../api/world';

import type { World } from '../../../../type/World';

import { Outlet, NavLink } from 'react-router-dom';

import Modal from '../../../../Comps/Modal';
import Loader from '../../../../Comps/Loader';
import Overlay, { OverlayHeader } from '../../../../Comps/Overlay';
import Button from '../../../../Comps/Button';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as GlobeIcon } from '../../../../assets/icons/globeIcon.svg';
import { ReactComponent as CheckSquareIcon } from '../../../../assets/icons/checkSquareIcon.svg';
import { ReactComponent as CheckReloadIcon } from '../../../../assets/icons/checkReloadIcon.svg';
import Footer from '../../../../Comps/Footer';

type WorldExtended = World & {
  links: Record<string, string>;
  showcase: string[];
};

type State =
  | {
      data: null;
      loading: true;
      error: false;
      errorMessage: '';
    }
  | {
      data: null;
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      data: WorldExtended;
      loading: false;
      error: false;
      errorMessage: '';
    };

export type OutletContext = {
  world: WorldExtended;
  update: any;
  setUpdate: (newState: any) => void | any;
  setUpdateField: (field: string, value: any) => void | any;
  //removeUpdateField: (field: string) => void | any;
  //mutation: boolean;
  getField: <ReturnType>(
    field: string | any,
    backup?: ReturnType | undefined,
    doNotOverride?: boolean
  ) => ReturnType | undefined;
  savingData: boolean;
  fetchWorld: () => void;
  setSavingData: (newState: boolean) => void;
};

export default function WorldsWorldManagementWrapper() {
  const navigate = useNavigate();

  const { worldId } = useParams();
  const { isLoading, isLoggedIn } = useUser();

  const [state, setState] = useState<State>({
    data: null,
    loading: true,
    error: false,
    errorMessage: '',
  });

  const fetchWorld = useCallback(
    (signal?: AbortSignal) =>
      Promise.all([
        getWorld(worldId as string, signal),
        getWorldLinks(worldId as string, signal),
      ]).then(([worldResult, worldLinksResult]) => {
        if (
          !worldResult.success &&
          (worldResult.errorMessage.includes('not found') ||
            worldResult.errorMessage.includes('invalid world'))
        )
          return navigate('/creations/worlds');

        if (!worldResult.success || !worldLinksResult.success) {
          return setState({
            data: null,
            loading: false,
            error: true,
            errorMessage: worldResult.errorMessage || worldLinksResult.error,
          });
        }

        const links = worldLinksResult.data;
        let showcase: string[] = [];
        if (
          typeof links.showcase === 'object' &&
          links.showcase !== null &&
          Array.isArray(links.showcase) && // @ts-ignore
          links.showcase.length > 0
        )
          showcase = [...links.showcase];

        delete links.showcase;

        const data = {
          ...worldResult.data,
          links: worldLinksResult.data,
          showcase,
        };
        console.log(`Got world ${worldId}`, data);

        setState({
          data,
          loading: false,
          error: false,
          errorMessage: '',
        });
      }),
    [worldId]
  );

  useEffect(() => {
    if (!worldId) return navigate('/creations/worlds');
    if (isLoading || !isLoggedIn) return;

    fetchWorld();
  }, [worldId, isLoading, fetchWorld, isLoggedIn]);

  const { mutation, setUpdateField, update, setUpdate, getField, reset } =
    useMutation(state.data, undefined, ['showcase']);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    if (!mutation) return;
    window.onbeforeunload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [mutation]);

  const [controller, modal] = useModal();

  const handleDeleteWorld = async () => {
    if (savingData || state.loading || state.error) return;
    const result = await modal.open({
      title: 'Delete World',
      description:
        'Are you sure you want to permanently delete this world? This action cannot be undone.',
      actions: [
        {
          label: 'cancel',
          variant: 'dark',
          value: 'cancel',
        },
        {
          label: (
            <>
              <TrashIcon />
              delete
            </>
          ),
          variant: 'red',
          value: 'confirm',
        },
      ],
    });

    if (result !== 'confirm') return;
    setSavingData(true);

    const toastId = toast.loading('Deleting world...');
    const response = await deleteWorld(state.data.name);
    setSavingData(false);

    if (!response.success) return toast.error(response.error, { id: toastId });
    toast.success('World deleted successfully', { id: toastId });
    navigate('/creations/worlds');
  };

  usePageConfig({
    title: `${state.data?.title ?? 'World'} | HELIX Creator Hub`,
  });

  return (
    <Overlay>
      <Modal controller={controller} />
      <OverlayHeader
        closeable
        closeDisabled={savingData}
        onClose={async () => {
          if (mutation) {
            const result = await modal.open({
              title: 'Unsaved Changes',
              description:
                'Are you sure you want to leave without saving your changes?',
              escapable: false,
              closeable: false,
              actions: [
                {
                  label: "don't save",
                  variant: 'red',
                  value: 'confirm',
                },
                {
                  label: 'cancel',
                  variant: 'dark',
                  value: 'cancel',
                },
              ],
            });
            if (result !== 'confirm') return;
          }
          /*if (
            mutation &&
            !confirm(
              'Are you sure you want to leave without saving your changes?'
            )
          )
            return;*/
          navigate('/creations/worlds');
        }}
      >
        {!state.data?.published && (
          <Button
            variant="yellow"
            disabled={savingData}
            className={styles.saveButton}
            onClick={async () => {
              if (savingData) return;

              const toastId = toast.loading('Publishing world...');
              setSavingData(true);
              try {
                await axios.patch('/world', {
                  id: worldId,
                  published: true,
                });

                toast.success('World published successfully', {
                  id: toastId,
                });
                fetchWorld();
              } catch (e) {
                toast.error(processError(e), { id: toastId });
              }
              setSavingData(false);
            }}
          >
            <span>
              <CheckReloadIcon style={{ height: 22, width: 22 }} />
              publish
            </span>
            {savingData && (
              <Loader scale={0.6} className={styles.buttonLoader} />
            )}
          </Button>
        )}
        <Button
          variant={savingData ? 'dark' : ''}
          onClick={async () => {
            if (savingData) return;
            if (!mutation) return navigate('/creations/worlds');

            setSavingData(true);

            const updateClone = { ...update };

            if (typeof updateClone.metadata === 'string') {
              if (updateClone.metadata === '') updateClone.metadata = {};
              else {
                try {
                  updateClone.metadata = JSON.parse(updateClone.metadata);
                } catch (e) {
                  setSavingData(false);
                  return toast.error('Metadata is not valid JSON');
                }
              }
            }

            if (typeof updateClone.type === 'string') {
              updateClone.type_id = Number(updateClone.type);
              delete updateClone.type;
            }
            if (typeof updateClone.fqdn === 'string' && updateClone.fqdn !== '')
              updateClone.fqdn = `${updateClone.fqdn.toLowerCase()}.lix`;

            if (typeof updateClone.hasPassword === 'boolean') {
              if (!updateClone.hasPassword) {
                delete updateClone.hasPassword;
                updateClone.password = '';
              } else {
                updateClone.has_password = updateClone.hasPassword;
                delete updateClone.hasPassword;
              }
            }

            if (typeof updateClone.maxPlayers === 'string') {
              updateClone.max_players = Number(updateClone.maxPlayers);
              delete updateClone.maxPlayers;
            }

            if (typeof updateClone.public === 'boolean') {
              updateClone.is_public = updateClone.public;
              delete updateClone.public;
            }

            if (typeof updateClone.gameMode === 'string') {
              updateClone.gamemode = updateClone.gameMode;
              delete updateClone.gameMode;
            }

            if (typeof updateClone.gameMap === 'string') {
              updateClone.gamemap = updateClone.gameMap;
              delete updateClone.gameMap;
            }

            try {
              const linksUpdated =
                typeof updateClone.links === 'object' &&
                updateClone.links !== null &&
                Object.keys(updateClone.links).length > 0;
              const showcaseUpdated =
                typeof updateClone.showcase === 'object' &&
                updateClone.showcase !== null &&
                Array.isArray(updateClone.showcase);

              if (linksUpdated || showcaseUpdated) {
                let updateSet = {
                  ...(state?.data?.links ?? {}),
                  showcase: state?.data?.showcase ?? [],
                };
                if (linksUpdated)
                  updateSet = {
                    ...updateSet,
                    ...updateClone.links,
                  };

                if (showcaseUpdated) {
                  const imagesResult = await getWorldImages(worldId as string);
                  if (!imagesResult.success) {
                    setSavingData(false);
                    return toast.error(imagesResult.error);
                  }
                  const imagesUpdateResult = await updateWorldImages(
                    worldId as string,
                    imagesResult.data.filter((url) =>
                      updateClone.showcase.includes(url)
                    )
                  );
                  if (!imagesUpdateResult.success) {
                    setSavingData(false);
                    return toast.error(imagesUpdateResult.error);
                  }

                  updateSet.showcase = updateClone.showcase;
                }

                const updateResult = await updateWorldLinks(
                  worldId as string,
                  updateSet
                );
                if (!updateResult.success) {
                  setSavingData(false);
                  return toast.error(updateResult.error);
                }

                delete updateClone.links;
              }
              if (Object.keys(updateClone).length > 0) {
                await axios.patch('/world', {
                  id: worldId,
                  ...updateClone,
                });
              }
              //reset();
              setSavingData(false);
              navigate('/creations/worlds');
            } catch (e) {
              setSavingData(false);
              toast.error(`Error: ${processError(e)}`);
            }
          }}
          disabled={savingData}
          className={styles.saveButton}
        >
          <span>
            <CheckIcon />
            {mutation ? 'Save & Close' : 'close'}
          </span>
          {savingData && <Loader scale={0.6} className={styles.buttonLoader} />}
        </Button>
      </OverlayHeader>
      <div
        className={`${commonStyles.splitRoot} ${
          state.loading && commonStyles.splitLoading
        } ${state.error && !state.loading && commonStyles.splitError}`}
      >
        {!state.loading && !state.error && (
          <>
            <aside className={commonStyles.splitAside}>
              <figure className={styles.worldPreview}>
                <figure
                  className={styles.worldIcon}
                  style={{
                    backgroundImage: `url(${state.data?.iconUrl})`,
                  }}
                >
                  {state.data.iconUrl === '' && (
                    <GlobeIcon className={styles.defaultIcon} />
                  )}
                </figure>
                <figcaption className={styles.worldDetails}>
                  <h3 className={styles.worldTitle}>{state.data?.title}</h3>
                  <span
                    className={`${styles.worldVisibility} ${
                      !state.data?.published && styles.worldVisibilityPrivate
                    }`}
                  >
                    {state.data?.published ? 'published' : 'draft'}
                  </span>
                </figcaption>
              </figure>
              <nav className={commonStyles.splitAsideNav}>
                <NavLink
                  to={`/creations/worlds/${worldId}`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                  end
                >
                  Details
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/showcase`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Showcase
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/access`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Access
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/iaps`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  In-World Purchases
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/sales`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Sales
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/hosting`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Hosting
                </NavLink>
                <NavLink
                  to={`/creations/worlds/${worldId}/config`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Config
                </NavLink>
                <div
                  className={`${commonStyles.splitAsideNavLink} ${commonStyles.splitAsideNavLinkDisabled}`}
                >
                  Analytics
                  <span>coming soon</span>
                </div>
                <div
                  className={`${commonStyles.splitAsideNavLink} ${commonStyles.splitAsideNavLinkDisabled}`}
                >
                  Data
                  <span className={commonStyles.splitAsideNavLinkTag}>
                    coming soon
                  </span>
                </div>
              </nav>
              <Button
                variant="tomato"
                className={styles.floatButton}
                disabled={savingData}
                onClick={handleDeleteWorld}
              >
                <TrashIcon /> delete world
              </Button>
            </aside>
            <main className={`${commonStyles.splitContent}`}>
              <Outlet
                context={
                  {
                    world: state.data as WorldExtended,
                    update,
                    setUpdate,
                    setUpdateField,
                    savingData,
                    getField,
                    fetchWorld,
                    setSavingData,
                  } as OutletContext
                }
              />
            </main>
          </>
        )}
        {state.loading && <Loader scale={1.5} />}
        {!state.loading && state.error && (
          <div className={commonStyles.splitErrorMessage}>
            {state.errorMessage}
          </div>
        )}
      </div>
    </Overlay>
  );
}
