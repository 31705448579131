/*
eslint-disable import/no-extraneous-dependencies
*/

import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import useDeepState from './useDeepState';

import axios from 'axios';
import { processError } from '../utils'; // @ts-ignore
import { rgbToHsl } from '../utils';

import type { Unreal } from '..';

import ColorThief from 'colorthief';

const REFETCH_BALANCE_COOLDOWN = 30; // 30 seconds

export interface UserData {
  email: string;
  username: string;
  usernameId: string;
  iconUrl?: string;
  iconColor: string | '';
  emailVerified: boolean;
  bio?: string;
  country?: string;
}

export interface UserContextType {
  isLoading: boolean;
  isLoggedIn: boolean;
  userId: string;
  currentUser: UserData | null;
  authToken: string;
  error: string;
  login: (email: string, password: string) => Promise<true | string>;
  logout: () => void;
  balance: number | 0;
  fetchBalance: () => Promise<void>;
  fetchUser: () => Promise<void>;
  setSmartBalanceChecker: (value: boolean) => void;
}

// Create a context to hold the current user's state
const UserContext = createContext<UserContextType>({
  isLoading: true,
  isLoggedIn: false,
  userId: '',
  currentUser: null,
  authToken: '',
  error: '',
  login: () => Promise.resolve('Currently loading'),
  logout: () => {},
  balance: 0,
  fetchBalance: () => Promise.resolve(),
  fetchUser: () => Promise.resolve(),
  setSmartBalanceChecker: () => {},
});

// Create a custom hook to access the user context
export function useUser() {
  return useContext(UserContext);
}

// Create a provider component that stores the current user's state
export function UserProvider({ children }: { children: React.ReactNode }) {
  const [userId, setUserId] = useState('');
  const [currentUser, setCurrentUser] = useState<UserData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [authToken, setAuthToken] = useState('');
  const [error, setError] = useState('');
  const { getDeepState } = useDeepState();

  const [balance, setBalance] = useState(0);

  const fetchUser = async (token?: string) => {
    const r = await axios.get(
      (process.env.REACT_APP_API_URL || 'http://localhost:8080/v1') + '/user/',
      typeof token === 'string' && token !== ''
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {}
    );

    setUserId(r.data.payload.id);
    setCurrentUser({
      email: r.data.payload.email,
      username: r.data.payload.username,
      usernameId: r.data.payload.usernameId,
      iconUrl: r.data.payload.icon_url,
      iconColor: '',
      emailVerified: r.data.payload.verified,
      bio: r.data.payload.bio,
      country: r.data.payload.country,
    });

    if (r.data.payload.icon_url !== '') {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = `https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=${encodeURIComponent(
        r.data.payload.icon_url
      )}`;
      image.onload = () => {
        try {
          const colorThief = new ColorThief();
          const colorRgb = colorThief.getColor(image);
          const hslColor = rgbToHsl(colorRgb[0], colorRgb[1], colorRgb[2]);
          if (hslColor[2] > 0.85) hslColor[2] = 0.85;
          if (hslColor[2] < 0.3) hslColor[2] = 0.3;
          setCurrentUser(
            (u) =>
              ({
                ...u,
                iconColor: `hsl(${hslColor[0] * 360}, ${hslColor[1] * 100}%, ${
                  hslColor[2] * 100
                }%)`,
              } as any)
          );
        } catch (e) {}
      };
    }
  };

  const fetchBalance = async (token?: string) => {
    try {
      const r = await axios.get(
        '/payment/wallet/',
        typeof token === 'string' && token !== ''
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {}
      );

      if (
        typeof r.data.payload === 'object' &&
        r.data.payload !== null &&
        typeof r.data.payload.balance === 'number'
      ) {
        setBalance(false ? 901290 : r.data.payload.balance);
      } else throw new Error('Invalid balance', r.data);
    } catch (e) {
      console.error('Unable to get balance:', processError(e));
    }
  };

  const [smartBalanceCheckerEnabled, setSmartBalanceCheckerEnabled] =
    useState(false);
  const lastSmartBalanceCheck = useRef<number>(0);
  useEffect(() => {
    if (!smartBalanceCheckerEnabled) return;

    const onVisibilityChange = () => {
      if (document.hidden) return;
      if (
        lastSmartBalanceCheck.current + REFETCH_BALANCE_COOLDOWN >
        Math.floor(Date.now() / 1000)
      )
        return;

      fetchBalance();
      lastSmartBalanceCheck.current = Math.floor(Date.now() / 1000);
    };
    window.addEventListener('visibilitychange', onVisibilityChange);

    return () =>
      window.removeEventListener('visibilitychange', onVisibilityChange);
  }, [smartBalanceCheckerEnabled]);

  const getAuthToken = async () => {
    const unreal = getDeepState<Unreal>('unreal');
    if (unreal.exists) {
      try {
        const token = await unreal.binding.getauthtoken();
        console.log('Got auth token from Unreal:', token);
        return token;
      } catch (e) {
        console.error('Unable to get auth token from Unreal:', e);
        return '';
      }
    }
    const token = localStorage.getItem('authenticationToken');
    if (typeof token !== 'string') return '';
    return token ?? '';
  };

  useEffect(() => {
    setError('');
    setIsLoading(true);

    getAuthToken()
      .then((t) => {
        console.log('getAuthToken', !t ? null : t.length);
        setAuthToken(t);
        if (t === '') {
          setIsLoading(false);
          return;
        }

        Promise.all([fetchUser(t), fetchBalance(t)])
          .catch((e) => console.error(processError(e)))
          .finally(() => setIsLoading(false));
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);

  // Log in the user by setting the current user state
  async function login(
    email: string,
    password: string
  ): Promise<true | string> {
    setError('');
    if (isLoading) return 'Currently loading';

    setIsLoading(true);
    try {
      const resp = await axios.post(
        process.env.REACT_APP_AUTH_API_URL + '/v1/auth',
        // const resp = await axios.post('https://api.helixmetaverse.com/v1/auth', {
        {
          email,
          password,
        }
      );

      if (resp.data.code !== 0) {
        setIsLoading(false);
        return processError(resp.data);
      }

      const token = resp.data.payload.authorization;
      setAuthToken(token);

      localStorage.setItem('authenticationToken', token);

      await Promise.all([fetchUser(token), fetchBalance(token)]);
    } catch (err: unknown) {
      setIsLoading(false);
      const errorMesage = processError(err);
      setError(errorMesage);
      console.log(err);
      return errorMesage;
    }

    setIsLoading(false);
    return true;
  }

  // Log out the user by clearing the current user state
  function logout() {
    setCurrentUser(null);
    setAuthToken('');
    setUserId('');
    setBalance(0);
    localStorage.removeItem('authenticationToken');
  }

  // Provide the user context and functions to children components
  return (
    <UserContext.Provider
      value={{
        isLoading,
        userId,
        isLoggedIn: userId !== '',
        currentUser,
        authToken,
        error,
        login,
        logout,
        balance,
        fetchBalance,
        fetchUser,
        setSmartBalanceChecker: setSmartBalanceCheckerEnabled,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
