import styled from 'styled-components';

export const H1Compo = styled.h1`
  margin: 0;
  font-weight: 900;
  font-size: 80px;
  line-height: 80px;
  color: #ededed;
`;

export const H2Compo = styled.h2`
  margin: 0;
  font-weight: 900;
  font-size: 56px;
  line-height: 56px;
  color: #ededed;
`;

export const H3Compo = styled.h3`
  margin: 0;
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  //font-size: 48px;
  //line-height: 56px;
  color: #ededed;
`;

export const H4Compo = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  color: #ededed;
`;

export const H5Compo = styled.h5`
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #ededed;
`;

export const H6Compo = styled.h6`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #ededed;
`;

export const HintCompo = styled.p`
  margin-top: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #a0a0a0;
`;
