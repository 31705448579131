import axios from 'axios';

export async function sendPageClip(
  apiKey: string,
  formName: string,
  data: Record<string, string | number | boolean | string[]>
): Promise<{ success: boolean }> {
  try {
    const result = await axios.put(
      `https://api.pageclip.co/data/${formName}`,
      data,
      {
        auth: {
          username: apiKey,
          password: '',
        },
        headers: {
          accept: 'application/vnd.pageclip.v1+json',
        },
      }
    );

    if (
      typeof result.data.errors === 'object' &&
      result.data.errors !== null &&
      result.data.errors.length > 0
    )
      return {
        success: false,
      };
    return { success: true };
  } catch (e) {
    return { success: false };
  }
}

export const sendNewsletterPageClip = sendPageClip.bind(
  null,
  process.env.REACT_APP_PAGECLIP_API_KEY as string,
  process.env.REACT_APP_PAGECLIP_NEWSLETTER_FORM as string
);
