import React, { useEffect, useState } from 'react';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useActiveController from '../../hook/useActiveController';
import { useUser } from '../../hook/useUser';
import usePageConfig from '../../hook/usePageConfig';

import type { User } from '../../api/user';

import styles from './Search.module.css';
import commonStyles from '../common.module.css';
import {
  ItemCategory,
  ItemCategoryName,
  ItemCategoryPlural,
  ItemCategoryPluralReverse,
} from '../../type/Item';
import { getUser } from '../../api/user';
import { toast } from 'react-hot-toast';

import Input from '../../Comps/Input';
import Button from '../../Comps/Button';
import Select from '../../Comps/Select';
import { Toggle } from '../../Comps/Checkbox';
import Loader from '../../Comps/Loader';
import TagInput from '../../Comps/TagInput';

import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as DescendingIcon } from '../../assets/icons/descendingIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendarIcon.svg';
import { ReactComponent as PlayersIcon } from '../../assets/icons/playersIcon.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/crossIcon.svg';

export type SearchFilter = {
  tags: string[];
  creator: string | '';
  maxPrice: number | -1;
  minDiscount: number | 0;
  packsOnly: boolean;
  query: string | '';
};

type CreatorState =
  | {
      exists: false;
    }
  | {
      exists: true;
      loading: true;
    }
  | {
      exists: true;
      loading: false;
      state: User;
    };

export type OutletContext = {
  searchFilter: SearchFilter;
  setSearchFilter: React.Dispatch<React.SetStateAction<SearchFilter>>;
  category: ItemCategory | '';
  creatorState: CreatorState;
};

const getIntegerSearchParam = (
  value: null | string | number,
  defaultValue = 0
): number => {
  if (value === null || value === '') return defaultValue;
  if (typeof value === 'number') return isNaN(value) ? defaultValue : value;
  if (isNaN(parseInt(value))) return defaultValue;
  return parseInt(value);
};
const getBooleanSearchParam = (
  value: null | string | boolean,
  defaultValue = false
): boolean => {
  if (value === null || value === '') return defaultValue;
  if (typeof value === 'boolean') return value;
  if (value === 'true' || value === '1' || value === 'yes') return true;
  if (value === 'false' || value === '0' || value === 'no') return false;
  return defaultValue;
};
const getArraySearchParam = (value: null | string): string[] => {
  if (value === null || value === '') return [];
  const arr = value.split(' ').filter((e) => e.trim() !== '');
  if (arr.length === 0) return [];
  return arr;
};

export default function ExchangeSearchLayout() {
  const navigate = useNavigate();

  const { category: categoryParam } = useParams<{
    category: string | undefined;
  }>();
  const categoryName =
    typeof categoryParam !== 'string' || categoryParam === ''
      ? 'all'
      : (Object.values(ItemCategory) as string[]).includes(
          categoryParam as string
        )
      ? categoryParam
      : (Object.keys(ItemCategoryPlural) as string[]).includes(categoryParam) // @ts-ignore
      ? ItemCategoryPlural[categoryParam]
      : '';
  const category = categoryName === 'all' ? '' : categoryName.toLowerCase();
  useEffect(() => {
    if (categoryName === '') {
      console.log('Unknown category');
      //navigate('/exchange/search', { replace: true })
      setTimeout(() => navigate('/exchange/search', { replace: true }), 500); // react-router-dom bug as it does not change the url if the component is not different
    }
  }, [categoryName]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    tags: getArraySearchParam(searchParams.get('tags')),
    creator: searchParams.get('creator') || '',
    maxPrice: getIntegerSearchParam(searchParams.get('max_price'), -1),
    minDiscount: getIntegerSearchParam(searchParams.get('min_discount'), 0),
    packsOnly: getBooleanSearchParam(searchParams.get('packs_only'), false),
    query: searchParams.get('query') || '',
  });

  useEffect(() => {
    const clone = new URLSearchParams(searchParams);

    if (searchFilter.tags.length === 0) clone.delete('tags');
    else clone.set('tags', searchFilter.tags.join(' '));

    if (searchFilter.creator === '') clone.delete('creator');
    else clone.set('creator', searchFilter.creator);

    if (searchFilter.maxPrice === -1) clone.delete('max_price');
    else clone.set('max_price', searchFilter.maxPrice.toString());

    if (searchFilter.minDiscount === 0) clone.delete('min_discount');
    else clone.set('min_discount', searchFilter.minDiscount.toString());

    if (searchFilter.packsOnly === false) clone.delete('packs_only');
    else clone.set('packs_only', searchFilter.packsOnly.toString());

    if (searchFilter.query === '') clone.delete('query');
    else clone.set('query', searchFilter.query);

    setSearchParams(clone);
  }, [searchFilter]);

  useEffect(() => {
    const creator = searchParams.get('creator') || '';
    if (searchFilter.creator === creator) return;
    setSearchFilter((prev) => ({
      ...prev,
      creator,
    }));
  }, [searchParams]);

  const { isLoading, isLoggedIn } = useUser();
  const [creatorState, setCreatorState] = useState<CreatorState>({
    exists: false,
  });
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    if (searchFilter.creator === '') return setCreatorState({ exists: false });
    setCreatorState({ exists: true, loading: true });

    const abortController = new AbortController();
    getUser(searchFilter.creator, abortController.signal).then(
      ({ success, error, exists, data }) => {
        if (!success) {
          toast.error(error);
          return setCreatorState({ exists: false });
        }

        if (!exists) {
          setCreatorState({ exists: false });
          return setSearchFilter((prev) => ({
            ...prev,
            creator: '',
          }));
        }

        setCreatorState({ exists: true, loading: false, state: data });
      }
    );

    return () => abortController.abort();
  }, [isLoading, searchFilter.creator, isLoggedIn]);

  const persistQueryString = () =>
    searchParams.toString() === '' ? '' : `?${searchParams.toString()}`;

  const [searchFocused, setSearchFocused] = useState(
    getBooleanSearchParam(searchParams.get('autoFocus'), false)
  );

  const creatorController = useActiveController(creatorState.exists, 300);

  usePageConfig({
    title: `${
      searchFilter.query !== ''
        ? 'Search'
        : category === ''
        ? 'All Assets'
        : // @ts-ignore
          `${ItemCategoryName[category]}`
    } | HELIX Exchange`,
  });

  return (
    <div className={commonStyles.splitRoot}>
      <aside className={`${commonStyles.splitAside} ${styles.filter}`}>
        <nav
          className={`${commonStyles.splitAsideNav} ${styles.filterCategoryList}`}
        >
          <Button
            className={`${commonStyles.splitAsideNavLink} ${
              category === '' && commonStyles.splitAsideNavLinkActive
            } ${styles.filterCategory}`}
            onClick={() => navigate(`/exchange/search${persistQueryString()}`)}
          >
            all assets
          </Button>
          {Object.values(ItemCategory).map((categoryType) => (
            <Button
              key={categoryType}
              className={`${commonStyles.splitAsideNavLink} ${
                category === categoryType &&
                commonStyles.splitAsideNavLinkActive
              } ${styles.filterCategory}`}
              onClick={() =>
                navigate(
                  `/exchange/search/${
                    ItemCategoryPluralReverse[categoryType]
                  }${persistQueryString()}`
                )
              }
            >
              {ItemCategoryName[categoryType]}
            </Button>
          ))}
        </nav>
        <figure className={styles.filterDivider} />
        <figure className={styles.filterGroup}>
          <h3 className={styles.filterGroupTitle}>Tags</h3>
          <TagInput
            placeholder="Search tags"
            style={{ width: '100%' }}
            theme="yellow"
            onChange={(value) =>
              setSearchFilter((prev) => ({
                ...prev,
                tags: value as string[],
              }))
            }
            value={searchFilter.tags}
          />
        </figure>
        {creatorController.view && (
          <div
            className={`${styles.creatorWrapper} ${
              creatorController.animate && styles.creatorWrapperActive
            }`}
          >
            <figure className={styles.filterDivider} />
            <figure className={styles.filterGroup}>
              <h3 className={styles.filterGroupTitle}>Creator</h3>
              <figure className={styles.creator}>
                {creatorState.exists && creatorState.loading && (
                  <Loader scale={0.5} className={styles.creatorLoader} />
                )}
                {creatorState.exists && !creatorState.loading && (
                  <>
                    <figure
                      className={styles.creatorIcon}
                      style={{
                        backgroundImage: `url(${creatorState.state.icon_url})`,
                      }}
                    />
                    <figcaption className={styles.creatorName}>
                      {creatorState.state.username}
                    </figcaption>
                  </>
                )}
                <Button
                  variant="yellow"
                  className={styles.creatorButton}
                  onClick={() =>
                    setSearchFilter((prev) => ({
                      ...prev,
                      creator: '',
                    }))
                  }
                >
                  <CrossIcon />
                </Button>
              </figure>
            </figure>
          </div>
        )}
        {/*<figure className={styles.filterDivider} />
        <figure className={styles.filterGroup}>
          <h3 className={styles.filterGroupTitle}>
            Max Price
            <span className={styles.filterGroupTitleLabel}>
              <LIXIcon /> LIX
            </span>
          </h3>
          <figure className={styles.filterGroupButtons}>
            {[0, 100, 250, 500, 1000, 2000, 3000, 5000, 10000].map((price) => (
              <button
                className={`${styles.filterGroupButton} ${
                  searchFilter.maxPrice === price &&
                  styles.filterGroupButtonActive
                }`}
                key={price}
                onClick={() =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    maxPrice: prev.maxPrice === price ? -1 : price,
                  }))
                }
              >
                {price === 0 ? 'Free' : price.toLocaleString()}
              </button>
            ))}
          </figure>
              </figure>*/}
        {/*<figure className={styles.filterDivider} />
        <figure className={styles.filterGroup}>
          <h3 className={styles.filterGroupTitle}>Discount</h3>
          <figure className={styles.filterGroupButtons}>
            {[0, 10, 30, 50, 70, 90].map((discount) => (
              <button
                className={`${styles.filterGroupButton} ${
                  searchFilter.minDiscount === discount &&
                  styles.filterGroupButtonActive
                }`}
                key={discount}
                onClick={() =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    minDiscount: discount,
                  }))
                }
              >
                {discount === 0 ? 'Any' : `${discount}%`}
              </button>
            ))}
          </figure>
              </figure>*/}
      </aside>
      <article className={commonStyles.splitContent}>
        <header className={styles.header}>
          <figure className={styles.headerTitle}>
            <button
              className={styles.backButton}
              onClick={() => navigate('/exchange')}
            >
              <ArrowIcon />
            </button>
            <h1 className={styles.title}>
              {category === ''
                ? 'All Assets'
                : // @ts-ignore
                  ItemCategoryName[category]}
            </h1>
          </figure>
          <figure className={`${commonStyles.row} ${styles.headerTools}`}>
            {/*<figure className={commonStyles.toggleGroup}>
              <Toggle />
              <figcaption
                className={commonStyles.toggleGroupLabel}
                style={{ color: '#A0A0A0' }}
              >
                Packs only
              </figcaption>
            </figure>
            <Select
              style={{ width: 180 }}
              value={0}
              options={[
                {
                  value: 0,
                  label: (
                    <>
                      <DescendingIcon />
                      Trending first
                    </>
                  ),
                },
                {
                  value: 1,
                  label: (
                    <>
                      <LIXIcon /> Cheapest
                    </>
                  ),
                },
                {
                  value: 2,
                  label: (
                    <>
                      <CalendarIcon /> Newest
                    </>
                  ),
                },
                {
                  value: 3,
                  label: (
                    <>
                      <PlayersIcon />
                      Best selling
                    </>
                  ),
                },
              ]}
            />*/}
            <Input
              placeholder={`Search ${
                category === ''
                  ? 'assets' // @ts-ignore
                  : ItemCategoryName[category].toLowerCase()
              }`}
              icon={<SearchIcon />}
              className={`${styles.searchBar} ${
                searchFocused && styles.searchBarActive
              }`}
              autoFocus={getBooleanSearchParam(
                searchParams.get('autoFocus'),
                false
              )}
              onFocus={() => setSearchFocused(true)}
              onBlur={() => setSearchFocused(false)}
              onChange={(e) =>
                setSearchFilter((prev) => ({
                  ...prev,
                  query: e.target.value,
                }))
              }
              value={searchFilter.query}
            />
          </figure>
        </header>
        <Outlet
          context={
            {
              searchFilter,
              setSearchFilter,
              category,
              creatorState,
            } as OutletContext
          }
        />
        
      </article>
    </div>
  );
}
