import useQuickChart from '../../hook/useQuickChart';
import { useEffect, useRef, useState } from 'react';
import { useUser } from '../../hook/useUser';
import usePageConfig from '../../hook/usePageConfig';

import type { Transaction } from '../../api/wallet';

import commonStyles from '../common.module.css';
import styles from './styles.module.css';
import { toast } from 'react-hot-toast';
import { getWalletTransactions } from '../../api/wallet';

import Input from '../../Comps/Input';
import Table, { TableDisplay } from '../../Comps/Table';
import Button from '../../Comps/Button';
import Select from '../../Comps/Select';
import Loader from '../../Comps/Loader';

import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/walletIcon.svg';
import { ReactComponent as BookIcon } from '../../assets/icons/bookIcon.svg';
import { ReactComponent as CalendarIcon } from '../../assets/icons/calendarIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warningIcon.svg';

const NOT_ENOUGH_DATA_THRESHOLD = 25; // if greater than this percent of days have no data, blur the graph

const quickChartOptions = {
  title: {
    display: false,
  },
  layout: {
    padding: {
      top: 1,
    },
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: '#2E2E2E',
          lineWidth: 1,
          drawBorder: false,
          drawTicks: false,
          offsetGridLines: false,
          zeroLineWidth: 1,
          zeroLineColor: '#2E2E2E',
        },
        ticks: {
          display: false,
          maxTicksLimit: 3,
        },
      },
    ],
  },
};

type StateTransaction = {
  id: string;
  name: React.ReactNode;
  nameIcon: string | '';
  change: number;
  type: {
    value: string;
    style: {
      color: string;
      background: string;
    };
  };
  time: string;
};

type State =
  | {
      loading: true;
      error: false;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      saleCount: number;
      saleChange: number;
      timeline: {
        range: [string, string];
        plot: number[];
        blur: boolean;
      };
      transactions: StateTransaction[];
    };

const getUnixTimestamp = (dateString: string): number =>
  Math.floor(new Date(dateString).getTime() / 1000);

enum Period {
  Day = 1,
  Week = 7,
  Month = 30,
  Quarter = 90,
}

export default function SalesPage() {
  usePageConfig({ title: 'Sales | HELIX Hub' });
  const { isLoading, balance, isLoggedIn } = useUser();

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
  });
  const [period, setPeriod] = useState<Period>(Period.Month);

  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    const abortController = new AbortController();

    const end = new Date();
    const begin = new Date(Date.now() - 1000 * 60 * 60 * 24 * period);

    getWalletTransactions(
      new Date(begin).toISOString(),
      new Date(end).toISOString(),
      20,
      abortController.signal
    ).then(({ success, error, data }) => {
      if (!success)
        return setState({
          loading: false,
          error: true,
          errorMessage: error,
        });
      if (data.length === 0)
        return setState({
          loading: false,
          error: true,
          errorMessage: 'No transactions found',
        });

      const transactions = data.sort(
        (a: Transaction, b: Transaction) =>
          getUnixTimestamp(b.created_at) - getUnixTimestamp(a.created_at)
      ); // this sorts the dates in descending order
      const rangeStart =
        Math.floor(begin.getTime() / 1000 / (60 * 60 * 24)) * 60 * 60 * 24;
      const rangeEnd =
        Math.floor(end.getTime() / 1000 / (60 * 60 * 24)) * 60 * 60 * 24;

      type PlotPoint = {
        dateString: string;
        startTime: number;
        endTime: number;
        change: number;
      };

      let totalSales = 0,
        totalChange = 0;

      const changes: StateTransaction[] = [],
        plot: PlotPoint[] = [];

      for (let i = rangeStart; i <= rangeEnd; i += 60 * 60 * 24) {
        const date = new Date(i * 1000);
        plot.push({
          dateString: date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
          }),
          startTime: i,
          endTime: i + 60 * 60 * 24,
          change: 0,
        });
      }

      const TRANSACTION_TYPE = {
        MARKETPLACE: 'Marketplace sale',
        REWARD: 'Reward granted',
        PURCHASED: 'LIX Purchase',
        UNKNOWN: 'Unknown',
        DEX_CREDIT: 'Exchange Sale',
        DEX_DEBIT: 'Exchange Purchase',
      };

      const TRANSACTION_TYPE_STYLE = {
        MARKETPLACE: { background: '#132C3F', color: '#63C5EA' },
        REWARD: { background: '#1C2F1F', color: '#7BBE7B' },
        PURCHASED: {
          background: '#3B1813',
          color: '#F16A50',
        },
        UNKNOWN: {
          background: '#3B1813',
          color: '#F16A50',
        },
        DEX_DEBIT: { background: '#132C3F', color: '#63C5EA' },
        DEX_CREDIT: { background: '#1C2F1F', color: '#7BBE7B' },
      };

      transactions.forEach((transaction) => {
        const transactionType: any =
          transaction.origin === 'DEX'
            ? `DEX_${transaction.direction}`
            : typeof TRANSACTION_TYPE[transaction.origin] === 'string'
            ? transaction.origin
            : 'UNKNOWN';

        changes.push({
          id: `${transaction.receipt}`,
          type: {
            // @ts-ignore
            value: TRANSACTION_TYPE[transactionType],
            // @ts-ignore
            style: TRANSACTION_TYPE_STYLE[transactionType],
          },
          change:
            transaction.balance * (transaction.direction === 'CREDIT' ? 1 : -1),
          name:
            transaction.origin === 'PURCHASED'
              ? 'HELIX LIX Store'
              : transaction.metadata?.title ?? 'Unknown',
          nameIcon: transaction.metadata?.icon_url ?? '',

          time: new Date(transaction.created_at).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          }),
        });

        if (
          transaction.origin === 'MARKETPLACE' ||
          (transaction.origin === 'DEX' && transaction.direction === 'CREDIT')
        ) {
          totalSales++;
          totalChange += transaction.balance;

          const plotPoint = plot.find(
            (e) =>
              e.startTime <=
                new Date(transaction.created_at).getTime() / 1000 &&
              e.endTime > new Date(transaction.created_at).getTime() / 1000
          );
          if (plotPoint) plotPoint.change += transaction.balance;
        }
      });

      const shouldBlurGraph =
        plot.filter((day) => day.change).length / plot.length <=
        NOT_ENOUGH_DATA_THRESHOLD / 100;

      setState({
        loading: false,
        error: false,
        saleCount: totalSales,
        saleChange: totalChange,
        timeline: {
          range: [
            new Date(rangeStart * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit',
            }),
            new Date(rangeEnd * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: '2-digit',
            }),
          ],
          plot: !shouldBlurGraph
            ? plot.map((day) => day.change)
            : new Array(30)
                .fill(0)
                .map((_, i) => 300 + Math.abs(((i + 20) % 20) - 10) * 100),
          blur: shouldBlurGraph,
        },
        transactions: changes,
      });
    });

    return () => abortController.abort();
  }, [isLoading, isLoggedIn, period]);

  const quickChartRef = useRef(null);
  const quickChart = useQuickChart(
    !state.loading && !state.error,
    quickChartRef
  );

  return (
    <div className={styles.root}>
      {state.loading && <Loader className={styles.rootLoader} scale={1.5} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Sales</h1>
          <figure className={`${commonStyles.rowFull} ${styles.header}`}>
            <div className={styles.headerWrapper}>
              <p className={styles.headerTitle}>total lix balance</p>
              <h2
                className={`${commonStyles.row} ${commonStyles.splitContentTitle} ${styles.headerStat}`}
              >
                <LIXIcon />
                {balance.toLocaleString()}
              </h2>
              {/*<p className={styles.headerSubtitle}>
            ~${(5032.32).toLocaleString()} USD
  </p>*/}
            </div>
            <div className={styles.headerActions}>
              <Button
                variant="yellow"
                disabled
                onClick={() => toast.error('Coming soon')}
              >
                <WalletIcon />
                cash out
              </Button>
              {/*<p className={styles.headerActionsSubtitle}>
            You must have at least {(50000).toLocaleString()} LIX and verified
            your account to withdrawl.
</p>*/}
              {/*<Button variant="invisible">
            <BookIcon /> learn more
  </Button>*/}
            </div>
          </figure>
          <figure className={commonStyles.rowFull}>
            <h1 className={commonStyles.splitContentTitle}>By period</h1>
            <figure className={commonStyles.row} style={{ gap: 12 }}>
              <Input
                value={`${state.timeline.range.join(' - ')}`}
                disabled
                style={{ width: 180 }}
              />
              <Select
                icon={<CalendarIcon />}
                options={[
                  { value: Period.Day, label: 'Last 24 hours' },
                  { value: Period.Week, label: 'Last week' },
                  {
                    value: Period.Month,
                    label: 'Last month',
                  },
                  {
                    value: Period.Quarter,
                    label: 'Last quarter',
                  },
                ]}
                onChange={(e) => setPeriod(e as Period)}
                style={{ width: 180 }}
                value={period}
                placeholder="Select a time period"
              />
            </figure>
          </figure>
          <figure className={styles.statWrapper}>
            <aside className={styles.stats}>
              <figure className={styles.stat}>
                <p className={styles.statSubtitle}>Sale amount</p>
                <h4
                  className={`${commonStyles.row} ${commonStyles.splitContentTitle} ${styles.statTitle}`}
                >
                  <LIXIcon />
                  {state.saleChange.toLocaleString()}
                </h4>
              </figure>
              <figure className={styles.stat}>
                <p className={styles.statSubtitle}>Sale count</p>
                <h4
                  className={`${commonStyles.row} ${commonStyles.splitContentTitle} ${styles.statTitle}`}
                >
                  {state.saleCount.toLocaleString()}
                </h4>
              </figure>
            </aside>
            <figure className={styles.statFigure}>
              <figure
                className={`${styles.statFigureImage} ${
                  state.timeline.blur && styles.statFigureBlur
                }`}
                ref={quickChartRef}
              >
                {state.timeline.blur && (
                  <figure className={styles.statFigureOverlay}>
                    <WarningIcon />
                    Not enough data
                  </figure>
                )}
                <picture
                  style={{
                    backgroundImage: `url('${quickChart(
                      'bar',
                      {
                        datasets: [
                          {
                            label: 'Dataset 1',

                            data: state.timeline.plot,
                            backgroundColor: '#68DDFD',
                            fill: false,
                            spanGaps: false,
                            lineTension: 0.4,
                            pointStyle: 'circle',
                            borderDash: [0, 0],
                            barPercentage: 0.4,
                            categoryPercentage: 0.8,
                            borderColor: 'rgba(0, 0, 0, 0)',
                            borderWidth: 0,
                            hidden: false,
                          },
                        ],
                        labels: new Array(state.timeline.plot.length).fill(''),
                      },
                      quickChartOptions
                    )}')`,
                  }}
                />
              </figure>
              <figure
                className={`${commonStyles.rowFull} ${styles.statFigureCaption}`}
              >
                <figcaption>{state.timeline.range[0]}</figcaption>
                <figcaption>{state.timeline.range[1]}</figcaption>
              </figure>
            </figure>
          </figure>
          <figure className={`${commonStyles.rowFull} ${styles.tableHeader}`}>
            <h1 className={commonStyles.splitContentTitle}>Transactions</h1>
            {/*<Input
              placeholder="Search items"
              icon={<SearchIcon />}
              style={{ width: 180 }}
              />*/}
          </figure>
          <Table
            header={[
              { value: 'name', width: 'minmax(38px, 1fr)' },
              { value: 'date' },
              { value: 'type', widthBuffer: 10 + 10 },
              { value: 'change', widthBuffer: 20 + 8 },
            ]}
            rows={state.transactions.map((transaction) => [
              {
                value: '',
                display: (
                  <TableDisplay className={styles.infoCell} key="name">
                    {transaction.nameIcon && (
                      <figure
                        className={styles.infoIcon}
                        style={{
                          backgroundImage: `url('${transaction.nameIcon}')`,
                        }}
                      />
                    )}
                    {transaction.name}
                  </TableDisplay>
                ),
              },
              {
                value: transaction.time,
              },
              {
                value: transaction.type.value,
                display: (
                  <TableDisplay key="type">
                    <span
                      className={styles.label}
                      style={transaction.type.style}
                    >
                      {transaction.type.value}
                    </span>
                  </TableDisplay>
                ),
              },
              {
                value: transaction.change.toLocaleString(),
                display: (
                  <TableDisplay
                    key="change"
                    className={styles.priceCell}
                    style={{
                      color: transaction.change > 0 ? '#A5D34D' : '#f63131',
                    }}
                  >
                    <LIXIcon />
                    {transaction.change > 0 ? '+' : ''}
                    {transaction.change.toLocaleString()}
                  </TableDisplay>
                ),
              },
            ])}
          />
        </>
      )}
    </div>
  );
}
