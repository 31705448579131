import axios from 'axios';
const API_DATA_URL =
  process.env.REACT_APP_GRAPHQL_URL || 'http://localhost:8080/graphql';

const client = axios.create({
  baseURL: API_DATA_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-hasura-admin-secret':
      process.env.REACT_APP_GRAPHQL_ADMIN_SECRET || undefined,
  },
});

export async function graphQLRequest(query: string, variables: any) {
  try {
    const resp = await client.post<any>(
      API_DATA_URL,
      {
        query,
        variables,
      },
      {}
    );
    if (resp.data.errors) {
      console.log('graphql error:', resp.data.errors);
      return null;
    }
    return resp.data.data;
  } catch (e) {
    console.log('graphql error: ' + e);
  }
  return null;
}
