import styles from './styles.module.css';

export function Toggle({
  className = '',
  checked = false,
  disabled = false,
  onChange = () => {},
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: () => void;
}): React.ReactElement {
  return (
    <button
      className={`${styles.toggle} ${
        checked && styles.toggleActive
      } ${className}`}
      onClick={() => (disabled ? null : onChange())}
      disabled={disabled}
      {...props}
    >
      <figure className={styles.toggleHandle} />
    </button>
  );
}
