import { useEffect, useState, useMemo, useRef, Suspense } from 'react';
import { useUser } from './hook/useUser';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useActiveController from './hook/useActiveController';
import useDeepState from './hook/useDeepState';

import styles from './App.module.css';
import React from 'react';

import type { Unreal } from '.';

import AnimatedRoutes from 'react-animated-router';
import { Navigate, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast'; // eslint-disable-line import/no-extraneous-dependencies

import Loader from './Comps/Loader';
import { NavCompo } from './components/nav';
import { UserProvider } from './hook/useUser';
import Footer from './Comps/Footer';

import { ExplorePage } from './pages/Explore.page';
/*import { ItemsPage } from './pages/items';
import { StepPage } from './pages/Step.page';
import { ItemsCreateExplorePage } from './pages/items/create/ItemsCreateExplore.page';
import { ItemsCreateEditPage } from './pages/items/create/ItemsCreateEdit.page';
import { ItemsCreateDetailPage } from './pages/items/create/ItemsCreateDetail.page';
import { CompletePage } from './pages/items/Complete.page';*/

//import DeveloperVaultPage from './pages/Creations/Vault';
//import DeveloperVaultEditorPage from './pages/Creations/Vault/Editor';

import SignupPage from './pages/auth/Signup';
import RecoveryPage from './pages/auth/Recovery';
import LoginPage from './pages/auth/Login';

import VerifyPage from './pages/auth/Verify';
import EmailChangePage from './pages/Auth2/EmailChange';

import DownloadPage from './pages/Download';
import WelcomePage from './pages/Welcome';

import ExchangePage from './pages/Exchange';
import ExchangeOwnedPage from './pages/Exchange/Owned';
import ExchangeItemPage from './pages/Exchange/Asset';
import ExchangeSearchPage from './pages/Exchange/Search';

import CreationsRoot from './pages/Creations/Root';
import CreationsWorldsPage from './pages/Creations/Worlds';
import CreationsAssetsPage from './pages/Creations/Assets';
import CreationsHEXItemsPage from './pages/Creations/HEX';

import CreationsWorldsWorldManagerWrapper from './pages/Creations/Worlds/World';
import CreationsWorldsWorldManagerDetailsPage from './pages/Creations/Worlds/World/Details';
import CreationsWorldsWorldManagerShowcasePage from './pages/Creations/Worlds/World/Showcase';
import CreationsWorldsWorldManagerAccessPage from './pages/Creations/Worlds/World/Access';
import CreationsWorldsWorldManagerSalesPage from './pages/Creations/Worlds/World/Sales';
import CreationsWorldsWorldManagerHostingPage from './pages/Creations/Worlds/World/Hosting';
import CreationsWorldsWorldManagerConfigPage from './pages/Creations/Worlds/World/Config';

import CreationsWorldsWorldManagerIAPManagerPage from './pages/Creations/Worlds/World/IAPs';
import CreationsWorldsWorldManagerIAPsIAPManagerPage from './pages/Creations/Worlds/World/IAP';

import CreationsAssetsAssetManagerWrapper from './pages/Creations/Assets/Asset';
import CreationsAssetsAssetManagerDetailsPage from './pages/Creations/Assets/Asset/Details';
import CreationsAssetsAssetManagerShowcasePage from './pages/Creations/Assets/Asset/Showcase';
import CreationsAssetsAssetManagerConfigPage from './pages/Creations/Assets/Asset/Config';

import SalesPage from './pages/Sales';
import TopupPage from './pages/Topup';

import AccountDetailsPage from './pages/Account/Details';
import AccountWalletPage from './pages/Account/Wallet';
import AccountInvitesPage from './pages/Account/Invites';
import AccountAccessTokenManagementPage from './pages/Account/AccessToken';

import ServerConsolePage from './pages/Server/Console';

import setupAxiosInterceptors from './config/axios-interceptor';
import ExchangeSearchLayout from './pages/Exchange/SearchLayout';

const NO_FOOTER_ROUTE = [
  '/creations',
  '/exchange',
  '/auth',
  '/console',
  '/signup',
];

export default function AppWrapper() {
  return (
    <UserProvider>
      <Toaster
        position="bottom-center"
        toastOptions={{
          className: styles.toast,
          success: {
            className: `${styles.toast} ${styles.toastSuccess}`,
          },
          loading: {
            className: `${styles.toast} ${styles.toastLoading}`,
          },
          error: {
            className: `${styles.toast} ${styles.toastError}`,
          },
        }}
        gutter={8}
        containerStyle={{ inset: 32 }}
      />
      <App />
    </UserProvider>
  );
}

const PUBLIC_PAGES = ['/auth/login', '/auth/recovery'];

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, logout, isLoading, authToken } = useUser();
  const [lastUrl, setLastUrl] = useState<string>('/');

  const { getDeepState } = useDeepState();

  const isUnreal = useMemo(() => getDeepState<Unreal>('unreal').exists, []);

  const BYPASS_ALL = ['/auth/verify', '/auth/emailchange'];

  useEffect(() => {
    if (isUnreal) {
      if (!window.location.pathname.startsWith('/exchange'))
        return navigate('/exchange');
      return;
    }
    if (isLoading) return;
    if (BYPASS_ALL.includes(window.location.pathname)) return;

    if (
      isLoggedIn &&
      (window.location.pathname.startsWith('/signup') ||
        PUBLIC_PAGES.includes(window.location.pathname))
    ) {
      console.log('[ user ] - session valid, redirect to home');
      return navigate('/');
    }

    if (!isLoggedIn) {
      if (window.location.pathname.startsWith('/signup')) {
        setLastUrl('/');
        return console.log('on signup page, no need to redirect');
      }
      if (window.location.pathname === '/auth/recovery') {
        setLastUrl('/');
        return console.log('[ user ] - on recovery page, no need to redirect');
      }
      if (BYPASS_ALL.includes(window.location.pathname)) {
        setLastUrl('/');
        return console.log('[ user ] - BYPASS ALL PAGE, no need to redirect');
      }
      const url =
        window.location.pathname === '/auth/login'
          ? '/'
          : window.location.pathname;
      console.log(
        `[ user ] - session invalid, redirect to login, saving previous url: ${url}`
      );
      setLastUrl(url);
      navigate('/auth/login');
      console.log('not logged in, trigger login');
    } else if (lastUrl !== '/' && window.location.pathname !== '/auth/verify') {
      navigate(lastUrl);
      console.log(
        `[ user ] - session valid, user currently at login page, resuming to ${lastUrl}`
      );
    } else {
      console.log(
        `[ user ] - session valid, user has not been sent to the login page, no need to resume`
      );
    }
  }, [isLoading, isLoggedIn]);

  useEffect(() => {
    console.log('token changed', !authToken ? null : authToken.length, {
      isUnreal,
      isLoading,
    });
  }, [authToken]);

  const tokenRef = useRef<string>(authToken);
  useEffect(() => {
    tokenRef.current = authToken;
  }, [authToken]);

  useEffect(() => {
    setupAxiosInterceptors(
      () => {
        if (isUnreal) return;
        logout();
      },
      () => {
        console.log('axios requesting token:', (tokenRef.current ?? '').length);
        return tokenRef.current;
      }
    ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeController = useActiveController(isLoading, 200, false);

  return (
    <>
      {activeController.view && (
        <figure
          className={`${styles.loader} ${
            !activeController.animate && styles.loaderHide
          }`}
        >
          <Loader scale={2} />
        </figure>
      )}
      {!isUnreal && <NavCompo />}
      {isUnreal && (
        <style>{`
      :root {
        --nav-offset: 0px;
      }
      `}</style>
      )}

      {isUnreal && (
        <AnimatedRoutes className="container">
          <Route path="/exchange" element={<ExchangePage />} />
          <Route path="/exchange/owned" element={<ExchangeOwnedPage />} />
          <Route path="/exchange/item/:id" element={<ExchangeItemPage />} />
          <Route path="/exchange/search" element={<ExchangeSearchLayout />}>
            <Route path="" element={<ExchangeSearchPage />} />
            <Route path=":category" element={<ExchangeSearchPage />} />
          </Route>
        </AnimatedRoutes>
      )}
      {!isUnreal && (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            <AnimatedRoutes className="container">
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/signup/:code" element={<SignupPage />} />
              {/* Login page */}
              <Route path="/auth/login" element={<LoginPage />} />
              <Route path="/auth/recovery" element={<RecoveryPage />} />
              {/* Verification */}
              <Route path="/auth/emailchange" element={<EmailChangePage />} />
              <Route path="/auth/verify" element={<VerifyPage />} />
              {/** Web Page **/}
              <Route path="/download" element={<Navigate to="/welcome" />} />
              <Route path="/welcome" element={<WelcomePage />} />
              {/** Creator Page **/}
              {/* <Route path="/creator/items" element={<ItemsPage />} />
              <Route
                path="/creator/items/create"
                element={<StepPage step={['explore', 'edit', 'details']} />}
              >
                <Route path="explore" element={<ItemsCreateExplorePage />} />
                <Route path="edit" element={<ItemsCreateEditPage />} />
                <Route path="detail" element={<ItemsCreateDetailPage />} />
                <Route path="done" element={<CompletePage />} />
      </Route>*/}
              {/* Exchange */}
              <Route path="/exchange" element={<ExchangePage />} />
              <Route path="/exchange/owned" element={<ExchangeOwnedPage />} />
              <Route path="/exchange/item/:id" element={<ExchangeItemPage />} />
              <Route path="/exchange/search" element={<ExchangeSearchLayout />}>
                <Route path="" element={<ExchangeSearchPage />} />
                <Route path=":category" element={<ExchangeSearchPage />} />
              </Route>
              {/* Creations/Worlds Management */}
              <Route
                path="/creations/worlds/:worldId"
                element={<CreationsWorldsWorldManagerWrapper />}
              >
                <Route
                  path=""
                  element={<CreationsWorldsWorldManagerDetailsPage />}
                />
                <Route
                  path="showcase"
                  element={<CreationsWorldsWorldManagerShowcasePage />}
                />
                <Route
                  path="access"
                  element={<CreationsWorldsWorldManagerAccessPage />}
                />
                <Route
                  path="iaps"
                  element={<CreationsWorldsWorldManagerIAPManagerPage />}
                />
                <Route
                  path="iaps/:iapId"
                  element={<CreationsWorldsWorldManagerIAPsIAPManagerPage />}
                />
                <Route
                  path="sales"
                  element={<CreationsWorldsWorldManagerSalesPage />}
                />
                <Route
                  path="hosting"
                  element={<CreationsWorldsWorldManagerHostingPage />}
                />
                <Route
                  path="config"
                  element={<CreationsWorldsWorldManagerConfigPage />}
                />
                <Route path="*" element={<Navigate to="" />} />
              </Route>
              <Route
                path="/creations/assets/:assetId"
                element={<CreationsAssetsAssetManagerWrapper />}
              >
                <Route
                  path=""
                  element={<CreationsAssetsAssetManagerDetailsPage />}
                />
                <Route
                  path="showcase"
                  element={<CreationsAssetsAssetManagerShowcasePage />}
                />
                <Route
                  path="config"
                  element={<CreationsAssetsAssetManagerConfigPage />}
                />
                <Route path="*" element={<Navigate to="" />} />
              </Route>
              {/* Creations */}
              <Route path="/creations" element={<CreationsRoot />}>
                <Route path="" element={<Navigate to="/creations/worlds" />} />

                <Route
                  path="/creations/worlds"
                  element={<CreationsWorldsPage />}
                />

                <Route
                  path="/creations/assets"
                  element={<CreationsAssetsPage />}
                />

                {/*<Route
            path="/creations/playeritems"
            element={<CreationsPlayerItemsPage />}
/>*/}
              </Route>
              <Route path="/sales" element={<SalesPage />} />
              {/* Account */}
              <Route path="/account/details" element={<AccountDetailsPage />} />
              <Route
                path="/account/tokens"
                element={<AccountAccessTokenManagementPage />}
              />
              <Route path="/account/wallet" element={<AccountWalletPage />} />
              <Route path="/account/invites" element={<AccountInvitesPage />} />
              <Route
                path="/account"
                element={<Navigate to="/account/details" />}
              />
              {/* Server console */}
              <Route
                path="/console/:ip/:port"
                element={<ServerConsolePage />}
              />
              {/* Index */}
              <Route path="/" element={<Navigate to="/creations/worlds" />} />
              {/* Top up */}
              <Route path="/topup1234" element={<TopupPage />} />
            </AnimatedRoutes>
          </Suspense>
          {!NO_FOOTER_ROUTE.some((route) =>
            location.pathname.startsWith(route)
          ) && <Footer style={{ marginTop: 86 - 32 }} />}
        </>
      )}
    </>
  );
}
