import { useRef, useState, useEffect, useMemo } from 'react';
import useActiveController from '../../hook/useActiveController';

import styles from './styles.module.css';
import { randomString } from '../../utils';

import Loader from '../Loader';

import { ReactComponent as CheckIcon } from '../../assets/icons/checkIcon.svg';
import { ReactComponent as TickIcon } from '../../assets/icons/tickIcon.svg';

export type SelectOption =
  | string
  | {
      value: string | number;
      label?: string | React.ReactNode;
    };

enum POSITION {
  TOP = 0,
  BOTTOM = 1,
}

const SHOW_DEBUG_LOGS = false;

export default function Select({
  disabled = false,
  onChange = () => {},
  className = '',
  value = '',
  options = [],
  loading = false,
  placeholder = 'Select an option',
  icon = null,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  disabled?: boolean;
  onChange?: (value: string | number) => void;
  className?: string;
  value?: string | number;
  options?: SelectOption[];
  loading?: boolean;
  placeholder?: string;
  icon?: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight / 2);
  useEffect(() => {
    if (disabled) setOpen(false);
  }, [disabled]);

  const uniqueID = useMemo(() => randomString(16), []);
  useEffect(() => {
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!e || !e.target) return;

      if (!(e.target as any).closest(`.toggle_${uniqueID}`)) setOpen(false);
    };

    document.addEventListener('click', onClick as any);

    return () => document.removeEventListener('click', onClick as any);
  }, [uniqueID]);

  const ref = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<POSITION>(POSITION.BOTTOM);

  useEffect(() => {
    if (!ref.current) return;

    const boundingBox = ref.current.getBoundingClientRect();
    if (boundingBox.top + boundingBox.height / 2 > window.innerHeight / 2) {
      setPosition(POSITION.TOP);
      setMaxHeight(boundingBox.top - 16);
    } else {
      setPosition(POSITION.BOTTOM);
      setMaxHeight(window.innerHeight - boundingBox.bottom - 16);
    }
  }, [ref.current]);

  useEffect(() => {
    if (!ref.current || !open) return;

    const boundingBox = ref.current.getBoundingClientRect();
    if (boundingBox.top + boundingBox.height / 2 > window.innerHeight / 2) {
      setPosition(POSITION.TOP);
      setMaxHeight(boundingBox.top - 16 - 72);
    } else {
      setPosition(POSITION.BOTTOM);
      setMaxHeight(window.innerHeight - boundingBox.bottom - 16);
    }
  }, [ref.current, open]);

  type NormalizedOption = {
    value: string | number;
    label: string | React.ReactNode;
  };

  const normalizedOptions = useMemo<NormalizedOption[]>(() => {
    if (SHOW_DEBUG_LOGS) console.log('Normalizing options', options);
    if (options.length === 0) return [];

    return options
      .filter(
        (option) => typeof option === 'string' || typeof option === 'object'
      )
      .map((option: SelectOption) => {
        if (typeof option === 'string')
          return {
            value: option,
            label: option,
          };

        return {
          value: option.value,
          label: !option.label ? option.value : option.label,
        };
      });
  }, [options]);

  const selectedOptionLabel = useMemo<React.ReactNode | string>(() => {
    if (normalizedOptions.length === 0) return '';

    const selected = normalizedOptions.find(
      (option) => option.value === value
    ) as NormalizedOption;

    return (selected || { label: '' }).label;
  }, [normalizedOptions, value]);

  const optionsController = useActiveController(open, 200);

  return (
    <figure
      className={`${styles.root} ${
        position === POSITION.TOP && styles.rootTop
      } ${(disabled || loading) && styles.rootDisabled} ${className}`}
      ref={ref}
      {...props}
    >
      <button
        className={`${styles.toggle} toggle_${uniqueID}`}
        disabled={disabled || loading}
        onClick={() => {
          if (loading || disabled) return;
          setOpen(!open);
        }}
      >
        {icon !== null && <figure className={styles.toggleIcon}>{icon}</figure>}
        {loading ? (
          <figure className={styles.toggleLoader}>
            <Loader scale={0.45} />
          </figure>
        ) : value === '' ? (
          <span className={styles.toggleInfo}>{placeholder}</span>
        ) : (
          <span className={styles.toggleContent}>{selectedOptionLabel}</span>
        )}
        <TickIcon className={styles.toggleIcon} />
      </button>
      {optionsController.view && (
        <figure
          className={`${styles.options} ${
            optionsController.animate && styles.optionsActive
          }`}
          style={{
            maxHeight:
              normalizedOptions.length * 36 > maxHeight ? maxHeight : undefined,
          }}
        >
          {normalizedOptions.map((option) => (
            <button
              className={`${styles.option} ${
                option.value === value && styles.optionActive
              }`}
              key={option.value}
              onClick={() => {
                if (disabled) return;

                setOpen(false);
                onChange(option.value);
              }}
            >
              <span className={styles.optionLabel}>{option.label}</span>
              {option.value === value && (
                <CheckIcon className={styles.optionIcon} />
              )}
            </button>
          ))}
        </figure>
      )}
    </figure>
  );
}
