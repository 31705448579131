import 'react-animated-router/animate.css';
import './index.scss';

import styles from './App.module.css';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StepProvider } from './hook/useStep';
import { DeepStateProvider } from './hook/useDeepState';
import { TaskProvider } from './hook/useTaskQueue';
import AppWrapper from './App';
import { Toaster } from 'react-hot-toast';
import { PageConfigProvider } from './hook/usePageConfig';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export interface BrowserBinding {
  getauthtoken(): Promise<string>;
  downloadasset(assetId: string): void;
  openexternalurl(Url: string): void;
}

export type Unreal =
  | {
      exists: true;
      binding: BrowserBinding;
    }
  | {
      exists: false;
      binding: null;
    };

declare global {
  interface Window {
    ue?: {
      browserbinding?: BrowserBinding;
    };
  }
}
const hasUnreal =
  typeof window.ue === 'object' &&
  window.ue !== null &&
  !Array.isArray(window.ue) &&
  typeof window.ue.browserbinding === 'object' &&
  window.ue.browserbinding !== null &&
  !Array.isArray(window.ue.browserbinding);
const unrealBinding: BrowserBinding | null = hasUnreal
  ? (window.ue as any).browserbinding
  : null;

root.render(
  <QueryClientProvider client={queryClient}>
    <Toaster
      position="bottom-center"
      toastOptions={{
        className: styles.toast,
        success: {
          className: `${styles.toast} ${styles.toastSuccess}`,
        },
        loading: {
          className: `${styles.toast} ${styles.toastLoading}`,
        },
        error: {
          className: `${styles.toast} ${styles.toastError}`,
        },
      }}
      gutter={8}
      containerStyle={{ inset: 32 }}
    />
    <DeepStateProvider
      initialState={
        {
          unreal: {
            exists: hasUnreal,
            binding: unrealBinding,
          },
        } as {
          unreal: Unreal;
        }
      }
    >
      <PageConfigProvider suffix="">
        <TaskProvider>
          <StepProvider>
            <BrowserRouter>
              <AppWrapper />
            </BrowserRouter>
          </StepProvider>
        </TaskProvider>
      </PageConfigProvider>
    </DeepStateProvider>
  </QueryClientProvider>
);
