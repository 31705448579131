import { useState, createContext, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useActiveController from '../../hook/useActiveController';

import type { WorldPartial } from '../../type/World';

import styles from './styles.module.css';
import { copyText } from '../../utils';
import { toast } from 'react-hot-toast'; // eslint-disable-line

import { ReactComponent as CogIcon } from '../../assets/icons/cogIcon.svg';
import { ReactComponent as PencilIcon } from '../../assets/icons/pencilIcon.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/linkIcon.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicateIcon.svg';
import { ReactComponent as GlobeIcon } from '../../assets/icons/globeIcon.svg';
import { ReactComponent as ExportIcon } from '../../assets/icons/exportIcon.svg'; // eslint-disable-line

const ActiveWorldContext = createContext<{
  active: string;
  focused: string;
  setActive: (id: string) => void;
  setFocused: (id: string) => void;
}>({ active: '', focused: '', setActive: () => {}, setFocused: () => {} });

export function ActiveWorldProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
}) {
  const [activeWorldId, setActiveWorldId] = useState<string>('');
  const [focusedWorldId, setFocusedWorldId] = useState<string>('');

  useEffect(() => {
    return () => setFocusedWorldId('');
  }, [activeWorldId]);

  return (
    <ActiveWorldContext.Provider
      value={{
        active: activeWorldId,
        focused: focusedWorldId,
        setActive: setActiveWorldId,
        setFocused: setFocusedWorldId,
      }}
    >
      {children}
    </ActiveWorldContext.Provider>
  );
}

export default function World({
  data,
  children = null,
  className = '',
  group = '',
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  data: WorldPartial;
  children?: React.ReactNode;
  group?: string;
}) {
  const { active, focused, setFocused, setActive } =
    useContext(ActiveWorldContext);

  const navigate = useNavigate();

  const id = `${group !== '' ? `${group}_` : ''}${data.id}`;

  const wrapperActiveController = useActiveController(active === id, 400);
  const menuActiveController = useActiveController(focused === id, 250);

  return (
    <figure
      className={`${styles.root} ${
        /*active === id*/ wrapperActiveController.animate && styles.rootActive
      } ${className}`}
      {...props}
      onMouseOver={() => setActive(id)}
    >
      <figure
        className={styles.icon}
        style={{ backgroundImage: `url(${data.iconUrl})` }}
      >
        {data.iconUrl === '' && <GlobeIcon className={styles.defaultIcon} />}
      </figure>
      <figure className={styles.details}>
        <h3 className={styles.title}>{data.title}</h3>
        <figcaption
          className={`${styles.visibility} ${
            !data.published&& styles.visibilityPrivate
          }`}
        >
          {data.published ? 'published' : 'draft'}
        </figcaption>
      </figure>
      {wrapperActiveController.view && (
        <>
          <figure className={styles.background} />
          <figure
            className={styles.wrapper}
            onMouseLeave={() => setActive('')}
            onClick={(e) => {
              if (
                !e.target ||
                !(e.target as HTMLElement).classList.contains(styles.wrapper)
              )
                return;

              navigate(`/creations/worlds/${data.id}`);
            }}
          >
            <button
              className={styles.ctaButton}
              onClick={() => setFocused(focused === id ? '' : id)}
            >
              <CogIcon />
            </button>
            {menuActiveController.view && (
              <figure
                className={`${styles.menu} ${
                  /*focused === id*/ menuActiveController.animate &&
                  styles.menuActive
                }`}
              >
                {children}
              </figure>
            )}
          </figure>
        </>
      )}
    </figure>
  );
}

export function WorldMenuButton({
  children = null,
  className = '',
  destructive = false,
  closeMenu = true,
  disabled = false,
  onClick = () => {},
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children?: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
  destructive?: boolean;
  closeMenu?: boolean;
}) {
  const { setFocused } = useContext(ActiveWorldContext);

  return (
    <button
      className={`${styles.menuButton} ${
        destructive && styles.menuButtonDestructive
      } ${className}`}
      onClick={(e) => {
        if (disabled) return;
        onClick(e);
        if (closeMenu) setFocused('');
      }}
      {...props}
    >
      {children}
    </button>
  );
}

export function DefaultWorldMenuButtons({
  data,
  owned = false,
}: {
  data: WorldPartial;
  owned?: boolean;
}): React.ReactElement {
  const navigate = useNavigate();

  return (
    <>
      {owned && (
        <WorldMenuButton
          onClick={() => navigate(`/creations/worlds/${data.id}`)}
        >
          <PencilIcon /> Edit World
        </WorldMenuButton>
      )}
      {/*<WorldMenuButton
        onClick={() =>
          toast.promise(copyText(`${data.id}.lix`), {
            success: 'Copied Public URL',
            error: 'Failed to copy Public URL',
            loading: '',
          })
        }
      >
        <LinkIcon /> Copy URL
      </WorldMenuButton>*/}
      <WorldMenuButton
        onClick={() =>
          toast.promise(copyText(data.id), {
            success: 'Copied World ID',
            error: 'Failed to copy World ID',
            loading: '',
          })
        }
      >
        <DuplicateIcon /> Copy World ID
      </WorldMenuButton>
      {/*<WorldMenuButton onClick={() => navigate(`/world/${data.id}`)}>
        <ExportIcon /> {owned ? 'Public Preview' : 'View'}
      </WorldMenuButton>*/}
    </>
  );
}

export function WorldMenuDivider() {
  return <figure className={styles.menuDivider} />;
}

export function WorldGrid({
  children = null,
  className = '',
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  children?: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
}) {
  return (
    <section className={`${styles.gridRoot} ${className}`} {...props}>
      {children}
    </section>
  );
}
