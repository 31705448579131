import { useState, useEffect, useMemo } from 'react';
import { useUser } from '../../hook/useUser';
import { useActiveItemId, ExchangeItemGrid } from '../../Comps/Item';
import useDeepState from '../../hook/useDeepState';
import usePageConfig from '../../hook/usePageConfig';

import styles from './owned.module.css';
import { getAssetDownloadLink, getOwnedAssets } from '../../api/asset';

import type { AssetItem } from '../../modal';

import Loader from '../../Comps/Loader';
import Input from '../../Comps/Input';
import Footer from '../../Comps/Footer';

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      assets: AssetItem[];
    };

const TEMPORARY_FIX_REMOVE_IN_PRODUCTION = true;

export default function ExchangeOwnedPage() {
  usePageConfig({ title: 'Owned Assets' });

  const { isLoading, isLoggedIn } = useUser();

  const [state, setState] = useState<State>({
    loading: true,
  });
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();

    getOwnedAssets(abortController.signal).then(({ success, error, data }) => {
      if (!success)
        return setState({
          loading: false,
          error: true,
          errorMessage: error,
        });

      if (TEMPORARY_FIX_REMOVE_IN_PRODUCTION)
        // @ts-ignore
        data = data.map((e) => (typeof e.asset === 'object' ? e.asset : e));

      setState({
        loading: false,
        error: false,
        assets: data,
      });
    });

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  const [activeItemId, setActiveItemId] = useActiveItemId();

  const { getDeepState, setDeepState } = useDeepState();
  const searchQuery = getDeepState<string>('ExchangeOwnedPage.search') ?? '';

  const data = useMemo<AssetItem[]>(() => {
    if (state.loading || state.error) return [];
    if (searchQuery.trim() === '') return state.assets;
    return state.assets.filter((asset) =>
      asset.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [state, searchQuery]);

  return (
    <div className={styles.root}>
      {state.loading && <Loader className={styles.rootLoader} scale={2} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <header className={styles.header}>
            <h1 className={styles.headerTitle}>Owned Assets</h1>
            <Input
              className={styles.headerSearch}
              placeholder="Search assets"
              value={searchQuery}
              onChange={(e) =>
                setDeepState('ExchangeOwnedPage.search', e.target.value)
              }
            />
          </header>
          <ExchangeItemGrid
            className={styles.wrapper}
            items={data}
            activeItemId={activeItemId}
            setActiveItemId={setActiveItemId}
          />
          <Footer />
        </>
      )}
    </div>
  );
}
