import { useUser } from '../../hook/useUser';
import { useState, useEffect } from 'react';
import usePageConfig from '../../hook/usePageConfig';

import styles from './index.module.css';
import commonStyles from '../common.module.css';
import { claimGameKey } from '../../api/user';

import type { StateExtendable } from '../../types/common';

import Loader from '../../Comps/Loader';
import Input from '../../Comps/Input';
import Button, { buttonClassName } from '../../Comps/Button';
import Footer from '../../Comps/Footer';

import { ReactComponent as UserKeyIcon } from '../../assets/icons/userKeyIcon.svg';
import { ReactComponent as SparkleIcon } from '../../assets/icons/sparkleIcon.svg';

export default function WelcomePage() {
  usePageConfig({ title: 'Welcome to HELIX' });
  const { isLoading, isLoggedIn } = useUser();

  const [state, setState] = useState<StateExtendable<{ key: string }>>({
    loading: true,
  });

  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();
    claimGameKey(abortController.signal)
      .then((result) => {
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        setState({
          loading: false,
          error: false,
          key: result.key,
        });
      })
      .catch((e) => console.error(e));

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  return (
    <div className={styles.root}>
      {state.loading && <Loader className={styles.rootLoader} scale={1.5} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Welcome to HELIX</h1>
          <p className={styles.subtitle}>
            HELIX is the future of gaming. You're now part of a small group of
            invite-only users who have exclusive early access to the game and
            creator suite.
          </p>
          <header className={styles.header}>
            <figure className={`${commonStyles.row} ${styles.headerInfo}`}>
              <picture className={styles.headerIcon}>
                <UserKeyIcon />
              </picture>
              <div className={styles.headerText}>
                <h2>Your Game Key</h2>
                <p>Redeem your game key on Epic Games to start playing.</p>
              </div>
            </figure>
            <figure className={`${commonStyles.row} ${styles.headerTools}`}>
              <Input value={state.key} className={styles.headerInput} />
              <a
                className={`${buttonClassName('sky')}`}
                href={`https://store.epicgames.com/redeem?code=${state.key}`}
                target="_blank"
              >
                <SparkleIcon />
                redeem
              </a>
            </figure>
          </header>
          <h2 className={styles.sectionTitle}>How to Play:</h2>
          <section className={styles.section}>
            <div className={styles.card}>
              <div className={styles.cardNumber}>01</div>
              <div className={styles.cardImage} data-image="install" />
              <h5 className={styles.cardTitle}>Install HELIX</h5>
              <p className={styles.cardDescription}>
                First, click the 'redeem' button above to go to the Epic Games
                Store, where you can download the HELIX Client using your unique
                code.
              </p>
            </div>
            <div className={styles.card}>
              <div className={styles.cardNumber}>02</div>
              <div className={styles.cardImage} data-image="login" />
              <h5 className={styles.cardTitle}>Login</h5>
              <p className={styles.cardDescription}>
                Next, load up the client and login to your HELIX account. You'll
                need to verify your email address if you haven't already to
                continue.
              </p>
            </div>
            <div className={styles.card}>
              <div className={styles.cardNumber}>03</div>
              <div className={styles.cardImage} data-image="play" />
              <h5 className={styles.cardTitle}>Start Playing</h5>
              <p className={styles.cardDescription}>
                Once you're in HELIX, head over to the worlds tab. Here, you can
                try out an endless selection of user-created games and
                experiences.
              </p>
            </div>
          </section>
          <h2 className={styles.sectionTitle}>For Creators:</h2>
          <section className={`${styles.section} ${styles.section2}`}>
            <div className={styles.card}>
              <div className={styles.cardNumber}>01</div>
              <div className={styles.cardImage} data-image="unreal" />
              <h5 className={styles.cardTitle}>Download HELIX Studio</h5>
              <p className={styles.cardDescription}>
                Are you an aspiring creator? Head over to our{' '}
                <a href="https://helixgame.com/docs" target="_blank">
                  API Docs
                </a>{' '}
                where you can learn how to install HELIX Studio and create
                worlds and items on the HELIX platform.
              </p>
            </div>
            <div className={styles.card}>
              <div className={styles.cardNumber}>02</div>
              <div className={styles.cardImage} data-image="discord" />
              <h5 className={styles.cardTitle}>Join our Discord</h5>
              <p className={styles.cardDescription}>
                Be sure to join our{' '}
                <a
                  href="https://discord.gg/helixcreators"
                  target="_blank"
                  rel="noreferrer"
                >
                  HELIX Creators Discord
                </a>
                , where our team and community members are ready to help you
                with any question or issue you may have.
              </p>
            </div>
            <div className={styles.card}>
              <div className={styles.cardNumber}>03</div>
              <div className={styles.cardImage} data-image="studio" />
              <h5 className={styles.cardTitle}>Start Creating</h5>
              <p className={styles.cardDescription}>
                Ready to start creating? With the HELIX API and Studio, creation
                using Unreal Engine 5 has never been easier. We can't wait to
                see what you build!
              </p>
            </div>
          </section>
        </>
      )}
    </div>
  );
}
