import React, { createContext, useContext, useState } from 'react';

export interface StepContextType {
  active: boolean;
  steps: string[];
  cur: number;
  done: boolean;
  setCur: (step: number) => void;
  activeStep: (step: string[]) => void;
}

const StepContext = createContext<StepContextType>({
  active: false,
  steps: [],
  cur: 0,
  done: false,
  setCur: () => {},
  activeStep: () => {},
});

export function useStep() {
  return useContext(StepContext);
}

// Create a provider component that stores the current step's state
export function StepProvider({ children }: { children: React.ReactNode }) {
  const [steps, setSteps] = useState<string[]>([]);
  const [cur, setCur] = useState(0);
  const [active, setActive] = useState(false);
  const done = steps.length < cur;

  const activeStep = (step?: string[]) => {
    if (step && step.length > 0) {
      setActive(true);
      setSteps(step);
      setCur(1);
    } else {
      setActive(false);
      setCur(0);
      setSteps([]);
    }
  };

  return (
    <StepContext.Provider
      value={{
        active,
        steps,
        cur,
        done,
        setCur: setCur,
        activeStep: activeStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}
