import { forwardRef } from 'react';

import styles from './styles.module.css';

export default forwardRef(function Input(
  {
    disabled = false,
    onChange = () => {},
    className = '',
    maxLength = -1,
    style = {},
    value = '',
    icon: Icon = null,
    rightIcon: RightIcon = null,
    children = null,
    ...props
  }: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    maxLength?: number;
    value?: string;
    icon?: React.ReactNode | null;
    rightIcon?: React.ReactNode | null;
  },
  ref: React.ForwardedRef<HTMLInputElement>
): React.ReactElement {
  return (
    <div
      className={`${styles.root} ${
        disabled && styles.rootDisabled
      } ${className}`}
      style={style}
    >
      {Icon !== null && <figure className={styles.icon}>{Icon}</figure>}
      {children !== null && children}
      <input
        disabled={disabled}
        onChange={(e) => (disabled ? null : onChange(e))}
        value={value}
        {...(maxLength > -1 && { maxLength })}
        {...props}
        ref={ref}
      />
      {maxLength > -1 && (
        <span
          className={`${styles.counter} ${
            RightIcon !== null && styles.counterShift
          }`}
        >
          {maxLength - value.length}
        </span>
      )}
      {RightIcon !== null && (
        <figure className={styles.rightIcon}>{RightIcon}</figure>
      )}
    </div>
  );
});

export function TextArea({
  disabled = false,
  onChange = () => {},
  className = '',
  maxLength = -1,
  style = {},
  value = '',
  ...props
}: React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  maxLength?: number;
  value?: string;
}): React.ReactElement {
  return (
    <div
      className={`${styles.root} ${styles.rootTextArea} ${
        disabled && styles.rootDisabled
      } ${className}`}
      style={style}
    >
      <textarea
        disabled={disabled}
        onChange={(e) => (disabled ? null : onChange(e))}
        value={value}
        {...(maxLength > -1 && { maxLength })}
        {...props}
      />
      {maxLength > -1 && (
        <span className={styles.counter}>{maxLength - value.length}</span>
      )}
    </div>
  );
}
