import { useRef, useState } from 'react';

export type ModalController = {
  active: boolean;
  config: ModalConfig;
  promiseResolve: (value: string) => void;
};

export type ModalConfig = {
  closeable: boolean;
  escapable: boolean;
  title: string;
  description: string;
  actions: ModalAction[];
  children?: React.ReactNode;
  zIndex?: number;
};

export type ModalAction = {
  value: string;
  label: string | React.ReactNode;
  variant?: string;
  disabled?: boolean;
  className?: string;
};

export const DEFAULT_ACTIONS: ModalAction[] = [
  {
    value: 'confirm',
    label: 'Confirm',
  },
  {
    value: 'cancel',
    label: 'Cancel',
    variant: 'dark',
  },
];

export default function useModal(): [
  ModalController,
  {
    active: boolean;
    open: (config: Partial<ModalConfig>) => Promise<string>;
    close: () => void;
  }
] {
  const [active, setActive] = useState(false);
  const [config, setConfig] = useState<ModalConfig>({
    closeable: true,
    escapable: true,
    title: '',
    description: '',
    actions: [],
    children: null,
  });
  const promiseResolveRef = useRef<(value: string) => void>(() => null);

  return [
    {
      active,
      config,
      promiseResolve: (value: string) => {
        console.log(`Resolve called with value: ${value}`);
        setActive(false);
        promiseResolveRef.current(value);
        promiseResolveRef.current = () => null;
      },
    },
    {
      active,
      open: ({
        title = '',
        description = '',
        closeable = true,
        escapable = true,
        actions = DEFAULT_ACTIONS,
        children = null,
      }) =>
        new Promise((resolve) => {
          promiseResolveRef.current = resolve;
          setConfig({
            title,
            description,
            closeable,
            escapable,
            actions,
            children,
          });
          setActive(true);
        }),
      close: () => {
        promiseResolveRef.current = () => null;
        setActive(false);
      },
    },
  ];
}
