import { useState, useEffect } from 'react';
import { useUser } from '../../hook/useUser';
import usePageConfig from '../../hook/usePageConfig';

import type { StateExtendable } from '../../types/common';

import styles from './styles.module.css';
import { LIXBundle, getLIXBundles, purchaseLIXBundle } from '../../api/payment';
import toast from 'react-hot-toast';

import Loader from '../../Comps/Loader';
import Button from '../../Comps/Button';

import BronzeImage from '../../assets/images/topup/bronze.png';
import SilverImage from '../../assets/images/topup/silver.png';
import GoldImage from '../../assets/images/topup/gold.png';
import DiamondImage from '../../assets/images/topup/diamond.png';
import UltraImage from '../../assets/images/topup/ultra.png';

import { ReactComponent as CreditCardIcon } from '../../assets/icons/creditCardIcon.svg';

export default function TopupPage() {
  usePageConfig({ title: 'HELIX Top-Up' });
  const { isLoggedIn, isLoading, setSmartBalanceChecker } = useUser();
  useEffect(() => () => setSmartBalanceChecker(false), []);

  const [state, setState] = useState<
    StateExtendable<{
      bundles: LIXBundle[];
    }>
  >({
    loading: true,
  });
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();
    getLIXBundles(abortController.signal)
      .then((result) => {
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        setState({
          loading: false,
          error: false,
          bundles: result.data,
        });
      })
      .catch((e) => console.error(e));

    return () => abortController.abort();
  }, [isLoading]);

  const [activePackage, setActivePackage] = useState<number>(-1);

  const packageInfo: { image: string; name: string; textBackground: string }[] =
    [
      {
        image: BronzeImage,
        name: 'Bronze LIX Pack',
        textBackground:
          'linear-gradient(180deg, #BFA383 0%, rgba(255, 170, 72, 0.00) 227.61%)',
      },
      {
        image: SilverImage,
        name: 'Silver LIX Pack',
        textBackground:
          'linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 235.42%)',
      },
      {
        image: GoldImage,
        name: 'Gold LIX Pack',
        textBackground:
          'linear-gradient(180deg, #F0F292 0%, rgba(203, 222, 84, 0.00) 308.86%)',
      },
      {
        image: DiamondImage,
        name: 'Diamond LIX Pack',
        textBackground:
          'linear-gradient(180deg, #90FBFF 0%, rgba(149, 251, 255, 0.00) 227.61%)',
      },
      {
        image: UltraImage,
        name: 'Super LIX Pack',
        textBackground: '',
      },
    ];

  const [loading, setLoading] = useState(false);

  return (
    <div className={styles.root}>
      {state.loading && <Loader className={styles.rootLoader} scale={1.5} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Buy LIX</h1>
          <div className={styles.grid}>
            {state.bundles.slice(0, 5).map((bundle, i) => (
              <div
                className={`${styles.package} ${
                  i === 4 && styles.packageWide
                } ${activePackage === i && styles.packageActive}`}
                key={i}
                onMouseOver={() => setActivePackage(i)}
              >
                <div className={styles.packageImage}>
                  <picture
                    style={{
                      backgroundImage: `url('${packageInfo[i].image}')`,
                    }}
                  />
                  <h3
                    className={styles.packageAmount}
                    style={{
                      backgroundImage: packageInfo[i].textBackground,
                    }}
                  >
                    {bundle.lix.toLocaleString()}
                  </h3>
                  {bundle.price < bundle.normalPrice * 100 && (
                    <div className={styles.packageDiscount}>
                      -
                      {Math.floor(
                        ((bundle.normalPrice - bundle.price / 100) /
                          bundle.normalPrice) *
                          100
                      )}
                      %
                    </div>
                  )}
                </div>
                <div className={styles.packageInfo}>
                  <h2 className={styles.packageName}>{packageInfo[i].name}</h2>
                  <div className={styles.packagePrice}>
                    ${(bundle.price / 100).toFixed(2)}
                    <span className={styles.packageNormalPrice}>
                      ${bundle.normalPrice.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className={styles.packageBackground}></div>
                <div
                  className={styles.packageOverlay}
                  onMouseOut={() => setActivePackage(-1)}
                  onClick={async () => {
                    if (loading) return;
                    setLoading(true);

                    const result = await purchaseLIXBundle(bundle.lix);
                    setLoading(false);
                    if (!result.success) return toast.error(result.error);
                    setSmartBalanceChecker(true);
                    window.open(result.stripeLink, '_blank');
                  }}
                >
                  <Button
                    variant="yellow"
                    className={styles.packageOverlayButton}
                    disabled={loading}
                  >
                    <CreditCardIcon />
                    buy now
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <section className={styles.divider} />
          <div className={styles.disclaimer}>
            When you acquire LIX, you are obtaining a limited, non-refundable,
            non-transferable, and revocable license for its use exclusively on
            the HELIX platform. LIX holds no intrinsic value in real currency
            and should not be considered an investment, security, commodity, or
            cryptocurrency. By completing a purchase, you acknowledge and agree
            to the following: (1) You affirm that you are of legal age (over 18)
            and provide authorization for us to debit your bank account for the
            selected amount, and (2) You confirm that you have reviewed and
            consented to our{' '}
            <a href="https://legal.helixgame.com" target="_blank">
              Terms of Use
            </a>
            , which encompass an agreement for the resolution of any disputes
            through arbitration between you and Hypersonic Laboratories Inc, in
            addition to our{' '}
            <a href="https://legal.helixgame.com/privacy" target="_blank">
              Privacy Policy
            </a>
            . Please note that there are no refunds under any circumstance, in
            accordance with our{' '}
            <a href="https://legal.helixgame.com" target="_blank">
              Paid Purchase Policy
            </a>{' '}
            and{' '}
            <a href="https://legal.helixgame.com" target="_blank">
              Refund Policy
            </a>
            . Additionally, any attempt to charge back or dispute a transaction
            will result in the immediate termination of your account(s) and a
            block from creation of additional accounts on the platform, and the
            possibility of a collections agency being engaged to recover any
            outstanding deficit. Hypersonic Laboratories Inc reserves the right
            to close accounts and/or revoke LIX at our sole discretion.
          </div>
        </>
      )}
    </div>
  );
}
