export enum ItemCategory {
  Map = 'map',
  Character = 'character',
  Vehicle = 'vehicle',
  Weapon = 'weapon',
  Prop = 'prop',
  Script = 'script',
  Other = 'other',
}
export enum ItemCategoryName {
  map = 'Maps',
  character = 'Characters',
  vehicle = 'Vehicles',
  weapon = 'Weapons',
  prop = 'Props',
  script = 'Scripts',
  other = 'Other',
}
export enum ItemCategoryPlural {
  maps = 'map',
  characters = 'character',
  vehicles = 'vehicle',
  weapons = 'weapon',
  props = 'prop',
  scripts = 'script',
  other = 'other',
}
export enum ItemCategoryPluralReverse {
  map = 'maps',
  character = 'characters',
  vehicle = 'vehicles',
  weapon = 'weapons',
  prop = 'props',
  script = 'scripts',
  other = 'other',
}
export enum ItemType {
  Created = 'created',
  Exchange = 'exchange',
}

export interface Version {
  size: number;
}

export type Item = {
  id: string;
  name: string;
  title: string;
  description: string;
  icon_url: string;
  is_public: boolean;
  published: boolean;
  status: string;
  type_id: number;
  user_id: string;
  created_at?: Date;
  updated_at?: Date;
  version?: Version[];

  // Old
  size: number;
  thumbnailUrl: string;
  category: ItemCategory;
  lastUpdate: number;
  created: number;
  type: ItemType;
};

export type CreatedItem = Item & {
  public: boolean;
  tags: string[];
  price?: number;
};

export type ExchangeItem = Item & {
  authorName: string;
  authorIconUrl: string;
  price: number;
  originalPrice: number;
  saved: boolean;
  dependencyCount: number;
  downloadCount: number;
  reviewCount: number;
  rating: number;
  owned: boolean;
};

export const isCreatedItem = (
  item: CreatedItem | ExchangeItem
): item is CreatedItem => item.type === ItemType.Created;
export const isExchangeItem = (
  item: CreatedItem | ExchangeItem
): item is ExchangeItem => item.type === ItemType.Exchange;
