import type { WorldServer } from '../modal';

import { processError } from '../utils';
import axios from 'axios';

const TESTING_MODE_DISABLE_THIS = false;

export async function getWorldPersistentServer(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      exists: false;
      data: null;
    }
  | {
      success: true;
      error: '';
      exists: true;
      data: WorldServer;
    }
  | {
      success: false;
      error: string;
      exists: false;
      data: null;
    }
> {
  try {
    const result = await axios.get('/server/world', {
      params: {
        page_size: 10,
        offset: 0,
        world_id: worldId,
      },
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        exists: false,
        data: null,
      };

    const {
      payload,
    }: {
      payload: WorldServer[];
    } = result.data;

    if (
      payload.length === 0 ||
      (!TESTING_MODE_DISABLE_THIS &&
        !payload.some((server) => server.persistent))
    )
      return {
        success: true,
        error: '',
        exists: false,
        data: null,
      };

    return {
      success: true,
      error: '',
      exists: true,
      data: payload.find(
        (server) => TESTING_MODE_DISABLE_THIS || server.persistent
      ) as WorldServer,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      exists: false,
      data: null,
    };
  }
}

export async function getWorldHostingOptions(signal?: AbortSignal): Promise<
  | {
      success: true;
      error: '';
      data: {
        id: string;
        region: string;
        pricing: number;
      }[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const response = await axios.get('/server/regions');

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: [],
      };

    console.log('Fetched hosting options', response.data.payload);

    const HOSTING_OPTIONS_TESTING = [
      {
        id: 'default',
        region: 'US - more regions coming soon',
        pricing: 0,
      },
      /*{
        id: 'us-west-1',
        region: 'US West (California)',
        pricing: 10000,
      },
      {
        id: 'eu-central-1',
        region: 'EU Central (OVH)',
        pricing: 250,
      },
      {
        id: 'eu-central-2',
        region: 'EU Central (Frankfurt)',
        pricing: 200,
      },
      {
        id: 'ap-central-1',
        region: 'Asia Pacific Central (Shanghai)',
        pricing: 2000,
      },*/
    ];

    return {
      success: true,
      error: '',
      data: HOSTING_OPTIONS_TESTING,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}
export async function startWorldPersistentServer(
  worldName: string,
  worldDescription: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      address: [string, number];
    }
  | {
      success: false;
      error: string;
      address: ['', 0];
    }
> {
  try {
    const response = await axios.post(
      '/server',
      {
        world_name: worldName,
        description: worldDescription,
        password: '',
        announce: true,
        max_players: 12,
        persistent: true,
      },
      {
        signal,
        timeout: 60 * 1000, // 60 seconds because it takes a while to start a server
      }
    );

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        address: ['', 0],
      };

    if (
      typeof response.data.payload !== 'string' ||
      response.data.payload === '' ||
      response.data.payload.search(':') === -1
    ) {
      return {
        success: false,
        error: 'Unprocessable response from server',
        address: ['', 0],
      };
    }

    const [ip, port] = response.data.payload.split(':');
    if (
      typeof ip !== 'string' ||
      ip === '' ||
      typeof port !== 'string' ||
      port === '' ||
      isNaN(Number(port))
    )
      return {
        success: false,
        error: 'Unexpected response from server',
        address: ['', 0],
      };

    return {
      success: true,
      error: '',
      address: [ip, Number(port)],
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      address: ['', 0],
    };
  }
}

export async function getWorldImages(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const response = await axios.get(`/world/images/${worldId}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: [],
      };

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      !Array.isArray(response.data.payload)
    )
      // world doesn't have any images
      return {
        success: true,
        error: '',
        data: [],
      };

    return {
      success: true,
      error: '',
      data: response.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function uploadWorldImage(
  worldId: string,
  image: File,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string;
    }
  | {
      success: false;
      error: string;
      data: '';
    }
> {
  try {
    const formData = new FormData();
    formData.append('world_id', worldId);
    formData.append('files', image);
    const response = await axios.post('/world/image', formData, {
      signal,
      timeout: 60 * 1000, // 60 seconds because it takes a while to upload an image
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: '',
      };

    return {
      success: true,
      error: '',
      data: response.data.payload.image_url,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function updateWorldImages(
  worldId: string,
  images: string[],
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[] | null;
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const response = await axios.put(
      '/world/images',
      {
        world_id: worldId,
        world_images: images,
      },
      {
        signal,
      }
    );

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: [],
      };

    return {
      success: true,
      error: '',
      data:
        typeof response.data.payload === 'object' &&
        response.data.payload !== null &&
        Array.isArray(response.data.payload)
          ? response.data.payload
          : null, // if the server doesn't have something nice to say, don't say anything at all
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function startWorldUpload(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: {
        upload_url: string;
        world_url: string;
      };
    }
  | {
      success: false;
      error: string;
      data: null;
    }
> {
  try {
    const response = await axios.get(`/world/upload/packages/${worldId}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: response.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function deleteWorld(
  worldName: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const response = await axios.delete(`/world/${worldName}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function validateWorldName(
  worldName: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      isAvailable: boolean;
    }
  | {
      success: false;
      error: string;
      isAvailable: boolean;
    }
> {
  try {
    const response = await axios.get(`/world/${worldName}`, {
      signal,
    });

    return {
      success: true,
      error: '',
      isAvailable: response.data.code !== 0,
    };
  } catch (e) {
    if (
      // @ts-ignore
      typeof e.response === 'object' && // @ts-ignore
      e.response !== null && // @ts-ignore
      typeof e.response.status === 'number' && // @ts-ignore
      e.response.status === 404
    )
      return {
        success: true,
        error: '',
        isAvailable: true,
      };
    return {
      success: false,
      error: processError(e),
      isAvailable: false,
    };
  }
}

export async function validateWorldDomain(
  domain: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      isAvailable: boolean;
    }
  | {
      success: false;
      error: string;
      isAvailable: false;
    }
> {
  try {
    const response = await axios.get(`/world/fqdn/${domain}`, {
      signal,
    });

    if (response.status === 400)
      return {
        success: false,
        error: 'Invalid domain',
        isAvailable: false,
      };

    if (response.status === 404)
      return {
        success: true,
        error: '',
        isAvailable: true,
      };

    if (response.data.code !== 0 && response.data.message !== 'world not found')
      return {
        success: false,
        error: processError(response.data),
        isAvailable: false,
      };

    return {
      success: true,
      error: '',
      isAvailable: response.data.code !== 0,
    };
  } catch (e) {
    if (
      // @ts-ignore
      typeof e.response === 'object' && // @ts-ignore
      e.response !== null && // @ts-ignore
      typeof e.response.status === 'number'
    ) {
      if (
        // @ts-ignore
        e.response.status === 404
      )
        return {
          success: true,
          error: '',
          isAvailable: true,
        };

      if (
        // @ts-ignore
        e.response.status === 400
      )
        return {
          success: false,
          error: 'Invalid domain',
          isAvailable: false,
        };
    }
    return {
      success: false,
      error: processError(e),
      isAvailable: false,
    };
  }
}

export type Transaction = {
  transaction_date: string;
  transaction_origin: string;
  transaction_amount: string;
  user_picture_url: string;
  user_username: string;
  iwp_title: string;
};

export async function getWorldTransactions(
  worldId: string,
  begin: string,
  end: string,
  signal: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: [];
    }
  | {
      success: true;
      error: '';
      data: Transaction[];
    }
> {
  try {
    const transactions: Transaction[] = [];
    for (let i = 0; i < 20; i++) {
      const result = await axios.get(`/iwp/world/${worldId}/transactions`, {
        params: {
          begin,
          end,
          offset: i * 100,
          page_size: 100,
        },
        signal,
      });

      if (result.data.code !== 0)
        return {
          success: false,
          error: processError(result.data),
          data: [],
        };

      if (result.data.payload === null) break;

      transactions.push(...result.data.payload);

      if (result.data.payload.length < 100) break;
    }

    return {
      success: true,
      error: '',
      data: transactions,
    };
  } catch (e: any) {
    if (e?.response?.status === 404)
      return {
        success: true,
        error: '',
        data: [],
      };

    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function getWorldLinks(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: null;
    }
  | {
      success: true;
      error: '';
      data: Record<string, string>;
    }
> {
  try {
    const result = await axios.get(`/world/links/${worldId}`, {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? {},
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function updateWorldLinks(
  worldId: string,
  links: Record<string, string | string[]>,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: null;
    }
  | {
      success: true;
      error: '';
      data: Record<string, string>;
    }
> {
  try {
    const result = await axios.patch(
      '/world/links',
      {
        world_id: worldId,
        links: links ?? {},
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? {},
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}
