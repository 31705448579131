import axios from 'axios';
import { processError } from '../../../utils';

import type { World, WorldPartial, ApiWorld } from '../../../type/World';

const maxPages = 10;
const pageSize = 100;

export function parseApiWorld(world: ApiWorld, partial: true): WorldPartial;
export function parseApiWorld(world: ApiWorld, partial: false): World;
export function parseApiWorld(world: ApiWorld, partial: boolean) {
  return {
    id: world.id,
    title: world.title,
    type: world.type_id,
    iconUrl: world.icon_url,
    public: world.is_public,
    published: world.published,
    ...(!partial && {
      name: world.name,
      description: world.description,
      metadata: world.value ?? {},
      gameMap: world.gamemap,
      gameMode: world.gamemode,
      maxPlayers: world.max_players,
      tags: (world.tags || []).map((tag: any) => tag.tag),
      allowUniversalItems: world.allow_universal_items,
      fqdn: (world.fqdn || '').replace(/\.lix$/, ''),
      hasPassword: world.has_password,
      instanceable: world.instanceable,
      loadingScreen: world.loading_screen || '',
      maxVisitor: world.max_visitor,
      nftAllowlist: world.nft_allowlist || '',
      subscriptionCost: world.subscription_cost,
      useNftAllowlist: world.use_nft_allowlist,
      creator: {
        id: world.creator.id,
        username: world.creator.username,
        discriminator: world.creator.usernameId,
        iconUrl: world.creator.icon_url,
      },
      status: {
        rating: world.status.rating,
        visitorCount: world.status.visits,
        playerCount: world.status.players_amount,
        serverCount: world.status.servers_amount,
      },
      version: !world.version
        ? null
        : {
            id: world.version.id,
            worldId: world.version.world_id,
            sizeInBytes: world.version.size_in_bytes,
            assets: world.version.assets,
            packageLocation: world.version.package_location,
            version: world.version.version,
          },
    }),
  } as typeof partial extends true ? WorldPartial : World;
}

export async function getWorlds(
  userId: string,
  partial: false
): Promise<{
  success: boolean;
  error: string | '';
  data: World[];
}>;
export async function getWorlds(
  userId: string,
  partial: true
): Promise<{
  success: boolean;
  error: string | '';
  data: WorldPartial[];
}>;
export async function getWorlds(userId: string, partial: boolean) {
  const worlds: (typeof partial extends true ? WorldPartial : World)[] = [];

  for (let i = 0; i < maxPages; i++) {
    try {
      const { data } = await axios.get(
        `/world?page_size=${pageSize}&offset=${
          i * pageSize
        }${
          true
            ? ''
            : `&creator=${
                false ? 'a0490991-1533-4a83-a7fa-528b9b14fd40' : userId
              }`
        }&owned=true`
      );

      if (typeof data.message === 'string' && data.message !== '')
        return {
          success: false,
          error: data.message,
          data: [],
        };

      if (
        typeof data.payload !== 'object' ||
        data.payload === null ||
        data.payload.length === 0
      )
        break;

      const worldsPartial: ApiWorld[] = data.payload;

      worldsPartial.forEach((world) =>
        worlds.push(parseApiWorld(world, partial as any) as any)
      );
    } catch (e) {
      return {
        success: false,
        error: processError(e),
        data: [],
      };
    }
  }

  return {
    success: true,
    error: '',
    /*new Array(20)
      .fill(worlds)
      .flat()
      .map((e, i) => ({
        ...e,
        id: `${e.id}${i === 0 ? '' : i}`,
      })) as (typeof partial extends true ? WorldPartial : World)[],*/
    data: worlds,
  };
}

export async function getWorld(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      errorMessage: '';
      data: World;
    }
  | {
      success: false;
      errorMessage: string;
      data: null;
    }
> {
  try {
    const { data } = await axios.get('/world', {
      params: {
        page_size: 1,
        offset: 0,
        id: worldId,
        owned: true,
      },
      signal,
    });

    if (typeof data.message === 'string' && data.message !== '')
      return {
        success: false,
        errorMessage: data.message,
        data: null,
      };

    if (
      typeof data.payload !== 'object' ||
      data.payload === null ||
      data.payload.length === 0
    )
      return {
        success: false,
        errorMessage: 'World not found',
        data: null,
      };

    const world: ApiWorld = data.payload[0];

    return {
      success: true,
      errorMessage: '',
      data: parseApiWorld(world, false),
    };
  } catch (e) {
    return {
      success: false,
      errorMessage: processError(e),
      data: null,
    };
  }
}
export async function getWorldTypes(signal: AbortSignal): Promise<{
  success: boolean;
  types?: Record<number, string>;
}> {
  try {
    const { data } = await axios.get('/world/types', {
      signal,
    });

    if (
      typeof data !== 'object' ||
      data === null ||
      typeof data.payload !== 'object' ||
      data.payload === null
    )
      return {
        success: false,
      };

    return {
      success: true,
      types: Object.fromEntries(
        Object.entries(data.payload).map(([key, value]) => [value, key])
      ),
    };
  } catch (e) {
    console.error('Unable to get types:', processError(e));
    return {
      success: false,
    };
  }
}
