/*
eslint-disable import/no-extraneous-dependencies
*/
import { useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useModal from '../../../../hook/useModal';

import type { OutletContext } from '.';

import commonStyles from '../../../common.module.css';
import styles from './showcase.module.css';
import { toast } from 'react-hot-toast'; // eslint-disable-line import/no-extraneous-dependencies
import axios from 'axios';
import { processError, selectFile } from '../../../../utils';
import {
  getWorldImages,
  updateWorldImages,
  uploadWorldImage,
} from '../../../../api/world';
import {
  getYoutubeIdFromLink,
  validateYoutubeURL,
} from '../../../../utils/youtube';

import Input from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import Loader from '../../../../Comps/Loader';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import Modal from '../../../../Comps/Modal';

import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../../../assets/icons/hamburgerIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtubeIcon.svg';
import { ReactComponent as GlobeIcon } from '../../../../assets/icons/globeIcon.svg';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/exportIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencilIcon.svg';
import { ReactComponent as UndoIcon } from '../../../../assets/icons/undoIcon.svg';

/*
const EXAMPLE_SCREENSHOTS = [
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/648207e7780469985381f9ac_pco-players2x-p-1080.jpeg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e0d5ffd0f6157449aa2d_s-img-p-1080.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e089cb6ff08a4807eeaa_image%20383-p-1080.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e1482ba149decd81de50_gf-img.png',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e083c4c4459a2b008782_fr-img-p-800.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e07de2b2108d30bc08e1_cs-img-p-800.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6489e084c3de2693cd29241b_m-img-p-800.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/64839dbbb7913eb6a044032a_rppco-img-p-800.jpg',
  'https://uploads-ssl.webflow.com/64526acacac2b6fd912b6ba6/6484ab5ee6dda9d4b920ea70_pco-action-server-p-1080.jpg',
  'https://pbs.twimg.com/media/Fzp7uc9WYAILBTv?format=jpg&name=large',
];*/

type State =
  | {
      loading: true;
      error: false;
      errorMessage: '';
      screenshots: string[];
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
      screenshots: string[];
    }
  | {
      loading: false;
      error: false;
      errorMessage: '';
      screenshots: string[];
    };

export default function WorldManagementShowcasePage() {
  const {
    world,
    getField,
    update,
    setUpdate,
    setUpdateField,
    savingData,
    fetchWorld,
    setSavingData,
  } = useOutletContext<OutletContext>();

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
    errorMessage: '',
    screenshots: [],
  });
  useEffect(() => {
    const abortController = new AbortController();

    getWorldImages(world.id, abortController.signal).then(
      ({ success, error, data }) => {
        if (!success) {
          setState({
            loading: false,
            error: true,
            errorMessage: processError(error),
            screenshots: [],
          });
          return;
        }

        setState({
          loading: false,
          error: false,
          errorMessage: '',
          screenshots: data,
        });
      }
    );

    return () => abortController.abort();
  }, [world.id]);

  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    url: string;
  }>({
    url: '',
  });
  const [activeId, setActiveId] = useState<number>(-1);

  const uploadFile = async (): Promise<string | null> => {
    if (savingData) return null;
    const file = await selectFile('image/jpeg,image/png');
    if (!file) {
      toast.error('No file selected');
      return null;
    }
    setSavingData(true);

    const toastId = toast.loading('Uploading...');
    const result = await uploadWorldImage(world.id, file);

    setSavingData(false);
    if (!result.success) {
      toast.error(result.error, { id: toastId });
      return null;
    }

    toast.success('Uploaded!', { id: toastId });
    return result.data;
  };

  const selectVideo = (): Promise<string | null> =>
    new Promise(async (resolve) => {
      if (savingData) return null;
      const result = await modal.open({
        title: 'Select YouTube video',
      });
      if (result !== 'confirm') return null;
      setModalData((prev) => {
        if (!prev.url || prev.url.trim() === '') {
          resolve(null);
          return prev;
        }

        /* const videoId = prev.url.match(
          /^https?:\/\/(www\.)?youtu\.be\/([^?]+).*$/
        )
          ? prev.url.replace(/^https?:\/\/(www\.)?youtu\.be\/([^?]+).*$/, '$2')
          : prev.url.match(
              /^https?:\/\/(www\.)?youtube\.com\/watch\?v=([^&]+).*$/
            )
          ? prev.url.replace(
              /^https?:\/\/(www\.)?youtube\.com\/watch\?v=([^&]+).*$/,
              '$2'
            )
          : null;*/

        if (!validateYoutubeURL(prev.url)) {
          toast.error('Invalid YouTube link');
          resolve(null);
          return prev;
        }

        resolve(getYoutubeIdFromLink(prev.url));
        return { url: '' };
      });
    });

  return (
    <div className={`${commonStyles.splitContentWrapper} ${styles.root}`}>
      <Modal controller={modalController}>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Video Link
          </figcaption>
          <Input
            type="url"
            value={modalData.url}
            onChange={(e) => setModalData({ url: e.target.value })}
            placeholder="https://youtu.be/..."
          />
        </figure>
      </Modal>
      {state.loading && <Loader className={styles.rootLoader} scale={1.25} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <div className={commonStyles.splitContentWrapperLimit}>
          <h1 className={commonStyles.splitContentTitle}>Showcase</h1>
          <h3
            className={commonStyles.formGroupLabel}
            style={{ lineHeight: '20px', margin: '16px 0 32px 0' }}
          >
            Show off your world with screenshots and videos! Some changes will
            only be visible after reviewed by moderators.
          </h3>
          <figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Main Cover
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
              <figcaption className={commonStyles.formGroupLabel}>
                JPG or PNG less than 1MB. Recommended size: 1920x1080
              </figcaption>
              <Button
                style={{ width: 'fit-content' }}
                disabled={savingData}
                onClick={async () => {
                  if (savingData) return;

                  const file = await selectFile('image/jpeg,image/png');
                  setSavingData(true);
                  if (!file) return;

                  const formData = new FormData();
                  formData.append('files', file as any);
                  formData.append('world_id', world.id);

                  toast
                    .promise(
                      axios.post('/world/icon', formData, {
                        headers: {
                          'content-type': 'multipart/form-data',
                        },
                      }),
                      {
                        loading: 'Uploading...',
                        success: 'Cover uploaded successfully!',
                        error: (e) => `Unable to upload: ${processError(e)}`,
                      }
                    )
                    .finally(() => {
                      fetchWorld();
                      setSavingData(false);
                    });
                }}
              >
                <UploadIcon />
                Upload
              </Button>
              <figure
                className={styles.coverPreview}
                style={{ backgroundImage: `url(${world.iconUrl})` }}
              >
                {world.iconUrl === '' && (
                  <GlobeIcon className={styles.defaultCover} />
                )}
              </figure>
            </figure>
          </section>
          {false && (
            <>
              <figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <h3
                  className={commonStyles.splitContentSubtitle}
                  style={{ marginBottom: -16 }}
                >
                  Screenshots (this is for debugging preview uploads only)
                </h3>
                <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
                  <figcaption className={commonStyles.formGroupLabel}>
                    JPG or PNG less than 1MB. Recommended size: 1920x1080. Max 5
                  </figcaption>
                  <Button
                    style={{ width: 'fit-content' }}
                    disabled={savingData || state.screenshots.length >= 5}
                    onClick={async () => {
                      if (savingData || state.screenshots.length >= 5) return;
                      const file = await selectFile('image/jpeg,image/png');
                      if (!file) return toast.error('No file selected');
                      setSavingData(true);

                      const toastId = toast.loading('Uploading...');
                      const result = await uploadWorldImage(world.id, file);

                      setSavingData(false);
                      if (!result.success)
                        return toast.error(result.error, { id: toastId });

                      setState((prev) => ({
                        ...prev,
                        screenshots: [...prev.screenshots, result.data],
                      }));
                      toast.success('Uploaded!', { id: toastId });
                    }}
                  >
                    <UploadIcon />
                    upload
                  </Button>
                  <figure className={styles.screenshotManager}>
                    {state.screenshots.map((url, i) => (
                      <figure
                        key={url}
                        className={styles.screenshotManagerItem}
                      >
                        <figure>
                          <HamburgerIcon />
                        </figure>
                        <picture style={{ backgroundImage: `url('${url}')` }} />
                        <figcaption>
                          {url
                            .split(/\/+/)
                            .pop()
                            ?.split('.')
                            .map((e) => e.substring(0, 32))
                            .join('.')}
                        </figcaption>
                        <button
                          disabled={savingData}
                          onClick={async () => {
                            if (savingData) return;
                            setSavingData(true);

                            const toastId = toast.loading('Deleting...');
                            const result = await updateWorldImages(world.id, [
                              ...state.screenshots.slice(0, i),
                              ...state.screenshots.slice(i + 1),
                            ]);

                            setSavingData(false);
                            if (!result.success)
                              return toast.error(result.error, { id: toastId });

                            if (result.data !== null)
                              setState((prev) => ({
                                ...prev,
                                screenshots: result.data ?? prev.screenshots,
                              }));
                            else
                              setState((prev) => ({
                                ...prev,
                                screenshots: [
                                  ...prev.screenshots.slice(0, i),
                                  ...prev.screenshots.slice(i + 1),
                                ],
                              }));
                            toast.success('Deleted!', { id: toastId });
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </figure>
                    ))}
                  </figure>
                </figure>
              </section>
            </>
          )}
          <figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Preview
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
              <figcaption className={commonStyles.formGroupLabel}>
                Maximum 5 items. Only Youtube links are supported for videos.
              </figcaption>
              <figure
                className={`${styles.previewGrid} ${
                  savingData && styles.previewGridDisabled
                }`}
              >
                {(getField<string[]>('showcase') as string[]).map((url, i) => (
                  <figure
                    className={`${styles.previewItem} ${
                      activeId === i && styles.previewItemAdd
                    }`}
                    key={i}
                  >
                    {activeId !== i && (
                      <picture
                        style={{
                          backgroundImage: `url('${
                            url.match(/^https?:\/\//)
                              ? url
                              : `https://i.ytimg.com/vi/${url}/hqdefault.jpg`
                          }')`,
                        }}
                      >
                        {!url.match(/^https?:\/\//) && (
                          <div className={styles.previewItemYoutube}>
                            <YoutubeIcon />
                          </div>
                        )}
                        <figure className={styles.previewItemOverlay}>
                          <Button
                            variant="yellow"
                            disabled={savingData}
                            onClick={() => setActiveId(i)}
                          >
                            <PencilIcon />
                          </Button>
                          <Button
                            variant="red"
                            disabled={savingData}
                            onClick={() => {
                              setActiveId(-1);
                              setUpdateField(
                                'showcase',
                                (
                                  getField<string[]>('showcase') as string[]
                                ).filter((_, j) => j !== i)
                              );
                            }}
                          >
                            <TrashIcon />
                          </Button>
                          <Button
                            disabled={savingData}
                            onClick={() => {
                              setActiveId(-1);
                              return window.open(
                                url.match(/^https?:\/\//)
                                  ? url
                                  : `https://youtu.be/${url}`
                              );
                            }}
                          >
                            <ExportIcon />
                          </Button>
                        </figure>
                      </picture>
                    )}
                    {activeId === i && (
                      <>
                        <Button
                          style={{ width: 'fit-content' }}
                          disabled={savingData}
                          onClick={async () => {
                            const result = await uploadFile();
                            if (!result) return;

                            setUpdateField(
                              'showcase',
                              (getField<string[]>('showcase') as string[]).map(
                                (e, index) => (index === i ? result : e)
                              )
                            );
                            setActiveId(-1);
                          }}
                        >
                          <UploadIcon />
                          upload image
                        </Button>
                        <span>or</span>
                        <Button
                          style={{ width: 'fit-content' }}
                          disabled={savingData}
                          onClick={async () => {
                            const result = await selectVideo();
                            if (!result) return;

                            setUpdateField(
                              'showcase',
                              (getField<string[]>('showcase') as string[]).map(
                                (e, index) => (index === i ? result : e)
                              )
                            );
                            setActiveId(-1);
                          }}
                        >
                          <YoutubeIcon />
                          select video
                        </Button>
                        <button
                          onClick={() => setActiveId(-1)}
                          className={styles.previewUndo}
                          disabled={savingData}
                        >
                          <UndoIcon />
                          cancel
                        </button>
                      </>
                    )}
                  </figure>
                ))}
                {(getField<string[]>('showcase') as string[]).length < 5 && (
                  <figure
                    className={`${styles.previewItem} ${styles.previewItemAdd}`}
                  >
                    <Button
                      style={{ width: 'fit-content' }}
                      disabled={savingData}
                      onClick={async () => {
                        const result = await uploadFile();
                        if (!result) return;

                        setUpdateField(
                          'showcase',
                          (getField<string[]>('showcase') as string[]).concat(
                            result
                          )
                        );
                      }}
                    >
                      <UploadIcon />
                      upload image
                    </Button>
                    <span>or</span>
                    <Button
                      style={{ width: 'fit-content' }}
                      disabled={savingData}
                      onClick={async () => {
                        const result = await selectVideo();
                        if (!result) return;

                        setUpdateField(
                          'showcase',
                          (getField<string[]>('showcase') as string[]).concat(
                            result
                          )
                        );
                      }}
                    >
                      <YoutubeIcon />
                      select video
                    </Button>
                  </figure>
                )}
              </figure>
            </figure>
          </section>
          {/*<figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Videos
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 12 }}>
              <figcaption
                className={commonStyles.formGroupLabel}
                style={{ marginBottom: 4 }}
              >
                Only YouTube links are supported.
              </figcaption>
              <Input
                type="url"
                value={getLink('youtube_0')}
                rightIcon={
                  getLink('youtube_0') !== '' && (
                    <CheckCircleIcon style={{ color: '#99D52A' }} />
                  )
                }
                placeholder="Enter YouTube link"
                onChange={(e) => updateLink('youtube_0', e.target.value)}
                icon={<YoutubeIcon />}
                disabled={savingData}
              />
              <Input
                type="url"
                value={getLink('youtube_1')}
                rightIcon={
                  getLink('youtube_1') !== '' && (
                    <CheckCircleIcon style={{ color: '#99D52A' }} />
                  )
                }
                placeholder="Enter YouTube link"
                onChange={(e) => updateLink('youtube_1', e.target.value)}
                icon={<YoutubeIcon />}
                disabled={savingData}
              />
              <Input
                type="url"
                value={getLink('youtube_2')}
                rightIcon={
                  getLink('youtube_2') !== '' && (
                    <CheckCircleIcon style={{ color: '#99D52A' }} />
                  )
                }
                placeholder="Enter YouTube link"
                onChange={(e) => updateLink('youtube_2', e.target.value)}
                icon={<YoutubeIcon />}
                disabled={savingData}
              />
            </figure>
              </section>*/}
        </div>
      )}
    </div>
  );
}
