import { useContext, useState } from 'react';
import useActiveController from '../../hook/useActiveController';

import type { IAP } from '../../api/IAP';

import styles from './styles.module.css';
import { createContext } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as TagIcon } from '../../assets/icons/tagIcon.svg';
import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';

const Context = createContext<{
  activeId: string | '';
  setActiveId: (id: string | '') => void;
}>({
  activeId: '',
  setActiveId: () => {},
});

export default function IAPView({
  className = '',
  data,
  to = '',
  ...props
}: React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> & {
  data: IAP;
  to: string;
}) {
  const { activeId, setActiveId } = useContext(Context);

  const controller = useActiveController(activeId === data.id, 200);

  return (
    <Link
      className={`${styles.root} ${className} ${
        controller.animate && styles.rootActive
      }`}
      onMouseOver={() => setActiveId(data.id)}
      to={to}
      {...(props as any)}
    >
      <figure className={styles.image}>
        {data.icon_url !== '' && (
          <picture style={{ backgroundImage: `url('${data.icon_url}')` }} />
        )}
        {data.icon_url === '' && <TagIcon className={styles.defaultIcon} />}
        <figcaption className={styles.price}>
          <LIXIcon /> {data.price.toLocaleString()}
        </figcaption>
      </figure>
      <figcaption className={styles.title}>{data.title}</figcaption>
      {controller.view && (
        <>
          <figure
            className={styles.overlay}
            onMouseOut={() => setActiveId('')}
          />
          <figure className={styles.background} />
        </>
      )}
    </Link>
  );
}

export function IAPGrid({
  children = null,
  className = '',
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {
  const [activeId, setActiveId] = useState<string | ''>('');

  return (
    <Context.Provider
      value={{
        activeId,
        setActiveId,
      }}
    >
      <figure className={`${styles.grid} ${className}`} {...props}>
        {children}
      </figure>
    </Context.Provider>
  );
}
