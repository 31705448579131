import { processError } from '../utils';
import axios from 'axios';

export type IAP = {
  shop_id: string;
  id: string;
  title: string;
  description: string | '';
  category: string;
  price: number;
  icon_url: string | '';
};

export async function getWorldIAPs(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      exists: false;
      data: [];
    }
  | {
      success: true;
      error: '';
      exists: true;
      data: IAP[];
    }
  | {
      success: false;
      error: string;
      data: [];
      exists: false;
    }
> {
  try {
    const result = await axios.get(`/iwp/world/${worldId}`, {
      signal,
    });

    if (result.data.code !== 0) {
      if (result.data.code === 1700)
        return {
          success: true,
          error: '',
          data: [],
          exists: false,
        };

      return {
        success: false,
        error: processError(result.data),
        data: [],
        exists: false,
      };
    }

    return {
      success: true,
      error: '',
      data: result.data.payload ?? [],
      exists: true,
    };
  } catch (e: any) {
    if (e?.response?.status === 404)
      return {
        success: true,
        error: '',
        data: [],
        exists: false,
      };

    return {
      success: false,
      error: processError(e),
      data: [],
      exists: false,
    };
  }
}

export async function getWorldShopId(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: '';
    }
  | {
      success: true;
      error: '';
      data: string;
    }
> {
  try {
    const result = await axios.get(`/iwp/shop/${worldId}`, {
      signal,
    });

    if (result.data.code !== 0) {
      return {
        success: false,
        error: processError(result.data),
        data: '',
      };
    }

    return {
      success: true,
      error: '',
      data: result.data.payload ?? '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function createWorldShop(
  worldId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string;
      exists: boolean;
    }
  | {
      success: false;
      error: string;
      data: '';
      exists: boolean;
    }
> {
  try {
    const result = await axios.post(
      `/iwp`,
      {
        world_id: worldId,
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0) {
      if (result.data.code === 1700)
        return {
          success: true,
          error: '',
          data: '',
          exists: true,
        };
      return {
        success: false,
        error: processError(result.data),
        data: '',
        exists: false,
      };
    }

    return {
      success: true,
      error: '',
      data: result.data.payload.id ?? '',
      exists: false,
    };
  } catch (e: any) {
    if (e?.response?.status === 404 && e?.response?.data?.code === 1700)
      return {
        success: true,
        error: '',
        data: '',
        exists: true,
      };
    return {
      success: false,
      error: processError(e),
      data: '',
      exists: false,
    };
  }
}

export async function createShopIAP(
  shopId: string,
  data: {
    title: string;
    category: string;
    price: number;
  } & Partial<{
    description: string;
  }>,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: '';
    }
  | {
      success: true;
      error: '';
      data: string;
    }
> {
  try {
    const result = await axios.post(
      '/iwp/item',
      {
        shop_id: shopId,
        ...data,
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: '',
      };

    return {
      success: true,
      error: '',
      data: result.data.payload.id ?? '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function getIAP(
  iapId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      exists: false;
      data: null;
    }
  | {
      success: true;
      error: '';
      exists: false;
      data: null;
    }
  | {
      success: true;
      error: '';
      exists: true;
      data: IAP;
    }
> {
  try {
    const response = await axios.get(`/iwp/item/${iapId}`, {
      signal,
    });

    if (response.data.code !== 0) {
      if (response.data.code === 1700)
        return {
          success: true,
          error: '',
          exists: false,
          data: null,
        };

      return {
        success: false,
        error: processError(response.data),
        exists: false,
        data: null,
      };
    }

    return {
      success: true,
      error: '',
      exists: true,
      data: response.data.payload,
    };
  } catch (e: any) {
    if (e?.response?.status === 400 || e?.response?.status === 404)
      return {
        success: true,
        error: '',
        exists: false,
        data: null,
      };

    return {
      success: false,
      error: processError(e),
      exists: false,
      data: null,
    };
  }
}

export async function updateShopIAP(
  iapId: string,
  data: Partial<{
    title: string;
    description: string;
    price: number;
    category: string;
  }>,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.patch(
      '/iwp/item',
      {
        shop_item_id: iapId,

        ...data,
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function uploadIAPIcon(
  iapId: string,
  file: File,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string;
    }
  | {
      success: false;
      error: string;
      data: '';
    }
> {
  try {
    const formData = new FormData();
    formData.append('icon', file);
    formData.append('shop_item_id', iapId);

    const result = await axios.patch('/iwp/item/upload-icon', formData, {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: '',
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}
