import axios from 'axios';
import { processError } from '../utils';

export type TransactionMetadata = {
  receipt: string;
  title: string;
  description: string;
  icon_url: string;
  created_at: string;
  updated_at: string;
};

export type Transaction = {
  receipt: number;
  balance: number;
  direction: 'DEBIT' | 'CREDIT';
  origin: 'MARKETPLACE' | 'REWARD' | 'PURCHASED' | 'DEX';
  metadata: TransactionMetadata | null;
  created_at: string;
  linked_transaction_id: string | null;
};

export async function getWalletTransactions(
  begin: string,
  end: string,
  maxPages = 20,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: Transaction[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const transactions: Transaction[] = [];

    for (let i = 0; i < maxPages; i++) {
      const response = await axios.get('/payment/history', {
        params: {
          page_size: 100,
          offset: i * 100,
          begin,
          end,
        },
        signal,
      });

      if (response.data.code !== 0)
        return {
          success: false,
          error: processError(response.data),
          data: [],
        };

      if (
        typeof response.data.payload !== 'object' ||
        response.data.payload === null ||
        response.data.payload.length === 0
      )
        break;

      transactions.push(...response.data.payload);

      if (response.data.payload.length < 100) break;
    }

    return {
      success: true,
      data: transactions,
      error: '',
    };
  } catch (error) {
    return {
      success: false,
      error: processError(error),
      data: [],
    };
  }
}
