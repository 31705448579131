import { useMemo } from 'react';

import styles from './styles.module.css';

export type TableCell = {
  value: string;
  display?: string | React.ReactNode;
  sortableValue?: string | number;
  width?: 'auto' | string | number;
  widthBuffer?: string | number;
};

const isNotEmptyArray = (arr: any): arr is any[] =>
  typeof arr === 'object' &&
  arr !== null &&
  Array.isArray(arr) &&
  arr.length !== 0;

export default function Table({
  className = '',
  header = [],
  rows = [],
  style = {},
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  header?: TableCell[];
  rows?: TableCell[][];
}) {
  let error = '';
  if (header.length !== 0 && rows.length !== 0) {
    if (rows.some((row) => row.length !== header.length)) {
      error = 'Table rows must have the same number of cells as the header';
    }
  }
  if (rows.length !== 0) {
    if (rows.some((row) => row.length !== rows[0].length)) {
      error = 'Table rows must have the same number of cells';
    }
  }

  const columns = useMemo(() => {
    const cols: TableCell[][] = [],
      colSizes: string[] = [];
    if (isNotEmptyArray(header)) {
      header.forEach((cell, i) => {
        if (!cols[i]) cols[i] = [];
        cols[i].push(cell);
      });
    }
    if (isNotEmptyArray(rows)) {
      rows.forEach((row) => {
        row.forEach((cell, i) => {
          if (!cols[i]) cols[i] = [];
          cols[i].push(cell);
        });
      });
    }

    cols.forEach((col) => {
      let sizer: string | number = 'auto',
        buffer: string | number = 0;

      if (
        col.some(
          (cell) =>
            typeof cell.widthBuffer === 'string' ||
            typeof cell.widthBuffer === 'number'
        )
      ) {
        buffer =
          col.find(
            (cell) =>
              typeof cell.widthBuffer === 'string' ||
              typeof cell.widthBuffer === 'number'
          )?.widthBuffer || 0;
      }

      if (
        col.some(
          (cell) =>
            (typeof cell.width === 'string' && cell.width !== 'auto') ||
            typeof cell.width === 'number'
        )
      ) {
        if (!col.some((cell) => typeof cell.width === 'string')) {
          sizer = Math.max(
            0,
            ...col
              .filter((cell) => typeof cell.width === 'number')
              .map((cell) => cell.width as number)
          );
        }

        sizer =
          (
            col.find(
              (cell) =>
                (typeof cell.width === 'string' && cell.width !== 'auto') ||
                typeof cell.width === 'number'
            ) || { width: 'auto' }
          ).width || 'auto';
      }

      if (sizer === 'auto') {
        sizer = `${Math.max(0, ...col.map((cell) => cell.value.length)) + 1}ch`;
      }

      if (typeof sizer === 'number') {
        sizer = `${sizer}px`;
      }

      if (typeof buffer === 'number') {
        buffer = `${buffer}px`;
      }

      if (typeof buffer === 'string' && buffer !== '0px' && buffer !== '0') {
        sizer = `calc(${sizer} + ${buffer})`;
      }

      colSizes.push(sizer);
    });

    return colSizes.join(' ');
  }, [header, rows]);

  return error ? (
    <div className={styles.error}>{error}</div>
  ) : (
    <div
      className={`${styles.table} ${className}`}
      style={
        {
          '--columns': columns,
          ...style,
        } as any
      }
      {...props}
    >
      {isNotEmptyArray(header) && (
        <header className={`${styles.tableRow} ${styles.tableHeader}`}>
          {header.map((cell: TableCell, i) =>
            typeof cell.display === 'undefined' ||
            cell.display === null ||
            typeof cell.display === 'string' ? (
              <div key={i} className={styles.tableCell}>
                {typeof cell.display === 'string' ? cell.display : cell.value}
              </div>
            ) : (
              cell.display
            )
          )}
        </header>
      )}
      {isNotEmptyArray(rows) &&
        rows.map((row, rowI) => (
          <figure key={rowI} className={styles.tableRow}>
            {row.map((cell, i) =>
              typeof cell.display === 'undefined' ||
              cell.display === null ||
              typeof cell.display === 'string' ? (
                <div key={i} className={styles.tableCell}>
                  {typeof cell.display === 'string' ? cell.display : cell.value}
                </div>
              ) : (
                cell.display
              )
            )}
          </figure>
        ))}
    </div>
  );
}

export const tableCell = styles.tableCell;

export function TableDisplay({
  className = '',
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) {
  return <div className={`${styles.tableCell} ${className}`} {...props} />;
}
