/*
eslint-disable import/no-extraneous-dependencies
*/
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { useActiveItemId } from '../../../Comps/Item';
import { useUser } from '../../../hook/useUser';
import usePageConfig from '../../../hook/usePageConfig';
import useModal from '../../../hook/useModal';
import useDeepState from '../../../hook/useDeepState';

import styles from './styles.module.css';
import {
  getAllAssetByFilter,
  getAsset,
  getAssetCategoryById,
  getAssetDownloadLink,
  getAssetDownloads,
  getAssetOwnership,
  purchaseAsset,
} from '../../../api/asset';
import { toast } from 'react-hot-toast';
import remarkOembed from '@agentofuser/remark-oembed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import React from 'react';
import { copyText, shortNumber } from '../../../utils';

import type { DetailAssetItem } from '../../../modal';
import type { Unreal } from '../../..';

import { Link } from 'react-router-dom';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import Button from '../../../Comps/Button';
import {
  ExchangeItemView,
  ExchangeItemGrid,
  ExchangeItemRow,
} from '../../../Comps/Item';
import Loader from '../../../Comps/Loader';
import ReactMarkdown from 'react-markdown';
import Footer from '../../../Comps/Footer';
import Modal from '../../../Comps/Modal';

import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrowIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as LIXIcon } from '../../../assets/icons/lixIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heartIcon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/shareIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warningIcon.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/infoIcon.svg';

enum InfoTab {
  Description = 'description',
  Dependencies = 'dependencies',
  Releases = 'releases',
  Reviews = 'reviews',
  Assets = 'assets',
}

type State =
  | {
      loading: true;
      error: false;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      data: DetailAssetItem;
      owned: null | boolean;
    };

export default function ExchangeItem() {
  const navigate = useNavigate();
  const { id } = useParams<{
    id: string;
  }>();
  const { isLoading, userId, balance, isLoggedIn, fetchBalance } = useUser();
  const { getDeepState } = useDeepState();
  const hasUnreal = useMemo<boolean>(
    () => getDeepState<Unreal>('unreal').exists,
    []
  );

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
  });

  useEffect(() => {
    if (typeof id !== 'string' || id.trim() === '')
      return navigate('/exchange');
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();
    getAsset(id, false, abortController.signal).then((result) => {
      if (!result.success)
        return setState({
          loading: false,
          error: true,
          errorMessage: result.error,
        });

      if (!result.exists) return navigate('/exchange');

      setState({
        data: result.data,
        loading: false,
        error: false,
        owned: null,
      });

      if (result.data.creator?.id === userId || result.data.price === 0)
        return setState((prev) => ({
          ...prev,
          owned: true,
        }));
      getAssetOwnership(id, abortController.signal).then(
        ({ success, error, data }) =>
          setState((prev) => ({
            ...prev,
            owned: success ? data : null,
          }))
      );
    });

    return () => abortController.abort();
  }, [id, isLoading, isLoggedIn]);

  const category = [
    state.loading || state.error
      ? ''
      : {
          200: 'character',
          100: 'map',
          700: 'other',
          500: 'prop',
          600: 'script',
          300: 'vehicle',
          400: 'weapon',
          0: 'all',
        }[state.data.type_id] ?? 'all',
  ];

  const [infoTab, setInfoTab] = useState<InfoTab>(InfoTab.Description);

  const showcase =
    state.loading ||
    state.error ||
    typeof state.data.links.showcase !== 'object' ||
    !Array.isArray(state.data.links.showcase)
      ? []
      : state.data.links.showcase;
  if (!state.loading && !state.error && showcase.length === 0) {
    showcase.push(state.data.icon_url || '');
    // showcase.push(...EXAMPLE_SCREENSHOTS);
  }
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [userInteracted, setUserInteracted] = useState(false);
  useEffect(() => {
    if (showcase.length <= 1 || userInteracted) return;
    const interval = setInterval(
      () => setCurrentImageIndex((prev) => (prev + 1) % showcase.length),
      5000
    );

    return () => clearInterval(interval);
  }, [userInteracted, showcase.length]);

  usePageConfig({
    title: `${
      state.loading || state.error ? 'Exchange' : state.data.title
    } | HELIX Exchange`,
  });

  const canDownload =
    state.loading || state.error || isLoading || state.owned === null
      ? false
      : state.owned;

  const [working, setWorking] = useState(false);
  useEffect(() => {
    if (!working) return;
    const onBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [working]);

  const [modalController, modal] = useModal();

  const [assetDownloads, setAssetDownloads] = useState<number>(-1);
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();
    getAssetDownloads(id as string, abortController.signal).then(
      ({ success, error, data }) => {
        if (!success) return console.error(error);

        setAssetDownloads(data);
      }
    );

    return () => abortController.abort();
  }, [id, isLoading, isLoggedIn]);

  return (
    <div className={styles.root}>
      <Modal controller={modalController} />
      {!state.loading && !state.error && (
        <>
          <nav
            className={styles.breadcrumbs}
            style={
              !working
                ? {}
                : {
                    pointerEvents: 'none',
                    opacity: 0.5,
                  }
            }
          >
            <button
              className={styles.breadcrumb}
              onClick={() => (working ? null : navigate(-1))}
            >
              <ArrowIcon
                className={styles.breadcrumbArrow}
                style={{
                  height: 14,
                  width: 14,
                  transformOrigin: 'center',
                  transform: 'rotate(180deg)',
                }}
              />
            </button>
            {category.map((str, index) => (
              <React.Fragment key={index}>
                <Link
                  className={styles.breadcrumb}
                  to={`/exchange/search/${str.toLowerCase()}`}
                >
                  {{
                    character: 'Characters',
                    map: 'Maps',
                    other: 'Other',
                    prop: 'Props',
                    script: 'Scripts',
                    vehicle: 'Vehicles',
                    weapon: 'Weapons',
                    all: 'All',
                  }[str] ?? 'All'}
                </Link>
                {index < category.length - 1 && (
                  <figure className={styles.breadcrumbDivider} />
                )}
              </React.Fragment>
            ))}
          </nav>
          <header className={styles.header}>
            <h1 className={styles.title}>{state.data.title}</h1>
            <figure className={styles.headerDetails}>
              <Link
                className={styles.headerAuthor}
                to={`/exchange/search?creator=${state.data.creator?.id || ''}`}
                style={
                  !working
                    ? {}
                    : {
                        pointerEvents: 'none',
                        opacity: 0.5,
                      }
                }
              >
                <figure
                  className={styles.headerAuthorAvatar}
                  style={{
                    backgroundImage: `url(${
                      state.data.creator?.icon_url || ''
                    })`,
                  }}
                />
                <figcaption className={styles.headerAuthorName}>
                  @{state.data.creator?.username || ''}
                </figcaption>
              </Link>
              {false && (
                <>
                  <figure className={styles.headerDivider} />
                  <figure className={styles.headerRating}>
                    <figcaption className={styles.headerRatingTag}>
                      {/*{item.rating}*/}
                      5.0
                    </figcaption>
                    <figcaption className={styles.headerRatingText}>
                      {/*{item.reviewCount.toLocaleString()}*/} 0 reviews
                    </figcaption>
                  </figure>
                </>
              )}
            </figure>
          </header>
          <section className={styles.split}>
            <figure className={styles.info}>
              <figure
                className={styles.jumbotron}
                style={
                  (showcase[currentImageIndex] || '').match(/^https?:\/\//)
                    ? {
                        backgroundImage: `url(${
                          showcase[currentImageIndex] || ''
                        })`,
                      }
                    : {
                        backgroundColor: '#000',
                      }
                }
              >
                {!(showcase[currentImageIndex] || '').match(/^https?:\/\//) && (
                  <LiteYouTubeEmbed
                    id={showcase[currentImageIndex]}
                    title={`Preview Video`}
                    onIframeAdded={() => setUserInteracted(true)}
                  />
                )}
                {showcase.length > 1 && (
                  <figure className={styles.jumbotronIcons}>
                    {showcase.map((_, index) => (
                      <button
                        key={index}
                        className={styles.jumbotronIcon}
                        onClick={() => {
                          setCurrentImageIndex(index);
                          setUserInteracted(true);
                        }}
                        disabled={index === currentImageIndex}
                      />
                    ))}
                  </figure>
                )}
              </figure>

              <figure className={styles.jumbotronScroller}>
                {showcase.map((imageUrl, index) => (
                  <button
                    className={styles.jumbotronThumb}
                    key={index}
                    style={{
                      backgroundImage: `url('${
                        imageUrl.match(/^https?:\/\//)
                          ? imageUrl
                          : `https://i.ytimg.com/vi/${imageUrl}/hqdefault.jpg`
                      }')`,
                    }}
                    onClick={() => {
                      setCurrentImageIndex(index);
                      setUserInteracted(true);
                    }}
                    disabled={index === currentImageIndex}
                  />
                ))}
              </figure>

              <nav className={styles.infoTabs}>
                <button
                  className={`${styles.infoTab} ${
                    infoTab === InfoTab.Description && styles.infoTabActive
                  }`}
                  onClick={() => setInfoTab(InfoTab.Description)}
                >
                  Description
                </button>
                {/*{data.dependencyCount > 0 && (*/}
                {/*  <button*/}
                {/*    className={`${styles.infoTab} ${*/}
                {/*      infoTab === InfoTab.Dependencies && styles.infoTabActive*/}
                {/*    }`}*/}
                {/*    onClick={() => setInfoTab(InfoTab.Dependencies)}*/}
                {/*  >*/}
                {/*    Dependencies*/}
                {/*  </button>*/}
                {/*)}*/}
                {!hasUnreal && (
                  <button
                    className={`${styles.infoTab} ${
                      infoTab === InfoTab.Releases && styles.infoTabActive
                    }`}
                    onClick={() => setInfoTab(InfoTab.Releases)}
                  >
                    Releases
                  </button>
                )}
                {state.data.thumbnails.length > 0 && (
                  <button
                    className={`${styles.infoTab} ${
                      infoTab === InfoTab.Assets && styles.infoTabActive
                    }`}
                    onClick={() => setInfoTab(InfoTab.Assets)}
                  >
                    Assets
                  </button>
                )}
                {false && (
                  <button
                    className={`${styles.infoTab} ${
                      infoTab === InfoTab.Reviews && styles.infoTabActive
                    }`}
                    onClick={() => setInfoTab(InfoTab.Reviews)}
                  >
                    Reviews
                  </button>
                )}
              </nav>
              <article className={styles.infoContent}>
                {infoTab === InfoTab.Description && (
                  <>
                    <div className={styles.infoContentDescription}>
                      {(() => {
                        const markdown =
                          state.data.full_description || 'No description yet.';
                        const chunks = markdown
                          .replace(/\n{3,}/g, '\n')
                          .replace(
                            /\[([^\]]+)\]\(https?:\/\/(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-\9_\-]{8,13})\)/gi,
                            '\nyoutube:$3|$1\n'
                          )
                          .replace(
                            /\[([^\]]+)\]\(https?:\/\/(www\.)?youtu\.be\/([a-zA-Z0-\9_\-]{8,13})\)/gi,
                            '\nyoutube:$3|$1\n'
                          )
                          .replace(/^\n+|\n+$/g, '')
                          .split(/\n/g);
                        /* markdown parser */
                        return chunks.map((chunk, i) => {
                          return chunk.trim() === '' ? (
                            <br key={i} />
                          ) : chunk.match(
                              /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi
                            ) ? (
                            <LiteYouTubeEmbed
                              id={chunk.replace(
                                /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi,
                                '$1'
                              )}
                              key={i}
                              title={`YouTube Video: ${
                                chunk.replace(
                                  /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi,
                                  '$2'
                                ) || 'unknown'
                              }`}
                            />
                          ) : (
                            <ReactMarkdown
                              key={i}
                              remarkPlugins={[remarkOembed]}
                              skipHtml
                              allowedElements={[
                                'h1',
                                'h2',
                                'h3',
                                'h4',
                                'h5',
                                'h6',
                                'a',
                                'i',
                                'b',
                                'strong',
                                'em',
                                'u',
                                'strike',
                                'code',
                                'sup',
                                'sub',
                                'ul',
                                'ol',
                                'li',
                                'img',
                                'blockquote',
                                'hr',
                                'br',
                                'table',
                                'thead',
                                'tbody',
                                'tr',
                                'th',
                                'td',
                                'p',
                              ]}
                              {...(hasUnreal && {
                                components: {
                                  a: ({
                                    node,
                                    className,
                                    href,
                                    title,
                                    ...props
                                  }) => (
                                    <button
                                      title={title}
                                      type="button"
                                      className={`${className} ${styles.a}`}
                                      onClick={() => {
                                        if (typeof href !== 'string') return;
                                        const unreal =
                                          getDeepState<Unreal>('unreal');
                                        if (!unreal.exists) return;
                                        unreal.binding.openexternalurl(href);
                                      }}
                                    >
                                      {props.children}
                                    </button>
                                  ),
                                },
                              })}
                            >
                              {chunk.trim()}
                            </ReactMarkdown>
                          );
                        });
                      })()}
                    </div>
                    {state.data.tags.length > 0 && (
                      <div className={styles.tags}>
                        <h3 className={styles.tagsTitle}>tags</h3>
                        <figure className={styles.tagsWrapper}>
                          {state.data.tags.map((tag, index) => (
                            <div className={styles.tag} key={index}>
                              {tag.tag}
                            </div>
                          ))}
                        </figure>
                      </div>
                    )}
                  </>
                )}
                {infoTab === InfoTab.Dependencies && (
                  <>
                    <figure className={styles.notice}>
                      <InfoIcon /> All dependencies must be owned and installed
                      for this asset to work
                    </figure>
                    {/*<ExchangeItemGrid*/}
                    {/*  style={{ marginTop: 34 }}*/}
                    {/*  items={item.dependencies}*/}
                    {/*  activeItemId={activeItemId}*/}
                    {/*  setActiveItemId={setActiveItemId}*/}
                    {/*  navigate={navigate}*/}
                    {/*/>*/}
                  </>
                )}
                {infoTab === InfoTab.Releases && (
                  <div className={styles.releases}>
                    {!canDownload && (
                      <figure className={styles.notice}>
                        <InfoIcon /> You must own this asset to download
                        versions
                      </figure>
                    )}
                    {canDownload && state.data.version && (
                      <figure className={styles.release}>
                        <figure className={styles.releaseInfo}>
                          <h3 className={styles.releaseVersion}>
                            {state.data.version.toml_version}
                          </h3>
                          <time
                            className={styles.releaseDate}
                            dateTime={new Date(
                              state.data.version.updated_at
                            ).toISOString()}
                          >
                            {new Date(
                              state.data.version.updated_at
                            ).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </time>
                        </figure>
                        <button
                          className={styles.releaseDownload}
                          // disabled={!item.owned}
                          onClick={() => {
                            if (!state.data.version) return;
                            window.open(
                              state.data.version.asset_location,
                              '_blank'
                            );
                            toast.success('Downloaded');
                          }}
                        >
                          <DownloadIcon />
                        </button>
                      </figure>
                    )}
                  </div>
                )}
                {infoTab === InfoTab.Assets && (
                  <div className={styles.assets}>
                    {state.data.thumbnails.map((url, i) => (
                      <div className={styles.assetThumbnail} key={i}>
                        <picture style={{ backgroundImage: `url('${url}')` }} />
                      </div>
                    ))}
                  </div>
                )}
              </article>
            </figure>
            <aside className={styles.aside}>
              {state.data.price > 0 && state.owned !== null && state.owned && (
                <Link to="/exchange/owned" className={styles.vaultLink}>
                  <CheckCircleIcon /> Owned
                </Link>
              )}
              <figure className={styles.details}>
                <figure className={styles.detailsPrice}>
                  <LIXIcon className={styles.detailsPriceIcon} />
                  <figcaption className={styles.detailsPriceText}>
                    {state.data.price === 0
                      ? 'Free'
                      : state.data.price.toLocaleString()}
                  </figcaption>
                  {/*{item.originalPrice > item.price && (*/}
                  {/*  <>*/}
                  {/*    <figcaption className={styles.detailsPriceSubtext}>*/}
                  {/*      {item.originalPrice.toLocaleString()}*/}
                  {/*    </figcaption>*/}
                  {/*    <figure className={styles.detailsPriceTag}>*/}
                  {/*      -{Math.floor((item.price / item.originalPrice) * 100)}%*/}
                  {/*    </figure>*/}
                  {/*  </>*/}
                  {/*)}*/}
                </figure>
                <figure className={styles.detailsActions}>
                  {/*{!item.owned && (*/}
                  {/*  <Button*/}
                  {/*    variant="yellow"*/}
                  {/*    className={styles.detailsAction}*/}
                  {/*    onClick={() =>*/}
                  {/*      setItem((prev) => ({ ...prev, owned: true }))*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <PlusIcon />*/}
                  {/*    add to cart*/}
                  {/*  </Button>*/}
                  {/*)}*/}
                  {/*{item.owned && (*/}
                  <Button
                    className={styles.detailsAction}
                    onClick={async () => {
                      if (working) return;
                      if (canDownload) {
                        if (hasUnreal)
                          getDeepState<Unreal>('unreal').binding?.downloadasset(
                            state.data.name
                          );

                        if (
                          !state.data.version ||
                          !state.data.version.asset_location
                        )
                          return;
                        if (!hasUnreal)
                          return window.open(
                            getAssetDownloadLink(
                              state.data.id,
                              state.data.version.asset_location
                            ),
                            '_blank'
                          );

                        return fetch(
                          getAssetDownloadLink(
                            state.data.id,
                            state.data.version.asset_location
                          ),
                          {
                            mode: 'no-cors',
                            redirect: 'follow',
                          }
                        );
                      }

                      if (state.data.price > balance)
                        return modal
                          .open({
                            title: 'Not enough LIX',
                            description: 'Please top up your wallet',
                            closeable: true,
                            escapable: false,
                            actions: [
                              {
                                variant: 'yellow',
                                value: 'success',
                                label: (
                                  <>
                                    <LIXIcon /> top up wallet
                                  </>
                                ),
                              },
                              {
                                variant: 'dark',
                                value: 'cancel',
                                label: 'cancel',
                              },
                            ],
                          })
                          .then((result) =>
                            result === 'success'
                              ? window.open(
                                  'https://app.helixmetaverse.com/wallet'
                                )
                              : null
                          );

                      const confirmation = await modal.open({
                        title: 'Buy Asset',
                        description: 'Please confirm your purchase.',
                        children: (
                          <div className={styles.priceWrapper}>
                            <div
                              className={`${styles.priceConfirmation} ${styles.priceConfirmationHead}`}
                            >
                              <h3>Asset price</h3>
                              <span>
                                <LIXIcon /> {state.data.price.toLocaleString()}
                              </span>
                            </div>
                            <div className={styles.priceConfirmation}>
                              <h4>New wallet balance</h4>
                              <span>
                                <LIXIcon />{' '}
                                {(balance - state.data.price).toLocaleString()}
                              </span>
                            </div>
                          </div>
                        ),
                        actions: [
                          {
                            label: 'cancel',
                            value: 'cancel',
                            variant: 'dark',
                          },
                          {
                            label: (
                              <>
                                <LIXIcon />
                                buy now
                              </>
                            ),
                            value: 'confirm',
                            variant: 'yellow',
                          },
                        ],
                      });

                      if (confirmation !== 'confirm') return;

                      setWorking(true);

                      const toastId = toast.loading('Purchasing...');
                      const result = await purchaseAsset(id as string);
                      setWorking(false);

                      if (!result.success)
                        return toast.error(result.error, { id: toastId });

                      setState((prev) => ({
                        ...prev,
                        owned: true,
                      }));
                      toast.success('Purchase successful', { id: toastId });
                      fetchBalance();
                    }}
                    variant="yellow"
                    disabled={working}
                  >
                    <DownloadIcon /> {canDownload ? 'Download' : 'Buy now'}
                  </Button>
                  <Button
                    className={styles.detailsAction}
                    onClick={() => {
                      copyText(window.location.href).then((success) =>
                        success
                          ? toast.success('Copied to clipboard')
                          : toast.error('Failed to copy to clipboard')
                      );
                    }}
                    variant="dark"
                  >
                    <ShareIcon />
                    share
                  </Button>
                  {/*)}*/}
                  {/*<Button variant="dark" className={styles.detailsAction}>
                    <HeartIcon /> save
                  </Button>*/}
                  {/*<Button variant="dark" className={styles.detailsAction}>
                    <ShareIcon /> share
                  </Button>*/}
                </figure>
                <figure className={styles.detailsStats}>
                  {state.data.depends_on.length > 0 && (
                    <figure className={styles.detailsStat}>
                      <h3 className={styles.detailsStatTitle}>Dependencies</h3>
                      <figcaption
                        className={styles.detailsStatText}
                        style={{ color: '#FF8B3E' }}
                      >
                        <WarningIcon />
                        {state.data.depends_on.length.toLocaleString()}{' '}
                        dependenc
                        {state.data.depends_on.length === 1 ? 'y' : 'ies'}
                      </figcaption>
                    </figure>
                  )}
                  <figure className={styles.detailsStat}>
                    <h3 className={styles.detailsStatTitle}>Category</h3>
                    <figcaption
                      className={styles.detailsStatText}
                      style={{ color: '#68ddfd', textTransform: 'capitalize' }}
                    >
                      <Link
                        to={`/exchange/search/${category[0].toLowerCase()}`}
                      >
                        {state.data.type_id === 0 ? 'All' : category[0]}
                      </Link>
                    </figcaption>
                  </figure>
                  <figure className={styles.detailsStat}>
                    <h3 className={styles.detailsStatTitle}>Asset ID</h3>
                    <figcaption
                      className={styles.detailsStatText}
                      style={{ textDecoration: 'underline', cursor: 'copy' }}
                      onClick={() =>
                        copyText(state.data.name).then(() =>
                          toast.success('Copied asset ID to clipboard')
                        )
                      }
                    >
                      {state.data.name}
                    </figcaption>
                  </figure>
                  <figure className={styles.detailsStat}>
                    <h3 className={styles.detailsStatTitle}>Published</h3>
                    <time
                      className={styles.detailsStatText}
                      dateTime={new Date(
                        state.data.created_at || new Date()
                      ).toISOString()}
                    >
                      {new Date(
                        state.data.created_at || new Date()
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </time>
                  </figure>
                  <figure className={styles.detailsStat}>
                    <h3 className={styles.detailsStatTitle}>Last updated</h3>
                    <time
                      className={styles.detailsStatText}
                      dateTime={new Date(
                        state.data.updated_at || new Date()
                      ).toISOString()}
                    >
                      {new Date(
                        state.data.updated_at || new Date()
                      ).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </time>
                  </figure>
                  <figure className={styles.detailsStat}>
                    <h3 className={styles.detailsStatTitle}>Downloads</h3>
                    <figcaption className={styles.detailsStatText}>
                      {assetDownloads === -1
                        ? '?'
                        : shortNumber(assetDownloads)}
                    </figcaption>
                  </figure>
                </figure>
              </figure>
            </aside>
          </section>
          {/*<section className={styles.divider} />*/}
          {/*<section className={styles.discover}>*/}
          {/*  <header className={styles.discoverHeader}>*/}
          {/*    <h2 className={styles.discoverTitle}>More by</h2>*/}
          {/*    <Link to="/" className={styles.discoverAuthor}>*/}
          {/*      <figure*/}
          {/*        className={styles.discoverAuthorAvatar}*/}
          {/*        style={{*/}
          {/*          backgroundImage: `url(${item.authorAvatar})`,*/}
          {/*        }}*/}
          {/*      />*/}
          {/*      <figcaption className={styles.discoverAuthorName}>*/}
          {/*        @{item.authorName}*/}
          {/*      </figcaption>*/}
          {/*    </Link>*/}
          {/*  </header>*/}
          {/*  <ExchangeItemRow*/}
          {/*    items={item.discoverItems}*/}
          {/*    activeItemId={activeItemId}*/}
          {/*    setActiveItemId={setActiveItemId}*/}
          {/*    navigate={navigate}*/}
          {/*    style={{ marginTop: 30 }}*/}
          {/*  />*/}
          {/*</section>*/}
          <Footer />
        </>
      )}
      {state.loading && <Loader scale={1.5} className={styles.loader} />}
      {state.error && <div className={styles.error}>{state.errorMessage}</div>}
    </div>
  );
}
