import styles from './Root.module.css';
import commonStyles from '../common.module.css';

import { NavLink, Outlet } from 'react-router-dom';

export default function CreationsRoot() {
  return (
    <main className={commonStyles.splitRoot}>
      <aside className={commonStyles.splitAside}>
        <nav className={commonStyles.splitAsideNav}>
          <NavLink
            to="/creations/worlds"
            className={({ isActive }) =>
              `${commonStyles.splitAsideNavLink} ${
                isActive && commonStyles.splitAsideNavLinkActive
              }`
            }
          >
            Worlds
          </NavLink>
          <NavLink
            to="/creations/assets"
            className={({ isActive }) =>
              `${commonStyles.splitAsideNavLink} ${
                isActive && commonStyles.splitAsideNavLinkActive
              }`
            }
          >
            Developer Asset
          </NavLink>
          {/*<NavLink
            to="/creations/playeritems"
            className={({ isActive }) =>
              `${commonStyles.splitAsideNavLink} ${
                isActive && commonStyles.splitAsideNavLinkActive
              }`
            }
          >
            Player Items
          </NavLink>*/}
        </nav>
      </aside>
      <article className={commonStyles.splitContent}>
        <Outlet />
      </article>
    </main>
  );
}
