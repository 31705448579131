import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../../../hook/useUser';
import useMutation from '../../../../hook/useMutation';
import useModal from '../../../../hook/useModal';
import usePageConfig from '../../../../hook/usePageConfig';

import styles from './index.module.css';
import commonStyles from '../../../common.module.css';
import { processError } from '../../../../utils';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import {
  deleteAsset,
  getAsset,
  getAssetLinks,
  getAssetScreenshots,
  updateAssetLinks,
  updateAssetScreenshots,
} from '../../../../api/asset';

import type { AssetItem } from '../../../../modal';

import { NavLink, Outlet } from 'react-router-dom';
import Overlay, { OverlayHeader } from '../../../../Comps/Overlay';
import Modal from '../../../../Comps/Modal';
import Button from '../../../../Comps/Button';
import Loader from '../../../../Comps/Loader';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as AssetIcon } from '../../../../assets/icons/assetIcon.svg';

type AssetItemExtended = AssetItem & {
  links: Record<string, string>;
  showcase: string[];
};

type State =
  | {
      data: null;
      loading: true;
      error: false;
      errorMessage: '';
    }
  | {
      data: AssetItemExtended;
      loading: false;
      error: false;
      errorMessage: '';
    }
  | {
      data: null;
      loading: false;
      error: true;
      errorMessage: string;
    };

export type OutletContext = {
  asset: AssetItemExtended;
  update: any;
  setUpdate: (newState: any) => void | any;
  setUpdateField: (field: string, value: any) => void | any;
  //removeUpdateField: (field: string) => void | any;
  //mutation: boolean;
  getField: <ReturnType>(
    field: string | any,
    backup?: ReturnType | undefined,
    doNotOverride?: boolean
  ) => ReturnType | undefined;
  savingData: boolean;
  setSavingData: (newState: boolean) => void;
  fetchAsset: (force?: boolean) => void;
};

export default function AssetsAssetManagementWrapper() {
  const navigate = useNavigate();

  const { assetId } = useParams<{ assetId: string }>();
  const { isLoading, isLoggedIn } = useUser();

  const [state, setState] = useState<State>({
    data: null,
    loading: true,
    errorMessage: '',
    error: false,
  });

  const fetchAsset = useCallback(
    (force = false, signal?: AbortSignal) => {
      if (force)
        setState({
          data: null,
          loading: true,
          errorMessage: '',
          error: false,
        });
      Promise.all([
        getAsset(assetId as string, true, signal),
        getAssetLinks(assetId as string, signal),
      ]).then(([assetResult, assetLinksResult]) => {
        if (!assetResult.exists) return navigate('/creations/assets');

        if (!assetResult.success || !assetLinksResult.success)
          return setState({
            data: null,
            loading: false,
            errorMessage: assetResult.error,
            error: true,
          });

        const links = assetLinksResult.data;
        let showcase: string[] = [];

        if (
          typeof links.showcase === 'object' &&
          links.showcase !== null &&
          Array.isArray(links.showcase) && // @ts-ignore
          links.showcase.length > 0
        )
          showcase = [...links.showcase];

        delete links.showcase;

        const data = {
          ...assetResult.data,
          links: assetLinksResult.data,
          showcase,
        };

        console.log('Fetched asset', data);

        setState({
          data,
          loading: false,
          errorMessage: '',
          error: false,
        });
      });
    },
    [assetId]
  );

  useEffect(() => {
    if (!assetId) return navigate('/creations/assets');
    if (isLoading || !isLoggedIn) return;

    const controller = new AbortController();
    fetchAsset(false, controller.signal);

    return () => controller.abort();
  }, [assetId, isLoading, fetchAsset, isLoggedIn]);

  const { mutation, setUpdateField, update, setUpdate, getField, reset } =
    useMutation(state.data, undefined, ['showcase']);
  const [savingData, setSavingData] = useState(false);

  useEffect(() => {
    if (!mutation) return;
    window.onbeforeunload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [mutation]);

  const [modalController, modal] = useModal();

  const handleDeleteAsset = async () => {
    if (savingData) return;
    const result = await modal.open({
      title: 'Delete Asset',
      description:
        'Are you sure you want to permanently delete this asset? This action cannot be undone.',
      actions: [
        {
          label: 'cancel',
          variant: 'dark',
          value: 'cancel',
        },
        {
          label: (
            <>
              <TrashIcon />
              delete
            </>
          ),
          variant: 'red',
          value: 'confirm',
        },
      ],
    });

    if (result !== 'confirm') return;
    setSavingData(true);

    const toastId = toast.loading('Deleting asset...');
    const response = await deleteAsset(assetId as string);
    setSavingData(false);
    if (!response.success) return toast.error(response.error, { id: toastId });
    toast.success('Asset deleted successfully', { id: toastId });
    navigate('/creations/assets');
  };

  const handleEditAsset = useCallback(
    async (passive: boolean) => {
      if (savingData) return;
      setSavingData(true);

      const updateClone = { ...update };
      if (typeof updateClone.type_id === 'string') {
        updateClone.type_id = parseInt(update.type_id);
      }
      if (typeof updateClone.price === 'string') {
        if (updateClone.price === '') updateClone.price = 0;
        if (isNaN(Number(updateClone.price))) {
          setSavingData(false);
          return toast.error('Price must be a number');
        }

        updateClone.price = parseFloat(updateClone.price);
      }

      try {
        const linksUpdated =
          typeof updateClone.links === 'object' &&
          updateClone.links !== null &&
          Object.keys(updateClone.links).length > 0;
        const showcaseUpdated =
          typeof updateClone.showcase === 'object' &&
          updateClone.showcase !== null &&
          Array.isArray(updateClone.showcase);

        if (linksUpdated || showcaseUpdated) {
          let updateSet = {
            ...(state?.data?.links ?? {}),
            showcase: state?.data?.showcase ?? [],
          };
          if (linksUpdated)
            updateSet = {
              ...updateSet,
              ...updateClone.links,
            };

          if (showcaseUpdated) {
            // de-allocate unused screenshot slots
            const imagesResult = await getAssetScreenshots(assetId as string);
            if (!imagesResult.success) {
              setSavingData(false);
              return toast.error(imagesResult.error);
            }
            const imagesUpdateResult = await updateAssetScreenshots(
              assetId as string,
              imagesResult.data.filter((url) =>
                updateClone.showcase.includes(url)
              )
            );
            if (!imagesUpdateResult.success) {
              setSavingData(false);
              return toast.error(imagesUpdateResult.error);
            }

            updateSet.showcase = updateClone.showcase;
          }

          const updateResult = await updateAssetLinks(
            assetId as string,
            updateSet
          );
          if (!updateResult.success) {
            setSavingData(false);
            return toast.error(updateResult.error);
          }

          delete updateClone.links;
        }

        if (Object.keys(updateClone).length > 0) {
          await axios.patch('/asset', {
            id: assetId,
            ...updateClone,
          });
        }
        //reset();
        setSavingData(false);
        if (passive) {
          fetchAsset(false);
          toast.success('Asset saved successfully');
        } else navigate('/creations/assets');
      } catch (e) {
        setSavingData(false);
        toast.error(`Error: ${processError(e)}`);
      }
    },
    [
      update,
      assetId,
      savingData,
      fetchAsset,
      state?.data?.links,
      state?.data?.showcase,
    ]
  );

  useEffect(() => {
    const onKeyDown = (ev: KeyboardEvent) => {
      ev = ev || window.event; // Event object 'ev'
      const key = ev.which || ev.keyCode; // Detecting keyCode

      const ctrl =
        ev.ctrlKey || ev.metaKey
          ? ev.ctrlKey || ev.metaKey
          : key === 17
          ? true
          : false;

      if (key == 83 && ctrl) {
        ev.preventDefault();
        handleEditAsset(true);
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [handleEditAsset]);

  usePageConfig({
    title: `${state.data?.title || 'Asset'} | HELIX Creator Hub`,
  });

  return (
    <Overlay>
      <Modal controller={modalController} />
      <OverlayHeader
        closeable
        closeDisabled={savingData}
        onClose={async () => {
          if (mutation) {
            const result = await modal.open({
              title: 'Unsaved Changes',
              description:
                'Are you sure you want to leave without saving your changes?',
              escapable: false,
              closeable: false,
              actions: [
                {
                  label: "don't save",
                  variant: 'red',
                  value: 'confirm',
                },
                {
                  label: 'cancel',
                  variant: 'dark',
                  value: 'cancel',
                },
              ],
            });
            if (result !== 'confirm') return;
          }
          /*if (
            mutation &&
            !confirm(
              'Are you sure you want to leave without saving your changes?'
            )
          )
            return;*/
          navigate('/creations/assets');
        }}
      >
        <Button
          variant={savingData ? 'dark' : ''}
          onClick={async () => {
            if (savingData) return;
            if (!mutation) return navigate('/creations/assets');

            handleEditAsset(false);
          }}
          disabled={savingData}
        >
          {!savingData && (
            <>
              <CheckIcon />
              {mutation ? 'Save & Close' : 'close'}
            </>
          )}
          {savingData && <Loader scale={0.5} />}
        </Button>
      </OverlayHeader>
      <div
        className={`${commonStyles.splitRoot} ${
          state.loading && commonStyles.splitLoading
        }`}
      >
        {!state.loading && !state.error && (
          <>
            <aside className={commonStyles.splitAside}>
              <figure className={styles.assetPreview}>
                <figure className={styles.assetIcon}>
                  {state.data.icon_url !== '' && (
                    <picture
                      style={{
                        backgroundImage: `url(${state.data.icon_url})`,
                      }}
                    />
                  )}
                  {state.data.icon_url === '' && (
                    <AssetIcon className={styles.defaultIcon} />
                  )}
                </figure>
                <figcaption className={styles.assetDetails}>
                  <h3 className={styles.assetTitle}>{state.data.title}</h3>
                  <span
                    className={`${styles.assetVisibility} ${
                      !state.data.published && styles.assetVisibilityPrivate
                    }`}
                  >
                    {state.data.published ? 'published' : 'private'}
                  </span>
                </figcaption>
              </figure>
              <nav className={commonStyles.splitAsideNav}>
                <NavLink
                  to={`/creations/assets/${assetId}`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                  end
                >
                  Details
                </NavLink>
                <NavLink
                  to={`/creations/assets/${assetId}/showcase`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Showcase
                </NavLink>
                <NavLink
                  to={`/creations/assets/${assetId}/config`}
                  className={({ isActive }) =>
                    `${commonStyles.splitAsideNavLink} ${
                      isActive && commonStyles.splitAsideNavLinkActive
                    }`
                  }
                >
                  Config
                </NavLink>

                <div
                  className={`${commonStyles.splitAsideNavLink} ${commonStyles.splitAsideNavLinkDisabled}`}
                >
                  Analytics
                  <span>coming soon</span>
                </div>
                {false && (
                  <div
                    className={`${commonStyles.splitAsideNavLink} ${commonStyles.splitAsideNavLinkDisabled}`}
                  >
                    Data
                    <span className={commonStyles.splitAsideNavLinkTag}>
                      coming soon
                    </span>
                  </div>
                )}
              </nav>
              <Button
                variant="tomato"
                className={styles.floatButton}
                onClick={handleDeleteAsset}
                disabled={savingData}
              >
                <TrashIcon /> delete asset
              </Button>
            </aside>
            <main className={`${commonStyles.splitContent}`}>
              <Outlet
                context={
                  {
                    asset: state.data as AssetItemExtended,
                    update,
                    setUpdate,
                    setUpdateField,
                    savingData,
                    getField,
                    fetchAsset,
                    setSavingData,
                  } as OutletContext
                }
              />
            </main>
          </>
        )}
        {state.loading && <Loader scale={1.5} />}
      </div>
    </Overlay>
  );
}
