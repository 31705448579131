import axios from 'axios';
import { processError } from '../utils';

import type { ResultExtendable } from '../types/common';

export interface LIXBundle {
  lix: number;
  price: number;
  normalPrice: number;
}

export const lixRate = 100;

export async function getLIXBundles(
  signal?: AbortSignal
): Promise<ResultExtendable<{ data: LIXBundle[] }>> {
  try {
    const result = await axios.get('/payment/lix', {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    const bundles: LIXBundle[] = Object.entries(result.data.payload).map(
      ([lixAmount, price]) => ({
        lix: typeof lixAmount === 'number' ? lixAmount : Number(lixAmount),
        price: price as number,
        normalPrice:
          typeof lixAmount === 'number'
            ? lixAmount
            : Number(lixAmount) / lixRate,
      })
    );

    return {
      success: true,
      error: '',
      data: bundles,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function purchaseLIXBundle(lixAmount: number): Promise<
  ResultExtendable<
    { stripeLink: string },
    {
      stripeLink: '';
    }
  >
> {
  try {
    const result = await axios.post('/payment/lix/buy', {
      amount: lixAmount,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        stripeLink: '',
        error: processError(result.data),
      };

    return {
      success: true,
      stripeLink: result.data.payload,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      stripeLink: '',
      error: processError(e),
    };
  }
}
