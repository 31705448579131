import { useOutletContext, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import useMutation from '../../../../../hook/useMutation';
import usePageConfig from '../../../../../hook/usePageConfig';

import type { OutletContext } from '..';
import { updateShopIAP, type IAP, uploadIAPIcon } from '../../../../../api/IAP';

import commonStyles from '../../../../common.module.css';
import { toast } from 'react-hot-toast';
import { getIAP } from '../../../../../api/IAP';
import styles from './index.module.css';

import Loader from '../../../../../Comps/Loader';
import { Link } from 'react-router-dom';
import Input, { TextArea } from '../../../../../Comps/Input';
import Select from '../../../../../Comps/Select';
import Button from '../../../../../Comps/Button';
import { selectFile } from '../../../../../utils';

import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/arrowIcon.svg';
import { ReactComponent as LIXIcon } from '../../../../../assets/icons/lixIcon.svg';
import { ReactComponent as CheckIcon } from '../../../../../assets/icons/checkIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trashIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TagIcon } from '../../../../../assets/icons/tagIcon.svg';

type State =
  | { loading: true }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      data: IAP;
    };

export default function CreationsWorldsWorldManagerIAPsIAPManagerPage() {
  const { iapId } = useParams<{ iapId: string }>();
  const navigate = useNavigate();

  const { savingData, setSavingData } = useOutletContext<OutletContext>();

  const fetchIAP = useCallback(
    async (signal?: AbortSignal) =>
      getIAP(iapId as string, signal).then(
        ({ success, error, data, exists }) => {
          if (!success) {
            setState({ loading: false, error: true, errorMessage: error });
            return toast.error(error);
          }
          if (!exists) return navigate('../iaps');

          setState({ loading: false, error: false, data });
        }
      ),
    [iapId]
  );

  const [state, setState] = useState<State>({ loading: true });
  useEffect(() => {
    if (typeof iapId !== 'string' || iapId === '') return navigate('../iaps');
    const abortController = new AbortController();

    fetchIAP(abortController.signal);

    return () => abortController.abort();
  }, [iapId, navigate, fetchIAP]);

  const { getField, mutation, update, setUpdateField, reset } = useMutation(
    state.loading || state.error ? null : state.data
  );
  const [validation, setValidation] = useState<{
    valid: boolean;
    error: {
      title: string;
      description: string;
      price: string;
      category: string;
    };
  }>({
    valid: true,
    error: {
      title: '',
      description: '',
      price: '',
      category: '',
    },
  });
  useEffect(() => {
    if (Object.keys(update).length === 0 || !mutation) return;

    const i = setTimeout(() => {
      const errors: Partial<{
        title: string;
        description: string;
        price: string;
        category: string;
      }> = {};

      if (typeof update.title === 'string') {
        if (update.title.length === 0) errors.title = 'This field is required';
        else if (update.title.length > 100)
          errors.title = 'Maximum length is 100 characters';
      }

      if (typeof update.price === 'string') {
        if (update.price === '') errors.price = 'This field is required';
        else if (isNaN(parseInt(update.price)))
          errors.price = 'Must be a number';
        else if (parseInt(update.price) <= 0) errors.price = 'Must be positive';
        else if (parseInt(update.price) > 100000000)
          errors.price = 'Maximum price is 100M';
        else if (update.price.match(/\./)) errors.price = 'Must be an integer';
      }

      if (typeof update.description === 'string') {
        if (update.description.length > 100)
          errors.description = 'Maximum length is 100 characters';
      }

      if (typeof update.category === 'string') {
        if (update.category === '') errors.category = 'This field is required';
        if (update.category.length > 12)
          errors.category = 'Max length is 12 characters';
      }

      setValidation({
        valid: Object.keys(errors).length === 0,
        error: {
          title: '',
          description: '',
          price: '',
          category: '',
          ...errors,
        },
      });
    }, 500);

    return () => clearTimeout(i);
  }, [update, mutation]);

  usePageConfig({
    title: `${
      state.loading || state.error ? 'World' : state.data.title
    } | HELIX Hub`,
  });

  return (
    <div className={`${commonStyles.splitContentWrapper} ${styles.root}`}>
      {state.loading && <Loader scale={1.25} className={styles.rootLoader} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <div className={commonStyles.splitContentWrapperLimit}>
          <h1
            className={`${commonStyles.splitContentTitle} ${commonStyles.row}`}
            style={{ marginBottom: 24, gap: 14 }}
          >
            <Link to="../iaps" className={styles.arrow}>
              <ArrowIcon />
            </Link>
            Edit In-World Purchase
          </h1>
          <section className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              General
            </h3>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Display Name
              </figcaption>
              <Input
                className={commonStyles.formGroupInput}
                value={getField<string>('title')}
                onChange={(e) => setUpdateField('title', e.target.value)}
                disabled={savingData}
                maxLength={100}
                placeholder="Super speed"
              />
              {validation.error.title !== '' && (
                <figcaption className={commonStyles.formGroupError}>
                  {validation.error.title}
                </figcaption>
              )}
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Category
              </figcaption>
              <Input
                value={getField<string>('category')}
                onChange={(e) => setUpdateField('category', e.target.value)}
                disabled={savingData}
                maxLength={12}
                placeholder="powerup"
              />
              {validation.error.category !== '' && (
                <figcaption className={commonStyles.formGroupError}>
                  {validation.error.category}
                </figcaption>
              )}
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Description
              </figcaption>
              <TextArea
                className={commonStyles.formGroupInput}
                value={getField<string>('description')}
                onChange={(e) => setUpdateField('description', e.target.value)}
                disabled={savingData}
                maxLength={100}
                placeholder="Increases your maximum speed by 50% for 1 hour. Glide past your opponents with ease"
              />
              {validation.error.description !== '' && (
                <figcaption className={commonStyles.formGroupError}>
                  {validation.error.description}
                </figcaption>
              )}
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Price
              </figcaption>
              <Input
                className={commonStyles.formGroupInput}
                value={`${getField<string>('price')}`}
                onChange={(e) =>
                  setUpdateField('price', e.target.value.replace(/[^0-9]/, ''))
                }
                icon={<LIXIcon />}
                style={{ width: 130 }}
                disabled={savingData}
                placeholder="10"
              />
            </figure>
          </section>
          <section className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Icon
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
              <figcaption className={commonStyles.formGroupLabel}>
                JPG or PNG less than 1MB. Recommended size: 1080x1080.
              </figcaption>
              <Button
                disabled={savingData}
                style={{ width: 'fit-content' }}
                onClick={async () => {
                  if (savingData) return;
                  const file = await selectFile('image/png,image/jpeg');
                  if (!file) return;
                  setSavingData(true);

                  const toastId = toast.loading('Uploading icon...');
                  const result = await uploadIAPIcon(iapId as string, file);
                  setSavingData(false);
                  if (!result.success)
                    return toast.error(result.error, { id: toastId });

                  toast.success('Uploaded icon', { id: toastId });
                  fetchIAP();
                }}
              >
                <UploadIcon /> upload
              </Button>
              <figure className={styles.preview}>
                {state.data.icon_url !== '' && (
                  <picture
                    style={{ backgroundImage: `url('${state.data.icon_url}')` }}
                  />
                )}
                {state.data.icon_url === '' && (
                  <TagIcon className={styles.defaultPreview} />
                )}
              </figure>
            </figure>
          </section>
          <section className={commonStyles.splitContentDivider} />
          <section
            className={`${commonStyles.splitContentSection} ${styles.actions}`}
          >
            <Button
              variant="sky"
              disabled={savingData || !validation.valid || !mutation}
              onClick={async () => {
                if (savingData || !validation.valid || !mutation) return;
                setSavingData(true);

                const toastId = toast.loading('Saving changes...');
                const updateClone = { ...update };
                if (typeof updateClone.price === 'string')
                  updateClone.price = parseInt(updateClone.price);
                const result = await updateShopIAP(
                  iapId as string,
                  updateClone
                );
                setSavingData(false);
                if (!result.success)
                  return toast.error(result.error, { id: toastId });

                toast.success('Saved changes', { id: toastId });
                fetchIAP().then(() => reset());
              }}
            >
              <CheckIcon />
              save changes
            </Button>
            <Button variant="tomato" disabled={savingData}>
              <TrashIcon />
              delete
            </Button>
          </section>
        </div>
      )}
    </div>
  );
}
