import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useActiveItemId } from '../../Comps/Item';
import { useUser } from '../../hook/useUser';

import type { ExchangeItem } from '../../type/Item';
import type { AssetItem } from '../../modal';
import type { OutletContext, SearchFilter } from './SearchLayout';

import styles from './Search.module.css';
import { ItemCategory } from '../../type/Item';
import {
  getAllAsset,
  getAllAssetsByType,
  getPublicAssets,
} from '../../api/asset';
import { randomString } from '../../utils';

import { ExchangeItemGrid } from '../../Comps/Item';
import Button from '../../Comps/Button';
import Input from '../../Comps/Input';
import Loader from '../../Comps/Loader';
import Footer from '../../Comps/Footer';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';

// @ts-ignore
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

type State = {
  loading: boolean;
  error: string | '';
  items: AssetItem[];
};

export default function ExchangeSearchPage() {
  const navigate = useNavigate();

  const { searchFilter, setSearchFilter, category, creatorState } =
    useOutletContext<OutletContext>();

  const instanceID = useMemo(() => randomString(16), []);
  /* useEffect(() => {
    if (
      typeof categoryParam !== 'string' ||
      categoryParam === '' ||
      categoryParam === 'search' ||
      categoryParam === 'all'
    )
      return setCategory('');

    if (Object.values(ItemCategory).includes(categoryParam as any))
      return setCategory(categoryParam as any);

    if (
      Object.values(ItemCategory).includes(
        categoryParam.replace(/s$/, '') as any
      )
    )
      return setCategory(categoryParam.replace(/s$/, '') as any);

    return navigate('/exchange/search');
  }, [categoryParam, navigate]);*/

  /*const [searchFilter, setSearchFilter] = useState<SearchFilter>({
    subcategory: '',
    tags: [],
    creator: '',
    maxPrice: -1,
    minDiscount: 0,
    packsOnly: false,
    query: '',
  });*/

  useEffect(() => {
    setSearchFilter((filter) => ({
      ...filter,
      subcategory: '',
    }));
  }, [category]);

  const { isLoading, isLoggedIn } = useUser();
  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    items: [],
  });
  const [shadow, setShadow] = useState<string | null>(null);
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    /*if (shadow !== null && shadow === `${category}`)
      return console.log(
        `[ ${instanceID} ] - Shadow is same, skipping re-fetch...`
      );*/
    if (shadow !== null && shadow !== `${category}`)
      return console.log(
        `[ ${instanceID} ] - Query changed, being un-rendered by animated router, skipping re-fetch...`
      );
    console.log(`[ ${instanceID} ] - [initial] Query changed, re-fetching...`);
    setShadow(`${category}`);

    setState((previous) => ({ ...previous, loading: true }));

    const abortController = new AbortController();
    getPublicAssets(
      {
        type:
          category === ''
            ? undefined
            : {
                character: 200,
                map: 100,
                other: 700,
                prop: 500,
                script: 600,
                vehicle: 300,
                weapon: 400,
                all: 0,
              }[category] ?? undefined,
        tags:
          searchFilter.tags.length > 0
            ? searchFilter.tags.join(',')
            : undefined,
        creator: searchFilter.creator !== '' ? searchFilter.creator : undefined,
      },
      1,
      abortController.signal
    ).then(({ success, error, data }) => {
      if (!success)
        return setState({
          loading: false,
          error: error,
          items: [],
        });

      setState({
        loading: false,
        error: '',
        items: data,
      });
    });
    /*getAllAssetsByType(category, searchFilter.creator, abortController.signal)
      .then((items) => {
        setState((previous) => ({ ...previous, loading: false, items }));
      })
      .catch((error) => {
        setState((previous) => ({ ...previous, loading: false, error }));
      });*/

    return () => abortController.abort();
  }, [
    category,
    isLoading,
    searchFilter.creator,
    searchFilter.tags,
    isLoggedIn,
  ]);

  /*const { data, isLoading, isError } = useQuery(
    ['exchange', 'search', category],
    () => getAllAssetByFilter({ type: category }, 0)
  );*/

  const items = useMemo<AssetItem[]>(
    () =>
      state.loading || state.items.length === 0
        ? []
        : state.items.filter((item: AssetItem) => {
            // // TODO: implement tags & subcategory filter
            // if (
            //   searchFilter.creator !== '' &&
            //   item.creator?.id !== searchFilter.creator
            // )
            //   return false;

            if (
              searchFilter.maxPrice !== -1 &&
              item.price > searchFilter.maxPrice
            )
              return false;

            // if (searchFilter.minDiscount !== 0 && item.price !== 0) {
            //   if (item.originalPrice === item.price) return false;
            //
            //   const discount = Math.floor(
            //     (1 - item.price / item.originalPrice) * 100
            //   );
            //
            //   if (discount < searchFilter.minDiscount) return false;
            // }

            // TODO: implement packsOnly filter

            if (
              searchFilter.query !== '' &&
              !item.title
                .toLowerCase()
                .includes(searchFilter.query.toLowerCase())
            )
              return false;

            return true;
          }),
    [
      state.loading,
      state.items,
      searchFilter.creator,
      searchFilter.maxPrice,
      searchFilter.minDiscount,
      searchFilter.query,
    ]
  );

  const [activeItemId, setActiveItemId] = useActiveItemId();

  return (
    <main className={styles.main}>
      {state.loading ? (
        <Loader scale={2} className={styles.mainLoader} />
      ) : (
        <>
          <ExchangeItemGrid
            items={items}
            activeItemId={activeItemId}
            setActiveItemId={setActiveItemId}
            style={{ paddingLeft: 32, paddingRight: 'var(--gutter)' }}
          />
          <Footer slim />
        </>
      )}
    </main>
  );
}
