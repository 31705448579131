import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { ItemsCreateNavCompo } from '../../../.archive/ItemsCreateNav.compo';
import { ItemsEditNavCompo } from '../../../.archive/ItemsEditNav.compo';
import MainNavCompo from './Nav';
import { LogoNavCompo } from '../../../.archive/LogoNav.compo';
import { StepNavCompo } from '../../../.archive/StepNav.compo';

const NO_NAV_PATHS = [
  '/auth/login',
  '/signup',
  '/auth/recovery',
  '/auth/verify',
  "/auth/emailchange",
];

export const NavCompo = (props: { hide?: boolean }) => {
  const location = useLocation();

  const navEnabled = !NO_NAV_PATHS.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <Header
      style={
        navEnabled
          ? {}
          : {
              pointerEvents: 'none',
              opacity: 0,
              transform: 'translateY(-3px)',
            }
      }
    >
      <MainNavCompo />
    </Header>
  );
};

const Header = styled.div`
  position: fixed;
  height: 72px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  transition: all 0.2s ease-in;
`;
