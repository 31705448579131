import { useState, useEffect, useRef } from 'react';
import { useUser } from '../../hook/useUser';
import usePageConfig from '../../hook/usePageConfig';
import useModal from '../../hook/useModal';
import useMutation from '../../hook/useMutation';

import type { UserData } from '../../hook/useUser';

import styles from './details.module.css';
import sharedStyles from './shared.module.css';
import commonStyles from '../common.module.css';
import { selectFile } from '../../utils';
import { toast } from 'react-hot-toast';
import {
  changeUserPassword,
  checkEmailValid,
  checkUsernameValid,
  resendEmailVerification,
  updateUser,
  sendEmailChangeConfirmation,
  uploadUserAvatar,
} from '../../api/user';
import countriesData from '../../data/countries.json';

import Input, { TextArea } from '../../Comps/Input';
import Button from '../../Comps/Button';
import Select from '../../Comps/Select';
import Loader from '../../Comps/Loader';
import Modal, { modalActions } from '../../Comps/Modal';

import { ReactComponent as PencilIcon } from '../../assets/icons/pencilIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/uploadIcon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/checkIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warningIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/checkCircleIcon.svg';

type State =
  | { loading: true }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      user: UserData;
    };

enum ModalType {
  Change_Username = 0,
  Change_Password = 1,
  Change_Email = 2,
}

const PASSWORD_RESET_COOLDOWN = 60; // seconds

export default function AccountDetailsPage() {
  usePageConfig({ title: 'Account Details | HELIX Hub' });
  const { isLoading, isLoggedIn, currentUser, error, fetchUser } = useUser();

  const [state, setState] = useState<State>({ loading: true });
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    if (error !== '' || !currentUser)
      return setState({
        loading: false,
        error: true,
        errorMessage: error !== '' ? error : 'An unknown error occurred',
      });

    setState({ loading: false, error: false, user: currentUser });
  }, [isLoading, isLoggedIn, currentUser, error]);

  const { mutation, setUpdateField, getField, reset, update } =
    useMutation(currentUser);
  const [saving, setSaving] = useState(false);

  const [modalType, setModalType] = useState<ModalType>(
    ModalType.Change_Username
  );
  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    username: string;
    usernameValid: boolean;
    newPassword: string;
    currentPassword: string;
    newEmail: string;
    newEmailValid: boolean;
  }>({
    username: '',
    usernameValid: true,
    newPassword: '',
    currentPassword: '',
    newEmail: '',
    newEmailValid: true,
  });

  useEffect(() => {
    if (modalData.username === '') return;

    const timeout = setTimeout(
      () =>
        checkUsernameValid(modalData.username).then((res) =>
          setModalData((prev) => ({
            ...prev,
            usernameValid: res.valid && !res.taken,
          }))
        ),
      500
    );

    return () => clearTimeout(timeout);
  }, [modalData.username]);

  const [passwordResetCooldown, setPasswordResetCooldown] = useState<number>(0);
  const passwordResetTimer = useRef<null | NodeJS.Timeout>(null);

  useEffect(
    () => () => {
      try {
        clearInterval(passwordResetTimer.current!);
      } catch (e) {}
    },
    []
  );

  useEffect(() => {
    if (modalData.newEmail === '') return;

    const timeout = setTimeout(
      () =>
        checkEmailValid(modalData.newEmail).then((res) =>
          setModalData((prev) => ({
            ...prev,
            newEmailValid: res.valid && res.success && !res.registered,
          }))
        ),
      500
    );

    return () => clearTimeout(timeout);
  }, [modalData.newEmail]);

  return (
    <div className={sharedStyles.root}>
      <Modal controller={modalController}>
        {modalType === ModalType.Change_Username && (
          <>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Username
              </figcaption>
              <Input
                type="text"
                placeholder="starboy98"
                value={modalData.username}
                onChange={(e) =>
                  setModalData((prev) => ({
                    ...prev,
                    username: e.target.value,
                    usernameValid: true,
                  }))
                }
                disabled={saving}
                autoComplete="none"
                className={commonStyles.formGroupInput}
              />
              {!modalData.usernameValid && (
                <figcaption className={commonStyles.formGroupError}>
                  Username is invalid or taken
                </figcaption>
              )}
            </figure>
          </>
        )}
        {modalType === ModalType.Change_Password && (
          <>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                New password
              </figcaption>
              <Input
                type="password"
                placeholder="Super_s3cure!!"
                value={modalData.newPassword}
                onChange={(e) =>
                  setModalData((prev) => ({
                    ...prev,
                    newPassword: e.target.value,
                  }))
                }
                disabled={saving}
                autoComplete="new-password"
                className={commonStyles.formGroupInput}
              />
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Current password
              </figcaption>
              <Input
                type="password"
                placeholder="hard_to_remember222"
                value={modalData.currentPassword}
                onChange={(e) =>
                  setModalData((prev) => ({
                    ...prev,
                    currentPassword: e.target.value,
                  }))
                }
                disabled={saving}
                autoComplete="current-password"
                className={commonStyles.formGroupInput}
              />
            </figure>
          </>
        )}
        {modalType === ModalType.Change_Email && (
          <>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                New email
              </figcaption>
              <Input
                type="email"
                placeholder="conqr@ibbsinc.com"
                value={modalData.newEmail}
                onChange={(e) =>
                  setModalData((prev) => ({
                    ...prev,
                    newEmail: e.target.value,
                    newEmailValid: true,
                  }))
                }
                disabled={saving}
                autoComplete="email"
                className={commonStyles.formGroupInput}
              />
              {!modalData.newEmailValid && (
                <figcaption className={commonStyles.formGroupError}>
                  Email is invalid or already in use
                </figcaption>
              )}
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Current password
              </figcaption>
              <Input
                type="password"
                placeholder="I_forgor_it22"
                value={modalData.currentPassword}
                onChange={(e) =>
                  setModalData((prev) => ({
                    ...prev,
                    currentPassword: e.target.value,
                  }))
                }
                disabled={saving}
                autoComplete="current-password"
                className={commonStyles.formGroupInput}
              />
            </figure>
          </>
        )}
        <div className={modalActions}>
          <Button
            disabled={
              saving ||
              (modalType === ModalType.Change_Username &&
                !modalData.usernameValid) ||
              (modalType === ModalType.Change_Email && !modalData.newEmailValid)
            }
            onClick={async () => {
              if (modalType === ModalType.Change_Username) {
                // @ts-ignore
                if (modalData.username === state.user.username)
                  return modal.close();
                if (modalData.username === '')
                  return toast.error('Username cannot be empty');
              }

              if (modalType === ModalType.Change_Password) {
                if (modalData.newPassword === '')
                  return toast.error('New password cannot be empty');
                if (modalData.currentPassword === '')
                  return toast.error('Current password cannot be empty');
                if (modalData.newPassword === modalData.currentPassword)
                  return toast.error(
                    'New password cannot be the same as current password'
                  );
              }

              if (modalType === ModalType.Change_Email) {
                if (modalData.newEmail === '')
                  return toast.error('New email cannot be empty');
                // @ts-ignore
                if (modalData.newEmail === state.user.email)
                  return modal.close();

                if (modalData.currentPassword === '')
                  return toast.error('Current password cannot be empty');
              }
              setSaving(true);
              const toastId = toast.loading(
                modalType === ModalType.Change_Email
                  ? 'Sending confirmation'
                  : 'Saving changes'
              );

              if (modalType === ModalType.Change_Email) {
                const result = await sendEmailChangeConfirmation({
                  newEmail: modalData.newEmail,
                  currentPassword: modalData.currentPassword,
                });
                setSaving(false);

                if (!result.success)
                  return toast.error(result.error, { id: toastId });
                toast.success('Verification email sent', { id: toastId });
              }

              if (modalType === ModalType.Change_Username) {
                const result = await updateUser({
                  username: modalData.username,
                });
                setSaving(false);

                if (!result.success)
                  return toast.error(result.error, { id: toastId });
                toast.success('Username changed', { id: toastId });
              }

              if (modalType === ModalType.Change_Password) {
                const result = await changeUserPassword({
                  newPassword: modalData.newPassword,
                  currentPassword: modalData.currentPassword,
                });
                setSaving(false);

                if (!result.success)
                  return toast.error(result.error, { id: toastId });
                toast.success('Password changed', { id: toastId });
              }

              fetchUser();
              modal.close();
            }}
          >
            confirm
          </Button>
          <Button variant="dark" onClick={modal.close} disabled={saving}>
            cancel
          </Button>
        </div>
      </Modal>
      {state.loading && (
        <Loader className={sharedStyles.rootLoader} scale={1.5} />
      )}
      {!state.loading && state.error && (
        <div className={sharedStyles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={sharedStyles.title}>Account Details</h1>
          <header
            className={`${commonStyles.row} ${sharedStyles.header} ${styles.header}`}
          >
            <figure
              className={styles.headerIcon}
              style={
                {
                  '--theme':
                    state.user.iconColor !== ''
                      ? state.user.iconColor
                      : undefined,
                } as any
              }
            >
              <picture
                style={{ backgroundImage: `url('${state.user.iconUrl}')` }}
              />
            </figure>
            <div className={styles.headerInfo}>
              <h2 className={styles.headerInfoName}>{state.user.username}</h2>
              <p className={styles.headerInfoEmail}>{state.user.email}</p>
            </div>
          </header>
          <section className={styles.group}>
            <div className={styles.groupTitle}>Username</div>
            <div className={styles.groupContent}>
              <Input
                spellCheck={false}
                style={{ pointerEvents: 'none', userSelect: 'none' }}
                disabled
                value={state.user.username}
                className={styles.groupInput}
              />
            </div>
            <div className={styles.groupActions}>
              <Button
                variant="invisible"
                disabled={saving}
                onClick={() => {
                  setModalType(ModalType.Change_Username);
                  setModalData((prev) => ({
                    ...prev,
                    username: '',
                    usernameValid: true,
                  }));
                  modal.open({
                    title: 'Change username',
                    description: 'Choose something unique',
                    actions: [],
                    escapable: false,
                    closeable: false,
                  });
                }}
              >
                <PencilIcon /> edit
              </Button>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.group}>
            <div className={styles.groupTitle}>Email</div>
            <div className={styles.groupContent}>
              <Input
                spellCheck={false}
                disabled
                value={state.user.email}
                className={styles.groupInput}
                rightIcon={
                  state.user.emailVerified && (
                    <CheckCircleIcon className={styles.emailVerified} />
                  )
                }
              />
              {!state.user.emailVerified && (
                <div className={`${commonStyles.row} ${styles.emailWarning}`}>
                  <figure className={commonStyles.row}>
                    <WarningIcon />
                    Your current email address is not verified
                  </figure>
                  <Button
                    variant="dark"
                    disabled={saving || passwordResetCooldown > 0}
                    onClick={async () => {
                      setSaving(true);
                      try {
                        clearInterval(passwordResetTimer.current!);
                      } catch (e) {}

                      const toastId = toast.loading(
                        'Sending verification email'
                      );
                      const result = await resendEmailVerification();

                      setSaving(false);
                      if (!result.success)
                        return toast.error(result.error, { id: toastId });
                      toast.success('Verification email sent', { id: toastId });
                      setPasswordResetCooldown(PASSWORD_RESET_COOLDOWN);
                      passwordResetTimer.current = setInterval(() => {
                        setPasswordResetCooldown((prev) => {
                          if (prev <= 1) {
                            clearInterval(passwordResetTimer.current!);
                          }
                          return prev - 1;
                        });
                      }, 1000);
                    }}
                  >
                    {passwordResetCooldown > 0 ? (
                      <>
                        <svg
                          width="50"
                          height="50"
                          viewBox="-6.25 -6.25 62.5 62.5"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ transform: 'rotate(-90deg) scale(1.4)' }}
                        >
                          <circle
                            r="18"
                            cx="25"
                            cy="25"
                            fill="transparent"
                            stroke="#282828"
                            stroke-width="8"
                            stroke-dasharray="94.2px"
                            stroke-dashoffset="0"
                          ></circle>
                          <circle
                            r="18"
                            cx="25"
                            cy="25"
                            stroke="#7e7e7e"
                            stroke-width="8"
                            stroke-linecap="round"
                            style={{
                              transition: 'stroke-dashoffset 1s linear',
                            }}
                            stroke-dashoffset={Math.max(
                              0,
                              94 -
                                Math.floor(
                                  94 *
                                    (passwordResetCooldown /
                                      PASSWORD_RESET_COOLDOWN)
                                )
                            )}
                            fill="transparent"
                            stroke-dasharray="94.2px"
                          ></circle>
                        </svg>
                        <span style={{ width: 30, textTransform: 'none' }}>
                          {passwordResetCooldown}s
                        </span>
                      </>
                    ) : (
                      <>
                        <CheckIcon />{' '}
                      </>
                    )}
                    verify
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.groupActions}>
              <Button
                variant="invisible"
                disabled={saving}
                onClick={() => {
                  setModalType(ModalType.Change_Email);
                  setModalData((prev) => ({
                    ...prev,
                    newEmail: '',
                    currentPassword: '',
                  }));
                  modal.open({
                    title: 'Change email',
                    description:
                      "You'll need to verify your old email address to make this change",
                    actions: [],
                    escapable: false,
                    closeable: false,
                  });
                }}
              >
                <PencilIcon /> edit
              </Button>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.group}>
            <div className={styles.groupTitle}>Password</div>
            <div className={styles.groupContent}>
              <Input
                disabled
                type="password"
                autoComplete="none"
                style={{ pointerEvents: 'none', userSelect: 'none' }}
                value={new Array(16).fill('*').join('')}
                className={styles.groupInput}
              />
            </div>
            <div className={styles.groupActions}>
              <Button
                variant="invisible"
                disabled={saving}
                onClick={() => {
                  setModalType(ModalType.Change_Password);
                  setModalData((prev) => ({
                    ...prev,
                    newPassword: '',
                    currentPassword: '',
                  }));
                  modal.open({
                    title: 'Change password',
                    description: 'Make sure to use a strong password',
                    actions: [],
                    escapable: false,
                    closeable: false,
                  });
                }}
              >
                <PencilIcon /> edit
              </Button>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.group}>
            <div className={styles.groupTitle}>Avatar</div>
            <div className={styles.groupContent}>
              <figure className={`${commonStyles.row} ${styles.avatar}`}>
                <picture
                  style={{
                    backgroundImage: `url('${state.user.iconUrl}')`,
                  }}
                />
                <figcaption>Current avatar</figcaption>
              </figure>
              <figure className={`${commonStyles.row} ${styles.avatarEditor}`}>
                <div className={`${commonStyles.row} ${styles.avatarInfo}`}>
                  <picture>
                    <UserIcon />
                  </picture>
                  <div className={styles.avatarInfoText}>
                    <p className={styles.avatarInfoTextTitle}>
                      Upload an image for your avatar
                    </p>
                    <p className={styles.avatarInfoTextTagline}>
                      PNG, JPG, or WEBP (524 KB max)
                    </p>
                  </div>
                </div>
                <Button
                  disabled={saving}
                  onClick={async () => {
                    const file = await selectFile(
                      'image/png, image/jpeg, image/webp'
                    );
                    if (!file) return toast.error('No file selected');
                    setSaving(true);

                    const toastId = toast.loading('Uploading avatar');
                    const result = await uploadUserAvatar(file);
                    setSaving(false);

                    if (!result.success)
                      return toast.error(result.error, { id: toastId });

                    toast.success('Avatar uploaded', { id: toastId });
                    fetchUser();
                  }}
                >
                  <UploadIcon /> upload
                </Button>
              </figure>
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.group}>
            <div className={styles.groupTitle}>Country</div>
            <div className={styles.groupContent}>
              <Select
                disabled={saving}
                className={styles.groupInput}
                options={countriesData.map((country) => ({
                  value: country.code,
                  label: country.name,
                }))}
                value={
                  countriesData.find(
                    (country) =>
                      country.code === getField<string>('country', 'US')
                  )?.code || 'US'
                }
                onChange={(e) => setUpdateField('country', e as string)}
                placeholder="Select your country"
              />
            </div>
          </section>
          <div className={styles.divider} />
          <section className={styles.group}>
            <div className={styles.groupTitle}>Bio (optional)</div>
            <div className={styles.groupContent}>
              <TextArea
                className={styles.groupInput}
                placeholder="I am a passionate HELIX creator!"
                style={{ height: 164 }}
                value={getField<string>('bio', '')}
                onChange={(e) => setUpdateField('bio', e.target.value)}
                maxLength={400}
                disabled={saving}
              />
            </div>
          </section>
          <div className={styles.divider} />
          <div className={`${commonStyles.row} ${styles.actions}`}>
            <Button
              variant="dark"
              disabled={!mutation || saving}
              onClick={reset}
            >
              reset
            </Button>
            <Button
              variant="sky"
              disabled={!mutation || saving}
              onClick={async () => {
                if (!mutation || saving) return;
                setSaving(true);

                const toastId = toast.loading('Updating your profile...');
                const result = await updateUser(update);
                setSaving(false);

                if (!result.success)
                  return toast.error(result.error, { id: toastId });
                toast.success('Profile updated', { id: toastId });
                fetchUser();
              }}
            >
              <CheckIcon />
              Save changes
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
