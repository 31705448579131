import usePageConfig from '../../hook/usePageConfig';
import { useState, useEffect } from 'react';
import { useUser } from '../../hook/useUser';
import { useNavigate } from 'react-router-dom';
import useActiveController from '../../hook/useActiveController';

import styles from './login.module.css';
import toast from 'react-hot-toast';
import commonStyles from '../common.module.css';
import { buttonClassName } from '../../Comps/Button';

import Loader from '../../Comps/Loader';
import Input from '../../Comps/Input';
import Button from '../../Comps/Button';
import { Link } from 'react-router-dom';

export default function LoginPage() {
  usePageConfig({
    title: 'Login | HELIX Hub',
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  const { isLoading, isLoggedIn, login } = useUser();

  useEffect(() => {
    if (isLoggedIn) return navigate('/');
  }, [isLoggedIn]);

  const loaderController = useActiveController(loading, 200);

  const onSubmit = async () => {
    if (formData.email.trim() === '') return toast.error('Email is required');
    if (formData.password.trim() === '')
      return toast.error('Password is required');
    setLoading(true);

    const result = await login(formData.email, formData.password);
    if (typeof result === 'string') {
      setLoading(false);
      return toast.error(result);
    }
  };

  return (
    <div className={styles.root}>
      {loaderController.view && (
        <div
          className={`${styles.loader} ${
            loaderController.animate && styles.loaderShow
          }`}
        >
          <Loader scale={2} />
        </div>
      )}
      {!isLoading && (
        <div
          className={`${styles.wrapper} ${
            loaderController.animate && styles.wrapperHide
          }`}
        >
          <h1 className={styles.title}>Login with your HELIX account</h1>
          <form
            className={styles.form}
            action="#"
            onSubmit={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              onSubmit();
            }}
          >
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Email
              </figcaption>
              <Input
                className={commonStyles.formGroupInput}
                type="email"
                autoComplete="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, email: e.target.value }))
                }
                disabled={loading}
                placeholder="conqr@ibbsinc.com"
              />
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Password
              </figcaption>
              <Input
                className={commonStyles.formGroupInput}
                type="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }))
                }
                placeholder="password"
                disabled={loading}
              />
            </figure>
            <Link className={styles.forgotLink} to="/auth/recovery">
              forgot password
            </Link>
            <Button
              type="submit"
              className={styles.button}
              variant="sky"
              disabled={loading}
            >
              log in
            </Button>
            <Link
              to="/signup"
              className={`${buttonClassName('yellow-dark')} ${styles.button} ${
                loading && 'disabled'
              }`}
            >
              create account
            </Link>
          </form>
        </div>
      )}
    </div>
  );
}
