import { useState } from 'react';

import styles from './styles.module.css';
import toast from 'react-hot-toast';

import { NavLink } from 'react-router-dom';
import Input from '../Input';
import Button from '../Button';

import { ReactComponent as HELIXLogo } from '../../assets/logo_long.svg';
import { ReactComponent as ExternalIcon } from '../../assets/icons/externalIcon.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discordIcon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitterIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/youtubeIcon.svg';
import { ReactComponent as TwitchIcon } from '../../assets/icons/twitchIcon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/icons/facebookIcon.svg';
import { ReactComponent as TransferIcon } from '../../assets/icons/transferIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../assets/icons/checkCircleIcon.svg';
import { sendNewsletterPageClip } from '../../api/pageclip';

export default function Footer({
  className = '',
  overflow = false,
  slim = false,
  ...props
}: {
  overflow?: boolean;
  slim?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>) {
  const [email, setEmail] = useState('');
  const [emailFilled, setEmailFilled] = useState(
    /* window.localStorage.getItem('emailFilled') === 'y'*/
    true
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  return (
    <footer
      className={`${styles.root} ${overflow && styles.rootOverflow} ${
        slim && styles.rootSlim
      } ${className}`}
      {...props}
    >
      <div className={styles.nav}>
        <div className={styles.navColumn}>
          <HELIXLogo className={styles.navLogo} />
          <div className={styles.navSocial}>
            <a
              href="https://discord.gg/helixcreators"
              target="_blank"
              rel="noreferrer"
              className={styles.navSocialLink}
            >
              <DiscordIcon />
            </a>
            <a
              href="https://twitter.com/helix_metaverse"
              target="_blank"
              rel="noreferrer"
              className={styles.navSocialLink}
            >
              <TwitterIcon />
            </a>
            <a
              href="https://youtube.com/helixmetaverse"
              target="_blank"
              rel="noreferrer"
              className={styles.navSocialLink}
            >
              <YoutubeIcon />
            </a>
            <a
              href="https://twitch.tv/helixmetaverse"
              target="_blank"
              rel="noreferrer"
              className={styles.navSocialLink}
            >
              <TwitchIcon style={{ height: 18 }} />
            </a>
            <a
              href="https://facebook.com/helixgameofficial"
              rel="noreferrer"
              target="_blank"
              className={styles.navSocialLink}
            >
              <FacebookIcon />
            </a>
          </div>
        </div>
        <div className={styles.navSpacer} />
        <div className={styles.navColumn}>
          <h2 className={styles.navColumnTitle}>account</h2>
          <nav className={styles.navList}>
            <NavLink to="/account">Account Details</NavLink>
            <NavLink to="/account/wallet">Wallet</NavLink>
            <NavLink to="/account/invites">Invite Friends</NavLink>
            <NavLink to="/account/tokens">Access Tokens</NavLink>
          </nav>
        </div>
        <div className={styles.navColumn}>
          <h2 className={styles.navColumnTitle}>product</h2>
          <nav className={styles.navList}>
            <NavLink to="/creations">Creations</NavLink>
            <NavLink to="/exchange">Exchange</NavLink>
            <NavLink target="_blank" to="https://parallelcityonline.com">
              Parallel City Online
              <ExternalIcon />
            </NavLink>
            <NavLink target="_blank" to="https://helixgame.com">
              HELIX
              <ExternalIcon />
            </NavLink>
          </nav>
        </div>
        <div className={styles.navColumn}>
          <h2 className={styles.navColumnTitle}>resources</h2>
          <nav className={styles.navList}>
            <NavLink target="_blank" to="https://helixgame.com/docs">
              API Docs
              <ExternalIcon />
            </NavLink>

            <NavLink target="_blank" to="https://legal.helixgame.com/privacy">
              Privacy Policy
              <ExternalIcon />
            </NavLink>
            <NavLink target="_blank" to="https://legal.helixgame.com">
              Terms of Service
              <ExternalIcon />
            </NavLink>
          </nav>
        </div>
      </div>
      <figure className={styles.newsletter}>
        <figure className={styles.newsletterText}>
          <h2>Join our Newsletter</h2>
          <p>
            Stay up-to-date with everything HELIX & participate in regular
            giveaways and events.
          </p>
        </figure>
        {!emailFilled && (
          <form
            className={styles.newsletterForm}
            action="#"
            onSubmit={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (loading) return;

              if (email.trim() === '')
                return toast.error('Please enter an email address.');
              setLoading(true);
              const id = toast.loading('Baking cookies...');
              const success = await sendNewsletterPageClip({
                email,
                domain: window.location.hostname,
                path: new URL(window.location.href).pathname,
                capture: 'footer',
              });

              setLoading(false);

              if (!success)
                return toast.error(
                  'Something went wrong. Please try again later.',
                  { id }
                );
              setEmailFilled(true);
              window.localStorage.setItem('emailFilled', 'y');
              toast.success('Thanks for subscribing!', { id });
            }}
          >
            <Input
              type="email"
              placeholder="conqr@ibbsinc.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.newsletterInput}
              disabled={loading}
              autoComplete="email"
            />
            <Button type="submit" variant="sky" disabled={loading}>
              <TransferIcon />
              subscribe
            </Button>
          </form>
        )}
        {emailFilled && (
          <div className={styles.newsletterThanks}>
            <CheckCircleIcon />
            Thanks for subscribing!
          </div>
        )}
      </figure>
      <div className={styles.footer}>
        <div className={styles.footerLeft}>
          &reg; & &copy; 2023 Hypersonic Laboratories Inc
        </div>
        <div className={styles.footerRight}>
          <figure className={styles.status}>
            <span />
            All Systems Online
          </figure>
        </div>
      </div>
    </footer>
  );
}
