import { useUser } from '../../hook/useUser';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import usePageConfig from '../../hook/usePageConfig';

import styles from './signup.module.css';
import commonStyles from '../common.module.css';
import { getInvite } from '../../api/invite';

import Loader from '../../Comps/Loader';
import Input from '../../Comps/Input';
import Button from '../../Comps/Button';

import { ReactComponent as UnlockIcon } from '../../assets/icons/unlockIcon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/warningIcon.svg';

import { ReactComponent as HELIXLogo } from '../../assets/logo_long.svg';
import { ReactComponent as KeyIcon } from '../../assets/icons/keyIcon.svg';
import { ReactComponent as UserKeyIcon } from '../../assets/icons/userKeyIcon.svg';
import {
  checkEmailValid,
  checkUsernameValid,
  createAccountWithInvite,
} from '../../api/user';
import { toast } from 'react-hot-toast';

enum Step {
  Enter_Code = 0,
  Account_Creation = 1,
  Pick_Username = 2,
  Success = 3,
}
type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
    };

export default function SignupPage() {
  usePageConfig({
    title: 'Sign Up | HELIX',
  });
  const { isLoggedIn, isLoading, login } = useUser();
  const navigate = useNavigate();

  const { code } = useParams<{ code: string | undefined }>();
  const codeExists = typeof code === 'string' && code.length > 0;
  useEffect(() => {
    if (isLoading || isLoggedIn) return;
    if (!codeExists) return;

    const abortController = new AbortController();
    getInvite(code, abortController.signal).then((result) => {
      if (!result.success) {
        setStep(Step.Enter_Code);
        return setState({
          loading: false,
          error: true,
          errorMessage: result.error,
        });
      }

      if (result.data.usages >= result.data.max_usage) {
        setStep(Step.Enter_Code);
        return setState({
          loading: false,
          error: true,
          errorMessage: 'This invite code has already been used',
        });
      }

      setState({ loading: false, error: false });
      setData({
        code,
      });
    });

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  const [step, setStep] = useState<Step>(
    codeExists ? Step.Account_Creation : Step.Enter_Code
  );
  const [state, setState] = useState<State>(
    codeExists ? { loading: true } : { loading: false, error: false }
  );
  const [data, setData] = useState<
    Partial<{ email: string; password: string; code: string; username: string }>
  >({});

  const frames = [
    <EnterCode
      loading={state.loading}
      initialCode={codeExists ? code : ''}
      onSubmit={async (newCode) => {
        setState({ loading: true });

        const result = await getInvite(newCode);
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        if (result.data.usages >= result.data.max_usage)
          return setState({
            loading: false,
            error: true,
            errorMessage: 'This invite code has already been used',
          });

        setState({ loading: false, error: false });
        setStep(Step.Account_Creation);
        setData({
          code: newCode,
        });
      }}
      error={state.loading || !state.error ? '' : state.errorMessage}
    />,
    <AccountDetails
      loading={state.loading}
      error={state.loading || !state.error ? '' : state.errorMessage}
      onSubmit={async (email, password) => {
        setState({ loading: true });
        if (email.trim() === '')
          return setState({
            loading: false,
            error: true,
            errorMessage: 'Email is required',
          });
        if (password.trim() === '')
          return setState({
            loading: false,
            error: true,
            errorMessage: 'Password is required',
          });

        const result = await checkEmailValid(email);
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        if (!result.valid)
          return setState({
            loading: false,
            error: true,
            errorMessage: 'Email is invalid',
          });

        if (result.registered)
          return setState({
            loading: false,
            error: true,
            errorMessage: 'Email is already registered',
          });

        setState({ loading: false, error: false });
        setStep(Step.Pick_Username);
        setData((prev) => ({
          ...prev,
          email,
          password,
        }));
      }}
    />,
    <PickUsername
      loading={state.loading}
      error={state.loading || !state.error ? '' : state.errorMessage}
      onSubmit={async (username) => {
        setState({ loading: true });
        const result = await createAccountWithInvite({
          email: data.email ?? '',
          password: data.password ?? '',
          username,
          code: data.code ?? '',
        });

        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        const loginResult = await login(data.email ?? '', data.password ?? '');
        if (typeof loginResult === 'string') {
          toast.error(`Couldn't log you in: ${loginResult}`);
          return navigate('/auth/login');
        }

        toast.success('Account created successfully!');
        return navigate('/welcome');
      }}
    />,
  ];

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <HELIXLogo />
        </header>
        <main className={styles.main}>
          <div className={styles.frameWrapper}>
            <div
              className={styles.frameTrack}
              style={{
                transform: `translateX(calc(-1 * var(--width) * ${step})`,
              }}
            >
              {frames.map((frame, i) => (
                <div
                  className={`${styles.frame} ${
                    i === step && styles.frameActive
                  }`}
                  aria-disabled={i !== step}
                  key={i}
                >
                  {frame}
                </div>
              ))}
            </div>
          </div>
        </main>
        <footer className={styles.footer}>
          &copy; Hypersonic Laboratories Inc
        </footer>
      </div>
      <figure className={styles.bg} />
    </div>
  );
}

function EnterCode({
  error = '',
  onSubmit = (code: string) => null,
  loading = false,
  initialCode = '',
}: {
  error?: string;
  onSubmit?: (code: string) => void;
  loading?: boolean;
  initialCode?: string;
}) {
  const [code, setCode] = useState<
    [string, string, string, string, string, string]
  >(
    initialCode.length === 6
      ? (initialCode.split('') as any)
      : ['', '', '', '', '', '']
  );
  const ref1 = useRef<HTMLInputElement | null>(null),
    ref2 = useRef<HTMLInputElement | null>(null),
    ref3 = useRef<HTMLInputElement | null>(null),
    ref4 = useRef<HTMLInputElement | null>(null),
    ref5 = useRef<HTMLInputElement | null>(null),
    ref6 = useRef<HTMLInputElement | null>(null);

  const replaceArrayElement = (
    array: any[],
    index: number,
    value: any
  ): any => {
    const newArray = [...array];
    newArray[index] = value;
    return newArray;
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const paste = e.clipboardData.getData('text').trim();
    if (paste.length !== 6) return;
    setCode(paste.split('') as any);
    onSubmit(paste);
  };

  return (
    <>
      <figure className={styles.icon}>
        <UserKeyIcon />
      </figure>
      <h1 className={styles.title}>Got an invite code?</h1>
      <h3 className={styles.subtitle}>
        Enter it here to instantly unlock early access to HELIX.
      </h3>
      <div className={styles.codeInput}>
        <div className={styles.codeDivider} />
        <span>
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 0, e.target.value));
              if (e.target.value.length > 0) ref2.current?.focus();
              else if (e.target.value.length === 0) ref1.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 0, ''));
              }
            }}
            value={code[0]}
            ref={ref1}
            disabled={loading}
            onPaste={onPaste}
            autoFocus
          />
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 1, e.target.value));
              if (e.target.value.length > 0) ref3.current?.focus();
              else if (e.target.value.length === 0) ref1.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 1, ''));
                ref1.current?.focus();
              }
            }}
            value={code[1]}
            ref={ref2}
            disabled={loading}
            onPaste={onPaste}
          />
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 2, e.target.value));
              if (e.target.value.length > 0) ref4.current?.focus();
              else if (e.target.value.length === 0) ref2.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 2, ''));
                ref2.current?.focus();
              }
            }}
            value={code[2]}
            ref={ref3}
            disabled={loading}
            onPaste={onPaste}
          />
        </span>
        <div className={styles.codeDivider} />
        <span>
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 3, e.target.value));
              if (e.target.value.length > 0) ref5.current?.focus();
              else if (e.target.value.length === 0) ref3.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 3, ''));
                ref3.current?.focus();
              }
            }}
            value={code[3]}
            ref={ref4}
            disabled={loading}
            onPaste={onPaste}
          />
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 4, e.target.value));
              if (e.target.value.length > 0) ref6.current?.focus();
              else if (e.target.value.length === 0) ref4.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 4, ''));
                ref4.current?.focus();
              }
            }}
            value={code[4]}
            ref={ref5}
            disabled={loading}
            onPaste={onPaste}
          />
          <input
            type="text"
            maxLength={1}
            onChange={(e) => {
              setCode((prev) => replaceArrayElement(prev, 5, e.target.value));
              if (e.target.value.length === 0) ref5.current?.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Backspace') {
                setCode((prev) => replaceArrayElement(prev, 5, ''));
                ref5.current?.focus();
              }
            }}
            value={code[5]}
            ref={ref6}
            disabled={loading}
            onPaste={onPaste}
          />
        </span>
        <div className={styles.codeDivider} />
      </div>
      <Button
        disabled={code.join('').length !== 6 || loading}
        variant="yellow"
        className={styles.button}
        onClick={() => onSubmit(code.join(''))}
      >
        unlock <KeyIcon />
      </Button>
      <div
        className={`${styles.buttonError} ${
          error !== '' && styles.buttonErrorActive
        }`}
      >
        <WarningIcon /> {error}
      </div>
    </>
  );
}

function AccountDetails({
  error = '',
  onSubmit = (email: string, password: string) => null,
  loading = false,
}: {
  error?: string;
  onSubmit?: (email: string, password: string) => void;
  loading?: boolean;
}) {
  const [formData, setFormData] = useState<{
    email: string;
    password: string;
  }>({
    email: '',
    password: '',
  });

  return (
    <>
      <figure className={styles.icon}>
        <UnlockIcon />
      </figure>
      <h1 className={styles.title}>
        Congrats, you've just unlocked early access
      </h1>
      <h3 className={styles.subtitle}>
        Create your very own HELIX account today, for free.
      </h3>
      <form
        className={styles.form}
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (loading) return;

          onSubmit(formData.email, formData.password);
        }}
      >
        <figure className={commonStyles.formGroup}>
          <Input
            className={commonStyles.formGroupInput}
            placeholder="Email"
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            autoComplete="email"
            disabled={loading}
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <Input
            className={commonStyles.formGroupInput}
            placeholder="Password"
            type="password"
            value={formData.password}
            onChange={(e) =>
              setFormData({ ...formData, password: e.target.value })
            }
            autoComplete="new-password"
            disabled={loading}
          />
        </figure>
      </form>
      <Button
        variant="sky"
        className={styles.button}
        onClick={() => onSubmit(formData.email, formData.password)}
        disabled={loading}
      >
        next <ArrowIcon />
      </Button>
      <div
        className={`${styles.buttonError} ${
          error !== '' && styles.buttonErrorActive
        }`}
      >
        <WarningIcon /> {error}
      </div>
    </>
  );
}

function PickUsername({
  error = '',
  onSubmit = (username: string) => null,
  loading = false,
}: {
  error?: string;
  onSubmit?: (username: string) => void;
  loading?: boolean;
}) {
  const [username, setUsername] = useState<string>('');
  const [usernameAvailable, setUsernameAvailable] = useState<boolean>(true);

  useEffect(() => {
    if (username.trim() === '') return;
    const i = setTimeout(
      () =>
        checkUsernameValid(username).then((result) =>
          setUsernameAvailable(result.success && result.valid && !result.taken)
        ),
      500
    );

    return () => clearTimeout(i);
  });

  return (
    <>
      <figure className={styles.icon}>
        <UnlockIcon />
      </figure>
      <h1 className={styles.title}>One last step</h1>
      <h3 className={styles.subtitle}>
        Pick a username for your account. Make it unique!
      </h3>
      <form
        className={styles.form}
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (loading) return;

          onSubmit(username);
        }}
      >
        <figure className={commonStyles.formGroup}>
          <Input
            className={commonStyles.formGroupInput}
            placeholder="starboy98"
            type="text"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
              setUsernameAvailable(false);
            }}
            autoComplete="username"
            disabled={loading}
          />
          {!usernameAvailable && (
            <figcaption className={commonStyles.formGroupError}>
              Not available
            </figcaption>
          )}
        </figure>
      </form>
      <Button
        variant="sky"
        className={styles.button}
        onClick={() => onSubmit(username)}
        disabled={loading || username.trim() === '' || !usernameAvailable}
      >
        create account <ArrowIcon />
      </Button>
      <div
        className={`${styles.buttonError} ${
          error !== '' && styles.buttonErrorActive
        }`}
      >
        <WarningIcon /> {error}
      </div>
      <h3 className={styles.disclaimer}>
        By creating an account, you agree to our{' '}
        <a href="https://legal.helixgame.com/" target="_blank">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="https://legal.helixgame.com/privacy" target="_blank">
          Privacy Policy
        </a>
        .
      </h3>
    </>
  );
}
