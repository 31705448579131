import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useUser } from '../../../hook/useUser';
import useModal from '../../../hook/useModal';
import usePageConfig from '../../../hook/usePageConfig';

import {
  getAllCreatedAssets,
  validateAssetName,
  getAssetTypes,
} from '../../../api/asset';
import { ItemType, ItemCategory } from '../../../type/Item';
import { randomName, randomString, sleep } from '../../../utils';
import styles from './index.module.css';
import commonStyles from '../../common.module.css';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { processError } from '../../../utils';

import type { CreatedItem, ExchangeItem } from '../../../type/Item';
import type { DeveloperItemsState } from './state';
import type { AssetItem } from '../../../modal';

import { H1Compo } from '../../../components/H.compo';
import { DropdownCompo } from '../../../components/common/Dropdown.compo';
import { Link, Outlet } from 'react-router-dom';
import Modal, { modalAction, modalActions } from '../../../Comps/Modal';
import Select from '../../../Comps/Select';
import Input, { TextArea } from '../../../Comps/Input';
import { Toggle } from '../../../Comps/Checkbox';
import Loader from '../../../Comps/Loader';
import ExpandableItemView, {
  ExpandableItemGrid,
  ExpandableItemMenuButton,
  ExpandableItemMenuDivider,
  ExpandableItemProvider,
} from '../../../Comps/ExpandableItem';
import Button from '../../../Comps/Button';
import Footer from '../../../Comps/Footer';

import { ReactComponent as CogIcon } from '../../../assets/icons/cogIcon.svg';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencilIcon.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as LinkIcon } from '../../../assets/icons/linkIcon.svg';
import { ReactComponent as ExportIcon } from '../../../assets/icons/exportIcon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eyeIcon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eyeOffIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg';
import { ReactComponent as AppsIcon } from '../../../assets/icons/appsIcon.svg';
import { ReactComponent as DescendingIcon } from '../../../assets/icons/descendingIcon.svg';
import { ReactComponent as LIXIcon } from '../../../assets/icons/lixIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendarIcon.svg';
import { ReactComponent as PlayersIcon } from '../../../assets/icons/playersIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warningIcon.svg';
import useDeepState from '../../../hook/useDeepState';

enum Tab {
  Created = 'Created',
}

/*enum DeveloperItemsSortType {
  Alphabetical = 'Alphabetical',
  DateCreated = 'DateCreated',
  DateUpdated = 'DateUpdated',
  Largest = 'Largest',
  Smallest = 'Smallest',
}*/

type Filter = {
  searchQuery: string;
};

type State = {
  error: string;
  createdItems: AssetItem[] | null;
};

/*export const useDeveloperItemsContext = (): {
  vaultState: DeveloperItemsState;
  hasError: boolean;
  developerItemsLoading: boolean;
} => {
  const outletContext = useOutletContext<null | any>();

  return typeof outletContext === 'undefined' || outletContext === null
    ? {
        vaultState: initialDeveloperItemsState,
        hasError: false,
        developerItemsLoading: true,
      }
    : (outletContext as any);
};*/

export default function AssetsPage() {
  usePageConfig({ title: 'My Assets | HELIX Hub' });

  const navigate = useNavigate();
  const { isLoading, isLoggedIn } = useUser();
  const [state, setState] = useState<State>({
    error: '',
    createdItems: null,
  });
  const [tab, setTab] = useState<Tab>(Tab.Created);

  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    if (tab === Tab.Created && state.createdItems !== null) return;

    const abortController = new AbortController();
    if (tab === Tab.Created)
      getAllCreatedAssets(abortController.signal).then((items) =>
        setState((previous) => ({ ...previous, createdItems: items }))
      );

    return () => abortController.abort();
  }, [tab, isLoading, isLoggedIn]);

  // const [vaultState, setDeveloperItemsState] = useState<DeveloperItemsState>(initialDeveloperItemsState);
  /*const [spaceTotal, setSpaceTotal] = useState<number>(100 * 1024 * 1024);
  const [spaceUsed, setSpaceUsed] = useState<number>(0);*/

  /*const { data, isLoading, error } = useInfiniteQuery(
    ['vault'],
    ({ pageParam = 0 }) => getMyAsset(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length;
      },
    }
  );*/

  /*useEffect(() => {
    getDeveloperItemsStorage(userId).then((res) => {
      console.log(res);
      setSpaceUsed(res);
    });
  }, []);*/

  const { getDeepState, setDeepState, deleteDeepState } = useDeepState();
  const publicOnly = getDeepState<boolean>('assets.publicOnly') ?? false;
  const setPublicOnly = (value: boolean) =>
    setDeepState('assets.publicOnly', value);

  const [filter, setFilter] = useState<Filter>({
    searchQuery: '',
  });

  const hasError = state.error !== '';
  const developerItemsLoading = !state.createdItems;

  // const developerItems: (CreatedItem | ExchangeItem)[] = useMemo(
  //   () =>
  //     developerItemsLoading
  //       ? []
  //       : vaultState.items
  //           .filter((item) =>
  //             tab === Tab.Created
  //               ? !publicOnly
  //                 ? isCreatedItem(item)
  //                 : isCreatedItem(item) && item.public
  //               : isExchangeItem(item) && item.owned
  //           )
  //           .filter((item: CreatedItem | ExchangeItem) => {
  //             if (
  //               filter.category !== '' &&
  //               item.category !== filter.category
  //             )
  //               return false;
  //             if (
  //               filter.searchQuery !== '' &&
  //               !item.name
  //                 .toLowerCase()
  //                 .includes(filter.searchQuery.toLowerCase())
  //             )
  //               return false;
  //
  //             return true;
  //           })
  //           .sort(
  //             (
  //               itemA: CreatedItem | ExchangeItem,
  //               itemB: CreatedItem | ExchangeItem
  //             ): number => {
  //               if (filter.sortType === DeveloperItemsSortType.Alphabetical)
  //                 return itemA.name.localeCompare(itemB.name);
  //
  //               if (filter.sortType === DeveloperItemsSortType.DateCreated)
  //                 return itemA.created - itemB.created;
  //
  //               if (filter.sortType === DeveloperItemsSortType.DateUpdated)
  //                 return itemA.lastUpdate - itemB.lastUpdate;
  //
  //               if (filter.sortType === DeveloperItemsSortType.Largest)
  //                 return itemA.size - itemB.size;
  //
  //               if (filter.sortType === DeveloperItemsSortType.Smallest)
  //                 return itemB.size - itemA.size;
  //
  //               // should never happen, but typescript is dumb
  //               return itemA.name.localeCompare(itemB.name);
  //             }
  //           ),
  //   [
  //     developerItemsLoading,
  //     vaultState.items,
  //     tab,
  //     publicOnly,
  //     filter.category,
  //     filter.searchQuery,
  //     filter.sortType,
  //   ]
  // );
  const developerItems: { created: AssetItem[] } = {
    created: (state.createdItems ?? [])
      .filter((item: AssetItem) => !publicOnly || item.published)
      .filter(
        (item: AssetItem) =>
          filter.searchQuery === '' ||
          item.title.toLowerCase().includes(filter.searchQuery.toLowerCase())
      ),
  };

  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    title: string;
    description: string;
    name: string;
    type: string;
  }>({
    name: '',
    title: '',
    description: '',
    type: '0',
  });
  const [validationError, setValidationError] = useState<string | ''>('');
  useEffect(() => {
    if (modalData.name === '') return;

    if (modalData.name.length < 2)
      return setValidationError('Must be at least 2 characters long');

    const abortController = new AbortController();
    const i = setTimeout(
      () =>
        validateAssetName(modalData.name, abortController.signal).then(
          ({ success, error, isAvailable }) => {
            if (!success) return setValidationError(error);
            if (!isAvailable)
              return setValidationError('Name is already taken');
            setValidationError('');
          }
        ),
      500
    );

    return () => {
      clearTimeout(i);
      abortController.abort();
    };
  }, [modalData.name]);

  const createItem = useCallback(async () => {
    setModalData((previous) => {
      toast.promise(
        axios.post('/asset', {
          title: previous.title,
          name: previous.name,
          description: previous.description,
          type_id: Number(previous.type),
        }),
        {
          loading: `Creating asset...`,
          success: (response) => {
            navigate(`/creations/assets/${response.data.payload.id}`);
            return 'Created asset!';
          },
          error: (err) => `${processError(err)}`,
        }
      );

      return previous;
    });
  }, [modalData]);

  const [assetTypes, setAssetTypes] = useState<null | Record<number, string>>(
    null
  );
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    const abortController = new AbortController();
    getAssetTypes(abortController.signal).then(({ success, types }) => {
      if (!success || !types) return;

      //console.log(types, types[0]);

      setAssetTypes(types);
    });

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  return (
    <div className={commonStyles.splitContentWrapperSlim}>
      {/*<Modal
        title="Delete this asset?"
        subtitle="This action can't be undone."
        open={deleteItemId !== ''}
        onClose={() => setDeleteItemId('')}
      >
        <ModalAction
          variant="destructive"
          onClick={() => {
            // setDeveloperItemsState((previous) => ({
            //   ...state,
            //   assets: state.assets.filter((asset) => asset.id !== deleteItemId),
            // }));
            setDeleteItemId('');
          }}
        >
          <TrashIcon />
          delete
        </ModalAction>
        <ModalAction onClick={() => setDeleteItemId('')}>cancel</ModalAction>
      </Modal>
      */}

      <header className={commonStyles.splitContentHeader}>
        <h1
          className={`${commonStyles.splitContentHeaderTitle} ${commonStyles.row}`}
          style={{ justifyContent: 'space-between' }}
        >
          Assets
          {tab === Tab.Created && null}
        </h1>
        <figure className={commonStyles.splitContentHeaderTools}>
          <figure className={styles.toolbarSwitch}>
            <figure className={commonStyles.toggleGroup}>
              <Toggle
                onClick={() => setPublicOnly(!publicOnly)}
                checked={publicOnly}
              />
              <figcaption
                className={commonStyles.toggleGroupLabel}
                style={{ color: '#A0A0A0' }}
              >
                Published only
              </figcaption>
            </figure>
            {/*<button
              className={styles.toolbarSwitchButton}
              onClick={() => setTab(Tab.Created)}
              disabled={tab === Tab.Created}
            >
              Created
            </button>
            <button
              className={styles.toolbarSwitchButton}
              onClick={() => setTab(Tab.Owned)}
              disabled={tab === Tab.Owned}
            >
              Owned
          </button>*/}
          </figure>
          <figure className={commonStyles.splitContentHeaderToolsRow}>
            {tab === Tab.Created && null}

            {/*<Select
                  options={[
                    {
                      value: 0,
                      label: (
                        <>
                          <AppsIcon /> All assets
                        </>
                      ),
                    },
                  ]}
                  value={0}
                  style={{ width: 180 }}
                />
                <Select
                  style={{ width: 180 }}
                  value={filter.sortType}
                  onChange={(e) =>
                    setFilter((previous) => ({
                      ...previous,
                      sortType: e as number,
                    }))
                  }
                  options={[
                    {
                      value: 0,
                      label: (
                        <>
                          <DescendingIcon />
                          Last updated
                        </>
                      ),
                    },
                    {
                      value: 1,
                      label: (
                        <>
                          <LIXIcon /> Price
                        </>
                      ),
                    },
                    {
                      value: 2,
                      label: (
                        <>
                          <CalendarIcon /> Oldest
                        </>
                      ),
                    },
                    {
                      value: 3,
                      label: (
                        <>
                          <PlayersIcon />
                          Most popular
                        </>
                      ),
                    },
                  ]}
                />*/}
            <Input
              icon={<SearchIcon style={{ color: '#A0A0A0' }} />}
              style={{ width: 180 }}
              placeholder="Search assets"
              value={filter.searchQuery}
              onChange={(e) =>
                setFilter((previous) => ({
                  ...previous,
                  searchQuery: e.target.value,
                }))
              }
            />
            <Button
              variant="sky"
              onClick={() =>
                modal.open({
                  title: 'New Item',
                  actions: [],
                })
              }
            >
              <PlusIcon />
              New
            </Button>
          </figure>
        </figure>
      </header>
      {developerItemsLoading && (
        <div className={commonStyles.splitContentLoader}>
          <Loader scale={2} />
        </div>
      )}
      {!developerItemsLoading && tab === Tab.Created && false && (
        <figure className={styles.gauge}>
          <figcaption className={styles.gaugeTitle}>free space</figcaption>
          <figure className={styles.gaugeBar}>
            <figure
              className={styles.gaugeBarFill}
              style={
                {
                  /*width: `calc(${(spaceUsed / spaceTotal) * 100}%)`,*/
                }
              }
            />
          </figure>
          <figure className={styles.gaugeLabel}>
            <figure className={styles.gaugeLabelBreakdown}>
              <figure className={styles.gaugeLabelGroup}>
                <figure
                  className={styles.gaugeLabelGroupIcon}
                  style={{ backgroundColor: 'var(--yellow)' }}
                />
                <figure className={styles.gaugeLabelText}>
                  {/*spaceUsed*/} kb used
                </figure>
              </figure>
              <figure className={styles.gaugeLabelGroup}>
                <figure
                  className={styles.gaugeLabelGroupIcon}
                  style={{ color: 'var(--yellow)' }}
                />
                <figure className={styles.gaugeLabelText}>
                  {/*spaceTotal - spaceUsed*/} kb free
                </figure>
              </figure>
            </figure>
            <figure className={styles.gaugeLabelText}>
              {/*spaceTotal*/} kb
            </figure>
          </figure>
        </figure>
      )}
      {!developerItemsLoading && tab === Tab.Created && (
        <ExpandableItemProvider>
          <ExpandableItemGrid className={styles.grid}>
            {developerItems.created.map((asset) => (
              <ExpandableItemView
                item={asset}
                key={asset.id}
                fragment="/creations/assets"
              >
                {/*<ExpandableItemMenuButton>
                  <DownloadIcon /> Download
                </ExpandableItemMenuButton>
                <ExpandableItemMenuButton>
                  <LinkIcon /> Copy URL
                </ExpandableItemMenuButton>
                {asset.published && (
                  <ExpandableItemMenuButton
                    onClick={() => navigate(`/exchange/item/${asset.id}`)}
                  >
                    <ExportIcon /> Exchange Page
                  </ExpandableItemMenuButton>
                )}
                <ExpandableItemMenuButton
                  onClick={async () => {
                    toast.promise(
                      axios.patch('/asset', {
                        id: asset.id,
                        published: !asset.published,
                      }),
                      {
                        loading: asset.published
                          ? 'Making private...'
                          : 'Publishing...',
                        success: () => {
                          setState((previous) => ({
                            ...previous,
                            createdItems:
                              previous.createdItems?.map((i) =>
                                i.id === asset.id
                                  ? { ...i, published: !asset.published }
                                  : i
                              ) || [],
                          }));

                          return asset.published ? 'Changes saved' : 'Published';
                        },
                        error: (err) => `Error: ${processError(err)}`,
                      }
                    );
                  }}
                >
                  {asset.published ? (
                    <>
                      <EyeOffIcon />
                      Make Private
                    </>
                  ) : (
                    <>
                      <EyeIcon />
                      Publish
                    </>
                  )}
                </ExpandableItemMenuButton>
                <ExpandableItemMenuDivider />
                <ExpandableItemMenuButton destructive>
                  <TrashIcon />
                  Delete asset
                  </ExpandableItemMenuButton>*/}
              </ExpandableItemView>
            ))}
          </ExpandableItemGrid>
        </ExpandableItemProvider>
      )}
      {/*!developerItemsLoading && tab === Tab.Created && false && (
        <section className={styles.items}>
          {developerItems.created.map((asset) => (
            <figure
              className={`${styles.item} ${
                activeItemId === asset.id && styles.assetActive
              }`}
              key={asset.id}
              onMouseEnter={() => {
                setActiveItemId(asset.id);
                setActiveDropdownItemId((id) => (id === asset.id ? id : ''));
              }}
            >
              <figure className={styles.itemBackground} />
              <figure
                className={styles.itemRoot}
                onMouseLeave={() => {
                  setActiveItemId('');
                  setActiveDropdownItemId('');
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (
                    !e.target ||
                    !(e.target instanceof Element) ||
                    !e.target.classList.contains(styles.itemRoot)
                  )
                    return;
                  navigate(`/developer/vault/${asset.id}`);
                }}
              >
                <figure className={styles.itemActions}>
                  <button className={styles.itemAction}>
                    {tab === Tab.Created ? <PencilIcon /> : <DownloadIcon />}
                  </button>
                  <button
                    className={styles.itemAction}
                    onClick={() =>
                      setActiveDropdownItemId((id) =>
                        id === asset.id ? '' : asset.id
                      )
                    }
                  >
                    <CogIcon />
                  </button>
                </figure>
                <figure
                  className={`${styles.itemDropdown} ${
                    activeDropdownItemId === asset.id &&
                    styles.itemDropdownActive
                  }`}
                >
                  {tab === Tab.Created && (
                    <>
                      <button className={styles.itemDropdownAction}>
                        <DownloadIcon /> <span>Download</span>
                      </button>
                    </>
                  )}
                  <button className={styles.itemDropdownAction}>
                    <LinkIcon /> <span>Copy URL</span>
                  </button>
                  <button className={styles.itemDropdownAction}>
                    <ExportIcon /> <span>Open in Exchange</span>
                  </button>
                  {tab === Tab.Created &&
                    (asset.is_public ? (
                      <button
                        className={styles.itemDropdownAction}
                        onClick={() => {
                          // setDeveloperItemsState((previous) => ({
                          //   ...state,
                          //   assets: state.assets.map((i) =>
                          //     i.id === asset.id ? { ...i, public: false } : i
                          //   ),
                          // }));
                          setActiveDropdownItemId('');
                        }}
                      >
                        <EyeOffIcon /> <span>Make Private</span>
                      </button>
                    ) : (
                      <>
                        <button
                          className={styles.itemDropdownAction}
                          onClick={() => {
                            // setDeveloperItemsState((previous) => ({
                            //   ...state,
                            //   items: state.items.map((i) =>
                            //     i.id === item.id ? { ...i, public: true } : i
                            //   ),
                            // }));
                            setActiveDropdownItemId('');
                          }}
                        >
                          <EyeIcon /> <span>Make Public</span>
                        </button>
                      </>
                    ))}
                  <figure className={styles.itemDropdownSeparator} />
                  <button
                    className={`${styles.itemDropdownAction} ${styles.itemDropdownActionDestructive}`}
                    onClick={() => {
                      setActiveDropdownItemId('');
                      setActiveItemId('');
                    }}
                  >
                    <TrashIcon />{' '}
                    <span>
                      Delete
                      {tab === Tab.Owned
                        ? ' from DeveloperItems'
                  : ''}
                    </span>
                  </button>
                </figure>
              </figure>
              <figure
                className={styles.itemThumbnail}
                style={{ backgroundImage: `url(${item.icon_url})` }}
              >
                <figcaption className={styles.itemSize}>
                  {item.version ? item.version.size_in_bytes : 0} KB
                </figcaption>
              </figure>
              <figure className={styles.itemDetails}>
                <h2 className={styles.itemName}>{item.name}</h2>
                {tab === Tab.Created && item.is_public && (
                  <h3
                    className={styles.itemStatus}
                    style={!item.is_public ? {} : { color: 'var(--lime)' }}
                  >
                    {item.is_public ? 'Public' : 'Private'}
                  </h3>
                )}
                {tab === Tab.Owned && (
                  <h3 className={styles.itemAuthor}>
                    @{currentUser?.username ?? 'Anonymous'}
                  </h3>
                )}
              </figure>
            </figure>
          ))}
        </section>
      )*/}
      <Modal controller={modalController}>
        <figure className={commonStyles.formGroup}>
          <figcaption
            className={`${commonStyles.formGroupLabel} ${commonStyles.row}`}
          >
            Item ID (cannot be changed later)
            {validationError && (
              <div className={`${styles.formError} ${commonStyles.row}`}>
                <WarningIcon />
                {validationError}
              </div>
            )}
          </figcaption>
          <Input
            value={modalData.name}
            onChange={(e) => {
              setModalData((previous) => ({
                ...previous,
                name: e.target.value
                  .toLowerCase()
                  .replace(/ +/g, '-')
                  .replace(/\-+/g, '-')
                  .replace(/[^a-z\-]/g, ''),
              }));
            }}
            className={commonStyles.formGroupInput}
            maxLength={90}
            placeholder="super-cool-rock"
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Display Name
          </figcaption>
          <Input
            value={modalData.title}
            onChange={(e) => {
              setModalData((previous) => ({
                ...previous,
                title: e.target.value,
              }));
            }}
            className={commonStyles.formGroupInput}
            maxLength={90}
            placeholder="Super Cool Rock"
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>Type</figcaption>
          <Select
            loading={assetTypes === null}
            value={modalData.type}
            onChange={(e: any) =>
              setModalData((previous) => ({ ...previous, type: e }))
            }
            options={
              assetTypes === null
                ? []
                : Object.entries(assetTypes).map(([id, name]) => ({
                    value: id,
                    label: name,
                  }))
            }
            className={commonStyles.formGroupInput}
            placeholder="Select a type"
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Description
          </figcaption>
          <TextArea
            value={modalData.description}
            className={commonStyles.formGroupInput}
            onChange={(e) =>
              setModalData((previous) => ({
                ...previous,
                description: e.target.value,
              }))
            }
            placeholder="A very realistic rock that can be used in a variety of outdoor environments."
            maxLength={240}
            style={{ height: 100 }}
          />
        </figure>
        <figure className={modalActions}>
          <Button
            className={modalAction}
            disabled={
              modalData.name === '' ||
              modalData.title === '' ||
              validationError !== ''
            }
            onClick={() => {
              if (
                modalData.name === '' ||
                modalData.title === '' ||
                validationError !== ''
              )
                return;

              modal.close();
              createItem();
            }}
          >
            Create
          </Button>
          <Button className={modalAction} onClick={modal.close} variant="dark">
            Cancel
          </Button>
        </figure>
      </Modal>
      <Footer slim />
    </div>
  );
}
