/*
eslint-disable @typescript-eslint/no-shadow
*/
/* eslint-disable @typescript-eslint/naming-convention */
import { useState, useEffect } from 'react';

type UseMutationOptions = {
  original?: any;
  overrideValue?: (field: string, value: any) => any;
  strictCheck?: string[];
};

const useMutation = (
  original: any = null,
  overrideValue: any = (field: string, value: any) => value,
  strictCheck: string[] = []
): {
  reset: () => void;
  update: any;
  setUpdate: (newState: any) => void | any;
  setUpdateField: (field: string, value: any) => void | any;
  removeUpdateField: (field: string) => void | any;
  mutation: boolean;
  getField: <ReturnType>(
    field: string | any,
    backup?: ReturnType | undefined,
    doNotOverride?: boolean
  ) => ReturnType | undefined;
} => {
  const [mutation, setMutation] = useState(false);

  const [update, _setUpdate] = useState<{
    [key: string]: any;
  }>({}); // eslint-disable-line

  useEffect(() => {
    if (
      typeof original !== 'object' ||
      original === null ||
      Array.isArray(original) ||
      Object.keys(update).length === 0
    )
      return setMutation(false);

    const compare = (
      original: any,
      change: any,
      root = false,
      path = ''
    ): boolean => {
      if (typeof change === 'undefined') return true;

      if (typeof change === 'object') {
        if (change === null) return original !== null;
        if (typeof original !== 'object') return true;

        if (Array.isArray(change) !== Array.isArray(original)) return true;
        if (Array.isArray(change) && change.length !== original.length)
          return true;

        if (!Array.isArray(change)) {
          for (const key in change) {
            change[key] = overrideValue(
              root ? key : `${path}.${key}`,
              change[key]
            );
            if (typeof original[key] !== typeof change[key]) return true;
          }
          // @ts-ignore
          if (!root && strictCheck.includes(`${path}`)) {
            for (const key in original) {
              if (typeof change[key] === 'undefined') return true;
            }
          }
          for (const key in change) {
            if (
              compare(
                original[key],
                change[key],
                false,
                root ? key : `${path}.${key}`
              )
            )
              return true;
          }
          return false;
        } else {
          if (!change.some((element) => typeof element === 'object'))
            return (
              change.some((element) => !original.includes(element)) || // @ts-ignore
              original.some((element) => !change.includes(element))
            );

          return change.some(
            (_, i) =>
              compare(
                original[i],
                change[i],
                false,
                root ? i.toString() : `${path}[${i}]`
              ) as boolean
          ) as boolean;
        }
      }

      if (
        typeof change === 'string' ||
        typeof change === 'number' ||
        typeof change === 'boolean'
      )
        return original !== change;

      return false; // we don't know what that is
    };

    setMutation(compare(original, update, true));
  }, [original, update]);

  return {
    reset: () => {
      _setUpdate({});
      setMutation(false);
    },
    update,
    setUpdate: (newState: any) => {
      if (typeof newState === 'function')
        _setUpdate((update) => newState(update));
      else _setUpdate(newState);
    },
    setUpdateField: (field: string, value: any) =>
      _setUpdate((update) => ({ ...update, [field]: value })),
    removeUpdateField: (field: string) =>
      _setUpdate((update) => {
        const { [field]: _, ...newUpdate } = update; // eslint-disable-line
        return newUpdate;
      }),
    getField: function getField<ReturnType>(
      field: string | any,
      backup?: ReturnType | undefined,
      doNotOverride?: boolean
    ): ReturnType | undefined {
      return typeof update[field] !== 'undefined'
        ? update[field]
        : typeof original[field] !== 'undefined' && !doNotOverride
        ? original[field]
        : typeof backup !== 'undefined'
        ? backup
        : null;
    },
    mutation,
  };
};

export default useMutation;
