import axios from 'axios';
import { processError } from '../utils';

export type Invite = {
  id: string;
  user_id: string;
  invitation_code: string;
  expiration_date: string;
  usages: number;
  max_usage: number;
};

export async function createInvite(signal?: AbortSignal): Promise<
  | {
      success: false;
      error: string;
      data: null;
    }
  | {
      success: true;
      error: '';
      data: Invite;
    }
> {
  try {
    const result = await axios.post('/invitation', {
      signal,
    });

    if (result.data.code !== 0) {
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };
    }

    return {
      success: true,
      error: '',
      data: result.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function getMyInvites(signal?: AbortSignal): Promise<
  | {
      success: false;
      error: string;
      data: [];
    }
  | {
      success: true;
      error: '';
      data: Invite[];
    }
> {
  try {
    const result = await axios.get('/invitation/created', {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: [],
      };

    return {
      success: true,
      error: '',
      data: (result.data.payload ?? []) as Invite[],
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function getInvite(
  code: string,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: null;
    }
  | {
      success: true;
      error: '';
      data: Invite;
    }
> {
  try {
    const result = await axios.get(`/invitation`, {
      signal,
      params: {
        code,
      },
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}
