import useQuickChart from '../../../../hook/useQuickChart';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import type { OutletContext } from '.';

import sharedStyles from './shared.module.css';
import commonStyles from '../../../common.module.css';
import styles from './sales.module.css';
import { getWorldTransactions } from '../../../../api/world';

import Input from '../../../../Comps/Input';
import Table, { TableDisplay } from '../../../../Comps/Table';
import Select from '../../../../Comps/Select';
import Loader from '../../../../Comps/Loader';

import { ReactComponent as LIXIcon } from '../../../../assets/icons/lixIcon.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendarIcon.svg';
import { ReactComponent as AppsIcon } from '../../../../assets/icons/appsIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warningIcon.svg';

const NOT_ENOUGH_DATA_THRESHOLD = 25;

const quickChartOptions = {
  title: {
    display: false,
  },
  layout: {
    padding: {
      top: 1,
    },
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  scales: {
    xAxes: [
      {
        display: false,
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          color: '#2E2E2E',
          lineWidth: 1,
          drawBorder: false,
          drawTicks: false,
          offsetGridLines: false,
          zeroLineWidth: 1,
          zeroLineColor: '#2E2E2E',
        },
        ticks: {
          display: false,
          maxTicksLimit: 3,
        },
      },
    ],
  },
};

/*const data = [
  ['NinjasHyper', 'Wallhacks + Aimbot', 10000],
  ['Myer', 'Infinite Health', 5000],
  ['IloveNFTs', 'Bored Ape Yacht Club #5', 300000],
  ['degenerateDude', 'CryptoPunks #5112', 1000000],
  ['mai', 'cat', 300],
  ['scammer', 'scam', 10000000000],
];*/

type StateTransaction = {
  date: Date;
  type: React.ReactNode;
  change: number;
  name: string;
  username: string;
  userIcon: string;
};

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      totalRevenue: number;
      timeline: {
        range: [string, string];
        plot: number[];
        blur: boolean;
      };
      transactions: StateTransaction[];
    };

enum Range {
  Day = 1,
  Week = 7,
  Month = 30,
  Quarter = 90,
}

enum Type {
  IWP = 'IWP',
  Credit = 'CREDIT',
  All = '*',
  Unknown = 'UNKNOWN',
}

export default function WorldManagementSalesPage() {
  const { world } = useOutletContext<OutletContext>();

  /*const randomData = useMemo<number[]>(() => {
    const start = Math.floor(Math.random() * 40);

    return new Array(30)
      .fill(0)
      .map((_, i) => 300 + Math.abs(((i + start) % 20) - 10) * 100);
  }, [period]);
  const randomID = useMemo(
    () =>
      new Array(12)
        .fill(0)
        .map(() => Math.floor(Math.random() * 36).toString(36))
        .map((e) => (Math.floor(Math.random() * 2) === 0 ? e.toUpperCase() : e))
        .join('')
        .replace(/^[^a-zA-Z]+/g, ''),
    [period]
  );*/

  const [filter, setFilter] = useState<{
    range: Range;
    searchQuery: string;
    type: Type;
  }>({
    range: Range.Week,
    searchQuery: '',
    type: Type.All,
  });
  const [state, setState] = useState<State>({
    loading: true,
  });
  useEffect(() => {
    const end = new Date();
    const begin = new Date(Date.now() - 1000 * 60 * 60 * 24 * filter.range);
    setState({ loading: true });

    const abortController = new AbortController();
    getWorldTransactions(
      world.id,
      begin.toISOString(),
      end.toISOString(),
      abortController.signal
    ).then(({ success, error, data }) => {
      if (!success)
        return setState({ loading: false, error: true, errorMessage: error });

      type PlotPoint = {
        dateString: string;
        startTime: number;
        endTime: number;
        change: number;
      };

      const transactions: StateTransaction[] = [],
        plot: PlotPoint[] = [];
      const rangeStart =
        Math.floor(begin.getTime() / 1000 / (60 * 60 * 24)) * 60 * 60 * 24;
      const rangeEnd =
        Math.floor(end.getTime() / 1000 / (60 * 60 * 24)) * 60 * 60 * 24;

      for (let i = rangeStart; i <= rangeEnd; i += 60 * 60 * 24) {
        const date = new Date(i * 1000);
        plot.push({
          dateString: date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
          }),
          startTime: i,
          endTime: i + 60 * 60 * 24,
          change: 0,
        });
      }

      let totalRevenue = 0;
      data.map((transaction) => {
        totalRevenue += Number(transaction.transaction_amount);

        const plotPoint = plot.find(
          (e) =>
            e.startTime <=
              new Date(transaction.transaction_date).getTime() / 1000 &&
            e.endTime > new Date(transaction.transaction_date).getTime() / 1000
        );
        if (plotPoint)
          plotPoint.change += Number(transaction.transaction_amount);

        transactions.push({
          date: new Date(transaction.transaction_date),
          type: transaction.transaction_origin,
          change: Number(transaction.transaction_amount),
          name: transaction.iwp_title,
          username: transaction.user_username,
          userIcon: transaction.user_picture_url,
        });
      });

      const shouldBlurGraph =
        plot.filter((day) => day.change).length / plot.length <=
        NOT_ENOUGH_DATA_THRESHOLD / 100;

      setState({
        loading: false,
        error: false,
        totalRevenue,
        timeline: {
          range: [
            new Date(rangeStart * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            }),
            new Date(rangeEnd * 1000).toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
            }),
          ],
          plot: shouldBlurGraph
            ? new Array(30)
                .fill(0)
                .map((_, i) => 300 + Math.abs(((i + 20) % 20) - 10) * 100)
            : plot.map((e) => e.change),
          blur: shouldBlurGraph,
        },
        transactions,
      });
    });

    return () => abortController.abort();
  }, [world.id, filter.range]);

  const quickChartRef = useRef<HTMLDivElement>(null);
  const quickChart = useQuickChart(
    !state.loading && !state.error,
    quickChartRef
  );

  const transactions = useMemo(() => {
    if (state.loading || state.error || state.transactions.length === 0)
      return [];
    if (filter.searchQuery === '' && filter.type === Type.All)
      return state.transactions;
    return state.transactions
      .filter(
        (e) =>
          e.name.toLowerCase().includes(filter.searchQuery.toLowerCase()) ||
          e.username.toLowerCase().includes(filter.searchQuery.toLowerCase())
      )
      .filter((e) => filter.type === Type.All || e.type === filter.type);
  }, [state, filter.searchQuery, filter.type]);

  return (
    <div className={`${commonStyles.splitContentWrapper} ${styles.root}`}>
      {state.loading && <Loader scale={1.25} className={styles.rootLoader} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <header className={commonStyles.rowFull}>
            <h1 className={commonStyles.splitContentTitle}>Sales</h1>
            <figure className={commonStyles.row}>
              <Select
                icon={<CalendarIcon />}
                options={[
                  { value: Range.Day, label: 'Last 24 hours' },
                  { value: Range.Week, label: 'Last week' },
                  {
                    value: Range.Month,
                    label: 'Last month',
                  },
                  {
                    value: Range.Quarter,
                    label: 'Last quarter',
                  },
                ]}
                onChange={(e) => setFilter({ ...filter, range: e as Range })}
                style={{ width: 180 }}
                value={filter.range}
                placeholder="Select a time period"
              />
            </figure>
          </header>
          <figure className={styles.chart}>
            <h2 className={styles.chartTitle}>Revenue, total</h2>
            <figure className={styles.chartAmount}>
              <LIXIcon />
              <figcaption>{state.totalRevenue.toLocaleString()}</figcaption>
            </figure>
            <figure
              className={`${styles.chartImage} ${
                state.timeline.blur && styles.chartImageBlur
              }`}
              ref={quickChartRef}
            >
              {state.timeline.blur && (
                <figure className={styles.chartOverlay}>
                  <WarningIcon />
                  Not enough data
                </figure>
              )}
              <picture
                style={{
                  backgroundImage: `url('${quickChart(
                    'bar',
                    {
                      datasets: [
                        {
                          label: 'Dataset 1',
                          data: state.timeline.plot,
                          backgroundColor: '#68DDFD',
                          fill: false,
                          spanGaps: false,
                          lineTension: 0.4,
                          pointStyle: 'circle',
                          borderDash: [0, 0],
                          barPercentage: 0.4,
                          categoryPercentage: 0.8,
                          borderColor: 'rgba(0, 0, 0, 0)',
                          borderWidth: 0,
                          hidden: false,
                        },
                      ],
                      labels: new Array(state.timeline.plot.length).fill(''),
                    },
                    quickChartOptions
                  )}')`,
                }}
              />
            </figure>
            <figure className={styles.chartLabel}>
              <figcaption>{state.timeline.range[0]}</figcaption>
              <figcaption>{state.timeline.range[1]}</figcaption>
            </figure>
          </figure>
          <figure className={commonStyles.rowFull}>
            <h1
              className={`${commonStyles.row} ${commonStyles.splitContentTitle}`}
              style={{ gap: 10 }}
            >
              Transactions
              <span className={commonStyles.splitContentTag}>
                {transactions.length.toLocaleString()}
              </span>
            </h1>
            <figure className={commonStyles.row} style={{ gap: 20 }}>
              <Select
                icon={<AppsIcon />}
                placeholder="Select a type"
                style={{ width: 220 }}
                options={[
                  {
                    value: Type.All,
                    label: 'All types',
                  },
                  { value: Type.IWP, label: 'In-World Purchase' },
                  {
                    value: Type.Credit,
                    label: 'Direct Payment',
                  },
                  {
                    value: Type.Unknown,
                    label: 'Other',
                  },
                ]}
                value={filter.type}
                onChange={(e) =>
                  setFilter((prev) => ({
                    ...prev,
                    type: e as Type,
                  }))
                }
              />
              <Input
                placeholder="Search transactions"
                style={{ width: 200 }}
                value={filter.searchQuery}
                onChange={(e) =>
                  setFilter({ ...filter, searchQuery: e.target.value })
                }
                icon={<SearchIcon style={{ color: '#A0A0A0' }} />}
              />
            </figure>
          </figure>
          <Table
            style={{ marginTop: 32 }}
            header={[
              {
                value: 'date',
              },
              {
                value: 'user',
                widthBuffer: 24 + 8,
              },
              { value: 'name', width: '1fr' },
              {
                value: 'type',
                widthBuffer: 20,
              },
              {
                value: 'amount',
                widthBuffer: 28,
              },
            ]}
            rows={
              transactions.map(
                ({ name, username, date, userIcon, change, type }) => [
                  {
                    value: `${date.toLocaleDateString('en-US', {
                      dateStyle: 'short',
                    })} ${date.toLocaleTimeString('en-US', {
                      timeStyle: 'short',
                      hour12: false,
                    })}`,
                  },
                  {
                    value: username,
                    display: (
                      <TableDisplay className={styles.tableUser} key="username">
                        <figure
                          style={{ backgroundImage: `url('${userIcon}')` }}
                        />
                        {username}
                      </TableDisplay>
                    ),
                  },
                  {
                    value: name,
                  },
                  {
                    value: type,
                    display: (
                      <TableDisplay key="type">
                        <span
                          className={`${commonStyles.label} ${commonStyles.labelSky}`}
                        >
                          {type}
                        </span>
                      </TableDisplay>
                    ),
                  },
                  {
                    value: change.toLocaleString('en-US'),
                    display: (
                      <TableDisplay className={styles.tableAmount} key="amount">
                        <LIXIcon />
                        {change.toLocaleString('en-US')}
                      </TableDisplay>
                    ),
                  },
                ]
              ) as any
            }
          />
        </>
      )}
    </div>
  );
}
