import { useEffect, useMemo, useState, useCallback } from 'react';
import { useUser } from '../../../hook/useUser';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hook/useModal';
import usePageConfig from '../../../hook/usePageConfig';

import { getWorlds } from './api';
import styles from './index.module.css';
import commonStyles from '../../common.module.css';
import axios from 'axios';
import { toast } from 'react-hot-toast'; // eslint-disable-line
import { processError } from '../../../utils';
import { validateWorldName } from '../../../api/world';

import type { WorldPartial } from '../../../type/World';

import Loader from '../../../Comps/Loader';
import World, {
  ActiveWorldProvider,
  DefaultWorldMenuButtons,
  WorldGrid,
  WorldMenuButton,
  WorldMenuDivider,
} from '../../../Comps/World';
import Input, { TextArea } from '../../../Comps/Input';
import { H1Compo } from '../../../components/H.compo';
import Button from '../../../Comps/Button';
import { Toggle } from '../../../Comps/Checkbox';
import Select from '../../../Comps/Select';
import Modal, { modalAction, modalActions } from '../../../Comps/Modal';
import Footer from '../../../Comps/Footer';

import { ReactComponent as PencilIcon } from '../../../assets/icons/pencilIcon.svg';
import { ReactComponent as EyeOffIcon } from '../../../assets/icons/eyeOffIcon.svg';
import { ReactComponent as StopIcon } from '../../../assets/icons/stopIcon.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/trashIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/searchIcon.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusIcon.svg';
import { ReactComponent as DescendingIcon } from '../../../assets/icons/descendingIcon.svg';
import { ReactComponent as LIXIcon } from '../../../assets/icons/lixIcon.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/calendarIcon.svg';
import { ReactComponent as PlayersIcon } from '../../../assets/icons/playersIcon.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warningIcon.svg';

type State = {
  loading: boolean;
  error: string | '';
  data: WorldPartial[];
};

enum SortBy {
  LAST_UPDATED = 'Last updated',
  TOP_EARNING = 'Top earning',
  OLDEST = 'Oldest',
  MOST_PLAYERS = 'Most players',
}

type Filter = {
  publishedOnly: boolean;
  searchQuery: string;
  sortBy: SortBy;
};

export default function CreationsWorlds() {
  usePageConfig({ title: 'My Worlds | HELIX Hub' });

  const { isLoggedIn, userId } = useUser();
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    loading: true,
    error: '',
    data: [],
  });

  useEffect(() => {
    if (!isLoggedIn) return;

    getWorlds(userId, true).then(({ success, error, data }) => {
      if (!success) return setState({ loading: false, error: error, data: [] });

      setState({ loading: false, error: '', data: data });
    });
  }, [isLoggedIn, userId]);

  const [filter, setFilter] = useState<Filter>({
    publishedOnly: false,
    searchQuery: '',
    sortBy: SortBy.LAST_UPDATED,
  });

  const worldsList = useMemo(() => {
    if (state.loading || state.data.length === 0) return [];
    return state.data
      .filter((world) => {
        if (filter.publishedOnly && !world.published) return false;
        if (filter.searchQuery !== '') {
          if (
            !world.title
              .toLowerCase()
              .includes(filter.searchQuery.toLowerCase())
          )
            return false;
        }
        return true;
      })
      .sort((worldA, worldB) => {
        return worldA.title.localeCompare(worldB.title);
        // do something with the sort
      });
  }, [
    state.loading,
    state.data,
    filter.publishedOnly,
    filter.searchQuery,
    filter.sortBy,
  ]);

  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    title: string;
    description: string;
    name: string;
  }>({
    name: '',
    title: '',
    description: '',
  });

  const createWorld = useCallback(async () => {
    toast.promise(
      axios.post('/world', {
        title: modalData.title,
        name: modalData.name,
        description: modalData.description,
        metadata: {},
      }),
      {
        loading: `Creating world...`,
        success: (response) => {
          navigate(`/creations/worlds/${response.data.payload.id}`);
          return 'Created world!';
        },
        error: (err) => `Error: ${processError(err)}`,
      }
    );
  }, [modalData]);

  const [validationError, setValidationError] = useState<string | ''>('');
  useEffect(() => {
    if (modalData.name === '') return setValidationError('');
    const abortController = new AbortController();

    const i = setTimeout(
      () =>
        validateWorldName(modalData.name, abortController.signal).then(
          ({ success, error, isAvailable }) => {
            if (!success) return setValidationError(error);
            if (!isAvailable)
              return setValidationError('Name is not available');
            setValidationError('');
          }
        ),
      500
    );

    return () => {
      clearTimeout(i);
      abortController.abort();
    };
  }, [modalData.name]);

  return (
    <div className={`${commonStyles.splitContentWrapperSlim}`}>
      <Modal controller={modalController}>
        <figure className={commonStyles.formGroup}>
          <figcaption
            className={`${commonStyles.formGroupLabel} ${commonStyles.row}`}
          >
            World ID (cannot be changed later)
            {validationError && (
              <div className={`${styles.formError} ${commonStyles.row}`}>
                <WarningIcon />
                {validationError}
              </div>
            )}
          </figcaption>
          <Input
            value={modalData.name}
            onChange={(e) => {
              setModalData((previous) => ({
                ...previous,
                name: e.target.value
                  .toLowerCase()
                  .replace(/ +/g, '-')
                  .replace(/\-+/g, '-')
                  .replace(/[^a-z\-]/g, ''),
              }));
            }}
            className={commonStyles.formGroupInput}
            maxLength={90}
            placeholder="awesome-world"
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Display Name
          </figcaption>
          <Input
            value={modalData.title}
            onChange={(e) => {
              setModalData((previous) => ({
                ...previous,
                title: e.target.value,
              }));
            }}
            className={commonStyles.formGroupInput}
            maxLength={90}
            placeholder="Awesome World"
          />
        </figure>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Description
          </figcaption>
          <TextArea
            value={modalData.description}
            className={commonStyles.formGroupInput}
            onChange={(e) =>
              setModalData((previous) => ({
                ...previous,
                description: e.target.value,
              }))
            }
            placeholder="An awesome world!"
            maxLength={240}
          />
        </figure>
        <figure className={modalActions} style={{ marginTop: 15 }}>
          <Button
            variant="light"
            className={modalAction}
            disabled={
              modalData.name === '' ||
              modalData.title === '' ||
              validationError !== ''
            }
            onClick={() => {
              if (
                modalData.name === '' ||
                modalData.title === '' ||
                validationError !== ''
              )
                return;

              modal.close();
              createWorld();
            }}
          >
            create
          </Button>
          <Button
            variant="dark"
            className={modalAction}
            onClick={() => modal.close()}
          >
            cancel
          </Button>
        </figure>
      </Modal>
      {state.loading && (
        <figure className={commonStyles.splitContentLoader}>
          <Loader scale={2} />
        </figure>
      )}
      {!state.loading && (
        <>
          <header className={commonStyles.splitContentHeader}>
            <h1 className={commonStyles.splitContentHeaderTitle}>Worlds</h1>
            <figure className={commonStyles.splitContentHeaderTools}>
              <figure className={commonStyles.toggleGroup}>
                {/*<Toggle
                  checked={filter.publishedOnly}
                  onClick={() =>
                    setFilter((fil) => ({
                      ...fil,
                      publishedOnly: !fil.publishedOnly,
                    }))
                  }
                />
                <figcaption
                  className={commonStyles.toggleGroupLabel}
                  style={{ color: '#A0A0A0' }}
                >
                  Public only
                </figcaption>*/}
              </figure>
              <div className={commonStyles.splitContentHeaderToolsRow}>
                {/*<Select
                  style={{ width: 180 }}
                  value={filter.sortBy}
                  onChange={(e) =>
                    setFilter((fil) => ({ ...fil, sortBy: e as SortBy }))
                  }
                  options={[
                    {
                      value: SortBy.LAST_UPDATED,
                      label: (
                        <>
                          <DescendingIcon />
                          Last updated
                        </>
                      ),
                    },
                    {
                      value: SortBy.TOP_EARNING,
                      label: (
                        <>
                          <LIXIcon /> Top earning
                        </>
                      ),
                    },
                    {
                      value: SortBy.OLDEST,
                      label: (
                        <>
                          <CalendarIcon /> Oldest
                        </>
                      ),
                    },
                    {
                      value: SortBy.MOST_PLAYERS,
                      label: (
                        <>
                          <PlayersIcon />
                          Most players
                        </>
                      ),
                    },
                  ]}
                />*/}
                <Input
                  placeholder="Search worlds"
                  icon={<SearchIcon style={{ color: '#A0A0A0' }} />}
                  style={{ width: 180 }}
                  value={filter.searchQuery}
                  onChange={(e) =>
                    setFilter((fil) => ({
                      ...fil,
                      searchQuery: e.target.value,
                    }))
                  }
                />
                <Button
                  variant="sky"
                  onClick={() =>
                    modal.open({
                      title: 'New World',
                      actions: [],
                    })
                  }
                >
                  <PlusIcon />
                  new
                </Button>
              </div>
            </figure>
          </header>
          <ActiveWorldProvider>
            <WorldGrid className={styles.grid}>
              {worldsList.map((world) => (
                <World data={world} key={world.id}>
                  <DefaultWorldMenuButtons data={world} owned />
                  <WorldMenuButton
                    onClick={async () => {
                      const toastId = toast.loading(
                        world.published ? 'Unpublishing...' : 'Publishing...'
                      );
                      try {
                        await axios.patch('/world', {
                          id: world.id,
                          published: !world.published,
                        });

                        setState((stat) => ({
                          ...stat,
                          data: stat.data.map((w) =>
                            w.id === world.id
                              ? { ...w, published: !w.published }
                              : w
                          ),
                        }));

                        toast.success(
                          world.published
                            ? 'Unpublished world!'
                            : 'Published world!',
                          {
                            id: toastId,
                          }
                        );
                      } catch (e) {
                        toast.error(processError(e), {
                          id: toastId,
                        });
                      }
                    }}
                  >
                    <EyeOffIcon />
                    {world.published ? 'Unpublish' : 'Publish'}
                  </WorldMenuButton>
                  {/*<WorldMenuDivider />
                 <WorldMenuButton destructive>
                    <StopIcon />
                    Stop all Instances
                  </WorldMenuButton>
                  <WorldMenuButton destructive>
                    <TrashIcon />
                    Delete World
                  </WorldMenuButton>*/}
                </World>
              ))}
            </WorldGrid>
          </ActiveWorldProvider>
        </>
      )}
      <Footer style={{ marginTop: 86 - 32 }} slim />
    </div>
  );
}
