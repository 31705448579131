import { useState } from 'react';
import useActiveController from '../../hook/useActiveController';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.css';

import type { ExchangeItem } from '../../type/Item';
import type { AssetItem } from '../../modal';

import { Link } from 'react-router-dom';

import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/thumbsUpIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg';
import { ReactComponent as HeartIcon } from '../../assets/icons/heartIcon.svg';

export function ExchangeItemView({
  item,
  className = '',
  activeItemId = '',
  setActiveItemId = () => {},
  url = '',
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  item: AssetItem;
  activeItemId?: string | '';
  setActiveItemId?: (id: string) => void;
  url: string;
}) {
  const activeController = useActiveController(activeItemId === item.id, 400);
  const navigate = useNavigate();

  return (
    <figure
      className={`${styles.item} ${
        /*activeItemId === item.id*/ activeController.animate &&
        styles.itemActive
      } ${className}`}
      onMouseOver={() => {
        setActiveItemId(item.id);
      }}
      {...props}
    >
      <figure
        className={styles.itemImage}
        style={{ backgroundImage: `url('${item.icon_url}')` }}
      >
        {/*{item.originalPrice > item.price && (*/}
        {/*  <figure className={styles.itemDiscount}>*/}
        {/*    -{Math.round((1 - item.price / item.originalPrice) * 100)}%*/}
        {/*  </figure>*/}
        {/*)}*/}
        <figure className={styles.itemPrice}>
          <LIXIcon className={styles.itemPriceIcon} />
          <figcaption className={styles.itemPriceText}>
            {item.price === 0 ? 'free' : item.price.toLocaleString()}
          </figcaption>
          {/*{item.originalPrice !== 0 && item.price !== item.originalPrice && (*/}
          {/*  <figure className={styles.itemPriceOriginal}>*/}
          {/*    {item.originalPrice.toLocaleString()}*/}
          {/*  </figure>*/}
          {/*)}*/}
        </figure>
      </figure>
      <figure className={styles.itemDetails}>
        <h3 className={styles.itemTitle}>
          {
            /*item.title.substring(0, 26) + (item.title.length > 26 ? '...' : '')*/ item.title
          }
        </h3>
        <Link
          to={`/exchange/search?creator=${item.creator?.id || ''}`}
          className={styles.itemAuthor}
        >
          @{item.creator?.username || 'Anonymous'}
        </Link>
      </figure>
      {activeController.view && (
        <>
          <figure className={styles.itemBackground} />
          <figure
            className={styles.itemOverlay}
            onMouseLeave={() => {
              setActiveItemId('');
            }}
            onClick={(e) => {
              e.stopPropagation();
              if (
                !e.target ||
                !(e.target instanceof Element) ||
                !e.target.classList.contains(styles.itemOverlay)
              )
                return;
              if (url !== '') window.open(url, '_blank');
              else navigate(`/exchange/item/${item.id}`);
            }}
          >
            {false && (
              <figure className={styles.itemRating}>
                <figure
                  className={styles.itemRatingScore}
                  style={{ color: '#99D52A' }}
                >
                  <ThumbsUpIcon className={styles.itemRatingScoreIcon} />
                  <figcaption className={styles.itemRatingScoreText}>
                    {/*{item.rating}*/}
                    5.0
                  </figcaption>
                </figure>
                <figcaption className={styles.itemRatingText}>
                  {/*({item.reviewCount.toLocaleString()})*/}
                  (0)
                </figcaption>
              </figure>
            )}
            {/*<figure className={styles.itemActions}>
              <button className={styles.itemAction}>
                <PlusIcon />
              </button>
              <button className={styles.itemAction}>
                <HeartIcon />
              </button>
          </figure>*/}
          </figure>
        </>
      )}
    </figure>
  );
}

export const ExchangeItemRow = ({
  items,
  className = '',
  activeItemId = '',
  setActiveItemId = () => {},
  url = [],
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  items: AssetItem[];
  activeItemId?: string | '';
  setActiveItemId?: (id: string) => void;
  url?: string[];
}) => (
  <div className={`${styles.itemRow} ${className}`} {...props}>
    {items.map((item, i) => (
      <ExchangeItemView
        key={item.id}
        item={item}
        activeItemId={activeItemId}
        setActiveItemId={setActiveItemId}
        url={url[i] || ''}
      />
    ))}
  </div>
);

export function ExchangeItemGrid({
  items,
  className = '',
  activeItemId = '',
  setActiveItemId = () => {},
  url = [],
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  items: AssetItem[];
  activeItemId?: string | '';
  setActiveItemId?: (id: string) => void;
  url?: string[];
}) {
  return (
    <figure className={`${styles.itemGrid} ${className}`} {...props}>
      {items.map((item: AssetItem, i) => (
        <ExchangeItemView
          key={item.id}
          item={item}
          activeItemId={activeItemId}
          setActiveItemId={setActiveItemId}
          url={url[i] || ''}
        />
      ))}
    </figure>
  );
}

export const useActiveItemId = (): [string | '', (id: string) => void] => {
  const [activeItemId, setActiveItemId] = useState<string | ''>('');

  return [activeItemId, setActiveItemId];
};
