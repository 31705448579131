import { useUser } from '../../hook/useUser';
import { useState, useEffect, useMemo } from 'react';
import usePageConfig from '../../hook/usePageConfig';
import { useSearchParams, useNavigate } from 'react-router-dom';

import styles from './recovery.module.css';
import commonStyles from '../common.module.css';
import { recoverPassword, sendPasswordResetEmail } from '../../api/user';
import { toast } from 'react-hot-toast';

import Loader from '../../Comps/Loader';
import Button, { buttonClassName } from '../../Comps/Button';
import Input from '../../Comps/Input';

import { ReactComponent as SparkleIcon } from '../../assets/icons/sparkleIcon.svg';
import { ReactComponent as UserKeyIcon } from '../../assets/icons/userKeyIcon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/checkIcon.svg';
import { ReactComponent as LockResetIcon } from '../../assets/icons/lockResetIcon.svg';
import { ReactComponent as MailUnreadIcon } from '../../assets/icons/mailUnreadIcon.svg';

enum Type {
  Send_Recovery_Email,
  Reset_Password,
}

export default function RecoveryPage() {
  usePageConfig({
    title: 'Recover Password | HELIX',
  });
  const { isLoggedIn, isLoading } = useUser();

  const [searchParams] = useSearchParams();

  const type: Type = useMemo(() => {
    if (
      !searchParams.has('key') ||
      typeof searchParams.get('key') !== 'string' ||
      searchParams.get('key') === ''
    )
      return Type.Send_Recovery_Email;
    return Type.Reset_Password;
  }, []);

  return (
    <div className={styles.root}>
      {isLoggedIn && <Loader scale={1.5} className={styles.rootLoader} />}

      {!isLoggedIn && (
        <figure className={styles.wrapper}>
          {type === Type.Reset_Password && <ResetPassword />}
          {type === Type.Send_Recovery_Email && <SendRecoveryEmail />}
        </figure>
      )}
    </div>
  );
}

function SendRecoveryEmail() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  return success ? (
    <>
      <figure className={styles.successIcon}>
        <MailUnreadIcon />
      </figure>
      <h1 className={styles.title} style={{ marginBottom: 0 }}>
        Recover Password Success
      </h1>
      <h3 className={styles.subtitle}>
        Check your email for instructions on how to reset your password.
      </h3>
    </>
  ) : (
    <>
      <h1 className={styles.title}>Recover Password</h1>
      <figure className={commonStyles.formGroup}>
        <figcaption className={commonStyles.formGroupLabel}>Email</figcaption>
        <Input
          type="email"
          placeholder="conqr@ibbsinc.com"
          autoComplete="email"
          value={email}
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
      </figure>
      <Button
        variant="sky"
        disabled={loading}
        onClick={async () => {
          if (email === '') return toast.error('Email is required');
          setLoading(true);
          const toastId = toast.loading('Sending recovery email');

          const result = await sendPasswordResetEmail(email);
          setLoading(false);

          if (!result.success)
            return toast.error(result.error, { id: toastId });

          toast.success('Recovery email sent', { id: toastId });
          setSuccess(true);
        }}
      >
        <LockResetIcon />
        Reset Password
      </Button>
    </>
  );
}

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <>
      <h1 className={styles.title}>Reset Password</h1>
      <figure className={commonStyles.formGroup}>
        <figcaption className={commonStyles.formGroupLabel}>
          New Password
        </figcaption>
        <Input
          type="password"
          placeholder="eat_big_cookie"
          autoComplete="new-password"
          value={password}
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
      </figure>
      <Button
        variant="sky"
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          const toastId = toast.loading('Resetting password');
          const result = await recoverPassword(
            searchParams.get('key') as string,
            password
          );
          setLoading(false);

          if (!result.success)
            return toast.error(result.error, { id: toastId });

          toast.success('Password reset successfully', { id: toastId });
          navigate('/auth/login');
        }}
      >
        <CheckIcon />
        Reset Password
      </Button>
    </>
  );
}
