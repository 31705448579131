import { useUser } from '../../hook/useUser';
import { useState, useEffect } from 'react';
import usePageConfig from '../../hook/usePageConfig';

import type { Transaction } from '../../api/wallet';

import sharedStyles from './shared.module.css';
import styles from './wallet.module.css';
import commonStyles from '../common.module.css';
import { getWalletTransactions } from '../../api/wallet';
import { buttonClassName } from '../../Comps/Button';
import React from 'react';

import Loader from '../../Comps/Loader';
import { Link } from 'react-router-dom';
import Table, { TableDisplay } from '../../Comps/Table';
import Input from '../../Comps/Input';
import Select from '../../Comps/Select';
import Button from '../../Comps/Button';

import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as DescendingIcon } from '../../assets/icons/descendingIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as TagIcon } from '../../assets/icons/tagIcon.svg';

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      data: Transaction[];
    };

export default function AccountWalletPage() {
  usePageConfig({ title: 'Wallet | HELIX Hub' });
  const { isLoading, isLoggedIn, balance } = useUser();

  const [state, setState] = useState<State>({ loading: true });
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();

    const start = new Date(
      new Date().setDate(new Date().getDate() - 30)
    ).toISOString();
    const end = new Date().toISOString();

    getWalletTransactions(start, end, 20, abortController.signal).then(
      ({ success, data, error }) => {
        if (!success)
          return setState({
            loading: false,
            error: true,
            errorMessage: error,
          });

        setState({ loading: false, error: false, data });
      }
    );

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  return (
    <div className={sharedStyles.root}>
      {state.loading && (
        <Loader scale={1.5} className={sharedStyles.rootLoader} />
      )}
      {!state.loading && state.error && (
        <div className={sharedStyles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={sharedStyles.title}>Wallet</h1>
          <header
            className={`${commonStyles.row} ${sharedStyles.header} ${styles.header}`}
          >
            <div className={styles.headerGroup}>
              <p className={styles.headerText}>total lix balance</p>
              <h2 className={`${commonStyles.row} ${styles.headerTitle}`}>
                <LIXIcon /> {balance.toLocaleString()}
              </h2>
              <p className={styles.headerTag}>~$0.00 USD</p>
            </div>
            <Button variant="yellow" disabled>
              <LIXIcon /> Top up
            </Button>
          </header>
          <section className={`${commonStyles.row} ${styles.head}`}>
            <h2
              className={`${commonStyles.row} ${commonStyles.splitContentTitle} ${styles.headTitle}`}
            >
              Transactions
              <span className={commonStyles.splitContentTag}>
                {state.data.length.toLocaleString()}
              </span>
            </h2>
            <div className={commonStyles.row}>
              <Input
                icon={<SearchIcon style={{ color: '#A0A0A0' }} />}
                style={{ width: 200 }}
                placeholder="Search transactions"
              />
              <Select
                options={[
                  {
                    value: 'descending',
                    label: (
                      <>
                        <DescendingIcon /> Outgoing
                      </>
                    ),
                  },
                ]}
                style={{ width: 180 }}
                value={'descending'}
              />
            </div>
          </section>
          <Table
            header={[
              {
                value: 'Name',
                width: '1fr',
              },
              {
                value: 'Date',
              },
              { value: 'Amount', widthBuffer: 20 + 8 },
            ]}
            rows={state.data.map((transaction) => [
              {
                value: transaction.metadata?.title || 'Unknown',
                display: (
                  <TableDisplay key="name" className={styles.tableName}>
                    {!transaction.metadata?.icon_url ? (
                      <figure>
                        <TagIcon />
                      </figure>
                    ) : (
                      <picture
                        style={{
                          backgroundImage: `url('${transaction.metadata?.icon_url}')`,
                        }}
                      />
                    )}
                    {transaction.metadata?.title || 'Unknown'}
                  </TableDisplay>
                ),
              },
              {
                value: `${new Date(transaction.created_at).toLocaleDateString(
                  'en-US',
                  {
                    month: 'short',
                    day: 'numeric',
                  }
                )}, ${new Date(transaction.created_at).toLocaleTimeString(
                  'en-US',
                  {
                    hour: 'numeric',
                    minute: 'numeric',
                  }
                )}`,
              },
              {
                value: transaction.balance.toLocaleString(),
                display: (
                  <TableDisplay className={styles.tableAmount} key="amount">
                    <LIXIcon /> {transaction.direction === 'CREDIT' ? '+' : '-'}
                    {transaction.balance.toLocaleString()}
                  </TableDisplay>
                ),
              },
            ])}
          />
        </>
      )}
    </div>
  );
}
