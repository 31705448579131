import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePageConfig from '../../hook/usePageConfig';

import type { StateExtendable } from '../../types/common';

import { confirmEmailChange } from '../../api/user';
import styles from './emailChange.module.css';

import Loader from '../../Comps/Loader';

export default function EmailChangePage() {
  const [searchParams, setSearchParams] = useSearchParams();
  usePageConfig({ title: 'Confirm Email Change | HELIX' });

  const [state, setState] = useState<StateExtendable>(
    !searchParams.get('key') ||
      searchParams.get('key') === '' ||
      !searchParams.get('email') ||
      searchParams.get('email') === ''
      ? { loading: false, error: true, errorMessage: 'Invalid Request' }
      : { loading: true }
  );

  const [email] = useState(searchParams.get('email')!);

  useEffect(() => {
    if (!state.loading) return;
    const key = searchParams.get('key')!;

    setSearchParams(new URLSearchParams(''));

    confirmEmailChange(key, email).then((result) => {
      if (!result.success)
        return setState({
          loading: false,
          error: true,
          errorMessage: result.error,
        });

      setState({
        loading: false,
        error: false,
      });
    });
  }, []);

  return (
    <div className={styles.root}>
      {state.loading && (
        <>
          <figure className={styles.loader}>
            <Loader scale={1.25} />
          </figure>
          <h1 className={styles.title}>Baking cookies...</h1>
        </>
      )}
      {!state.loading && state.error && (
        <>
          <h1 className={styles.title}>Error</h1>
          <h3 className={styles.subtitle}>{state.errorMessage}</h3>
        </>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Success!</h1>
          <h3 className={styles.subtitle}>
            Your HELIX Account email has been changed to {email}.
          </h3>
          <h3 className={styles.subtitle}>
            Check your new email for a verification link.
          </h3>
        </>
      )}
    </div>
  );
}
