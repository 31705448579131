import { useOutletContext, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useModal from '../../../../hook/useModal';

import type { OutletContext } from '.';

import commonStyles from '../../../common.module.css';
import styles from './details.module.css';
import { getAssetTypes } from '../../../../api/asset';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { processError } from '../../../../utils';

import Input, { TextArea } from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import { Toggle } from '../../../../Comps/Checkbox';
import Select from '../../../../Comps/Select';
import TagInput from '../../../../Comps/TagInput';
import Modal from '../../../../Comps/Modal';

import { ReactComponent as LIXIcon } from '../../../../assets/icons/lixIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';

export default function CreationsAssetsAssetManagerDetailsPage() {
  const {
    asset,
    getField,
    setUpdateField,
    savingData,
    setSavingData,
    fetchAsset,
  } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const [testingVariable, setTestingVariable] = useState(0);

  const [assetTypes, setAssetTypes] = useState<null | Record<number, string>>(
    null
  );
  useEffect(() => {
    const abortController = new AbortController();
    getAssetTypes(abortController.signal).then(({ success, types }) => {
      if (!success || !types) return;

      //console.log(types, types[0]);

      setAssetTypes(types);
    });

    return () => abortController.abort();
  }, []);

  const [modalController, modal] = useModal();

  const canPublish =
    !!asset.version &&
    asset.version.status === 'VALID' &&
    asset.icon_url !== '';
  const canPublishMessage = canPublish
    ? ''
    : !asset.version
    ? 'You must upload at least one version of your asset'
    : asset.version.status === 'INVALID'
    ? 'Your uploaded asset is invalid'
    : asset.version.status !== 'VALID'
    ? 'Your uploaded asset has not been validated yet'
    : 'You must upload an asset icon';
  const canPublishCTA = canPublish
    ? ''
    : !asset.version
    ? 'upload'
    : asset.version.status === 'INVALID'
    ? 're-upload'
    : asset.version.status !== 'VALID'
    ? 'check upload'
    : 'upload icon';
  const canPublishLink = canPublish
    ? ''
    : !asset.version
    ? 'config'
    : asset.version.status === 'INVALID'
    ? 'config'
    : asset.version.status !== 'VALID'
    ? 'config'
    : 'showcase';

  return (
    <div className={`${commonStyles.splitContentWrapper}`}>
      <Modal controller={modalController} />
      <div className={commonStyles.splitContentWrapperLimit}>
        <h1 className={commonStyles.splitContentTitle}>Asset details</h1>
        {/*<h3
          className={commonStyles.formGroupLabel}
          style={{ lineHeight: '20px', margin: '16px 0 32px 0' }}
        >
          General details about your world. This information will be visible to
          everyone if your world is public.
  </h3>
        <figure className={commonStyles.splitContentDivider} />*/}
        {/* <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -20 }}
          >
            Thumbnail
      </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              This will represent your asset across HELIX.
            </figcaption>
            <figure className={styles.thumbnailPicker}>
              {new Array(4).fill(asset.icon_url).map((url, i) => (
                <button
                  key={i}
                  disabled={i === testingVariable}
                  onClick={() => setTestingVariable(i)}
                  className={styles.thumbnailPickerItem}
                >
                  <picture style={{ backgroundImage: `url('${url}')` }} />
                </button>
              ))}
            </figure>
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />*/}
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -20 }}
          >
            General
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              This information will be visible in the Exchange and to anyone
              that owns your asset.
            </figcaption>
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Asset ID
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={asset.name}
              disabled
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Display name
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={getField('title')}
              maxLength={90}
              onChange={(e) => setUpdateField('title', e.target.value)}
              disabled={savingData}
              placeholder="Super Cool Rock"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Description
            </figcaption>
            <TextArea
              className={commonStyles.formGroupInput}
              value={getField('description')}
              maxLength={240}
              style={{ height: 80 }}
              onChange={(e) => setUpdateField('description', e.target.value)}
              disabled={savingData}
              placeholder="Super cool multi-purpose rock that fits in a variety of environments. Took me an abysmal 5 minutes to make."
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Category
            </figcaption>
            <Select
              className={commonStyles.formGroupInput}
              value={`${getField('type_id') ?? ''}`}
              onChange={(value) => {
                //console.log(value, typeof value);
                setUpdateField('type_id', value);
              }}
              disabled={savingData}
              options={
                assetTypes === null
                  ? []
                  : Object.entries(assetTypes)
                      .filter((e) => {
                        //console.log(e);
                        return true;
                      })
                      .map(([id, name]) => ({
                        value: id,
                        label: name,
                      }))
              }
              loading={assetTypes === null}
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Tags
            </figcaption>
            <TagInput
              className={commonStyles.formGroupInput}
              value={
                getField<string[]>(
                  'tags',
                  asset.tags.map((tag: any) => tag.tag),
                  true
                ) || []
              }
              onChange={(newValue) => setUpdateField('tags', newValue)}
              sanitizeTag={(tag: string) => tag.toLowerCase()}
              disabled={savingData}
              theme="yellow"
            />
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -20 }}
          >
            Exchange
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ lineHeight: '20px' }}
            >
              {asset.published
                ? 'Your asset is now listed on the Exchange!'
                : 'By default, all assets are private. You can publish your asset to the Exchange to make it public. We encourage you to list it for free.'}
            </figcaption>
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Price (optional)
            </figcaption>
            <Input
              placeholder="0"
              className={commonStyles.formGroupInput}
              style={{ width: 148 }}
              disabled={savingData}
              value={getField('price')}
              onChange={(e) => setUpdateField('price', e.target.value)}
              icon={<LIXIcon style={{ color: '#EDEDED' }} />}
            />
            {isNaN(Number(getField('price') ?? '0')) && (
              <figcaption className={commonStyles.formGroupError}>
                Price must be a number
              </figcaption>
            )}
          </figure>
          {!asset.published && (
            <figure className={commonStyles.formGroup}>
              <figcaption
                className={commonStyles.formGroupLabel}
                style={{ color: '#D2785F' }}
              >
                {canPublishMessage}
              </figcaption>
              <Button
                disabled={savingData || !canPublish}
                variant="sky"
                onClick={async () => {
                  if (savingData || !canPublish) return;
                  setSavingData(true);

                  await toast.promise(
                    axios.patch('/asset', {
                      id: asset.id,
                      published: true,
                    }),
                    {
                      loading: 'Publishing...',
                      success: () => {
                        setSavingData(false);
                        fetchAsset(true);
                        return `Published!`;
                      },
                      error: (e) => {
                        setSavingData(false);
                        return `${processError(e)}`;
                      },
                    }
                  );
                }}
              >
                Publish
              </Button>
              {!canPublish && (
                <>
                  <Button
                    disabled={savingData}
                    variant="dark"
                    style={{ width: 'fit-content', marginTop: 12 }}
                    onClick={() =>
                      navigate(
                        `/creations/assets/${asset.id}/${canPublishLink}`
                      )
                    }
                  >
                    <UploadIcon />
                    {canPublishCTA}
                  </Button>
                </>
              )}
            </figure>
          )}
          {asset.published && (
            <figure className={commonStyles.formGroup}>
              <Button
                variant="red"
                disabled
                onClick={async () => {
                  return toast.error("You can't unpublish assets");
                  if (savingData) return;
                  setSavingData(true);

                  const result = await modal.open({
                    title: 'Unpublish asset',
                    description:
                      'Are you sure you want to unpublish this asset? It will no longer be available on the Exchange.',
                  });

                  if (result !== 'confirm') return setSavingData(false);
                  toast.promise(
                    axios.patch('/asset', {
                      id: asset.id,
                      published: false,
                    }),
                    {
                      loading: 'Unpublishing...',
                      success: () => {
                        setSavingData(false);
                        fetchAsset(true);
                        return `Unpublished!`;
                      },
                      error: (e) => {
                        setSavingData(false);
                        return `${processError(e)}`;
                      },
                    }
                  );
                }}
              >
                Unpublish
              </Button>
            </figure>
          )}
        </section>
      </div>
    </div>
  );
}
