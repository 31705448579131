import { useEffect, useRef, useState } from 'react';
import { useUser } from '../../hook/useUser';
import { useLocation, useNavigate } from 'react-router-dom';
import useActiveController from '../../hook/useActiveController';

import styled from 'styled-components';
import styles from './nav.module.css';

import { NavLink, Link } from 'react-router-dom';
import Button, { buttonClassName } from '../../Comps/Button';

import { IconCompo } from '../common/Icon.compo';
import { IconBtnCompo } from '../common/IconBtn.compo';

import { ReactComponent as LogoSvg } from '../../assets/logo.svg';
import { ReactComponent as LogoLongSvg } from '../../assets/logo_long.svg';
import { ReactComponent as MenuSvg } from '../../assets/icons/menu.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowIcon.svg';
import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as TickIcon } from './tick.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/userIcon.svg';
import { ReactComponent as PassKeyIcon } from '../../assets/icons/passKeyIcon.svg';
import { ReactComponent as PeopleIcon } from '../../assets/icons/peopleIcon.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons/walletIcon.svg';
import { ReactComponent as ExternalIcon } from '../../assets/icons/externalIcon.svg';
import { ReactComponent as ForumIcon } from '../../assets/icons/forumIcon.svg';
import { ReactComponent as DiscordIcon } from '../../assets/icons/discordIcon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/icons/documentIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadIcon.svg';
import { ReactComponent as PlayCircleIcon } from '../../assets/icons/playCircleIcon.svg';
import { ReactComponent as UserKeyIcon } from '../../assets/icons/userKeyIcon.svg';

const ROUTES: { [key: string]: string } = {
  creations: '/creations',
  exchange: '/exchange',
  /*sales: '/sales',*/
  /*guides: '/guides',*/
};

const HOME_PAGE = '/creations/worlds';

enum Menu {
  None = 0,
  Account = 1,
  Community = 2,
}

function CustomNavLink({
  active = false,
  onChange = () => null,
  className = '',
  children = null,
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  active?: boolean;
  onChange: (positionX: number) => void;
}) {
  const buttonRef = useRef<
    HTMLButtonElement & { getBoundingClientRect: () => DOMRect }
  >(null);

  return (
    <button
      className={`${styles.navOption} ${styles.navDropdown} ${
        active && styles.active
      } ${className}`}
      ref={buttonRef}
      onClick={() => {
        if (!buttonRef.current) return onChange(0);
        const position = buttonRef.current.getBoundingClientRect();
        onChange(position.left);
      }}
      {...props}
    >
      {children} <TickIcon />
    </button>
  );
}

export default function MainNavCompo() {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, logout, balance, isLoading } = useUser();

  const [menu, setMenu] = useState<Menu>(Menu.None);
  const [menuPosition, setMenuPosition] = useState<{
    account: number;
    community: number;
  }>({
    account: 0,
    community: 0,
  });
  const accountCountroller = useActiveController(menu === Menu.Account, 200);
  const communityCountroller = useActiveController(
    menu === Menu.Community,
    200
  );

  useEffect(() => {
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!e.target || !!(e.target as any).closest(`.${styles.navDropdown}`))
        return;
      setMenu(Menu.None);
    };

    window.addEventListener('click', onClick as any);
    return () => window.removeEventListener('click', onClick as any);
  }, []);

  return (
    <StyledDiv>
      <figure
        className={styles.logo}
        onClick={() =>
          window.location.pathname === HOME_PAGE ? null : navigate(HOME_PAGE)
        }
      >
        <LogoLongSvg className="long" />
        <LogoText>Account Hub</LogoText>
      </figure>
      {(accountCountroller.view || communityCountroller.view) && (
        <div className={`${styles.menuWrapper} ${styles.menuWrapperActive}`}>
          {accountCountroller.view && (
            <figure
              className={`${styles.dropDown} ${
                accountCountroller.animate && styles.dropDownActive
              }`}
              onMouseLeave={() => setMenu(Menu.None)}
              style={{ left: menuPosition.account }}
            >
              <NavLink
                to="/account/details"
                className={({ isActive }) =>
                  `${styles.dropDownButton} ${
                    isActive && styles.dropDownButtonActive
                  }`
                }
              >
                <UserIcon />
                Account Details
              </NavLink>
              <NavLink
                to="/account/wallet"
                className={({ isActive }) =>
                  `${styles.dropDownButton} ${
                    isActive && styles.dropDownButtonActive
                  }`
                }
              >
                <WalletIcon />
                Wallet
              </NavLink>
              <NavLink
                to="/account/invites"
                className={({ isActive }) =>
                  `${styles.dropDownButton} ${
                    isActive && styles.dropDownButtonActive
                  }`
                }
              >
                <PeopleIcon />
                Invite Friends
              </NavLink>
              <NavLink
                to="/account/tokens"
                className={({ isActive }) =>
                  `${styles.dropDownButton} ${
                    isActive && styles.dropDownButtonActive
                  }`
                }
              >
                <UserKeyIcon />
                Access Tokens
              </NavLink>
            </figure>
          )}
          {communityCountroller.view && (
            <figure
              className={`${styles.dropDown} ${
                communityCountroller.animate && styles.dropDownActive
              }`}
              onMouseLeave={() => setMenu(Menu.None)}
              style={{ left: menuPosition.community }}
            >
              {/*<a
                href="https://forums.helixmetaverse.com/"
                target="_blank"
                className={`${styles.dropDownButton}`}
              >
                <ForumIcon />
                Forums
                <ExternalIcon />
            </a>*/}
              <a
                href="https://helixgame.com/docs"
                target="_blank"
                className={`${styles.dropDownButton}`}
              >
                <DocumentIcon />
                API Docs <ExternalIcon />
              </a>
              <a
                href="https://helixmetaverse.notion.site/HELIX-Creator-Tutorials-8ab5ce3f379442aeaf1ca07b7739fc16"
                target="_blank"
                className={`${styles.dropDownButton}`}
              >
                <PlayCircleIcon />
                Tutorials <ExternalIcon />
              </a>
              <a
                href="https://discord.gg/helixcreators"
                target="_blank"
                className={`${styles.dropDownButton}`}
              >
                <DiscordIcon />
                Discord <ExternalIcon />
              </a>
            </figure>
          )}
        </div>
      )}
      <nav
        className={`${styles.navList} ${
          menu !== Menu.None && styles.navListStatic
        }`}
      >
        {Object.entries(ROUTES).map(([label, route]) => (
          <NavLink
            key={route}
            className={({ isActive }) =>
              `${styles.navOption} ${isActive && styles.active}`
            }
            to={route}
          >
            {label}
          </NavLink>
        ))}
        <CustomNavLink
          active={menu === Menu.Account}
          onChange={(position) => {
            setMenuPosition((prev) => ({
              ...prev,
              account: position as number,
            }));
            setMenu(menu === Menu.Account ? Menu.None : Menu.Account);
          }}
        >
          Account
        </CustomNavLink>
        <CustomNavLink
          active={menu === Menu.Community}
          onChange={(position) => {
            setMenuPosition((prev) => ({
              ...prev,
              community: position as number,
            }));
            setMenu(menu === Menu.Community ? Menu.None : Menu.Community);
          }}
        >
          Community
        </CustomNavLink>
      </nav>

      <div className={styles.options}>
        {/*<Link
          to="/download"
          className={buttonClassName('yellow')}
          style={{
            whiteSpace: 'nowrap',
          }}
        ></Link>*/}
        <NavLink
          to="/welcome"
          className={`${buttonClassName('yellow')}`}
          style={({ isActive }) => ({
            opacity: isActive ? 0.5 : undefined,
            pointerEvents: isActive ? 'none' : undefined,
            whiteSpace: 'nowrap',
          })}
        >
          <DownloadIcon /> get helix
        </NavLink>
        {!isLoading && (
          <Link to="/account/wallet" className={buttonClassName('dark')}>
            <LIXIcon /> {Math.floor(balance).toLocaleString()}
          </Link>
        )}

        <div
          className={styles.avatar}
          style={
            {
              position: 'relative',
              '--theme':
                currentUser?.iconColor !== ''
                  ? currentUser?.iconColor
                  : undefined,
            } as any
          }
          onClick={() => navigate('/account/details')}
        >
          <img
            src={
              currentUser?.iconUrl ??
              `https://i.pravatar.cc/150?u=${currentUser?.email ?? '0'}`
            }
            alt="avatar"
            style={{ pointerEvents: 'none' }}
          />
        </div>
        <IconBtnCompo icon="logout" variant="dark" onClick={() => logout()} />
      </div>
    </StyledDiv>
  );
}

const LogoText = styled.figcaption`
  color: #a0a0a0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  width: 64px;
`;

const StyledDiv = styled.div`
  position: absolute;
  user-select: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  padding: 0 var(--gutter);
  //padding: 0 32px;
  border-bottom: 1px solid #232323;
  background: #161616;
  transition: opacity 0.5s;

  .logo,
  .mode,
  .options {
    height: 100%;
    flex-flow: row nowrap;
    display: flex;
    align-items: center;
  }
`;
