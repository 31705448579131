import * as youtube from './youtube';

export { youtube };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function processError(err: any) {
  if (typeof err === 'string') return err;
  if (err.response?.data?.message) {
    return 'Error: ' + err.response.data.message;
  }
  if (err.message) {
    return err.message;
  }
  return err.toString();
}

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const copyText = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Unable to copy to clipboard:', err);
    return false;
  }
};

export function selectFile(accept?: string): Promise<File | null | undefined> {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept || '*';
    input.onchange = () => {
      if (input.files && input.files.length > 0) {
        resolve(input.files[0]);
      } else {
        resolve(null);
      }
    };
    input.click();
    input.remove();
  });
}

export function formatSize(bytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let size = bytes;
  let i = 0;
  while (size > 1024) {
    size /= 1024;
    i++;
  }
  return `${Number(
    ['GB', 'TB', 'PB'].includes(units[i]) ? size.toFixed(2) : size.toFixed(0)
  ).toLocaleString()} ${units[i]}`;
}

export function shortNumber(num: number): string {
  if (num < 1000) return num.toString();
  if (num < 1000000) return (num / 1000).toFixed(1) + 'k';
  if (num < 1000000000) return (num / 1000000).toFixed(1) + 'm';
  return (num / 1000000000).toFixed(1) + 'b';
}

export const randomString = (length: number): string =>
  new Array(length)
    .fill(0)
    .map(() => Math.floor(Math.random() * (36 + 36)))
    .map((n) =>
      n <= 36 ? n.toString(36) : (n - 36).toString(36).toUpperCase()
    )
    .join('');
export const randomName = (length: number): string =>
  new Array(length)
    .fill(10)
    .map((i) => i + Math.floor(Math.random() * 26))
    .map((n) => n.toString(36))
    .join('');

export const downloadFile = (data = '', fileName = 'file.txt'): void => {
  const a = document.createElement('a');
  const objectURL = URL.createObjectURL(
    new Blob([data], { type: 'text/plain' })
  );
  a.href = objectURL;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(objectURL);
};

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   {number}  r       The red color value
 * @param   {number}  g       The green color value
 * @param   {number}  b       The blue color value
 * @return  {Array}           The HSL representation
 */
export function rgbToHsl(
  r: number,
  g: number,
  b: number
): [number, number, number] {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (r /= 255), (g /= 255), (b /= 255);
  const vmax = Math.max(r, g, b),
    vmin = Math.min(r, g, b),
    l = (vmax + vmin) / 2;

  let h = 0;

  if (vmax === vmin) {
    return [0, 0, l]; // achromatic
  }

  const d = vmax - vmin;
  const s = l > 0.5 ? d / (2 - vmax - vmin) : d / (vmax + vmin);
  if (vmax === r) h = (g - b) / d + (g < b ? 6 : 0);
  if (vmax === g) h = (b - r) / d + 2;
  if (vmax === b) h = (r - g) / d + 4;
  h /= 6;

  return [h, s, l];
}
