import { graphQLRequest } from './utils';

import clist from '../assets/asset_category.json';
import { AssetItem, DetailAssetItem } from '../modal';
import axios, { AxiosError } from 'axios';
import { getAllCategoryNamesById } from './category';
import { processError } from '../utils';

import type { ResultExtendable } from '../types/common';

const categoryMappping: { [key: number]: string[] } = {};

export async function getVaultStorage(userId: string): Promise<number> {
  const resp = await graphQLRequest(
    `
    query GetVaultStorage($user_id: uuid = "") {
      total: asset_versions_aggregate(where: {asset: {user_id: {_eq: $user_id}}}) {
        aggregate {
          sum {
            total: size_in_bytes
          }
        }
      }
    }`,
    { user_id: userId }
  );
  return resp.total.aggregate.sum.total;
}

export async function getMyAsset(page: number): Promise<AssetItem[]> {
  const resp = await axios.get('/asset', {
    params: {
      page_size: 20,
      offset: page * 20,
      owned: true,
      // creator: creator || undefined,
    },
  });
  return resp.data.payload;
}

export async function getAllAsset(page: number): Promise<AssetItem[]> {
  const resp = await axios.get('/asset', {
    params: {
      page_size: 20,
      offset: page * 20,
      // creator: creator || undefined,
    },
  });
  return resp.data.payload;
}

export async function getAllAssets(
  abortSignal: AbortSignal,
  pageLimit = 10
): Promise<AssetItem[]> {
  const assets: AssetItem[] = [];

  for (let i = 0; i < pageLimit; i++) {
    const response = await axios.get('/asset', {
      params: {
        page_size: 20,
        offset: i * 20,
      },
      signal: abortSignal,
    });

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      response.data.payload.length === 0
    )
      break;

    assets.push(...response.data.payload);
    if (response.data.payload.length < 20) break;
  }

  return assets;
}

const category: { [key: string]: number } = {
  map: 1,
  character: 2,
  vehicle: 3,
  weapon: 4,
  prop: 5,
  script: 6,
  other: 7,
};

function getCategoryId(name: string): number {
  if (category[name.toLowerCase()]) return category[name.toLowerCase()];
  return 0;
}

export async function getAllAssetByFilter(
  filter: {
    type: string;
  },
  page: number
): Promise<AssetItem[]> {
  const resp = await axios.get('/asset', {
    params: {
      page_size: 20,
      offset: page * 20,
      type: filter.type === '' ? undefined : getCategoryId(filter.type),
      // creator: creator || undefined,
    },
  });
  return resp.data.payload;
}

export async function getAllAssetsByType(
  type: string,
  creatorId: string | '',
  abortSignal: AbortSignal,
  pageLimit = 10
): Promise<AssetItem[]> {
  const assets: AssetItem[] = [];

  for (let i = 0; i < pageLimit; i++) {
    const response = await axios.get('/asset', {
      params: {
        page_size: 20,
        offset: i * 20,
        type: getCategoryId(type),
        creator: creatorId === '' ? undefined : creatorId,
      },
      signal: abortSignal,
    });

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      response.data.payload.length === 0
    )
      break;

    assets.push(...response.data.payload);
    if (response.data.payload.length < 20) break;
  }

  return assets;
}

export async function getAssetById(
  id: string,
  owned = false,
  abortSignal?: AbortSignal | undefined
): Promise<AssetItem | 404 | null> {
  try {
    const resp = await axios.get(`/asset`, {
      params: {
        ...(owned && {
          owned,
        }),
        id,
        page_size: 1,
      },
      ...(!!abortSignal && {
        signal: abortSignal,
      }),
    });

    if (resp.status === 404 || resp.status === 400) return 404;

    if (
      typeof resp.data.payload === 'object' &&
      resp.data.payload !== null &&
      resp.data.payload.length > 0
    )
      return resp.data.payload[0];

    return null;
  } catch (e) {
    // console.log('error', e);
    if (
      (e as AxiosError)?.response?.status === 404 ||
      (e as AxiosError)?.response?.status === 400
    )
      return 404;

    return null;
  }
}

export async function getAsset(
  assetId: string,
  owned = true,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      exists: true;
      data: AssetItem;
    }
  | {
      success: true;
      error: '';
      exists: false;
      data: null;
    }
  | {
      success: false;
      error: string;
      exists: false;
      data: null;
    }
> {
  try {
    const resp = await axios.get(`/asset`, {
      params: {
        ...(owned && {
          owned,
        }),
        id: assetId,
        page_size: 1,
      },
      signal,
    });

    if (resp.status === 404 || resp.status === 400)
      return {
        success: true,
        error: '',
        exists: false,
        data: null,
      };

    if (
      typeof resp.data.payload !== 'object' ||
      resp.data.payload === null ||
      resp.data.payload.length === 0
    )
      return {
        success: true,
        error: '',
        exists: false,
        data: null,
      };

    const asset = resp.data.payload[0] as AssetItem;
    if (asset.depends_on === null) asset.depends_on = [];
    if (asset.screenshots === null) asset.screenshots = [];
    if (asset.thumbnails === null) asset.thumbnails = [];

    return {
      success: true,
      error: '',
      exists: true,
      data: asset,
    };
  } catch (e) {
    console.error('Unable to get asset:', e);
    return {
      success: false,
      error: processError(e),
      exists: false,
      data: null,
    };
  }
}

export async function getAssetByName(name: string): Promise<AssetItem> {
  const resp = await graphQLRequest(
    `
    query GetAsset($item_name: String = "") {
      data: assets(where: {published: {_eq: true}, is_public: {_eq: true}, name: {_eq: $item_name}}) {
        id
        name
        title
        description
        type_id
        icon_url
        published
        is_public
        status
        price
        created_at
        updated_at
        creator: user {
          id
          username
          usernameId
          icon_url
        }
        versions: asset_versions(order_by: {created_at: desc}, where: {status: {_neq: "INVALID"}}, limit: 10) {
          asset_location: location
          toml_version
          unreal_version
          size_in_bytes
          status
          created_at
          updated_at
        }
      }
    }`,
    { item_name: name }
  );
  return resp.data;
}

/*export async function getAssetById(name: string): Promise<DetailAssetItem> {
  const resp = await graphQLRequest(
    `
    query GetAsset($item_id: uuid = "") {
      data: assets(where: {published: {_eq: true}, is_public: {_eq: true}, id: {_eq: $item_id}}) {
        id
        name
        title
        description
        full_description
        type_id
        icon_url
        cover_urls
        published
        is_public
        status
        price
        created_at
        updated_at
        creator: user {
          id
          username
          usernameId
          icon_url
        }
        versions: asset_versions(order_by: {created_at: desc}, where: {status: {_neq: "INVALID"}}, limit: 10) {
          asset_location: location
          toml_version
          unreal_version
          size_in_bytes
          status
          created_at
          updated_at
        }
      }
    }`,
    { item_id: name }
  );
  return resp.data && resp.data.length > 0 ? resp.data[0] : null;
}*/

export function getAssetCategoryById(id: number): string[] {
  if (categoryMappping[id]) return categoryMappping[id];
  const names = getAllCategoryNamesById(clist.data, id);
  categoryMappping[id] = names;
  return names;
}

export async function getAllCreatedAssets(
  abortSignal: AbortSignal,
  maxPages = 0
): Promise<AssetItem[]> {
  const assets: AssetItem[] = [];

  for (let i = 0; i < 10; i++) {
    const response = await axios.get('/asset', {
      params: {
        page_size: 20,
        offset: i * 20,
        owned: true,
      },
      signal: abortSignal,
    });

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      response.data.payload.length === 0
    )
      break;

    assets.push(...response.data.payload);
    if (response.data.payload.length < 20) break;
  }

  return assets;
}

export async function getAssetTypes(abortSignal: AbortSignal): Promise<{
  success: boolean;
  types?: Record<number, string>;
}> {
  try {
    const { data } = await axios.get('/asset/types', {
      signal: abortSignal,
    });

    if (
      typeof data !== 'object' ||
      data === null ||
      typeof data.payload !== 'object' ||
      data.payload === null
    )
      return {
        success: false,
      };

    return {
      success: true,
      types: Object.fromEntries(
        Object.entries(data.payload).map(([key, value]) => [value, key])
      ),
    };
  } catch (e) {
    console.error('Unable to get types:', processError(e));
    return {
      success: false,
    };
  }
}

export async function deleteAsset(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const response = await axios.delete(`/asset/${assetId}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    console.error('Unable to delete asset:', processError(e));
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function setAssetIcon(
  assetId: string,
  image: File,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const formData = new FormData();
    formData.append('files', image);
    formData.append('asset_id', assetId);

    const response = await axios.post(`/asset/icon`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    console.error('Unable to set asset icon:', processError(e));
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function getAssetScreenshots(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const response = await axios.get(`/asset/screenshots/${assetId}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: [],
      };

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      !Array.isArray(response.data.payload)
    )
      // asset doesn't have any images
      return {
        success: true,
        error: '',
        data: [],
      };

    return {
      success: true,
      error: '',
      data: response.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function uploadAssetScreenshot(
  assetId: string,
  image: File,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string;
    }
  | {
      success: false;
      error: string;
      data: '';
    }
> {
  try {
    const formData = new FormData();
    formData.append('files', image);
    formData.append('asset_id', assetId);

    const response = await axios.post(`/asset/screenshot`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
        data: '',
      };

    return {
      success: true,
      error: '',
      data: response.data.payload.screenshot_url,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function updateAssetScreenshots(
  assetId: string,
  screenshots: string[],
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[] | null;
    }
  | {
      success: false;
      error: string;
      data: null;
    }
> {
  try {
    const response = await axios.put(
      `/asset/screenshots`,
      {
        asset_id: assetId,
        asset_screenshots: screenshots,
      },
      {
        signal,
      }
    );

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
        data: null,
      };

    return {
      success: true,
      error: '',
      data:
        typeof response.data.payload === 'object' &&
        response.data.payload !== null
          ? response.data.payload
          : null, // if the server doesn't have anything nice to say, don't say anything at all
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function validateAssetName(
  assetName: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      isAvailable: boolean;
    }
  | {
      success: false;
      error: string;
      isAvailable: false;
    }
> {
  try {
    const response = await axios.get(`/asset/${assetName}`, {
      signal,
    });

    return {
      success: true,
      error: '',
      isAvailable: response.data.code !== 0,
    };
  } catch (e) {
    if (
      // @ts-ignore
      typeof e.response === 'object' && // @ts-ignore
      e.response !== null && // @ts-ignore
      typeof e.response.status === 'number' && // @ts-ignore
      e.response.status === 404
    )
      return {
        success: true,
        error: '',
        isAvailable: true,
      };
    return {
      success: false,
      error: processError(e),
      isAvailable: false,
    };
  }
}

export async function getPublicAssets(
  filter: Partial<{
    creator: string;
    type: number | undefined;
    tags: string;
  }>,
  maxPages: number,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: [];
    }
  | {
      success: true;
      error: '';
      data: AssetItem[];
    }
> {
  try {
    const data: AssetItem[] = [];
    for (let i = 0; i < maxPages; i++) {
      const result = await axios.get(`/asset`, {
        params: { page_size: 100, offset: i * 100, ...(filter ?? {}) },
        signal,
      });

      if (result.data.code !== 0)
        return {
          success: false,
          error: processError(result.data),
          data: [],
        };

      if (result.data.payload === null || result.data.payload.length === 0)
        break;

      data.push(...result.data.payload);

      if (result.data.payload.length < 100) break;
    }

    return {
      success: true,
      error: '',
      data,
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function purchaseAsset(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.post(
      '/asset/purchase',
      {
        asset_id: assetId,
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function getAssetLinks(
  assetId: string,
  signal?: AbortSignal
): Promise<ResultExtendable<{ data: Record<string, string> }, { data: null }>> {
  try {
    const result = await axios.get(`/asset/links/${assetId}`, {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? {},
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function updateAssetLinks(
  assetId: string,
  links: Record<string, string | string[]>,
  signal?: AbortSignal
): Promise<ResultExtendable<{ data: Record<string, string> }, { data: null }>> {
  try {
    const result = await axios.patch(
      '/asset/links',
      {
        asset_id: assetId,
        links: links ?? {},
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? {},
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export async function getAssetDownloads(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: number;
    }
  | {
      success: false;
      error: string;
      data: 0;
    }
> {
  try {
    const result = await axios.get(
      `https://asset-download.helix-cdn.com/stats/${assetId}`,
      {
        signal,
        transformRequest: (data, headers) => {
          delete headers.Authorization;
          return data;
        },
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: 0,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? 0,
    };
  } catch (e) {
    console.error('Unable to get asset downloads:', processError(e));
    return {
      success: false,
      error: processError(e),
      data: 0,
    };
  }
}

export async function getOwnedAssets(signal?: AbortSignal): Promise<
  | {
      success: false;
      error: string;
      data: [];
    }
  | {
      success: true;
      error: '';
      data: AssetItem[];
    }
> {
  try {
    const result = await axios
      .get('/asset/purchased', {
        signal,
      })
      .catch();

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: [],
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? [],
    };
  } catch (e) {
    console.error('Unable to get owned assets:', processError(e));
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function getAssetOwnership(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: false;
    }
  | {
      success: true;
      error: '';
      data: boolean;
    }
> {
  try {
    const result = await axios.get(`/asset/purchased`, {
      signal,
      params: {
        asset_id: assetId,
      },
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: false,
      };

    return {
      success: true,
      error: '',
      data:
        typeof result.data.payload === 'object' &&
        result.data.payload !== null &&
        result.data.payload.length > 0,
    };
  } catch (e) {
    console.error('Unable to get asset ownership:', processError(e));
    return {
      success: false,
      error: processError(e),
      data: false,
    };
  }
}

export function getAssetDownloadLink(
  assetId: string,
  assetLocation: string
): string {
  return `https://asset-download.helix-cdn.com/download/${
    assetLocation.search('amazonaws') === -1 ? 'r2' : 's3'
  }/${assetId}/${assetLocation.split(/\/+/).pop()?.replace('.zip', '')}`;
}

export async function getAssetThumbnails(
  assetId: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const response = await axios.get(`/asset/thumbnails/${assetId}`, {
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: processError(response.data),
        data: [],
      };

    if (
      typeof response.data.payload !== 'object' ||
      response.data.payload === null ||
      !Array.isArray(response.data.payload)
    )
      // asset doesn't have any images
      return {
        success: true,
        error: '',
        data: [],
      };

    return {
      success: true,
      error: '',
      data: response.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function uploadAssetThumbnail(
  assetId: string,
  image: File,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string;
    }
  | {
      success: false;
      error: string;
      data: '';
    }
> {
  try {
    const formData = new FormData();
    formData.append('files', image);
    formData.append('asset_id', assetId);

    const response = await axios.post(`/asset/thumbnail`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
      signal,
    });

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
        data: '',
      };

    return {
      success: true,
      error: '',
      data: response.data.payload.thumbnail_url,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function updateAssetThumbnails(
  assetId: string,
  thumbnails: string[],
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      data: string[] | null;
    }
  | {
      success: false;
      error: string;
      data: null;
    }
> {
  try {
    const response = await axios.put(
      `/asset/thumbnails`,
      {
        asset_id: assetId,
        asset_thumbnails: thumbnails,
      },
      {
        signal,
      }
    );

    if (response.data.code !== 0)
      return {
        success: false,
        error: response.data.message,
        data: null,
      };

    return {
      success: true,
      error: '',
      data:
        typeof response.data.payload === 'object' &&
        response.data.payload !== null
          ? response.data.payload
          : null, // if the server doesn't have anything nice to say, don't say anything at all
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}

export type AssetSearchSuggestion = {
  id: string;
  name: string;
  title: string;
  icon_url: string;
  owned: boolean;
  created: boolean;
};
export async function getAssetSearchSuggestions(
  query: string
): Promise<ResultExtendable<{ data: AssetSearchSuggestion[] }, { data: null }>> {
  try {
    const result = await axios.get(`/asset/suggestions`, {
      params: {
        query,
      },
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: result.data.payload ?? [],
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}
