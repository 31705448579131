import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExchangeItemGrid, useActiveItemId } from '../../Comps/Item';
import { useUser } from '../../hook/useUser';
import { useInfiniteQuery } from '@tanstack/react-query';
import usePageConfig from '../../hook/usePageConfig';

import styles from './index.module.css';
import { getAllAsset, getAllAssets, getMyAsset } from '../../api/asset';
import { buttonClassName } from '../../Comps/Button';
//import { ExampleExchangeState } from './state';

import type { ExchangeState } from './state';
import type { ExchangeItem } from '../../type/Item';

import { H1Compo } from '../../components/H.compo';
import Input from '../../Comps/Input';
import { Link } from 'react-router-dom';
import { ExchangeItemView, ExchangeItemRow } from '../../Comps/Item';
import Loader from '../../Comps/Loader';
import Footer from '../../Comps/Footer';

import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';

import { ReactComponent as AppsIcon } from '../../assets/icons/appsIcon.svg';
import { ReactComponent as MapsIcon } from '../../assets/icons/mapsIcon.svg';
import { ReactComponent as PersonIcon } from '../../assets/icons/personIcon.svg';
import { ReactComponent as CarIcon } from '../../assets/icons/carIcon.svg';
import { ReactComponent as FlameIcon } from '../../assets/icons/flameIcon.svg';
import { ReactComponent as BuilderIcon } from '../../assets/icons/builderIcon.svg';
import { ReactComponent as CodeIcon } from '../../assets/icons/codeIcon.svg';
import { ReactComponent as MoreIcon } from '../../assets/icons/moreHorizontalIcon.svg';

import { ReactComponent as LIXIcon } from '../../assets/icons/lixIcon.svg';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/thumbsUpIcon.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/assetIcon.svg';
import { ReactComponent as BackpackIcon } from '../../assets/icons/backpackIcon.svg';
import { ReactComponent as BriefcaseIcon } from '../../assets/icons/briefcaseIcon.svg';

const initialExchangeState: ExchangeState = {
  loading: true,
  error: '',
  trendingItems: [],
  /*freeItems: [],
  discountedItems: [],*/
};

export default function ExchangePage() {
  usePageConfig({ title: 'HELIX Exchange' });

  const navigate = useNavigate();
  const { isLoading, isLoggedIn } = useUser();

  /*const { data, isLoading, error } = useInfiniteQuery(
    ['exchange'],
    ({ pageParam = 0 }) => getAllAsset(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length;
      },
    }
  );*/

  const [headerSticky, setHeaderSticky] = useState(false);
  useEffect(() => {
    setHeaderSticky(false);
    const i = setTimeout(() => {
      setHeaderSticky(true);
    }, 430);

    return () => {
      clearTimeout(i);
      setHeaderSticky(false);
    };
  }, []);

  const [exchangeState, setExchangeState] =
    useState<ExchangeState>(initialExchangeState);
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    setExchangeState(initialExchangeState);

    const abortController = new AbortController();
    getAllAssets(abortController.signal).then((assets) =>
      setExchangeState({
        loading: false,
        error: '',
        trendingItems: assets,
      })
    );

    return () => abortController.abort();
  }, [isLoading, isLoggedIn]);

  const [activeItemId, setActiveItemId] = useActiveItemId();

  return (
    <div className="exchange">
      <header
        className={`${styles.header} ${headerSticky && styles.headerSticky}`}
      >
        <H1Compo>Exchange</H1Compo>
        <span className={styles.headerTools}>
          <Link to="/exchange/owned" className={`${buttonClassName('')}`}>
            <BriefcaseIcon />
            Owned Assets
          </Link>
          <Input
            icon={<SearchIcon />}
            style={{ color: '#A0A0A0' }}
            placeholder="Search assets"
            onClick={() => navigate(`/exchange/search?autoFocus=true`)}
          />
        </span>
      </header>
      <nav className={styles.categoryNav}>
        <Link to="/exchange/search" className={styles.categoryNavLink}>
          <AppsIcon />
          <span>all assets</span>
        </Link>
        <Link to="/exchange/search/maps" className={styles.categoryNavLink}>
          <MapsIcon />
          <span>maps</span>
        </Link>
        <Link
          to="/exchange/search/characters"
          className={styles.categoryNavLink}
        >
          <PersonIcon />
          <span>characters</span>
        </Link>
        <Link to="/exchange/search/vehicles" className={styles.categoryNavLink}>
          <CarIcon />
          <span>vehicles</span>
        </Link>
        <Link to="/exchange/search/weapons" className={styles.categoryNavLink}>
          <FlameIcon />
          <span>weapons</span>
        </Link>
        <Link to="/exchange/search/props" className={styles.categoryNavLink}>
          <BuilderIcon />
          <span>props</span>
        </Link>
        <Link to="/exchange/search/scripts" className={styles.categoryNavLink}>
          <CodeIcon />
          <span>scripts</span>
        </Link>
        <Link to="/exchange/search/other" className={styles.categoryNavLink}>
          <MoreIcon />
          <span>other</span>
        </Link>
      </nav>
      {!exchangeState.loading && (
        <>
          <section className={styles.group}>
            <header className={styles.groupHeader}>
              <h2 className={styles.groupTitle}>Trending</h2>
              <Link to="/exchange/search" className={styles.groupLink}>
                view all
              </Link>
            </header>
            <ExchangeItemGrid
              items={exchangeState.trendingItems || []}
              activeItemId={activeItemId}
              setActiveItemId={setActiveItemId}
            />
          </section>
          {/*<section className={styles.group}>*/}
          {/*  <header className={styles.groupHeader}>*/}
          {/*    <h2 className={styles.groupTitle}>Free</h2>*/}
          {/*    <Link to="/developer/exchange/free" className={styles.groupLink}>*/}
          {/*      view all*/}
          {/*    </Link>*/}
          {/*  </header>*/}
          {/*  <ExchangeItemRow*/}
          {/*    items={data?.pages.flat() ?? []}*/}
          {/*    navigate={navigate}*/}
          {/*    activeItemId={activeItemId}*/}
          {/*    setActiveItemId={setActiveItemId}*/}
          {/*  />*/}
          {/*</section>*/}
          {/*<section className={styles.group} style={{ marginBottom: 236 }}>*/}
          {/*  <header className={styles.groupHeader}>*/}
          {/*    <h2 className={styles.groupTitle}>Discounts</h2>*/}
          {/*    <Link*/}
          {/*      to="/developer/exchange/discounted"*/}
          {/*      className={styles.groupLink}*/}
          {/*    >*/}
          {/*      view all*/}
          {/*    </Link>*/}
          {/*  </header>*/}
          {/*  <ExchangeItemRow*/}
          {/*    items={exchangeState.discountedItems.slice(0, 7)}*/}
          {/*    navigate={navigate}*/}
          {/*    activeItemId={activeItemId}*/}
          {/*    setActiveItemId={setActiveItemId}*/}
          {/*  />*/}
          {/*  <ExchangeItemRow*/}
          {/*    style={{ marginTop: 20 }}*/}
          {/*    items={exchangeState.discountedItems.slice(7, 14)}*/}
          {/*    navigate={navigate}*/}
          {/*    activeItemId={activeItemId}*/}
          {/*    setActiveItemId={setActiveItemId}*/}
          {/*  />*/}
          {/*</section>*/}
        </>
      )}
      {exchangeState.loading && (
        <div
          style={{
            height: 'calc(100vh - var(--nav-offset) - 270px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Loader scale={2} />
        </div>
      )}
      <Footer />
    </div>
  );
}
