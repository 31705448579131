import { useEffect, useRef } from 'react';
import useActiveController from '../../hook/useActiveController';

import { DEFAULT_ACTIONS } from '../../hook/useModal';
import styles from './styles.module.css';
import { createPortal } from 'react-dom';

import type { ModalController, ModalConfig } from '../../hook/useModal';

import Button from '../Button';

import { ReactComponent as CrossIcon } from '../../assets/icons/crossIcon.svg';

export default function Modal({
  controller,
  active = false,
  closeable = true,
  escapable = true,
  title = '',
  description = '',
  actions = DEFAULT_ACTIONS,
  onClose = (value: string) => null,
  className = '',
  zIndex,
  style = {},
  children = null,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> &
  Partial<ModalConfig> & {
    controller?: ModalController;
    active?: boolean;
    onClose?: (value: string) => void;
  }): JSX.Element | null {
  const hasController = typeof controller === 'object' && controller !== null;
  const modalActive = hasController ? controller.active : active;

  const modalActiveController = useActiveController(modalActive, 300);

  const config: ModalConfig = hasController
    ? controller.config
    : { title, description, actions, closeable, escapable, zIndex };

  const escapeRef = useRef<null | {
    escapable: boolean;
    onClose: (value: string) => void;
  }>(null);
  if (escapeRef.current === null)
    escapeRef.current = {
      escapable: config.escapable,
      onClose: hasController ? controller.promiseResolve : onClose,
    };

  useEffect(() => {
    if (escapeRef.current === null) return;
    escapeRef.current.escapable = config.escapable;
  }, [config.escapable]);

  useEffect(() => {
    if (escapeRef.current === null) return;
    escapeRef.current.onClose = hasController
      ? controller.promiseResolve
      : onClose;
  }, [hasController, hasController ? controller.promiseResolve : onClose]);

  useEffect(() => {
    if (!modalActive || escapeRef.current === null) return;

    const onKeydown = (event: KeyboardEvent) => {
      if (escapeRef.current === null || !escapeRef.current.escapable) return;

      if (event.key === 'Escape') return escapeRef.current.onClose('');
    };

    window.addEventListener('keydown', onKeydown);
    return () => window.removeEventListener('keydown', onKeydown);
  }, [modalActive]);

  return createPortal(
    !modalActiveController.view ? null : (
      <div
        className={`${styles.root} ${
          modalActiveController.animate && styles.rootActive
        } ${className}`}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          if (!config.escapable) return;

          if ((event.target as any).classList.contains(styles.root))
            return hasController ? controller.promiseResolve('') : onClose('');
        }}
        style={{
          ...(typeof config.zIndex === 'number' && {
            zIndex: config.zIndex,
          }),
          ...style,
        }}
        {...props}
      >
        <figure className={styles.modal}>
          {config.closeable && (
            <button
              className={styles.closeButton}
              onClick={() =>
                hasController ? controller.promiseResolve('') : onClose('')
              }
            >
              <CrossIcon />
            </button>
          )}
          {config.title !== '' && (
            <h1 className={styles.modalTitle}>{config.title}</h1>
          )}
          {config.description !== '' && (
            <p className={styles.modalDescription}>{config.description}</p>
          )}
          {children ?? (hasController ? controller.config.children : null)}
          {config.actions.length > 0 && (
            <footer
              className={styles.modalActions}
              style={
                config.title === '' && config.description === ''
                  ? {}
                  : {
                      marginTop: 16,
                    }
              }
            >
              {config.actions.map((action, index) => (
                <Button
                  variant={action.variant || ''}
                  key={index}
                  onClick={() =>
                    hasController
                      ? controller.promiseResolve(action.value)
                      : onClose(action.value)
                  }
                  disabled={action.disabled || false}
                  className={`${styles.modalAction} ${action.className || ''}`}
                >
                  {action.label}
                </Button>
              ))}
            </footer>
          )}
        </figure>
      </div>
    ),
    document.body
  );
}

export const modalActions = styles.modalActions;
export const modalAction = styles.modalAction;
