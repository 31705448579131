/* eslint-disable @typescript-eslint/no-throw-literal */
import axios from 'axios';
import { processError } from '../utils';

const CHUNK_SIZE = 1024 * 1024 * 100; // 100MB

export default async function uploadAsset(
  assetId: string,
  assetName: string,
  file: File,
  {
    upload_id,
    asset_filename,
  }: {
    upload_id: string;
    asset_filename: string;
  },
  setProgress: (progress: number) => void,
  signal: AbortSignal
): Promise<string | undefined> {
  try {
    const fileSize = file.size;
    const chunkCount = Math.ceil(fileSize / CHUNK_SIZE);
    if (chunkCount > 10000) throw 'File is too large to upload from web.';

    let chunkStart = 0,
      chunkEnd = Math.min(CHUNK_SIZE, fileSize),
      totalUploadedSoFar = 0;
    const etags: string[] = [];

    for (let i = 0; i < chunkCount; i++) {
      const chunk = file.slice(chunkStart, chunkEnd);
      chunkStart = chunkEnd;
      chunkEnd = Math.min(chunkStart + CHUNK_SIZE, fileSize);

      const result = await axios.post(
        '/asset/multipart/presign',
        {
          asset_id: assetId,
          upload_id,
          asset_filename,
          part: i + 1,
        },
        {
          signal,
        }
      );

      if (result.status !== 200) throw result.data.message;

      //const asset_url = result.data.payload.asset_url; // i dont know what this is for

      const totalUploadedCache = totalUploadedSoFar;

      const uploadResult = await axios.put(
        result.data.payload.asset_upload_url,
        chunk,
        {
          headers: {
            /*'content-type': file.type,*/
          },
          timeout: 0,
          transformRequest: (data, headers) => {
            delete headers.Authorization;
            delete headers['Content-Type'];
            return data;
          },
          onUploadProgress: (progressEvent) => {
            if (!progressEvent || !progressEvent.total) return;
            const fileProgress =
              (progressEvent.loaded * 100) / progressEvent.total;

            setProgress(
              Math.floor(
                ((totalUploadedCache + progressEvent.loaded) / fileSize) * 100
              )
            );
          },
          signal,
        }
      );

      if (typeof uploadResult === 'undefined')
        throw `Chunk ${i + 1} failed to upload.`;

      etags.push(uploadResult.headers.etag);
      totalUploadedSoFar += chunk.size;
    }

    const finishResult = await axios.post('/asset/multipart/finish', {
      asset_id: assetId,
      upload_id: upload_id,
      asset_filename: asset_filename,
      part: etags.map((tag, i) => ({ part: i + 1, etag: tag })),
    });

    if (finishResult.status !== 200) throw finishResult;

    setProgress(100);

    return `Uploaded ${assetName}`;
  } catch (e: any) {
    if (e.toString() === 'CanceledError: canceled') return 'Upload canceled.';
    Promise.reject(processError(e) ?? "Couldn't upload asset.");
  }
}
