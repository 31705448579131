import styles from './styles.module.css';
import { createPortal } from 'react-dom';

import { ReactComponent as CrossIcon } from '../../assets/icons/crossIcon.svg';

export default function Overlay({
  className = '',
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <>
      {createPortal(
        <div className={`${styles.root} ${className}`} {...props} />,
        document.body
      )}
      {createPortal(
        <style>{`#root > .react-animated-router {
          opacity: 0;
        }`}</style>,
        document.head
      )}
    </>
  );
}

export function OverlayHeader({
  children = null,
  className = '',
  closeable = false,
  closeDisabled = false,
  onClose = () => {},
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  closeable?: boolean;
  closeDisabled?: boolean;
  onClose?: () => void;
}) {
  return (
    <header className={`${styles.header} ${className}`} {...props}>
      <figure className={styles.logo} />
      <figure className={styles.actions}>
        {children}
        {closeable && (
          <figure className={styles.closeWrapper}>
            <button
              className={styles.closeButton}
              disabled={closeDisabled}
              onClick={() => {
                if (closeDisabled) return;
                onClose();
              }}
              children={<CrossIcon />}
            />
          </figure>
        )}
      </figure>
    </header>
  );
}
