import { useState, useEffect, createContext, useContext, useMemo } from 'react';
import useActiveController from './useActiveController';

import { createPortal } from 'react-dom';
import { randomName } from '../utils';
import { toast } from 'react-hot-toast';

import { ReactComponent as CheckCircleIcon } from '../assets/icons/checkCircleIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/icons/crossIcon.svg';

type Task = {
  id: string;
  name: string;
  progress: number;
  status: 'running' | 'success' | 'error';
};
type TaskContextType = {
  tasks: Task[];
  addTask: (task: Task) => void;
  updateTask: (id: string, task: Partial<Task>) => void;
  deleteTask: (id: string) => void;
  classPrefix: string;
};
const TaskContext = createContext<TaskContextType>({
  tasks: [],
  addTask: () => {},
  updateTask: () => {},
  deleteTask: () => {},
  classPrefix: '',
});

export default function useTaskQueue(): {
  addTask: // a function that accepts a task name and a promise with 1 argument, which is a function that accepts a progress number
  (
    name: string,
    promise: (
      progress: (progress: number) => void
    ) => Promise<string | undefined>,
    onComplete: () => void,
    onCancel: () => void
  ) => Promise<void>;
} {
  const context = useContext<TaskContextType>(TaskContext);

  return {
    addTask: async function (
      name: string,
      promise: (
        progress: (progress: number) => void
      ) => Promise<string | undefined>,
      onComplete: () => void,
      onCancel: () => void
    ): Promise<void> {
      const id = randomName(36);
      const toastID = toast(
        (t) => (
          <>
            <button
              className={`${context.classPrefix}-cancel cancel-${id}`}
              onClick={onCancel}
            >
              <CrossIcon />
            </button>
            <div
              className={`task-toast ${context.classPrefix}-task ${context.classPrefix}_${id}`}
            >
              <div className={`${context.classPrefix}-title`}></div>
              <div className={`${context.classPrefix}-progress`}></div>
              <figure
                className={`${context.classPrefix}-progress-bar`}
              ></figure>
              <figure className={`${context.classPrefix}-icon`}>
                <CheckCircleIcon />
              </figure>
            </div>
          </>
        ),
        {
          duration: Infinity,
          position: 'bottom-right',
          style: {
            maxWidth: 'none',
            backgroundColor: '#282828',
            padding: 0,
            borderRadius: 6,
          },
        }
      );
      const task: Task = {
        id,
        name,
        progress: 0,
        status: 'running',
      };

      context.addTask(task);
      console.log('addTask', task);

      try {
        const result = await promise((progress) => {
          console.log('updateTask', id, progress);
          context.updateTask(id, { progress });
        });

        context.updateTask(id, { status: 'success', name: result });
        console.log('taskDone', id, 'success');
        onComplete();
        setTimeout(() => {
          toast.dismiss(toastID);
          setTimeout(() => context.deleteTask(id), 1000);
        }, 2000);
      } catch (e: unknown) {
        context.updateTask(id, { status: 'error', name: e as string });
        console.log('taskDone', id, 'error');
        onComplete();
        setTimeout(() => {
          toast.dismiss(toastID);
          setTimeout(() => context.deleteTask(id), 1000);
        }, 2000);
      }
    },
  };
}

export function TaskProvider({ children }: { children: React.ReactNode }) {
  const [tasks, setTasks] = useState<Task[]>([]);

  const classPrefix = useMemo(() => randomName(16), []);

  return (
    <TaskContext.Provider
      value={{
        tasks,
        addTask: (task: Task) => setTasks((prev) => [...prev, task]),
        updateTask: (id: string, task: Partial<Task>) =>
          setTasks((prev) =>
            prev.map((t) => (t.id === id ? { ...t, ...task } : t))
          ),
        deleteTask: (id: string) =>
          setTasks((prev) => prev.filter((t) => t.id !== id)),
        classPrefix,
      }}
    >
      {createPortal(
        <style>
          {`.${classPrefix}-task {
            width: 400px;
            height: 90px;
            border-radius: 6px;
            /*background-color: #282828;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);*/
            padding: 18px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
            justify-content: space-between;
            pointer-events: auto;
            user-select: auto;
           /* transform-origin: bottom right;
            animation: ${classPrefix}-task-enter 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
            will-change: transform;*/
            overflow: hidden;
        }
        .${classPrefix}-progress-bar {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: var(--progress, 100%);
            min-width: 5px;
            background-color: #4646467a;
            z-index: -1;
            transition: width 0.3s ease-in-out;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            pointer-events: none;
        }
        .${classPrefix}-progress-bar::after {
            left: 0;
            transform: translateY(18px);
            height: 1px;
            width: 100%;
            background-color: #68DDFD;
            content: "";
            display: block;
            position: absolute;
            transition: width 0.3s ease-in-out, opacity 0.4s ease-in, transform 0.4s ease-in;
            box-shadow: 0 0 7px rgba(104, 221, 253, 0.8);
        }
        @keyframes ${classPrefix}-bar-idle {
            from, 15%, 85%, to {
                opacity: 1;
            }
            50% {
                opacity: 0.75;
            }
        }
        .${classPrefix}-progress {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #a0a0a0;
            transition: all 0.4s ease-in;
            pointer-events: none;
        }
        .${classPrefix}-progress::after {
            content: "100%";
        }
        .${classPrefix}-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            transform: translateY(-2px);
            transition: transform 0.1s ease-in-out;
            color: #fff;
        }
        @keyframes ${classPrefix}-task-enter {
            from {
                transform: translate3d(0, 200%, 0) scale(.6);
                opacity: 0;
            }
        }
        .${classPrefix}-icon {
            position: absolute;
            pointer-events: none;
            right: 18px;
            height: 20px;
            width: 20px;
            color: #A6D34D;
            opacity: 0;
            transform-origin: center;
            transform: scale(0.5);
        }
        @keyframes ${classPrefix}-icon-enter {
            from {
                opacity: 0;
                transform: scale(0.5);
            }
        }
        @keyframes ${classPrefix}-icon-hold {
            from, to {
                opacity: 0;
                transform: scale(0.5);
            }
        }
        .${classPrefix}-icon > svg {
            height: 100%;
            width: 100%;
        }
        .${classPrefix}-cancel {
            position: absolute;
            top: -14px;
            right: -14px;
            height: 32px;
            width: 32px;
            z-index: 10;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            background-color: var(--gray-dark-3, #232323);
            box-shadow: inset 0 0 0 1px var(--gray-dark-5, #2e2e2e);
            color: #ededed;
            transition: all 0.2s ease-in-out;
            transform-origin: center center;
            transform: scale(0.5);
            cursor: pointer;
            opacity: 0;
        }
        .${classPrefix}-cancel > svg {
            height: 18px;
            width: 18px;
        }
        ${tasks
          .map(
            (task) => `.${classPrefix}_${task.id} .${classPrefix}-title::after {
                content: '${task.name}';
            }`
          )
          .join('\n')}
        ${tasks
          .filter((task) => task.status === 'running')
          .map(
            (task) => `.${classPrefix}_${task.id} {
                --progress: ${task.progress}%;
            }
            .${classPrefix}_${task.id} .${classPrefix}-progress::after {
                content: '${task.progress}%';
            }
            .cancel-${task.id} {
                transform: scale(1);
                opacity: 1;
            }
            .cancel-${task.id}:hover {
                transform: scale(1.1);
            }
            .cancel-${task.id}:active {
                transform: scale(0.9);
                transition: none;
            }
            .${classPrefix}_${task.id} .${classPrefix}-progress-bar::after {
                animation: ${classPrefix}-bar-idle 5s linear infinite;
            }
            `
          )
          .join('\n')}
          ${tasks
            .filter((task) => task.status === 'success')
            .map(
              (task) => `.${classPrefix}_${task.id} .${classPrefix}-title {
                transform: translateY(0);
              }
               .${classPrefix}-progress-bar::after {
                opacity: 0;
                transform: translateY(22px);
              }
              .${classPrefix}_${task.id} .${classPrefix}-progress {
                transform: translateY(-5px);
                opacity: 0;
              }
                .${classPrefix}_${task.id} .${classPrefix}-icon {
                    opacity: 1;
                    transform: scale(1);
                    animation: ${classPrefix}-icon-hold 0.5s linear, ${classPrefix}-icon-enter 0.2s ease-in-out 0.5s;
                }
              `
            )
            .join('\n')}
        `}
        </style>,
        document.head
      )}
      {children}
    </TaskContext.Provider>
  );
}
