import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePageConfig from '../../hook/usePageConfig';

import styles from './verify.module.css';
import commonStyles from '../common.module.css';
import { verifyUserEmail } from '../../api/user';

import Loader from '../../Comps/Loader';

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
    };

export default function VerifyPage() {
  usePageConfig({ title: 'HELIX Email Verification' });
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');
  const [state, setState] = useState<State>(
    typeof key === 'string' && key !== ''
      ? {
          loading: true,
        }
      : {
          loading: false,
          error: true,
          errorMessage: 'Invalid request',
        }
  );

  useEffect(() => {
    if (!state.loading) return;

    const abortController = new AbortController();
    verifyUserEmail(key!, abortController.signal).then((result) => {
      if (!result.success)
        return setState({
          loading: false,
          error: true,
          errorMessage: result.error,
        });

      setState({
        loading: false,
        error: false,
      });
    });

    return () => abortController.abort();
  }, []);

  return (
    <div className={styles.root}>
      {state.loading && (
        <>
          <figure className={styles.loader}>
            <Loader scale={1.25} />
          </figure>
          <h1 className={styles.title}>Baking cookies...</h1>
        </>
      )}
      {!state.loading && state.error && (
        <>
          <h1 className={styles.title}>Error</h1>
          <h3 className={styles.subtitle}>{state.errorMessage}</h3>
        </>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={styles.title}>Success!</h1>
          <h3 className={styles.subtitle}>Your email has been verified!</h3>
        </>
      )}
    </div>
  );
}
