import { useState, useEffect } from 'react';

const SHOW_DEBUG_LOGS = false;

export default function useActiveController(
  active = false,
  closingAnimationTime = 200,
  openingAnimation = true
): {
  view: boolean;
  animate: boolean;
} {
  const [state, setState] = useState<{
    nonce: number;
    view: boolean;
    animate: boolean;
  }>({
    nonce: 0,
    view: !openingAnimation && active,
    animate: !openingAnimation && active,
  });

  useEffect(() => {
    if (active && state.view && state.animate) return;
    if (!active && !state.view) return;

    let i: any;
    if (!active && state.animate) {
      if (SHOW_DEBUG_LOGS)
        console.log('Closing, must animate before removing view');
      setState((e) => ({
        ...e,
        animate: false,
        view: true,
      }));
      i = setTimeout(() => {
        if (SHOW_DEBUG_LOGS)
          console.log('Closing animation finished, removing view');
        setState((e) => ({ ...e, animate: false, view: false }));
      }, closingAnimationTime);
    }

    if (active && !state.view) {
      if (SHOW_DEBUG_LOGS)
        console.log(
          'No view, must create before animating, incrementing nonce'
        );
      return setState((e) => ({
        nonce: e.nonce + 1,
        view: true,
        animate: false,
      }));
    }

    if (active && state.view && !state.animate) {
      if (SHOW_DEBUG_LOGS)
        console.log(
          'View exists, ready to animate, waiting 20ms for the DOM to update'
        );
      i = setTimeout(() => {
        if (SHOW_DEBUG_LOGS) console.log('DOM has been updated, animating');
        setState((e) => ({
          ...e,
          animate: true,
        }));
      }, 20);
    }

    return () => {
      if (SHOW_DEBUG_LOGS)
        console.log("Aborting animation, it's no longer needed");
      clearTimeout(i);
    };
  }, [active, state.nonce]);

  return {
    view: state.view,
    animate: state.animate,
  };
}
