import { useOutletContext } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import useActiveController from '../../../../hook/useActiveController';
import useDeepState from '../../../../hook/useDeepState';
import useTaskQueue from '../../../../hook/useTaskQueue';

import type { OutletContext } from '.';
import type { World, WorldVersion } from '../../../../type/World';

import commonStyles from '../../../common.module.css';
import { toast } from 'react-hot-toast';
import { selectFile, processError, formatSize } from '../../../../utils';
import styles from './config.module.css';
import { createPortal } from 'react-dom';
import uploadWorld from '../../../../api/uploadWorld';
import { startWorldUpload } from '../../../../api/world';

import Input, { TextArea } from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import { Toggle } from '../../../../Comps/Checkbox';
import Select from '../../../../Comps/Select';
import TagInput from '../../../../Comps/TagInput';
import Modal from '../../../../Comps/Modal';
import Loader from '../../../../Comps/Loader';

import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../../../assets/icons/hamburgerIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtubeIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as PackageIcon } from '../../../../assets/icons/packageIcon.svg';

export default function CreationsWorldsWorldManagerConfigPage() {
  const {
    world,
    getField,
    setUpdateField,
    savingData,
    fetchWorld,
    setSavingData,
  } = useOutletContext<OutletContext>();
  const { getDeepState, setDeepState, deleteDeepState } = useDeepState();

  const [filePickerOpen, setFilePickerOpen] = useState(false);
  const filePickerRef = useRef<boolean>(false);
  useEffect(() => {
    filePickerRef.current = filePickerOpen;
  }, [filePickerOpen]);
  useEffect(
    () => () => {
      if (!filePickerRef.current) return;
      setFilePickerOpen(false);
    },
    []
  );
  useEffect(() => {
    window.addEventListener('dragover', (e) => {
      e.preventDefault();
      if (!filePickerRef.current) return;
      setBeaconActive(true);
    });
    window.addEventListener('dragleave', (e) => {
      e.preventDefault();
      setBeaconActive(false);
    });
    window.addEventListener('drop', (e) => {
      e.preventDefault();
      setBeaconActive(false);
      if (!e?.dataTransfer?.files) return;
      if (!filePickerRef.current) return;

      handleUpload(e.dataTransfer.files[0]);
    });
  }, []);
  const [beaconActive, setBeaconActive] = useState(false);
  const beaconController = useActiveController(beaconActive, 0.07 * 1000);

  const { addTask } = useTaskQueue();

  const handleUpload = async (file: File | null | undefined) => {
    if (!file) return;
    /*if (file.type !== 'application/zip')
      return toast.error('Unsupported file type');*/
    setSavingData(true);
    try {
      const uploadRequest = await startWorldUpload(world.id);

      if (!uploadRequest.success) {
        setSavingData(false);
        return toast.error(`Failed to start upload: ${uploadRequest.error}`);
      }
      setFilePickerOpen(false);
      setSavingData(false);
      toast.success('Upload started');
      setDeepState(`config.${world.id}.uploading`, true);

      const abortController = new AbortController();

      addTask(
        `Uploading ${world.name}.zip`,
        (setProgress) =>
          uploadWorld(
            world.id,
            world.name,
            file,
            uploadRequest.data,
            setProgress,
            abortController.signal
          ),
        () => {
          deleteDeepState(`config.${world.id}.uploading`);
          fetchWorld();
        },
        () => abortController.abort()
      );
    } catch (e) {
      setSavingData(false);
      return toast.error(`Failed to start upload: ${processError(e)}`);
    }
  };

  return (
    <div className={`${commonStyles.splitContentWrapper}`}>
      {beaconController.view &&
        createPortal(
          <div
            className={`${styles.beacon} ${
              beaconController.animate && styles.beaconActive
            }`}
          />,
          document.body
        )}
      <Modal
        active={filePickerOpen}
        actions={[]}
        title=""
        description=""
        closeable
        escapable
        className={styles.filePickerModal}
        onClose={() => setFilePickerOpen(false)}
      >
        <figure
          className={`${styles.dropZone} ${
            beaconController.animate && styles.dropZoneActive
          }`}
        >
          <figcaption className={styles.dropZoneTitle}>
            Drag & drop a file here
          </figcaption>
          <figure className={styles.dropZoneDivider} />
          <Button
            style={{ width: 'fit-content' }}
            variant="invisible"
            onClick={() => {
              if (savingData) return;

              selectFile('application/zip').then((file) => handleUpload(file));
            }}
            disabled={savingData}
          >
            <UploadIcon /> select a file
          </Button>
        </figure>
      </Modal>
      <div className={commonStyles.splitContentWrapperLimit}>
        <h1 className={commonStyles.splitContentTitle}>Config</h1>
        <figure
          className={commonStyles.splitContentDivider}
          style={{ marginTop: 24 }}
        />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Dependencies
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Asset ID of dependent assets
            </figcaption>
            <TagInput
              className={commonStyles.formGroupInput}
              theme="blue"
              value={
                getField<string[]>(
                  'assets',
                  !world.version
                    ? []
                    : world.version.assets.map((asset) => asset.name)
                ) ?? []
              }
              onChange={(newValue) => setUpdateField('assets', newValue)}
              sanitizeTag={(tag) => tag.toLowerCase().replace(/ /g, '-')}
              disabled={savingData}
              placeholder="Enter an asset"
              searchSuggestions='assets'
            />
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Depot
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              World package
            </figcaption>

            {!!world.version && (
              <WorldVersionView version={world.version} world={world} />
            )}

            <Button
              style={{ width: 'fit-content' }}
              disabled={
                savingData ||
                getDeepState<boolean>(`config.${world.id}.uploading`)
              }
              onClick={async () => {
                if (
                  savingData ||
                  getDeepState<boolean>(`config.${world.id}.uploading`)
                )
                  return;
                setFilePickerOpen(true);
              }}
            >
              <UploadIcon />
              {!world.version ? 'upload' : 're-upload'}
            </Button>
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Startup
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Game mode
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={getField('gameMode', '')}
              onChange={(e) =>
                setUpdateField(
                  'gameMode',
                  e.target.value
                    .toLowerCase()
                    .replace(/ +/g, '-')
                    .replace(/\-+/g, '-')
                    .replace(/[^a-z\-]/g, '')
                )
              }
              disabled={savingData}
              placeholder="free-roam"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Game map
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={getField('gameMap', '')}
              onChange={(e) => setUpdateField('gameMap', e.target.value)}
              disabled={savingData}
              placeholder="default"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Metadata (Custom data)
            </figcaption>
            <TextArea
              className={commonStyles.formGroupInput}
              value={getField<any>(
                'metadata',
                // @ts-ignore
                JSON.stringify(world.metadata ?? {}) ?? '{}',
                true
              )}
              style={{
                fontFamily: 'Space mono',
              }}
              onChange={(e) => setUpdateField('metadata', e.target.value)}
              disabled={savingData}
              placeholder="{ 'key': 'value' }"
            />
          </figure>
        </section>
      </div>
    </div>
  );
}

const STATUS_COLOR: Record<string, [string, string]> = {
  UNKNOWN: ['#EDEDED', '#EDEDED'], // gray
  VALID: ['#99D52A', '#1C1500'], // lime
  INVALID: ['#E54D2E', '#FEEFEC'], // tomato
  PENDING: ['#68DDFD', '#0C1820'], // sky
  VALIDATING: ['#F5D90A', '#1C1500'], // yellow
};

function WorldVersionView({
  version,
  world,
}: {
  version: WorldVersion;
  world: World;
}) {
  return (
    <figure
      className={styles.versionWrapper}
      style={
        {
          '--primary':
            /*(STATUS_COLOR[version.status] ??
            STATUS_COLOR.UNKNOWN)[0]*/ STATUS_COLOR.VALID[0],
          '--secondary':
            /*(STATUS_COLOR[version.status] ??
            STATUS_COLOR.UNKNOWN)[1]*/ STATUS_COLOR.VALID[1],
        } as React.CSSProperties
      }
    >
      <figure className={`${commonStyles.rowFull} ${styles.versionRoot}`}>
        <figure className={styles.versionInfo}>
          <picture className={styles.versionInfoIcon}>
            <PackageIcon />
          </picture>
          <figure className={styles.versionInfoText}>
            <h4 className={styles.versionInfoTitle}>{world.name}.zip</h4>
            <figcaption className={styles.versionInfoSubtitle}>
              {formatSize(version.sizeInBytes)}
            </figcaption>
          </figure>
        </figure>
        <figure className={styles.versionText}>
          {/*<span className={styles.versionStatus}>VALID</span>*/}
          <CheckCircleIcon className={styles.versionIcon} />
        </figure>
      </figure>
      <figure className={styles.versionStripe} />
    </figure>
  );
}
