import { useUser } from '../../hook/useUser';
import { useState, useEffect, useCallback } from 'react';
import useModal from '../../hook/useModal';
import usePageConfig from '../../hook/usePageConfig';

import type { AccessToken, AccessTokenExtended } from '../../api/accessToken';
import type { ResultExtendable } from '../../types/common';

import styles from './accessToken.module.css';
import commonStyles from '../common.module.css';
import toast from 'react-hot-toast';
import {
  getAccessTokens,
  createAccessToken,
  deleteAccessToken,
} from '../../api/accessToken';
import { copyText } from '../../utils';

import Loader from '../../Comps/Loader';
import Table, { TableDisplay } from '../../Comps/Table';
import Button from '../../Comps/Button';
import Modal from '../../Comps/Modal';
import Input from '../../Comps/Input';

import { ReactComponent as TrashIcon } from '../../assets/icons/trashIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plusIcon.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicateIcon.svg';
import { ReactComponent as EyeOffIcon } from '../../assets/icons/eyeOffIcon.svg';

type State = { accessTokens: AccessToken[] } & (
  | {
      loading: true;
      error: false;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
    }
);

export default function AccessTokenManagementPage() {
  usePageConfig({ title: 'Auth Tokens | HELIX Hub' });
  const { isLoading, isLoggedIn } = useUser();

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
    accessTokens: [],
  });
  const fetchAccessTokens = useCallback(
    (signal?: AbortSignal) =>
      getAccessTokens(signal).then(({ success, error, data }) => {
        setState({
          loading: false,
          error: !success,
          accessTokens: success ? data : [],
          errorMessage: error,
        });
      }),
    []
  );

  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    const abortController = new AbortController();

    fetchAccessTokens(abortController.signal);

    return () => abortController.abort();
  }, [isLoading, fetchAccessTokens, isLoggedIn]);

  const [savingData, setSavingData] = useState(false);

  const [modalController, modal] = useModal();
  enum ModalType {
    Create = 0,
    Success = 1,
    Delete = 2,
  }
  const [modalType, setModalType] = useState<ModalType>(ModalType.Create);
  const [modalState, setModalState] = useState<{
    password: string;
    alias: string;
  }>({
    password: '',
    alias: '',
  });

  const handleDeleteToken = async () => {};

  return (
    <div className={styles.root}>
      <Modal
        controller={modalController}
        children={
          modalType === ModalType.Success ? undefined : (
            <>
              {modalType === ModalType.Create && (
                <figure className={commonStyles.formGroup}>
                  <figcaption className={commonStyles.formGroupLabel}>
                    Friendly Name (optional)
                  </figcaption>
                  <Input
                    type="text"
                    placeholder="Development Server Key"
                    value={modalState.alias}
                    onChange={(e) =>
                      setModalState((prev) => ({
                        ...prev,
                        alias: e.target.value,
                      }))
                    }
                    disabled={savingData}
                    autoComplete="off"
                    className={commonStyles.formGroupInput}
                  />
                </figure>
              )}
              <figure className={commonStyles.formGroup}>
                <figcaption className={commonStyles.formGroupLabel}>
                  Your Password
                </figcaption>
                <Input
                  type="password"
                  placeholder="boogie123"
                  value={modalState.password}
                  onChange={(e) =>
                    setModalState((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  disabled={savingData}
                  autoComplete="current-password"
                  className={commonStyles.formGroupInput}
                />
              </figure>
            </>
          )
        }
      />
      {state.loading && <Loader className={styles.rootLoader} scale={1.5} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <header className={styles.header}>
            <h1 className={styles.title}>Access Tokens</h1>
            <Button
              disabled={savingData}
              variant="sky"
              onClick={async () => {
                if (savingData) return;
                setModalType(ModalType.Create);
                const modalResult = await modal.open({
                  title: 'Create Access Token',
                  description:
                    "Make sure you know what you're doing. Access Tokens grant indefinite access to your account and can be used to make changes to your account. ",
                });
                if (modalResult !== 'confirm') return;
                setSavingData(true);

                const toastId = toast.loading('Creating...');
                const result = await Promise.resolve(
                  new Promise<
                    ResultExtendable<
                      { data: AccessTokenExtended },
                      { data: '' }
                    >
                  >(async (resolve) =>
                    setModalState((prev) => {
                      createAccessToken(prev.password, prev.alias).then(
                        resolve
                      );
                      return prev;
                    })
                  )
                );
                setSavingData(false);

                if (!result.success)
                  return toast.error(result.error, { id: toastId });
                setModalType(ModalType.Success);
                toast.dismiss(toastId);
                fetchAccessTokens();
                modal
                  .open({
                    title: 'Access Token',
                    description:
                      'This token will not be shown again. Please copy it and / or write it down on your hand now.',
                    children: (
                      <>
                        <figure className={styles.accessToken}>
                          <figcaption>{result.data.token}</figcaption>
                          <Button
                            variant="invisible"
                            onClick={() =>
                              copyText(result.data.token).then((success) =>
                                success
                                  ? toast.success('Copied!')
                                  : toast.error('Failed to copy')
                              )
                            }
                          >
                            <DuplicateIcon />
                          </Button>
                        </figure>
                      </>
                    ),
                    actions: [
                      {
                        value: 'close',
                        variant: 'dark',
                        label: 'close',
                      },
                      {
                        value: 'delete',
                        variant: 'tomato',
                        label: 'delete now',
                      },
                    ],
                  })
                  .then((r) => {
                    if (r !== 'delete')
                      return setModalState({
                        password: '',
                        alias: '',
                      });
                    setModalState((prev) => {
                      const toastId2 = toast.loading('Deleting...');
                      deleteAccessToken(result.data.id, prev.password).then(
                        ({ success, error }) => {
                          if (!success)
                            return toast.error(error, { id: toastId2 });
                          toast.success('Deleted!', { id: toastId2 });
                          fetchAccessTokens();
                        }
                      );
                      return prev;
                    });
                  });
              }}
            >
              <PlusIcon /> generate new
            </Button>
          </header>
          <section className={styles.wrapper}>
            <h4 className={styles.subtitle}>
              Access Tokens (also known as API Keys or{' '}
              <a
                target="_blank"
                href="https://helixgame.com/docs/core-concepts/server-manual/server-configuration#command-line-parameters"
              >
                Server authorization tokens
              </a>
              ) are required to utilize certain APIs like{' '}
              <a
                target="_blank"
                href="https://helixgame.com/docs/scripting-reference/static-classes/payment"
              >
                Payments
              </a>{' '}
              and{' '}
              <a
                target="_blank"
                href="https://helixgame.com/docs/core-concepts/scripting/persistent-data"
              >
                Persistent Storage
              </a>
              .
            </h4>
            {state.accessTokens.length > 0 && (
              <Table
                className={styles.table}
                header={[
                  {
                    value: 'friendly name',
                    width: '1fr',
                  },
                  {
                    value: 'key secret',
                  },
                  {
                    value: 'key id',
                    /* width: '1fr',*/
                  },
                  {
                    value: 'created',
                  },
                  {
                    value: 'actions',
                  },
                ]}
                rows={state.accessTokens.map((accessToken) => [
                  {
                    value: accessToken.alias || '—',
                  },
                  {
                    value: 'e',
                    display: (
                      <TableDisplay key="key" className={styles.key}>
                        <EyeOffIcon /> hidden
                      </TableDisplay>
                    ),
                  },
                  {
                    value: accessToken.id,
                  },
                  {
                    value: new Date(accessToken.created_at).toLocaleString(),
                  },
                  {
                    value: '',
                    display: (
                      <TableDisplay key="actions">
                        <Button
                          disabled={savingData}
                          variant="tomato"
                          onClick={async () => {
                            if (savingData) return;
                            setModalType(ModalType.Delete);
                            const modalResult = await modal.open({
                              title: 'Delete Access Token',
                            });
                            if (modalResult !== 'confirm') return;
                            setSavingData(true);

                            const toastId = toast.loading('Deleting...');
                            const result = await Promise.resolve(
                              new Promise<
                                ResultExtendable<{ data: null }, { data: null }>
                              >(async (resolve) =>
                                setModalState((prev) => {
                                  deleteAccessToken(
                                    accessToken.id,
                                    prev.password
                                  ).then(resolve);
                                  return prev;
                                })
                              )
                            );
                            setSavingData(false);

                            if (!result.success)
                              return toast.error(result.error, {
                                id: toastId,
                              });

                            toast.success('Deleted!', { id: toastId });
                            setState((prev) => ({
                              ...prev,
                              accessTokens: prev.accessTokens.filter(
                                (token) => token.id !== accessToken.id
                              ),
                            }));
                          }}
                        >
                          <TrashIcon />
                        </Button>
                      </TableDisplay>
                    ),
                  },
                ])}
              />
            )}
          </section>
        </>
      )}
    </div>
  );
}
