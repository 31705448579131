import { useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useModal from '../../../../hook/useModal';

import sharedStyles from './shared.module.css';
import styles from './iaps.module.css';
import commonStyles from '../../../common.module.css';
import { toast } from 'react-hot-toast';

import type { OutletContext } from '.';
import {
  createShopIAP,
  createWorldShop,
  getWorldIAPs,
  getWorldShopId,
  type IAP,
} from '../../../../api/IAP';

import Input, { TextArea } from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import Select from '../../../../Comps/Select';
import Modal, { modalAction, modalActions } from '../../../../Comps/Modal';
import Loader from '../../../../Comps/Loader';
import IAPView, { IAPGrid } from '../../../../Comps/IAP';

import { ReactComponent as SearchIcon } from '../../../../assets/icons/searchIcon.svg';
import { ReactComponent as PlusIcon } from '../../../../assets/icons/plusIcon.svg';
import { ReactComponent as LIXIcon } from '../../../../assets/icons/lixIcon.svg';
import { ReactComponent as BookIcon } from '../../../../assets/icons/bookIcon.svg';

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      shopExists: false;
    }
  | {
      loading: false;
      error: false;
      shopExists: true;
      shopId: string;
      data: IAP[];
    };

export default function WorldManagementIAPPage() {
  const { world, savingData, fetchWorld, setSavingData } =
    useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    title: string;
    category: string;
    price: string;
    description: string;
  }>({
    title: '',
    category: '',
    price: '',
    description: '',
  });
  const [modalValidation, setModalValidation] = useState<{
    valid: boolean;
    first: boolean;
    error: {
      title: string;
      price: string;
      description: string;
      category: string;
    };
  }>({
    valid: false,
    first: true,
    error: {
      title: '',
      price: '',
      description: '',
      category: '',
    },
  });
  useEffect(() => {
    if (modalValidation.first)
      return setModalValidation((prev) => ({ ...prev, first: false }));
    const i = setTimeout(() => {
      const update: Partial<{
        title: string;
        category: string;
        price: string;
        description: string;
      }> = {};
      if (modalData.title === '') update.title = 'This field is required';
      else if (modalData.title.length > 100)
        update.title = 'Max length is 32 characters';

      if (modalData.price === '') update.price = 'Price is required';
      else if (isNaN(Number(modalData.price)))
        update.price = 'Must be a number';
      else if (Number(modalData.price) <= 0) update.price = 'Must be positive';
      else if (modalData.price.match(/\./)) update.price = 'Must be an integer';
      else if (Number(modalData.price) > 100000000)
        update.price = 'Max price is 100M LIX';

      if (modalData.description !== '') {
        if (modalData.description.length > 100)
          update.description = 'Max length is 100 characters';
      }

      /*if (modalData.category === '') update.category = 'Category is required';*/
      else if (modalData.category.length > 12)
        update.category = 'Max length is 12 characters';

      setModalValidation({
        valid: Object.values(update).length === 0,
        first: false,
        error: {
          title: '',
          price: '',
          description: '',
          category: '',
          ...update,
        },
      });
    }, 500);

    return () => clearTimeout(i);
  }, [
    modalData.title,
    modalData.category,
    modalData.price,
    modalData.description,
  ]);

  const [state, setState] = useState<State>({
    loading: true,
  });
  useEffect(() => {
    const abortController = new AbortController();

    getWorldIAPs(world.id, abortController.signal).then((result) => {
      if (!result.success)
        return setState({
          loading: false,
          error: true,
          errorMessage: result.error,
        });

      if (!result.exists)
        return setState({
          loading: false,
          error: false,
          shopExists: false,
        });

      getWorldShopId(world.id, abortController.signal).then((res) => {
        if (!res.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: res.error,
          });

        if (res.data === '')
          return setState({
            loading: false,
            error: false,
            shopExists: false,
          });

        const shopId = res.data;

        setState({
          loading: false,
          error: false,
          shopExists: true,
          shopId,
          data: result.data,
        });
      });
    });

    return () => abortController.abort();
  }, [world.id]);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const data: IAP[] =
    state.loading || state.error || !state.shopExists
      ? []
      : searchQuery === ''
      ? state.data
      : state.data.filter((item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase())
        );

  const createIAP = async () => {
    if (savingData || !modalValidation.valid || state.loading || state.error)
      return;
    setSavingData(true);
    const toastId = toast.loading('Creating In-World Purchase...');

    let shopId = state.shopExists ? state.shopId : '';

    if (!state.shopExists) {
      const res = await createWorldShop(world.id);
      if (!res.success) {
        setSavingData(false);
        return toast.error(res.error);
      }

      if (res.exists) {
        const shopRes = await getWorldShopId(world.id);
        if (!shopRes.success) {
          setSavingData(false);
          return toast.error(shopRes.error);
        }

        if (shopRes.data === '') {
          setSavingData(false);
          return toast.error('Unknown error');
        }

        shopId = shopRes.data;
      } else {
        shopId = res.data;
      }
      setState((prev) => ({ ...prev, shopExists: true, shopId, data: [] }));
    }

    const result = await createShopIAP(shopId, {
      title: modalData.title,
      category: modalData.category,
      price: Number(modalData.price),
      description: modalData.description,
    });

    setSavingData(false);

    if (!result.success) return toast.error(result.error, { id: toastId });
    modal.close();
    toast.success('Successfully created In-World Purchase', { id: toastId });
    return setTimeout(() => navigate(`../iaps/${result.data}`), 500);
  };

  return (
    <div className={`${commonStyles.splitContentWrapper} ${styles.root}`}>
      <Modal controller={modalController}>
        <figure className={commonStyles.formGroup}>
          <h3 className={commonStyles.formGroupLabel}>Display Name</h3>
          <Input
            placeholder="Super Speed"
            value={modalData.title}
            onChange={(e) =>
              setModalData((prev) => ({ ...prev, title: e.target.value }))
            }
            maxLength={100}
            disabled={savingData}
          />
          {modalValidation.error.title !== '' && (
            <figcaption className={commonStyles.formGroupError}>
              {modalValidation.error.title}
            </figcaption>
          )}
        </figure>
        <figure className={commonStyles.formGroup}>
          <h3 className={commonStyles.formGroupLabel}>Category</h3>
          <Input
            value={modalData.category}
            disabled={savingData}
            placeholder="powerup"
            onChange={(e) =>
              setModalData((prev) => ({ ...prev, category: e.target.value }))
            }
            maxLength={12}
          />
          {modalValidation.error.category !== '' && (
            <figcaption className={commonStyles.formGroupError}>
              {modalValidation.error.category}
            </figcaption>
          )}
        </figure>
        <figure className={commonStyles.formGroup}>
          <h3 className={commonStyles.formGroupLabel}>Price</h3>
          <Input
            placeholder="5000"
            value={modalData.price}
            onChange={(e) =>
              setModalData((prev) => ({
                ...prev,
                price: e.target.value.replace(/[^0-9]/, ''),
              }))
            }
            disabled={savingData}
            icon={<LIXIcon />}
          />
          {modalValidation.error.price !== '' && (
            <figcaption className={commonStyles.formGroupError}>
              {modalValidation.error.price}
            </figcaption>
          )}
        </figure>
        <figure className={commonStyles.formGroup}>
          <h3 className={commonStyles.formGroupLabel}>Description</h3>
          <TextArea
            value={modalData.description}
            onChange={(e) =>
              setModalData((prev) => ({ ...prev, description: e.target.value }))
            }
            disabled={savingData}
            placeholder="Increases your maximum speed by 50% for 1 hour. Glide past your opponents with ease!"
            style={{ height: 120 }}
            maxLength={100}
          />
          {modalValidation.error.description !== '' && (
            <figcaption className={commonStyles.formGroupError}>
              {modalValidation.error.description}
            </figcaption>
          )}
        </figure>
        <figure className={modalActions} style={{ marginTop: 15 }}>
          <Button
            className={modalAction}
            onClick={modal.close}
            variant="dark"
            disabled={savingData}
          >
            Cancel
          </Button>
          <Button
            className={modalAction}
            onClick={createIAP}
            disabled={savingData || !modalValidation.valid}
          >
            Create
          </Button>
        </figure>
      </Modal>
      {state.loading && <Loader scale={1.25} className={styles.rootLoader} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <header className={commonStyles.rowFull}>
            <h1
              className={`${commonStyles.splitContentTitle} ${commonStyles.row}`}
              style={{ gap: 10 }}
            >
              In-World Purchases
            </h1>
            <figure className={sharedStyles.headerTools}>
              <Input
                placeholder="Search"
                icon={<SearchIcon />}
                style={{ width: 180 }}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button
                variant="sky"
                onClick={() =>
                  !savingData &&
                  modal.open({
                    title: 'New In-World Purchase',
                    actions: [],
                    closeable: false,
                    escapable: false,
                  })
                }
                disabled={savingData}
              >
                <PlusIcon /> new
              </Button>
            </figure>
          </header>
          <figure
            className={commonStyles.formGroup}
            style={{ marginTop: 16, gap: 14, maxWidth: '645px' }}
          >
            <h3
              className={commonStyles.formGroupLabel}
              style={{ lineHeight: '20px' }}
            >
              In-World Purchases are entitlements which players can buy more
              than once, making them perfect for in-experience currency,
              consumables, or similar products.
            </h3>
            <Button
              variant="link"
              to="https://helixgame.com/docs/scripting-reference/static-classes/payment"
              target="_blank"
            >
              <BookIcon />
              Learn more
            </Button>
          </figure>
          {data.length > 0 && (
            <IAPGrid style={{ marginTop: 20 }}>
              {data.map((item) => (
                <IAPView key={item.id} data={item} to={`./${item.id}`} />
              ))}
            </IAPGrid>
          )}
        </>
      )}
    </div>
  );
}
