import { useOutletContext } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useModal from '../../../../hook/useModal';

import type { OutletContext } from '.';

import commonStyles from '../../../common.module.css';
import { toast } from 'react-hot-toast';
import { selectFile, processError } from '../../../../utils';
import axios from 'axios';
import styles from './showcase.module.css';
import {
  getAssetScreenshots,
  setAssetIcon,
  updateAssetScreenshots,
  uploadAssetScreenshot,
} from '../../../../api/asset';
import rehypeSanitize from 'rehype-sanitize'; // eslint-disable-line import/no-extraneous-dependencies
import remarkOembed from '@agentofuser/remark-oembed'; // eslint-disable-line import/no-extraneous-dependencies
import {
  getYoutubeIdFromLink,
  validateYoutubeURL,
} from '../../../../utils/youtube';

import Input, { TextArea } from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import { Toggle } from '../../../../Comps/Checkbox';
import Select from '../../../../Comps/Select';
import TagInput from '../../../../Comps/TagInput';
import Loader from '../../../../Comps/Loader';
import MDEditor from '@uiw/react-md-editor'; // eslint-disable-line import/no-extraneous-dependencies
import LiteYouTubeEmbed from 'react-lite-youtube-embed'; // eslint-disable-line import/no-extraneous-dependencies
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import Modal from '../../../../Comps/Modal';

import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../../../assets/icons/hamburgerIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtubeIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as AssetIcon } from '../../../../assets/icons/assetIcon.svg';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/exportIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencilIcon.svg';
import { ReactComponent as UndoIcon } from '../../../../assets/icons/undoIcon.svg';

type State = {
  screenshots: string[];
} & (
  | {
      loading: true;
      error: false;
      errorMessage: '';
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      errorMessage: '';
    }
);

export default function CreationsAssetsAssetManagerShowcasePage() {
  const {
    asset,
    getField,
    setUpdateField,
    savingData,
    fetchAsset,
    setSavingData,
  } = useOutletContext<OutletContext>();

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
    errorMessage: '',
    screenshots: [],
  });
  useEffect(() => {
    const abortController = new AbortController();

    getAssetScreenshots(asset.id, abortController.signal).then(
      ({ success, error, data }) => {
        if (!success) {
          setState({
            loading: false,
            error: true,
            errorMessage: error,
            screenshots: [],
          });
          return;
        }

        setState({
          loading: false,
          error: false,
          errorMessage: '',
          screenshots: data,
        });
      }
    );

    return () => abortController.abort();
  }, [asset.id]);

  const [modalController, modal] = useModal();
  const [modalData, setModalData] = useState<{
    url: string;
  }>({
    url: '',
  });
  const [activeId, setActiveId] = useState<number>(-1);

  const uploadFile = async (): Promise<string | null> => {
    if (savingData) return null;
    const file = await selectFile('image/jpeg,image/png');
    if (!file) {
      toast.error('No file selected');
      return null;
    }
    setSavingData(true);

    const toastId = toast.loading('Uploading...');
    const result = await uploadAssetScreenshot(asset.id, file);

    setSavingData(false);
    if (!result.success) {
      toast.error(result.error, { id: toastId });
      return null;
    }

    toast.success('Uploaded!', { id: toastId });
    return result.data;
  };

  const selectVideo = (): Promise<string | null> =>
    new Promise(async (resolve) => {
      if (savingData) return null;

      const result = await modal.open({
        title: 'Select YouTube video',
      });

      if (result !== 'confirm') return null;

      setModalData((prev) => {
        if (!prev.url || prev.url.trim() === '') {
          resolve(null);
          return prev;
        }

        /*const videoId = prev.url.match(
          /^https?:\/\/(www\.)?youtu\.be\/([^?]+).*$/
        )
          ? prev.url.replace(/^https?:\/\/(www\.)?youtu\.be\/([^?]+).*$/, '$2')
          : prev.url.match(
              /^https?:\/\/(www\.)?youtube\.com\/watch\?v=([^&]+).*$/
            )
          ? prev.url.replace(
              /^https?:\/\/(www\.)?youtube\.com\/watch\?v=([^&]+).*$/,
              '$2'
            )
          : null;*/

        if (!validateYoutubeURL(prev.url)) {
          toast.error('Invalid YouTube link');
          resolve(null);
          return prev;
        }

        resolve(getYoutubeIdFromLink(prev.url));
        return { url: '' };
      });
    });

  return (
    <div className={`${commonStyles.splitContentWrapper} ${styles.root}`}>
      <Modal controller={modalController}>
        <figure className={commonStyles.formGroup}>
          <figcaption className={commonStyles.formGroupLabel}>
            Video Link
          </figcaption>
          <Input
            type="url"
            value={modalData.url}
            onChange={(e) => setModalData({ url: e.target.value })}
            placeholder="https://youtu.be/..."
          />
        </figure>
      </Modal>
      {state.loading && <Loader className={styles.rootLoader} scale={1.25} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <div className={commonStyles.splitContentWrapperLimit}>
          <h1 className={commonStyles.splitContentTitle}>Showcase</h1>
          <h3
            className={commonStyles.formGroupLabel}
            style={{ lineHeight: '20px', margin: '16px 0 32px 0' }}
          >
            Show off your asset with screenshots and videos! Some changes will
            only be visible after reviewed by moderators.
          </h3>
          <figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Icon
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
              <figcaption className={commonStyles.formGroupLabel}>
                JPG or PNG less than 1MB. Recommended size: 1920x1080
              </figcaption>
              <Button
                style={{ width: 'fit-content' }}
                disabled={savingData}
                onClick={async () => {
                  if (savingData) return;

                  const file = await selectFile('image/jpeg,image/png');
                  if (!file) return;
                  setSavingData(true);

                  const toastId = toast.loading('Uploading...');
                  const result = await setAssetIcon(asset.id, file);

                  setSavingData(false);
                  if (!result.success)
                    return toast.error(result.error, {
                      id: toastId,
                    });

                  toast.success('Icon uploaded successfully!', {
                    id: toastId,
                  });
                  fetchAsset();
                }}
              >
                <UploadIcon />
                Upload
              </Button>
              <figure className={styles.coverPreview}>
                {asset.icon_url !== '' && (
                  <picture
                    style={{
                      backgroundImage: `url('${asset.icon_url}')`,
                    }}
                  />
                )}
                {asset.icon_url === '' && (
                  <AssetIcon className={styles.defaultIcon} />
                )}
              </figure>
            </figure>
          </section>
          {false && (
            <>
              <figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <h3
                  className={commonStyles.splitContentSubtitle}
                  style={{ marginBottom: -16 }}
                >
                  Screenshots (for debugging previews only, will be removed
                  before launch)
                </h3>
                <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
                  <figcaption className={commonStyles.formGroupLabel}>
                    JPG or PNG less than 1MB. Recommended size: 1920x1080. Max 5
                  </figcaption>
                  <Button
                    style={{ width: 'fit-content' }}
                    disabled={savingData || state.screenshots.length >= 5}
                    onClick={async () => {
                      if (savingData || state.screenshots.length >= 5) return;
                      const file = await selectFile('image/jpeg,image/png');
                      if (!file) return;
                      setSavingData(true);

                      const toastId = toast.loading('Uploading...');
                      const result = await uploadAssetScreenshot(
                        asset.id,
                        file
                      );
                      setSavingData(false);

                      if (!result.success)
                        return toast.error(result.error, {
                          id: toastId,
                        });

                      setState((prev) => ({
                        ...prev,
                        screenshots: [...prev.screenshots, result.data],
                      }));
                      toast.success('Screenshot uploaded successfully!', {
                        id: toastId,
                      });
                    }}
                  >
                    <UploadIcon />
                    upload
                  </Button>
                  <figure className={styles.screenshotManager}>
                    {state.screenshots.map((url, i) => (
                      <figure
                        key={url}
                        className={styles.screenshotManagerItem}
                      >
                        <figure>
                          <HamburgerIcon />
                        </figure>
                        <picture style={{ backgroundImage: `url('${url}')` }} />
                        <figcaption>
                          {url
                            .split(/\/+/)
                            .pop()
                            ?.split('.')
                            .map((e) => e.substring(0, 32))
                            .join('.')}
                        </figcaption>
                        <button
                          onClick={async () => {
                            if (savingData) return;
                            setSavingData(true);

                            const toastId = toast.loading('Deleting...');
                            const result = await updateAssetScreenshots(
                              asset.id,
                              state.screenshots.filter((_, j) => j !== i)
                            );
                            setSavingData(false);

                            if (!result.success)
                              return toast.error(result.error, {
                                id: toastId,
                              });

                            setState((prev) => ({
                              ...prev,
                              screenshots:
                                result.data !== null
                                  ? result.data
                                  : prev.screenshots.filter((_, j) => j !== i),
                            }));
                            toast.success('Screenshot deleted successfully!', {
                              id: toastId,
                            });
                          }}
                          disabled={savingData}
                        >
                          <TrashIcon />
                        </button>
                      </figure>
                    ))}
                  </figure>
                </figure>
              </section>
            </>
          )}
          <figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{ marginBottom: -16 }}
            >
              Preview
            </h3>
            <figure className={commonStyles.formGroup} style={{ gap: 14 }}>
              <figcaption className={commonStyles.formGroupLabel}>
                Maximum 5 items. Only Youtube links are supported for videos.
              </figcaption>
              <figure
                className={`${styles.previewGrid} ${
                  savingData && styles.previewGridDisabled
                }`}
              >
                {(getField<string[]>('showcase') as string[]).map((url, i) => (
                  <figure
                    className={`${styles.previewItem} ${
                      activeId === i && styles.previewItemAdd
                    }`}
                    key={i}
                  >
                    {activeId !== i && (
                      <picture
                        style={{
                          backgroundImage: `url('${
                            url.match(/^https?:\/\//)
                              ? url
                              : `https://i.ytimg.com/vi/${url}/hqdefault.jpg`
                          }')`,
                        }}
                      >
                        {!url.match(/^https?:\/\//) && (
                          <div className={styles.previewItemYoutube}>
                            <YoutubeIcon />
                          </div>
                        )}
                        <figure className={styles.previewItemOverlay}>
                          <Button
                            variant="yellow"
                            disabled={savingData}
                            onClick={() => setActiveId(i)}
                          >
                            <PencilIcon />
                          </Button>
                          <Button
                            variant="red"
                            disabled={savingData}
                            onClick={() => {
                              setActiveId(-1);
                              setUpdateField(
                                'showcase',
                                (
                                  getField<string[]>('showcase') as string[]
                                ).filter((_, j) => j !== i)
                              );
                            }}
                          >
                            <TrashIcon />
                          </Button>
                          <Button
                            disabled={savingData}
                            onClick={() => {
                              setActiveId(-1);
                              return window.open(
                                url.match(/^https?:\/\//)
                                  ? url
                                  : `https://youtu.be/${url}`
                              );
                            }}
                          >
                            <ExportIcon />
                          </Button>
                        </figure>
                      </picture>
                    )}
                    {activeId === i && (
                      <>
                        <Button
                          style={{ width: 'fit-content' }}
                          disabled={savingData}
                          onClick={async () => {
                            const result = await uploadFile();
                            if (!result) return;

                            setUpdateField(
                              'showcase',
                              (getField<string[]>('showcase') as string[]).map(
                                (e, index) => (index === i ? result : e)
                              )
                            );
                            setActiveId(-1);
                          }}
                        >
                          <UploadIcon />
                          upload image
                        </Button>
                        <span>or</span>
                        <Button
                          style={{ width: 'fit-content' }}
                          disabled={savingData}
                          onClick={async () => {
                            const result = await selectVideo();
                            if (!result) return;

                            setUpdateField(
                              'showcase',
                              (getField<string[]>('showcase') as string[]).map(
                                (e, index) => (index === i ? result : e)
                              )
                            );
                            setActiveId(-1);
                          }}
                        >
                          <YoutubeIcon />
                          select video
                        </Button>
                        <button
                          onClick={() => setActiveId(-1)}
                          className={styles.previewUndo}
                          disabled={savingData}
                        >
                          <UndoIcon />
                          cancel
                        </button>
                      </>
                    )}
                  </figure>
                ))}
                {(getField<string[]>('showcase') as string[]).length < 5 && (
                  <figure
                    className={`${styles.previewItem} ${styles.previewItemAdd}`}
                  >
                    <Button
                      style={{ width: 'fit-content' }}
                      disabled={savingData}
                      onClick={async () => {
                        const result = await uploadFile();
                        if (!result) return;

                        setUpdateField(
                          'showcase',
                          (getField<string[]>('showcase') as string[]).concat(
                            result
                          )
                        );
                      }}
                    >
                      <UploadIcon />
                      upload image
                    </Button>
                    <span>or</span>
                    <Button
                      style={{ width: 'fit-content' }}
                      disabled={savingData}
                      onClick={async () => {
                        const result = await selectVideo();
                        if (!result) return;

                        setUpdateField(
                          'showcase',
                          (getField<string[]>('showcase') as string[]).concat(
                            result
                          )
                        );
                      }}
                    >
                      <YoutubeIcon />
                      select video
                    </Button>
                  </figure>
                )}
              </figure>
            </figure>
          </section>
          <figure className={commonStyles.splitContentDivider} />
          <section className={commonStyles.splitContentSection}>
            <h3
              className={commonStyles.splitContentSubtitle}
              style={{
                marginBottom: -16,
              }}
            >
              Long description
            </h3>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Editor
              </figcaption>

              <MDEditor
                height={window.innerHeight - 72}
                value={getField('full_description', '')}
                onChange={(value) =>
                  setUpdateField('full_description', value ?? '')
                }
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
                className={styles.editor}
              />
            </figure>
            <figure className={commonStyles.formGroup}>
              <figcaption className={commonStyles.formGroupLabel}>
                Preview
              </figcaption>
              <div className={styles.preview}>
                {(() => {
                  const markdown = getField<string>('full_description') || '';
                  const chunks = markdown
                    .replace(/\n{3,}/g, '\n')
                    .replace(
                      /\[([^\]]+)\]\(https?:\/\/(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-\9_\-]{8,13})\)/gi,
                      '\nyoutube:$3|$1\n'
                    )
                    .replace(
                      /\[([^\]]+)\]\(https?:\/\/(www\.)?youtu\.be\/([a-zA-Z0-\9_\-]{8,13})\)/gi,
                      '\nyoutube:$3|$1\n'
                    )
                    .replace(/^\n+|\n+$/g, '')
                    .split(/\n/g);
                  /* markdown parser */
                  return chunks.map((chunk, i) => {
                    return chunk.trim() === '' ? (
                      <br key={i} />
                    ) : chunk.match(
                        /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi
                      ) ? (
                      <LiteYouTubeEmbed
                        id={chunk.replace(
                          /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi,
                          '$1'
                        )}
                        key={i}
                        title={`YouTube Video: ${
                          chunk.replace(
                            /^youtube:([a-zA-Z0-\9_\-]{8,13})\|(.*)$/gi,
                            '$2'
                          ) || 'unknown'
                        }`}
                      />
                    ) : (
                      <ReactMarkdown
                        key={i}
                        remarkPlugins={[remarkOembed]}
                        skipHtml
                        allowedElements={[
                          'h1',
                          'h2',
                          'h3',
                          'h4',
                          'h5',
                          'h6',
                          'a',
                          'i',
                          'b',
                          'strong',
                          'em',
                          'u',
                          'strike',
                          'code',
                          'sup',
                          'sub',
                          'ul',
                          'ol',
                          'li',
                          'img',
                          'blockquote',
                          'hr',
                          'br',
                          'table',
                          'thead',
                          'tbody',
                          'tr',
                          'th',
                          'td',
                          'p',
                        ]}
                      >
                        {chunk.trim()}
                      </ReactMarkdown>
                    );
                  });
                })()}
              </div>
            </figure>
          </section>
        </div>
      )}
    </div>
  );
}
