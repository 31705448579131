/*
eslint-disable import/no-extraneous-dependencies
*/
import { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import useModal from '../../../../hook/useModal';
import { useUser } from '../../../../hook/useUser';
import useSWR from 'swr';

import type { WorldServer } from '../../../../modal';
import type { OutletContext } from '.';

import styles from './hosting.module.css';
import commonStyles from '../../../common.module.css';
import axios from 'axios';
import {
  getWorldHostingOptions,
  getWorldPersistentServer,
  startWorldPersistentServer,
} from '../../../../api/world';
import {
  getServerLogs,
  sendServerCommand,
  serverStatsFetcher,
  terminateServer,
} from '../../../../api/server';
import { toast } from 'react-hot-toast';
import {
  downloadFile,
  formatSize,
  processError,
  shortNumber,
} from '../../../../utils';
import ReactDOM from 'react-dom';

import Button from '../../../../Comps/Button';
import Input from '../../../../Comps/Input';
import Select from '../../../../Comps/Select';
import Table, { TableDisplay } from '../../../../Comps/Table';
import Modal from '../../../../Comps/Modal';
import Loader from '../../../../Comps/Loader';

import { ReactComponent as BookIcon } from '../../../../assets/icons/bookIcon.svg';
import { ReactComponent as LIXIcon } from '../../../../assets/icons/lixIcon.svg';
import { ReactComponent as PlayerIcon } from '../../../../assets/icons/playersIcon.svg';
import { ReactComponent as CPUIcon } from '../../../../assets/icons/cpuIcon.svg';
import { ReactComponent as PieIcon } from '../../../../assets/icons/pieIcon.svg';
import { ReactComponent as ConsoleIcon } from '../../../../assets/icons/consoleIcon.svg';
import { ReactComponent as RewindIcon } from '../../../../assets/icons/rewindIcon.svg';
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reloadIcon.svg';
import { ReactComponent as StopIcon } from '../../../../assets/icons/stopIcon.svg';

const STATS_POLL_INTERVAL = 1000 * 60; //1 minute
const SURPRESS_STATS_ERRORS = false;

type State =
  | {
      loading: true;
      error: boolean;
      errorMessage: '';
      hasDedicatedHosting: false;
      data: null;
    }
  | {
      loading: false;
      error: false;
      errorMessage: '';
      hasDedicatedHosting: false;
      data: null;
    }
  | {
      loading: false;
      error: false;
      errorMessage: '';
      hasDedicatedHosting: true;
      data: WorldServer;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
      hasDedicatedHosting: false;
      data: null;
    };

export default function WorldManagementHostingPage() {
  const { world, setSavingData, savingData } =
    useOutletContext<OutletContext>();

  const [state, setState] = useState<State>({
    loading: true,
    error: false,
    errorMessage: '',
    hasDedicatedHosting: false,
    data: null,
  });

  const fetchDedicatedHosting = async (force = false, signal?: AbortSignal) => {
    if (force)
      setState({
        loading: true,
        error: false,
        errorMessage: '',
        hasDedicatedHosting: false,
        data: null,
      });

    getWorldPersistentServer(world.id, signal).then(
      ({ success, exists, error, data }) => {
        if (!success) {
          setState({
            loading: false,
            error: true,
            errorMessage: error,
            hasDedicatedHosting: false,
            data: null,
          });
          return;
        }

        if (!exists) {
          console.log(`World ${world.id} does not have dedicated hosting.`);
          setState({
            loading: false,
            error: false,
            errorMessage: '',
            hasDedicatedHosting: false,
            data: null,
          });
          return;
        }

        console.log(`World ${world.id} has dedicated hosting:`, data);
        setState({
          loading: false,
          error: false,
          errorMessage: '',
          hasDedicatedHosting: true,
          data,
        });
      }
    );
  };
  useEffect(() => {
    const abortController = new AbortController();
    fetchDedicatedHosting(false, abortController.signal);
    return () => abortController.abort();
  }, []);

  const { balance, fetchBalance, setSmartBalanceChecker } = useUser();
  useEffect(() => {
    setSmartBalanceChecker(!state.hasDedicatedHosting);
    if (state.hasDedicatedHosting) return;
    return () => setSmartBalanceChecker(false);
  }, [state.hasDedicatedHosting]);

  const [hostingOptions, setHostingOptions] = useState<
    | {
        region: string;
        id: string;
        pricing: number;
      }[]
    | null
  >(null);
  const [selectedHostingOption, setSelectedHostingOption] = useState<
    string | ''
  >('');
  useEffect(() => {
    if (hostingOptions !== null) return;
    if (state.loading || state.error || state.hasDedicatedHosting) return;

    const abortController = new AbortController();
    getWorldHostingOptions(abortController.signal).then(
      ({ success, error, data }) => {
        if (!success) {
          console.error('Unable to fetch hosting options:', error);
          toast.error(`Unable to get hosting options: ${error}`);
        }

        setHostingOptions(success ? data : []);
        setSelectedHostingOption(success && data.length > 0 ? data[0].id : '');
      }
    );

    return () => abortController.abort();
  }, [state.loading, state.error, state.hasDedicatedHosting]);
  const selectedHostingOptionPricing =
    hostingOptions === null || selectedHostingOption === ''
      ? -1
      : hostingOptions.find((option) => option.id === selectedHostingOption)
          ?.pricing ?? -1;

  const [controller, modal] = useModal();
  const [commandString, setCommandString] = useState<string | null>(null);

  return (
    <div
      className={`${commonStyles.splitContentWrapper} ${styles.root}`}
      style={{ paddingBottom: 32 }}
    >
      {ReactDOM.createPortal(
        <style>
          {`.${styles.root} .${commonStyles.splitContentSection} {
              gap: 14px
            }`}
        </style>,
        document.head
      )}
      <Modal controller={controller}>
        {commandString !== null && (
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Command
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={commandString}
              maxLength={90}
              onChange={(e) => setCommandString(e.target.value)}
              placeholder="package hotreload polygon-weapons"
            />
          </figure>
        )}
      </Modal>
      {state.loading && <Loader className={styles.rootLoader} scale={1.25} />}
      {!state.loading && state.error && (
        <div className={styles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <div className={commonStyles.splitContentWrapperLimit}>
          <h1 className={commonStyles.splitContentTitle}>Dedicated Hosting</h1>
          <figure
            className={commonStyles.formGroup}
            style={{ marginTop: 16, gap: 14 }}
          >
            <h3
              className={commonStyles.formGroupLabel}
              style={{ lineHeight: '20px' }}
            >
              By defaults, worlds automatically sleep when no one is in them.
              With dedicated hosting, your world will always be running, 24/7.
              Recommended for worlds that require persistence or high
              performance.
            </h3>
            <Button variant="link" to="HDB-1016" target="_blank">
              <BookIcon />
              {state.hasDedicatedHosting ? 'Documentation' : 'Learn more'}
            </Button>
          </figure>
          {!state.hasDedicatedHosting && (
            <>
              <figure
                className={commonStyles.splitContentDivider}
                style={{ marginTop: 16 }}
              />
              <section
                className={commonStyles.splitContentSection}
                style={{ paddingTop: 14 }}
              >
                <figure
                  className={commonStyles.formGroup}
                  style={{ marginTop: 16, gap: 14 }}
                >
                  <figcaption className={commonStyles.formGroupLabel}>
                    Select a region to see pricing
                  </figcaption>
                  <Select
                    options={
                      hostingOptions === null
                        ? []
                        : hostingOptions.map((option) => ({
                            value: option.id,
                            label: option.region,
                          }))
                    }
                    value={selectedHostingOption}
                    loading={hostingOptions === null}
                    onChange={(value) =>
                      setSelectedHostingOption(value as string)
                    }
                    placeholder="Select a region"
                    disabled={savingData}
                  />
                </figure>
              </section>
              <figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <h3 className={commonStyles.splitContentSubtitle}>
                  Hosting cost
                </h3>
                <figure className={commonStyles.rowFull}>
                  <figcaption className={commonStyles.formGroupLabel}>
                    Per month
                  </figcaption>
                  <Input
                    value={
                      selectedHostingOptionPricing === -1
                        ? ''
                        : selectedHostingOptionPricing.toLocaleString()
                    }
                    disabled
                    icon={<LIXIcon />}
                    style={{
                      color: '#F0C000',
                      width: 128,
                      opacity: hostingOptions === null ? 0.25 : 1,
                    }}
                  />
                </figure>
              </section>
              <figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <Button
                  variant="yellow"
                  disabled={
                    savingData ||
                    hostingOptions === null ||
                    selectedHostingOption === ''
                  }
                  onClick={async () => {
                    if (
                      savingData ||
                      hostingOptions === null ||
                      selectedHostingOption === '' ||
                      selectedHostingOptionPricing === -1
                    )
                      return;

                    if (!world.published)
                      return toast.error(
                        'You must publish your world before starting dedicated hosting.'
                      );

                    if (selectedHostingOptionPricing > balance)
                      return modal
                        .open({
                          title: 'Not enough LIX',
                          description: 'Please top up your wallet',
                          closeable: true,
                          escapable: false,
                          actions: [
                            {
                              variant: 'yellow',
                              value: 'success',
                              label: (
                                <>
                                  <LIXIcon /> top up wallet
                                </>
                              ),
                            },
                            {
                              variant: 'dark',
                              value: 'cancel',
                              label: 'cancel',
                            },
                          ],
                        })
                        .then((result) =>
                          result === 'success'
                            ? window.open(
                                'https://app.helixmetaverse.com/wallet'
                              )
                            : null
                        );

                    setSavingData(true);
                    const toastId = toast.loading('Starting Dedicated Hosting');
                    const result = await startWorldPersistentServer(
                      world.name,
                      world.description
                    );
                    if (!result.success)
                      toast.error(result.error, {
                        id: toastId,
                      });
                    else
                      toast.success(`Dedicated Hosting started`, {
                        id: toastId,
                      });
                    setSavingData(false);

                    if (!result.success) return;
                    fetchDedicatedHosting(true);
                    fetchBalance();
                  }}
                >
                  start dedicated hosting
                </Button>
              </section>
            </>
          )}
          {state.hasDedicatedHosting && (
            <>
              <figure
                className={commonStyles.splitContentDivider}
                style={{ marginTop: 32 }}
              />
              <section
                className={commonStyles.splitContentSection}
                style={{ gap: 18 }}
              >
                <RealTimeStats server={state.data} />
              </section>
              <figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <h3 className={commonStyles.splitContentSubtitle}>Controls</h3>
                <figure className={styles.statsGrid}>
                  <Button
                    variant="dark"
                    disabled={savingData}
                    onClick={async () => {
                      if (savingData) return;

                      setCommandString('');
                      const result = await modal.open({
                        title: 'Send command',
                      });
                      if (result !== 'confirm') return setCommandString(null);
                      setSavingData(true);

                      const toastId = toast.loading('Sending command');
                      setCommandString((prev) => {
                        (async () => {
                          if (prev === null) return;
                          const { success, error } = await sendServerCommand(
                            state.data.ip,
                            state.data.port,
                            prev
                          );
                          setSavingData(false);
                          setCommandString(null);
                          if (!success)
                            return toast.error(error, { id: toastId });
                          else toast.success('Command sent', { id: toastId });
                        })();
                        return prev;
                      });
                    }}
                  >
                    <ConsoleIcon />
                    send command
                  </Button>
                  <Button
                    variant="dark"
                    disabled={savingData}
                    onClick={() =>
                      window.open(
                        `/console/${state.data.ip}/${state.data.port}`
                      )
                    }
                  >
                    <ConsoleIcon /> open console
                  </Button>
                  <Button
                    variant="dark"
                    disabled={savingData}
                    onClick={async () => {
                      if (savingData) return;
                      setSavingData(true);
                      const toastId = toast.loading('Collecting logs');
                      const { success, error, logs } = await getServerLogs(
                        state.data.ip,
                        state.data.port
                      );
                      setSavingData(false);
                      if (!success) return toast.error(error, { id: toastId });
                      else toast.success('Logs collected', { id: toastId });
                      downloadFile(logs.join('\n'), `${world.name}-logs.txt`);
                    }}
                  >
                    <RewindIcon />
                    get logs
                  </Button>
                  <Button
                    variant="yellow-dark"
                    disabled={savingData}
                    onClick={async () => {
                      if (savingData) return;
                      setSavingData(true);

                      const toastId = toast.loading('Restarting server');
                      const { success, error } = await sendServerCommand(
                        state.data.ip,
                        state.data.port,
                        'package reload all'
                      );
                      setSavingData(false);
                      if (!success) return toast.error(error, { id: toastId });
                      else
                        toast.success('Server restarted', {
                          id: toastId,
                        });
                    }}
                  >
                    <ReloadIcon />
                    reload packages
                  </Button>
                  <Button
                    variant="tomato"
                    disabled={savingData}
                    onClick={() =>
                      savingData
                        ? null
                        : modal
                            .open({
                              title: 'Force restart server?',
                              description:
                                'This will disconnect all active players.',
                              actions: [
                                {
                                  variant: 'red',
                                  value: 'confirm',
                                  label: 'restart now',
                                },
                                {
                                  variant: 'dark',
                                  value: 'cancel',
                                  label: 'cancel',
                                },
                              ],
                            })
                            .then(async (result) => {
                              if (result !== 'confirm') return;
                              setSavingData(true);
                              const toastId =
                                toast.loading('Restarting server');
                              const { success, error } =
                                await sendServerCommand(
                                  state.data.ip,
                                  state.data.port,
                                  'restart'
                                );
                              setSavingData(false);
                              if (!success)
                                return toast.error(error, { id: toastId });
                              else
                                toast.success('Server restarted', {
                                  id: toastId,
                                });
                            })
                    }
                  >
                    <ReloadIcon />
                    force restart
                  </Button>
                </figure>
              </section>
              {/*<figure className={commonStyles.splitContentDivider} />
              <section className={commonStyles.splitContentSection}>
                <h3 className={commonStyles.splitContentSubtitle}>
                  Billing history
                </h3>
                <Table
                  header={[
                    {
                      value: 'billing date',
                    },
                    {
                      value: 'billing period',
                      width: '1fr',
                    },
                    {
                      value: 'cost',
                      widthBuffer: 28,
                    },
                  ]}
                  rows={new Array(5).fill([
                    {
                      value: new Date().toLocaleDateString('en-US', {
                        dateStyle: 'short',
                      }),
                    },
                    {
                      value: `${new Date().toLocaleDateString('en-US', {
                        dateStyle: 'short',
                      })} - ${new Date().toLocaleDateString('en-US', {
                        dateStyle: 'short',
                      })}`,
                    },
                    {
                      value: '500',
                      display: (
                        <TableDisplay className={styles.tableAmount} key="lix">
                          <LIXIcon /> 500
                        </TableDisplay>
                      ),
                    },
                  ])}
                />
                </section>*/}
              <figure className={`${commonStyles.rowFull} ${styles.footerRow}`}>
                <Button
                  disabled={savingData}
                  variant="tomato"
                  onClick={() =>
                    savingData
                      ? null
                      : modal
                          .open({
                            title: 'Cancel Dedicated Hosting?',
                            description:
                              'Dedicated Hosting will shut down immediately, and you will not be billed anymore. All active players will be disconnected.',
                            actions: [
                              {
                                value: 'confirm',
                                label: (
                                  <>
                                    <StopIcon /> cancel
                                  </>
                                ),
                                variant: 'red',
                              },
                              {
                                variant: 'dark',
                                value: 'cancel',
                                label: 'cancel',
                              },
                            ],
                          })
                          .then(async (result) => {
                            if (result !== 'confirm') return;
                            setSavingData(true);
                            const toastId = toast.loading('Terminating server');
                            const { success, error } = await terminateServer(
                              state.data.ip,
                              state.data.port
                            );
                            setSavingData(false);
                            if (!success)
                              return toast.error(error, { id: toastId });
                            else
                              toast.success('Dedicated Hosting cancelled', {
                                id: toastId,
                              });
                            fetchDedicatedHosting(true);
                          })
                  }
                >
                  cancel hosting
                </Button>
                <Button
                  variant="dark"
                  disabled={savingData}
                  onClick={() =>
                    savingData
                      ? null
                      : modal.open({
                          title: 'Change Region',
                          description:
                            'Keep in mind this will restart the server and disconnect all active players.',
                          children: (
                            <Select
                              options={[
                                'California',
                                'North America',
                                'Latin America',
                                'Europe',
                                'Asia',
                                'Oceania',
                              ]}
                              value="California"
                              placeholder="Select a region"
                              style={{ width: '100%' }}
                            />
                          ),
                          actions: [
                            {
                              value: 'confirm',
                              label: 'change',
                            },
                            {
                              variant: 'dark',
                              value: 'cancel',
                              label: 'cancel',
                            },
                          ],
                        })
                  }
                >
                  change region
                </Button>
              </figure>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function RealTimeStats({ server }: { server: WorldServer }) {
  const {
    data: stats,
    error,
    isLoading,
  } = useSWR(
    `/server/log/rusage?ip=${server.ip}&port=${server.port}`,
    serverStatsFetcher.bind(null, SURPRESS_STATS_ERRORS),
    {
      refreshInterval: STATS_POLL_INTERVAL,
      errorRetryInterval: STATS_POLL_INTERVAL,
      errorRetryCount: Infinity,
    }
  );

  useEffect(() => {
    if (!error) return;
    console.error('Error fetching stats:', error);
    if (SURPRESS_STATS_ERRORS) return;
    toast.error(processError(error));
  }, [error]);

  const live =
    !isLoading &&
    !error &&
    stats !== undefined &&
    stats[0] !== -1 &&
    stats[1] !== -1;

  return (
    <>
      <h3
        className={`${commonStyles.row} ${commonStyles.splitContentSubtitle}`}
        style={{ gap: 6 }}
      >
        Real-time stats
        {live && <span className={styles.live} />}
      </h3>
      <figure className={styles.statsGrid}>
        <figure className={styles.statsGroup}>
          <figcaption className={styles.statsGroupTitle}>players</figcaption>
          <figure className={`${commonStyles.row} ${styles.statsGroupValue}`}>
            <PlayerIcon />
            {shortNumber(server.players)}
          </figure>
        </figure>
        <figure className={styles.statsGroup}>
          <figcaption className={styles.statsGroupTitle}>cpu</figcaption>
          <figure className={`${commonStyles.row} ${styles.statsGroupValue}`}>
            {isLoading ? (
              <div className={styles.statsLoader}>
                <Loader scale={0.5} />
              </div>
            ) : (
              <>
                <CPUIcon />
                {(stats ?? [])[0] === -1 ? '?' : `${(stats ?? [])[0]}%`}
              </>
            )}
          </figure>
        </figure>
        <figure className={styles.statsGroup}>
          <figcaption className={styles.statsGroupTitle}>memory</figcaption>
          <figure className={`${commonStyles.row} ${styles.statsGroupValue}`}>
            {isLoading ? (
              <div className={styles.statsLoader}>
                <Loader scale={0.5} />
              </div>
            ) : (
              <>
                <PieIcon />
                {(stats ?? [])[1] === -1
                  ? '?'
                  : formatSize((stats ?? [])[1] as number)}
              </>
            )}
          </figure>
        </figure>
      </figure>
    </>
  );
}
