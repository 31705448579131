import { useUser } from '../../hook/useUser';
import usePageConfig from '../../hook/usePageConfig';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import commonStyles from '../common.module.css';
import sharedStyles from './shared.module.css';
import styles from './invites.module.css';
import { createInvite, getMyInvites } from '../../api/invite';
import { copyText } from '../../utils';
import { toast } from 'react-hot-toast';

import type { Invite } from '../../api/invite';

import Loader from '../../Comps/Loader';
import Input from '../../Comps/Input';
import Button from '../../Comps/Button';
import Table, { TableDisplay } from '../../Comps/Table';
import Select from '../../Comps/Select';

import { ReactComponent as InviteUserIcon } from '../../assets/icons/inviteUserIcon.svg';
import { ReactComponent as SparkleIcon } from '../../assets/icons/sparkleIcon.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/searchIcon.svg';
import { ReactComponent as DescendingIcon } from '../../assets/icons/descendingIcon.svg';
import { ReactComponent as DuplicateIcon } from '../../assets/icons/duplicateIcon.svg';

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      invites: Invite[];
    };

const transformDate = (date: string) => {
  const dateObj = new Date(date);
  return `${dateObj.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })}, ${dateObj.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  })}`;
};

export default function AccountInvitesPage() {
  usePageConfig({ title: 'Invites | HELIX Hub' });
  const { isLoading, isLoggedIn, currentUser } = useUser();

  const fetchInvites = useCallback(
    (signal?: AbortSignal, initial?: boolean) =>
      getMyInvites(signal).then((result) => {
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        setState({
          loading: false,
          error: false,
          invites: result.data,
        });
        if (initial)
          setLastCode(
            result.data.find((invite) => invite.usages < invite.max_usage)
              ?.invitation_code ?? ''
          );
      }),
    []
  );

  const [state, setState] = useState<State>({ loading: true });
  const [lastCode, setLastCode] = useState<string | ''>('');
  useEffect(() => {
    if (isLoading || !isLoggedIn) return;

    const abortController = new AbortController();
    fetchInvites(abortController.signal, true);

    return () => abortController.abort();
  }, [isLoading, isLoggedIn, fetchInvites]);

  const [creatingInvite, setCreatingInvite] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div className={sharedStyles.root}>
      {state.loading && (
        <Loader className={sharedStyles.rootLoader} scale={1.5} />
      )}
      {!state.loading && state.error && (
        <div className={sharedStyles.rootError}>{state.errorMessage}</div>
      )}
      {!state.loading && !state.error && (
        <>
          <h1 className={sharedStyles.title}>Invite Friends</h1>
          <header className={`${sharedStyles.header} ${styles.header}`}>
            <div className={`${commonStyles.row} ${styles.headerInfo}`}>
              <figure className={`${commonStyles.row} ${styles.headerFigure}`}>
                <picture
                  style={
                    {
                      backgroundImage: `url('${currentUser?.iconUrl ?? ''}')`,
                      '--theme': currentUser?.iconColor ?? '',
                    } as any
                  }
                />
                <figure>
                  <InviteUserIcon />
                </figure>
              </figure>
              <figure className={styles.headerText}>
                <h2 className={styles.headerTitle}>Unique Invite Link</h2>
                <p className={styles.headerSubtitle}>
                  Invite your friends to HELIX using your unique link.
                </p>
              </figure>
            </div>
            <div className={`${commonStyles.row} ${styles.headerActions}`}>
              <Input
                value={lastCode === '' ? '' : `https://helixga.me/${lastCode}`}
                className={styles.headerInput}
                placeholder="https://helixga.me/HEX123"
                ref={inputRef}
              />
              <Button
                variant="yellow"
                disabled={creatingInvite}
                onClick={async () => {
                  if (creatingInvite) return;
                  setCreatingInvite(true);

                  const toastId = toast.loading('Creating new invite...');
                  const result = await createInvite();
                  setCreatingInvite(false);

                  if (!result.success)
                    return toast.error(result.error, { id: toastId });

                  toast.success('Created new invite', { id: toastId });
                  setLastCode(result.data.invitation_code);
                  fetchInvites();
                  setTimeout(() => {
                    inputRef.current?.focus();
                    inputRef.current?.select();
                  }, 100);
                }}
              >
                <SparkleIcon />
                new link
              </Button>
            </div>
            {lastCode !== '' && (
              <p className={styles.headerDescription}>
                Want to invite another friend? Create a new link.
              </p>
            )}
          </header>
          <section className={`${commonStyles.row} ${styles.head}`}>
            <h2
              className={`${commonStyles.row} ${commonStyles.splitContentTitle} ${styles.headTitle}`}
            >
              Invites
              <span className={commonStyles.splitContentTag}>
                {state.invites.length.toLocaleString()}
              </span>
            </h2>
            <div className={commonStyles.row}>
              {/*<Input
                icon={<SearchIcon style={{ color: '#A0A0A0' }} />}
                style={{ width: 180 }}
                placeholder="Search users"
                />
              <Select
                options={[
                  {
                    value: 'descending',
                    label: (
                      <>
                        <DescendingIcon /> Recent
                      </>
                    ),
                  },
                ]}
                style={{ width: 180 }}
                value={'descending'}
              />*/}
            </div>
          </section>
          <Table
            header={[
              {
                value: 'invite code',
                width: '1fr',
              },
              {
                value: 'expires',
              },
              {
                value: 'status',
                widthBuffer: 10 + 10,
              },
              {
                value: 'actions',
                width: 50,
              },
            ]}
            rows={state.invites.map((invite) => [
              {
                value: invite.invitation_code,
                display: (
                  <TableDisplay key="code" className={styles.tableInvite}>
                    <span>{invite.invitation_code}</span>
                  </TableDisplay>
                ),
              },
              {
                value: transformDate(invite.expiration_date),
              },
              {
                value: invite.usages >= invite.max_usage ? 'Used' : 'Unused',
                display: (
                  <TableDisplay
                    key="status"
                    className={`${styles.tableStatus} ${
                      invite.usages >= invite.max_usage &&
                      styles.tableStatusUsed
                    }`}
                  >
                    <span>
                      {invite.usages >= invite.max_usage ? 'Used' : 'Unused'}
                    </span>
                  </TableDisplay>
                ),
              },
              {
                value: '',
                display: (
                  <TableDisplay key="actions">
                    <Button
                      variant="dark"
                      onClick={() =>
                        copyText(
                          `https://helixga.me/${invite.invitation_code}`
                        ).then(() => toast.success('Copied invite link'))
                      }
                    >
                      <DuplicateIcon style={{ height: 18 }} />
                    </Button>
                  </TableDisplay>
                ),
              },
            ])}
          />
        </>
      )}
    </div>
  );
}
