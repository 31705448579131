import { useState, useEffect, useContext } from 'react';

import { createContext } from 'react';

const PageConfigContext = createContext({
  title: '',
  setTitle: (title: string) => {},
});

export default function usePageConfig({
  title = '',
}: Partial<{ title: string }>) {
  const context = useContext(PageConfigContext);

  useEffect(() => {
    context.setTitle(title);
  }, [title]);
}

export function PageConfigProvider({
  children,
  suffix = '',
}: {
  children: React.ReactNode;
  suffix?: string;
}) {
  const [title, setTitle] = useState("Loading");
  useEffect(() => {
    document.title = `${title}${suffix}`;
  }, [title]);

  return (
    <PageConfigContext.Provider value={{ title, setTitle }}>
      {children}
    </PageConfigContext.Provider>
  );
}
