import styles from './styles.module.css';
import variants from './variants.module.css';

import { NavLink } from 'react-router-dom';

export default function Button({
  className = '',
  variant = '',
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  className?: string;
} & (
    | {
        variant: 'link';
        to?: string;
        target?: string;
      }
    | { variant?: string }
  )): React.ReactElement {
  return variant === 'link' ? (
    <NavLink
      className={`${styles.root} ${variants.link || ''} ${
        className === '' && styles.rootPad
      } ${className}`} // @ts-ignore
      to={props.to ?? '/'} // @ts-ignore
      target={props.target ?? '_self'}
      {...(props as any)}
    />
  ) : (
    <button
      className={`${styles.root} ${
        variant !== '' && (variants[variant] || '')
      } ${className === '' && styles.rootPad} ${className}`}
      {...props}
    />
  );
}

export function buttonClassName(variant = '') {
  return `${styles.root} ${styles.rootPad} ${variant !== '' && (variants[variant] || '')}`;
}
