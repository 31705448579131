import React, { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface IButtonCompo extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: string | React.ReactNode;
  variant?: 'transparent' | 'light' | 'dark' | 'sky';
  isLoading?: boolean;
  onClick?: () => void;
}

export const IconBtnCompo = (props: IButtonCompo) => {
  return (
    <StyledBtn
      onClick={props.onClick}
      className={props.variant + ' ' + props.className}
    >
      {typeof props.icon === 'string' ? (
        <StyledIcon className="material-symbols-outlined">
          {props.icon}
        </StyledIcon>
      ) : (
        props.icon
      )}
    </StyledBtn>
  );
};

const StyledBtn = styled.button`
  padding: 8px;

  border: none;
  border-radius: 6px;
  background: transparent;
  color: #a0a0a0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-in;

  &:hover {
    color: #ededed;
    background: #282828;
  }

  & > svg {
    height: 15px;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    background: #282828 !important;
    color: #a0a0a0 !important;
  }

  &.transparent {
    background: transparent;
    color: #a0a0a0;
  }

  &.transparent:hover {
    background: #282828;
  }

  &.light {
    color: #161616;

    background: #ededed;
  }

  &.light:hover {
    background: #ffffff;
  }

  &.sky {
    background: #68ddfd;
    color: #0c1820;
  }

  &.sky:hover {
    background: #8ae8ff;
  }

  &.dark {
    color: #ededed;

    background: #232323;
  }

  &.dark:hover {
    background: #282828;
  }
`;

const StyledIcon = styled.span``;
