import { useOutletContext } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

import commonStyles from '../../../common.module.css';
import styles from './access.module.css';
import { processError, selectFile } from '../../../../utils';
import { validateWorldDomain } from '../../../../api/world';

import type { OutletContext } from '.';

import Input from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import Select from '../../../../Comps/Select';
import { Toggle } from '../../../../Comps/Checkbox';

import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as PencilIcon } from '../../../../assets/icons/pencilIcon.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warningIcon.svg';

export default function WorldManagementShowcasePage() {
  const { world, getField, setUpdateField, savingData, fetchWorld, update } =
    useOutletContext<OutletContext>();

  const domainName = getField<string>('fqdn', '');
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);
  useEffect(() => {
    if (showPassword) {
      // @ts-ignore
      passwordRef.current?.focus();
    }
  }, [showPassword]);

  const [domainStatus, setDomainStatus] = useState({
    valid: world.fqdn !== '',
    message: '',
  });
  useEffect(() => {
    if (typeof update.fqdn !== 'string')
      return setDomainStatus({ valid: world.fqdn !== '', message: '' });
    if (update.fqdn === '')
      return setDomainStatus({ valid: false, message: '' });
    if (update.fqdn === world.fqdn)
      return setDomainStatus({ valid: true, message: '' });

    if (update.fqdn.length > 30)
      return setDomainStatus({
        valid: false,
        message: 'Too long',
      });

    setDomainStatus({ valid: false, message: '' });
    const abortController = new AbortController();

    const i = setTimeout(() => {
      if (update.fqdn.length < 2)
        return setDomainStatus({
          valid: false,
          message: 'Too short',
        });
      if (update.fqdn.match(/\-$/) || update.fqdn.match(/^\-/))
        return setDomainStatus({
          valid: false,
          message: update.fqdn.match(/^\-/)
            ? 'Cannot start with hypen'
            : 'Cannot end with hypen',
        });

      validateWorldDomain(`${update.fqdn}.lix`, abortController.signal).then(
        ({ success, error, isAvailable }) => {
          if (!success)
            return setDomainStatus({
              valid: false,
              message: error,
            });

          if (!isAvailable)
            return setDomainStatus({
              valid: false,
              message: 'This domain is already taken',
            });

          setDomainStatus({
            valid: true,
            message: '',
          });
        }
      );
    }, 500);

    return () => {
      clearTimeout(i);
      abortController.abort();
    };
  }, [update.fqdn, world.fqdn]);

  return (
    <div className={`${commonStyles.splitContentWrapper}`}>
      <div className={commonStyles.splitContentWrapperLimit}>
        <h1 className={commonStyles.splitContentTitle}>Access</h1>
        <figure
          className={commonStyles.splitContentDivider}
          style={{ marginTop: 24 }}
        />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -16 }}
          >
            HELIX URL
          </h3>
          <figure
            className={commonStyles.formGroup}
            style={
              {
                /*gap: 18*/
              }
            }
          >
            <figcaption className={commonStyles.formGroupLabel}>
              Get a unique HELIX URL for direct access to your world ingame.
            </figcaption>
            <Input
              className={`${commonStyles.formGroupInput} ${styles.domainName}`}
              value={domainName}
              onChange={(e) =>
                setUpdateField(
                  'fqdn',
                  e.target.value
                    .toLowerCase()
                    .replace(/^\-/, '')
                    .replace(/ +/g, '-')
                    .replace(/[^a-z0-9\-]/g, '')
                )
              }
              rightIcon={
                domainStatus.valid ? (
                  <CheckCircleIcon style={{ color: '#99D52A' }} />
                ) : undefined
              }
              placeholder="my-world"
              maxLength={32}
              autoCorrect="off"
            >
              <div className={styles.prefix}>helix://</div>
              <figure className={styles.overlay}>
                <figcaption className={styles.shadow}>
                  {domainName !== '' ? domainName : 'my-world'}
                </figcaption>
                <span className={styles.suffix}>.lix</span>
              </figure>
            </Input>
            {domainStatus.message !== '' && (
              <span className={commonStyles.formGroupError}>
                {domainStatus.message}
              </span>
            )}
            {domainStatus.message === '' && domainName !== '' && (
              <figcaption className={commonStyles.formGroupLabel}>
                Your world is also accessible at{' '}
                <a
                  href={`https://helixgame.com/worlds/${domainName}.lix`}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.worldLink}
                >
                  helixgame.com/worlds/{domainName}.lix
                </a>
              </figcaption>
            )}
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Protection
          </h3>
          <figure className={commonStyles.formGroup} style={{ gap: 12 }}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{
                lineHeight: '20px',
                marginBottom: 4,
              }}
            >
              Choose who can join your world.
            </figcaption>
            <Select
              className={commonStyles.formGroupInput}
              value={getField<boolean>('hasPassword') ? 1 : 0}
              onChange={(e: any) => {
                if (e === 1 && !world.hasPassword) {
                  setShowPassword(true);
                } else if (e === 0) {
                  setShowPassword(false);
                }
                setUpdateField('hasPassword', e === 1);
              }}
              options={[
                {
                  value: 0,
                  label: 'None',
                },
                {
                  value: 1,
                  label: 'Password Protected',
                },
                /* {
                  value: 2,
                  label: 'Friends Only',
                },
                { value: 3, label: 'Private' },*/
              ]}
            />
            {getField<boolean>('hasPassword', false) && (
              <Input
                ref={passwordRef}
                className={commonStyles.formGroupInput}
                placeholder={showPassword ? 'Password' : 'Set Password'}
                type={showPassword ? 'text' : 'password'}
                autoCorrect="off"
                disabled={savingData || !showPassword}
                value={
                  showPassword
                    ? getField<string>('password', '')
                    : new Array(18).fill('*').join('')
                }
                onChange={(e) => setUpdateField('password', e.target.value)}
                icon={
                  showPassword ? null : (
                    <Button
                      variant="yellow"
                      style={{
                        height: 30,
                        width: 30,
                        padding: 0,
                        transform: `translate(-5px, -5px)`,
                      }}
                      onClick={() => setShowPassword(true)}
                    >
                      <PencilIcon style={{ height: 15, width: 15 }} />
                    </Button>
                  )
                }
              />
            )}
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Visibility
          </h3>
          <figure className={commonStyles.formGroup} style={{ gap: 12 }}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{
                lineHeight: '20px',
                marginBottom: 4,
              }}
            >
              Choose how players can find your world.
            </figcaption>
            {/*<figure className={commonStyles.toggleGroup}>
              <Toggle
                onClick={() =>
                  setUpdateField('public', !getField<boolean>('public'))
                }
                checked={getField<boolean>('public')}
              />
              <figcaption
                className={commonStyles.toggleGroupLabel}
                style={{ color: '#A0A0A0' }}
              >
                Unlisted
              </figcaption>
              </figure>*/}
            <Select
              options={[
                {
                  value: 0,
                  label: 'Unlisted',
                },
                {
                  value: 1,
                  label: 'Listed',
                },
              ]}
              placeholder="Select"
              className={commonStyles.formGroupInput}
              value={getField<boolean>('public') ? 1 : 0}
              onChange={(e: any) => setUpdateField('public', e === 1)}
            />
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Max concurrent players per instance
          </h3>
          <figure
            className={commonStyles.formGroup}
            style={
              {
                /*gap: 18*/
              }
            }
          >
            <figcaption className={commonStyles.formGroupLabel}>
              Only this many players will be able to join each instance of your
              world simultaneously.
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={`${getField<number | string>('maxPlayers', '')}`}
              style={{ width: 'fit-content' }}
              onChange={(e) =>
                setUpdateField('maxPlayers', e.target.value.replace(/\D/g, ''))
              }
              size={10}
              placeholder="50"
            />
          </figure>
        </section>
      </div>
    </div>
  );
}
