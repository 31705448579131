import styles from './styles.module.css';

export default function Loader({
  className = '',
  scale = 1,
  style = {},
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
> & {
  scale?: number;
}) {
  return (
    <span
      className={`${styles.root} ${className}`}
      style={{
        // @ts-ignore
        '--scale': scale,
        ...style,
      }}
      {...props}
    ></span>
  );
}
