import { useEffect, useState, useCallback } from 'react';

export default function useQuickChart(
  active: boolean,
  ref: React.RefObject<HTMLElement>
): (type: string, data?: any, options?: any) => string {
  const [sizing, setSizing] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    devicePixelRatio: window.devicePixelRatio,
  });
  const [fragment, setFragment] = useState<string>('');

  useEffect(() => {
    console.log('useQuickChart called', ref.current);
    if (!active) return;
    if (!ref.current) return;

    setSizing({
      width: ref.current.clientWidth,
      height: ref.current.clientHeight,
      devicePixelRatio: window.devicePixelRatio,
    });

    const onResize = () => {
      if (!ref.current) return;

      console.log(
        'onResize',
        ref.current.clientWidth,
        ref.current.clientHeight
      );

      setSizing({
        width: ref.current.clientWidth,
        height: ref.current.clientHeight,
        devicePixelRatio: window.devicePixelRatio,
      });
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [ref.current, active]);

  useEffect(() => {
    let animationFrameId: number;

    requestAnimationFrame(() => {
      setFragment(
        `w=${sizing.width}&h=${sizing.height}&devicePixelRatio=${sizing.devicePixelRatio}`
      );
    });

    return () => cancelAnimationFrame(animationFrameId);
  }, [sizing]);

  return useCallback(
    function quickChart(type: string, data?: any, options?: any) {
      console.log('quickChart', type, data);
      const url = `https://quickchart.io/chart?c=${encodeURIComponent(
        JSON.stringify({
          type,
          data,
          options: {
            plugins: {
              roundedBars: {
                cornerRadius: 4,
              },
            },
            legend: {
              display: false,
            },
            ...options,
          },
        })
      )}&${fragment}`;
      console.log('quickChart', url);

      return url;
    },
    [fragment]
  );
}
