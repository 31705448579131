import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import useActiveController from '../../hook/useActiveController';
import { formatSize } from '../../utils';

import type { AssetItem } from '../../modal';

import { createContext } from 'react';
import styles from './styles.module.css';

import Button from '../Button';

import { ReactComponent as PencilIcon } from '../../assets/icons/pencilIcon.svg';
import { ReactComponent as CogIcon } from '../../assets/icons/cogIcon.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/assetIcon.svg';

type ExpandableItemContextType = {
  activeItemId: string | '';
  setActiveItemId: (id: string | '') => void;
  focusedItemId: string | '';
  setFocusedItemId: (id: string | '') => void;
};

const ExpandableItemContext = createContext<ExpandableItemContextType>({
  activeItemId: '',
  setActiveItemId: () => {},
  focusedItemId: '',
  setFocusedItemId: () => {},
});

export default function ExpandableItemView({
  item,
  className = '',
  showAuthor = false,
  fragment = '/',
  children = null,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  item: AssetItem;
  showAuthor?: boolean;
  fragment: string;
}) {
  const navigate = useNavigate();
  const { activeItemId, setActiveItemId, focusedItemId, setFocusedItemId } =
    useContext<ExpandableItemContextType>(ExpandableItemContext);

  const wrapperActiveController = useActiveController(
    activeItemId === item.id,
    400
  );
  const menuActiveController = useActiveController(
    focusedItemId === item.id,
    250
  );

  return (
    <figure
      className={`${styles.item} ${
        wrapperActiveController.animate && styles.itemActive
      } ${className}`}
      key={item.id}
      onMouseOver={() => setActiveItemId(item.id)}
      {...props}
    >
      <figure
        className={styles.itemIcon}
        style={{ backgroundImage: `url('${item.icon_url}')` }}
      >
        {item.icon_url === '' && <AssetIcon className={styles.defaultIcon} />}
        {!!item.version && (
          <figcaption className={styles.itemSize}>
            {formatSize(item.version.size_in_bytes)}
          </figcaption>
        )}
      </figure>
      <div className={styles.itemDetails}>
        <h3 className={styles.itemTitle}>{item.title}</h3>
        {showAuthor ? (
          <Link
            to={
              !!item.creator
                ? `/exchange/search?creator=${item.creator.id}`
                : '#'
            }
            className={styles.itemAuthor}
          >
            @{!!item.creator ? item.creator.username : 'Unknown'}
          </Link>
        ) : (
          <h4
            className={styles.itemStatus}
            style={{
              color: /*item.is_public*/ item.published ? '#93BD43' : '#A0A0A0',
            }}
          >
            {/*item.is_public*/ item.published ? 'published' : 'private'}
          </h4>
        )}
      </div>
      {wrapperActiveController.view && (
        <>
          <div className={styles.itemBackground} />
          <Link
            to={`${fragment.replace(/\/$/, '')}/${item.id}`}
            className={styles.itemOverlay}
            onMouseLeave={() => setActiveItemId('')}
          />

          {/*<div
            className={styles.itemOverlay}
            onMouseLeave={() => setActiveItemId('')}
            onClick={(e) => {
              if (
                !e.target ||
                !(e.target as HTMLElement).classList.contains(
                  styles.itemOverlay
                )
              )
                return;

              navigate(`${fragment.replace(/\/$/, '')}/${item.id}`);
            }}
          >
            {false && <><figure className={styles.itemActions}>
              <Button
                onClick={() =>
                  navigate(`${fragment.replace(/\/$/, '')}/${item.id}`)
                }
              >
                <PencilIcon />
              </Button>
              <Button
                variant="dark"
                onClick={() =>
                  setFocusedItemId(focusedItemId === item.id ? '' : item.id)
                }
              >
                <CogIcon />
              </Button>
            </figure>
            {menuActiveController.view && (
              <figure
                className={`${styles.itemMenu} ${
                  menuActiveController.animate && styles.itemMenuActive
                }`}
              >
                {children}
              </figure>
              )}</>}
          </div>*/}
        </>
      )}
    </figure>
  );
}

export function ExpandableItemGrid({
  children = null,
  className = '',
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
}) {
  return (
    <div className={`${styles.grid} ${className}`} {...props}>
      {children}
    </div>
  );
}

export function ExpandableItemProvider({
  children = null,
}: {
  children: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
}) {
  const [activeItemId, setActiveItemId] = useState<string | ''>('');
  const [focusedItemId, setFocusedItemId] = useState<string | ''>('');

  useEffect(() => () => setFocusedItemId(''), [activeItemId]);

  return (
    <ExpandableItemContext.Provider
      value={{
        activeItemId,
        setActiveItemId,
        focusedItemId,
        setFocusedItemId,
      }}
    >
      {children}
    </ExpandableItemContext.Provider>
  );
}

export function ExpandableItemMenuButton({
  children = null,
  className = '',
  destructive = false,
  closeMenu = true,
  disabled = false,
  onClick = () => {},
  ...props
}: React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  children?: React.ReactNode | React.ReactNode[] | React.ReactFragment | null;
  destructive?: boolean;
  closeMenu?: boolean;
}) {
  const { setFocusedItemId } = useContext(ExpandableItemContext);

  return (
    <button
      className={`${styles.itemMenuButton} ${
        destructive && styles.itemMenuButtonDestructive
      } ${className}`}
      onClick={(e) => {
        if (disabled) return;
        onClick(e);
        if (closeMenu) setFocusedItemId('');
      }}
      {...props}
    >
      {children}
    </button>
  );
}
export function ExpandableItemMenuDivider() {
  return <figure className={styles.itemMenuDivider} />;
}
