import axios from 'axios';
import { processError } from '../utils';

import type { ResultExtendable } from '../types/common';

export type AccessToken = { id: string; created_at: string; alias: string };
export type AccessTokenExtended = {
  id: string;
  alias: string;
  token: string;
};

export async function createAccessToken(
  password: string,
  alias = ''
): Promise<ResultExtendable<{ data: AccessTokenExtended }, { data: '' }>> {
  try {
    const result = await axios.post('/auth/token', {
      password,
      alias,
    });
    if (result.status !== 200 || result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: '',
      };

    return {
      success: true,
      error: '',
      data: result.data.payload,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: '',
    };
  }
}

export async function getAccessTokens(signal?: AbortSignal): Promise<
  | {
      success: true;
      error: '';
      data: AccessToken[];
    }
  | {
      success: false;
      error: string;
      data: [];
    }
> {
  try {
    const result = await axios.get('/auth/token', {
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: [],
      };

    return {
      success: true,
      error: '',
      data:
        typeof result.data.payload === 'object' && result.data.payload !== null
          ? result.data.payload
          : [],
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: [],
    };
  }
}

export async function deleteAccessToken(
  tokenId: string,
  password: string,
  signal?: AbortSignal
): Promise<ResultExtendable<{ data: null }, { data: null }>> {
  try {
    const result = await axios.delete(`/auth/token`, {
      data: {
        id: tokenId,
        password,
      },
      signal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        data: null,
      };

    return {
      success: true,
      error: '',
      data: null,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      data: null,
    };
  }
}
