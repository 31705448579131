import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import usePageConfig from '../../hook/usePageConfig';
import useSWR from 'swr';
import { useUser } from '../../hook/useUser';

import styles from './console.module.css';
import commonStyles from '../common.module.css';
import {
  getServerLogs,
  sendServerCommand,
  serverLogsFetcher,
} from '../../api/server';
import toast from 'react-hot-toast';
import { copyText, downloadFile, processError } from '../../utils';
import React from 'react';

import Overlay, { OverlayHeader } from '../../Comps/Overlay';
import Loader from '../../Comps/Loader';
import Button from '../../Comps/Button';
import { Toggle } from '../../Comps/Checkbox';

import { ReactComponent as HELIXIcon } from '../../assets/icons/helixIcon.svg';
import { ReactComponent as DownloadIcon } from '../../assets/icons/downloadIcon.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/trashIcon.svg';

const LOGS_POLL_INTERVAL = 1000 * 1;
const SURPRESS_ERRORS = false;
const MAX_LOGS_LINES = 1000;

type State =
  | {
      loading: true;
    }
  | {
      loading: false;
      error: true;
      errorMessage: string;
    }
  | {
      loading: false;
      error: false;
      logs: string[];
    };

enum Color {
  Command = '#03ffd1',
  Subcommand = '#03ff0f',
  Argument = '#ef5ddb',
  Argument2 = '#a864e5',
}
enum Span {
  Single = 0,
  All = 1,
}

type SubCommand = {
  description: string;
  example: string;
  usage: string;
  arguments: Argument[];
};

type Command = SubCommand & {
  subcommands: Record<string, SubCommand>;
};

type Argument = {
  span: Span;
  color: Color;
};

const commands: Record<string, Command> = {
  chat: {
    description: 'Sends a chat message to everyone',
    example: 'chat The server is shutting down in 15 minutes',
    usage: 'chat <message>',
    subcommands: {},
    arguments: [
      {
        span: Span.All,
        color: Color.Argument2,
      },
    ],
  },
  kick: {
    description: 'Kicks a player',
    example: 'kick myer scamming others',
    usage: 'kick <player_id> <reason?>',
    subcommands: {},
    arguments: [
      {
        span: Span.Single,
        color: Color.Argument,
      },
      { span: Span.All, color: Color.Argument2 },
    ],
  },
  map: {
    description:
      'Changes the map, restart all packages and reconnects all players',
    example: 'map abandoned-warehouse',
    usage: 'map <map_path>',
    subcommands: {},
    arguments: [{ span: Span.All, color: Color.Argument }],
  },
  package: {
    description: 'Does nothing without subcommands',
    example: 'package reload all',
    usage: 'package <subcommand>',
    arguments: [],
    subcommands: {
      hotreload: {
        description: 'Hotreloads one or more packages',
        usage: 'package hotreload <package_name_01> <package_name_02...>',
        example: 'package hotreload package1 package2 package3',
        arguments: [
          {
            span: Span.All,
            color: Color.Argument,
          },
        ],
      },
      load: {
        description: 'Loads one or more packages',
        usage: 'package load <package_name_01> <package_name_02...>',
        example: 'package load package1 package2 package3',
        arguments: [
          {
            span: Span.All,
            color: Color.Argument,
          },
        ],
      },
      'reload all': {
        description:
          'Reloads all packages and restarts the Lua Virtual Machine',
        usage: 'package reload all',
        example: 'package reload all',
        arguments: [],
      },
      reload: {
        description: 'Reloads one or more packages',
        usage: 'package reload <package_name_01> <package_name_02...>',
        example: 'package reload package1 package2 package3',
        arguments: [
          {
            span: Span.All,
            color: Color.Argument,
          },
        ],
      },

      run: {
        description: 'Executes a lua code into a package',
        usage: 'package run <package_name> <lua_code>',
        example: 'package run package1 testing_mode=true',
        arguments: [
          {
            span: Span.Single,
            color: Color.Argument,
          },
          {
            span: Span.All,
            color: Color.Argument2,
          },
        ],
      },
      unload: {
        description: 'Unloads one or more packages',
        usage: 'package unload <package_name_01> <package_name_02...>',
        example: 'package unload package1 package2 package3',
        arguments: [
          {
            span: Span.All,
            color: Color.Argument,
          },
        ],
      },
    },
  },
  players: {
    description: 'Lists all players connected',
    example: 'players',
    usage: 'players',
    subcommands: {},
    arguments: [],
  },
  restart: {
    description: 'Force restarts all packages and reconnects all players',
    example: 'restart',
    usage: 'restart',
    subcommands: {},
    arguments: [],
  },
  stop: {
    description: 'Stops the server (un)gracefully',
    example: 'stop',
    usage: 'stop',
    subcommands: {},
    arguments: [],
  },
};

export default function ServerConsole() {
  const navigate = useNavigate();
  const { ip, port } = useParams<{ ip: string; port: string }>();

  const [state, setState] = useState<State>({
    loading: true,
  });

  const { isLoading, isLoggedIn } = useUser();

  useEffect(() => {
    if (isLoading || !isLoggedIn) return;
    if (
      typeof ip !== 'string' ||
      ip === '' ||
      typeof port !== 'string' ||
      port === '' ||
      isNaN(Number(port))
    )
      return setState({
        loading: false,
        error: true,
        errorMessage: 'Invalid IP or Port',
      });

    const abortController = new AbortController();

    getServerLogs(ip, Number(port), abortController.signal)
      .then((result) => {
        if (!result.success)
          return setState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });

        setState({
          loading: false,
          error: false,
          logs: result.logs,
        });
      })
      .catch((e) => console.error(e));

    return () => abortController.abort();
  }, [isLoading, isLoggedIn, ip, port]);

  usePageConfig({
    title: state.loading ? 'Loading' : `HELIX Console - ${ip}:${port}`,
  });

  return (
    <Overlay>
      <OverlayHeader
        closeable
        onClose={() => {
          try {
            window.close();
            window.location.href = 'https://www.amazon.com/dp/B0BTZX18SH';
          } catch (e) {}
        }}
      ></OverlayHeader>
      <div
        className={`${commonStyles.splitRoot} ${
          state.loading && commonStyles.splitLoading
        } ${!state.loading && state.error && commonStyles.splitError} ${
          styles.wrapper
        }`}
      >
        {state.loading && <Loader scale={1.5} />}
        {!state.loading && state.error && (
          <div className={commonStyles.splitErrorMessage}>
            {state.errorMessage}
          </div>
        )}
        {!state.loading && !state.error && (
          <Console
            ip={ip as string}
            port={Number(port)}
            initialLogs={state.logs}
          />
        )}
      </div>
    </Overlay>
  );
}

function Console({
  ip,
  port,
  initialLogs,
}: {
  ip: string;
  port: number;
  initialLogs: string[];
}) {
  const { data: logUpdate, error } = useSWR(
    `/server/log/stdout?ip=${ip}&port=${port}`,
    serverLogsFetcher.bind(null, SURPRESS_ERRORS),
    {
      refreshInterval: LOGS_POLL_INTERVAL,
      errorRetryInterval: LOGS_POLL_INTERVAL,
      errorRetryCount: Infinity,
    }
  );
  const [logs, setLogs] = useState(initialLogs);
  useEffect(() => {
    if (!logUpdate || error || logUpdate.length === 0) return;

    setLogs((prev) => {
      if (prev.length + logUpdate.length <= MAX_LOGS_LINES)
        return [...prev, ...logUpdate];

      if (logUpdate.length >= MAX_LOGS_LINES)
        return logUpdate.slice(-1 * MAX_LOGS_LINES);

      return [
        ...prev.slice(-1 * (MAX_LOGS_LINES - logUpdate.length)),
        ...logUpdate,
      ];
    });
  }, [logUpdate]);

  useEffect(() => {
    if (logs.length === 0) return;

    if (!scrollPaused && consoleRef.current) {
      console.log(`Scrolling by ${consoleRef.current.scrollHeight}`);
      consoleRef.current.scrollTo({
        top: consoleRef.current.scrollHeight,
      });
    }
  }, [logs]);

  useEffect(() => {
    if (!error) return;
    console.error('Error fetching logs:', error);
    if (SURPRESS_ERRORS) return;
    toast.error(processError(error));
  }, [error]);

  const consoleRef = useRef<HTMLDivElement>(null);
  const [scrollPaused, setScrollPaused] = useState(false);

  const [inputText, setInputText] = useState('');
  const [inputFocused, setInputFocused] = useState(false);
  enum Level {
    None = 0,
    Command = 1,
    Subcommand = 2,
    Argument = 3,
  }

  type InputArgument = {
    value: string;
    color: Color;
  };
  type InputState =
    | {
        recognized: false;
        level: Level.None;
      }
    | {
        level: Level.Command;
        recognized: true;
        command: string;
        description: string;
        usage: string;
        example: string;
        extra: string;
        arguments: InputArgument[];
      }
    | {
        level: Level.Subcommand;
        recognized: true;
        command: string;
        subcommand: string;
        description: string;
        usage: string;
        example: string;
        arguments: InputArgument[];
        extra: string;
      };
  const [inputState, setInputState] = useState<InputState>({
    recognized: false,
    level: Level.None,
  });

  useEffect(() => {
    const i = setTimeout(() => {
      if (inputText.trim().length === 0)
        return setInputState({
          recognized: false,
          level: Level.None,
        });

      if (
        !Object.keys(commands).some((commandName) =>
          inputText.startsWith(commandName)
        )
      )
        return setInputState({
          recognized: false,
          level: Level.None,
        });

      const [commandName, command] = Object.entries(commands).find(
        ([_commandName]) => inputText.startsWith(_commandName)
      ) as [string, Command];

      let extra = inputText.replace(commandName, '');

      const subcommandMatch =
        Object.keys(command.subcommands).length !== 0 &&
        Object.keys(command.subcommands).some((subcommandName) =>
          extra.startsWith(` ${subcommandName}`)
        );
      const commandHasArguments = command.arguments.length > 0;

      if (
        extra.trim() === '' ||
        (!subcommandMatch && (extra[0] !== ' ' || !commandHasArguments))
      )
        return setInputState({
          recognized: true,
          level: Level.Command,
          description: command.description,
          usage: command.usage,
          example: command.example,
          command: commandName,
          arguments: [],
          extra,
        });

      const commandArguments: InputArgument[] = [];

      if (!subcommandMatch) {
        for (const argument of command.arguments) {
          if (extra.replace(/^ /, '').length === 0) break;
          if (argument.span === Span.Single) {
            commandArguments.push({
              value: extra.replace(/^ /, '').split(/ /)[0],
              color: argument.color,
            });
            extra = extra.replace(/^ /, '').split(/ /).slice(1).join(' ');
          }
          if (argument.span === Span.All) {
            commandArguments.push({
              value: extra.replace(/^ /, ''),
              color: argument.color,
            });
            extra = '';
          }
        }

        return setInputState({
          recognized: true,
          level: Level.Command,
          description: command.description,
          usage: command.usage,
          example: command.example,
          command: commandName,
          arguments: commandArguments,
          extra,
        });
      }

      const [subcommandName, subcommand] = Object.entries(
        command.subcommands
      ).find(([_subcommandName]) =>
        extra.startsWith(` ${_subcommandName}`)
      ) as [string, SubCommand];

      extra = extra.replace(` ${subcommandName}`, '');

      if (subcommand.arguments.length === 0)
        return setInputState({
          level: Level.Subcommand,
          recognized: true,
          description: subcommand.description,
          usage: subcommand.usage,
          example: subcommand.example,
          command: commandName,
          subcommand: subcommandName,
          arguments: [],
          extra,
        });

      for (const argument of subcommand.arguments) {
        if (extra.replace(/^ /, '').length === 0) break;
        if (argument.span === Span.Single) {
          commandArguments.push({
            value: extra.replace(/^ /, '').split(/ /)[0],
            color: argument.color,
          });
          extra = extra.replace(/^ /, '').split(/ /).slice(1).join(' ');
        }
        if (argument.span === Span.All) {
          commandArguments.push({
            value: extra.replace(/^ /, ''),
            color: argument.color,
          });
          extra = '';
        }
      }

      return setInputState({
        level: Level.Subcommand,
        recognized: true,
        description: subcommand.description,
        usage: subcommand.usage,
        example: subcommand.example,
        command: commandName,
        subcommand: subcommandName,
        arguments: commandArguments,
        extra,
      });
    }, 20);
    return () => clearTimeout(i);
  }, [inputText]);
  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <HELIXIcon />
        <span>/</span>
        <div
          onClick={() =>
            copyText(`${ip}:${port}`).then(() =>
              toast.success('Copied to clipboard')
            )
          }
        >
          {ip}
          <b>:{port}</b>
        </div>
        <figure className={styles.headerTools}>
          <figure className={commonStyles.toggleGroup}>
            <Toggle
              checked={!scrollPaused}
              onClick={() => setScrollPaused(!scrollPaused)}
            />
            <figcaption
              className={commonStyles.toggleGroupLabel}
              style={{ color: '#A0A0A0' }}
            >
              Auto-Scroll
            </figcaption>
          </figure>
          <Button
            className={styles.headerButton}
            variant="tomato"
            onClick={() => setLogs([])}
          >
            <TrashIcon />
          </Button>
          <Button
            className={styles.headerButton}
            variant="yellow"
            onClick={() => {
              try {
                downloadFile(logs.join('\n'), 'logs.txt');
                toast.success('Downloaded logs');
              } catch (e) {
                toast.error('Failed to download logs');
              }
            }}
          >
            <DownloadIcon /> download logs
          </Button>
        </figure>
      </header>
      <div
        className={styles.console}
        ref={consoleRef}
        onScroll={(e) => {
          //setScrollPaused(true);
          //console.log(e.target);
        }}
      >
        {!logs
          ? null
          : logs.map((log, i) => {
              let line = log;

              let level = '';
              if (line.match(/^\[[0-9\-: ]{19}\] \[[a-z]+\] /)) {
                level = line
                  .replace(/^\[[0-9\-: ]{19}\] \[([a-z]+)\] .*/, '$1')
                  .toLowerCase()
                  .trim();
                line = line
                  .replace(/^(\[[0-9\-: ]{19}\] )\[[a-z]+\] /, '$1')
                  .trim();
              }

              //console.log({ level, line });

              return (
                <div
                  className={`${styles.line} ${
                    level === 'error' && styles.lineError
                  } ${level === 'warning' && styles.lineWarning} ${
                    level === 'debug' && styles.lineDebug
                  } ${level === 'display' && styles.lineInfo}`}
                  key={i}
                >
                  {line}
                </div>
              );
            })}
      </div>
      <form
        className={styles.input}
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (inputText.trim() === '') return;
          sendServerCommand(ip, port, inputText);
          setInputText('');
        }}
      >
        <div className={styles.spacer}>
          {true ? '' : '[2023-09-29 16:40:3]'}
        </div>
        <div className={styles.caret}>$</div>
        <div className={styles.spacer}>_</div>
        <div className={styles.inputContainer}>
          <input
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="package reload all"
            style={{
              opacity: inputText === '' ? 1 : 0,
            }}
            autoCapitalize="off"
            autoCorrect="off"
            autoComplete="off"
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
          />
          <div className={styles.shadow}>
            {inputState.recognized && (
              <figure className={styles.modal}>
                <figcaption className={styles.modalUsage}>
                  {inputState.usage}
                </figcaption>
                <div className={styles.modalBody}>
                  {inputState.description}
                  <figure className={styles.modalExample}>
                    <h5>example</h5>
                    <figcaption>{inputState.example}</figcaption>
                  </figure>
                </div>
              </figure>
            )}
            <pre className={styles.coloring}>
              {!inputState.recognized ? (
                inputText.trim() === '' ? (
                  new Array(inputText.length)
                    .fill('')
                    .map((_, i) => <span className={styles.spacer} key={i} />)
                ) : (
                  inputText
                )
              ) : (
                <>
                  <span style={{ color: Color.Command }}>
                    {inputState.command}
                  </span>
                  {inputState.level === Level.Subcommand && (
                    <span style={{ color: Color.Subcommand }}>
                      {inputState.subcommand}
                    </span>
                  )}
                  {inputState.arguments.map((argument, i) => (
                    <span key={i} style={{ color: argument.color }}>
                      {argument.value}
                    </span>
                  ))}
                  {inputState.extra
                    .split('')
                    .map((char, i, arr) =>
                      char.trim() === '' ? (
                        char.trim() === '' &&
                        typeof arr[i + 1] === 'string' &&
                        arr[i + 1].trim() !== '' ? null : (
                          <span className={styles.spacer} key={i} />
                        )
                      ) : (
                        <React.Fragment key={i}>{char}</React.Fragment>
                      )
                    )}
                </>
              )}
              <span
                className={styles.cursor}
                style={{
                  marginLeft: inputText.trim() === '' ? 0 : -8,
                  animation: inputFocused ? undefined : 'none',
                }}
              >
                _
              </span>
            </pre>
          </div>
        </div>
      </form>
    </div>
  );
}
