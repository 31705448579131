/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ResultExtendable } from '../types/common';
import { processError } from '../utils';
import axios from 'axios';

export type User = {
  username: string;
  icon_url: string;
};

export async function getUser(
  userId: string,
  signal: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
      data: null;
      exists: false;
    }
  | {
      success: true;
      error: '';
      data: User;
      exists: true;
    }
  | {
      success: true;
      error: '';
      data: null;
      exists: false;
    }
> {
  try {
    const result = await axios.get(`/user/${userId}`, {
      signal,
    });

    if (result.data.code !== 0) {
      if (result.data.code === 1500)
        return {
          success: true,
          error: '',
          data: null,
          exists: false,
        };

      return {
        success: false,
        error: processError(result.data),
        data: null,
        exists: false,
      };
    }

    return {
      success: true,
      error: '',
      data: result.data.payload,
      exists: true,
    };
  } catch (e: any) {
    if (e?.response?.status === 404 || e?.response?.status === 400)
      return {
        success: true,
        error: '',
        data: null,
        exists: false,
      };

    return {
      success: false,
      error: processError(e),
      data: null,
      exists: false,
    };
  }
}

export async function uploadUserAvatar(
  file: File,
  signal?: AbortSignal
): Promise<
  | { success: true; error: '' }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const formData = new FormData();
    formData.append('icon', file);

    const result = await axios.post('/user/upload-icon', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      signal,
    });

    if (result.data.code !== 0) {
      return {
        success: false,
        error: processError(result.data),
      };
    }

    return {
      success: true,
      error: '',
    };
  } catch (e: any) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function claimGameKey(signal?: AbortSignal): Promise<
  | { success: true; error: ''; key: string }
  | {
      success: false;
      error: string;
      key: '';
    }
> {
  try {
    const result = await axios.get('/user/game-key', {
      signal,
    });

    if (result.data.code !== 0) {
      return {
        success: false,
        error: processError(result.data),
        key: '',
      };
    }

    return {
      success: true,
      error: '',
      key: result.data.payload.key,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      key: '',
    };
  }
}

export async function checkEmailValid(
  email: string,
  abortSignal?: AbortSignal
): Promise<
  | { success: true; error: ''; registered: boolean; valid: true }
  | {
      success: false;
      error: string;
      registered: false;
      valid: false;
    }
> {
  try {
    const result = await axios.get(`/user/email/${encodeURIComponent(email)}`, {
      signal: abortSignal,
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        registered: false,
        valid: false,
      };

    return {
      success: true,
      error: '',
      registered: result.data.payload ?? false,
      valid: true,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      registered: false,
      valid: false,
    };
  }
}

export async function checkUsernameValid(
  username: string,
  abortSignal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      taken: boolean;
      valid: true;
    }
  | {
      success: false;
      error: string;
      taken: false;
      valid: false;
    }
> {
  try {
    const result = await axios.get(
      `/user/username/${encodeURIComponent(username)}`,
      {
        signal: abortSignal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        taken: false,
        valid: false,
      };

    return {
      success: true,
      error: '',
      taken: result.data.payload ?? false,
      valid: true,
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      taken: false,
      valid: false,
    };
  }
}

export async function createAccountWithInvite(
  {
    username,
    email,
    password,
    code,
  }: {
    username: string;
    email: string;
    password: string;
    code: string;
  },
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.post(
      '/user/',
      {
        username,
        email,
        password,
        code,
        username_id: '0000',
      },
      {
        signal,
      }
    );

    if (result.data.code !== 0) {
      return {
        success: false,
        error: processError(result.data),
      };
    }

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
export async function recoverPassword(
  key: string,
  password: string,
  abortSignal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.post(
      '/user/reset-password',
      {
        key,
        password,
      },
      {
        signal: abortSignal,
      }
    );
    if (result.status === 200)
      return {
        success: true,
        error: '',
      };

    return {
      success: false,
      error: processError(result.data),
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function sendPasswordResetEmail(
  email: string,
  abortSignal?: AbortSignal
): Promise<
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      error: '';
    }
> {
  try {
    const result = await axios.post(
      '/user/forgot-password',
      {
        email,
      },
      {
        signal: abortSignal,
      }
    );
    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function sendEmailChangeConfirmation(
  {
    newEmail,
    currentPassword,
  }: {
    newEmail: string;
    currentPassword: string;
  },
  abortSignal?: AbortSignal
): Promise<{ success: true; error: '' } | { success: false; error: string }> {
  try {
    const result = await axios.put(
      '/user/email',
      {
        email: newEmail,
        password: currentPassword,
      },
      {
        signal: abortSignal,
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
export async function updateUser(
  update: Partial<{
    username: string;
  }>,
  abortSignal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.patch('/user/', update, {
      signal: abortSignal,
    });

    if (result.status === 200)
      return {
        success: true,
        error: '',
      };

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function changeUserPassword(
  {
    currentPassword = '',
    newPassword = '',
  }: {
    currentPassword: string;
    newPassword: string;
  },
  abortSignal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.post(
      'user/change-password',
      {
        current_password: currentPassword,
        new_password: newPassword,
      },
      {
        signal: abortSignal,
      }
    );

    if (result.status === 200)
      return {
        success: true,
        error: '',
      };

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
export async function resendEmailVerification(signal?: AbortSignal): Promise<
  | {
      success: false;
      error: string;
    }
  | {
      success: true;
      error: '';
    }
> {
  try {
    const result = await axios.post(
      '/user/resend-email-verification',
      {},
      {
        signal,
      }
    );

    if (result.status === 200)
      return {
        success: true,
        error: '',
      };

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
export async function verifyUserEmail(
  key: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.get('/user/verify', {
      signal,
      params: {
        key,
      },
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    });

    if (result.status === 200)
      return {
        success: true,
        error: '',
      };

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
export async function confirmEmailChange(
  key: string,
  newEmail: string
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: '';
    }
> {
  try {
    const result = await axios.get('/user/confirm', {
      params: {
        key,
        email: newEmail,
      },
      transformRequest: (data, headers) => {
        delete headers.Authorization;
        return data;
      },
    });

    if (result.data.code === 0)
      return {
        success: true,
        error: '',
      };

    return {
      success: false,
      error: processError(result.data),
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function updateUserProfile(
  update: Partial<{
    bio: string;
    country: string;
  }>,
  abortSignal?: AbortSignal
): Promise<ResultExtendable> {
  try {
    const result = await axios.patch('/user', update, {
      signal: abortSignal,
    });

    if (result.status === 200 || result.data.code === 0)
      return {
        success: true,
        error: '',
      };

    return {
      success: false,
      error: processError(result.data),
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}
