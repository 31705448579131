const youtubeIdRegex = /^[a-zA-Z0-9-_]{11}$/;
export const validateYoutubeId = (id: string): boolean =>
  youtubeIdRegex.test(id.trim());

const validYoutubeQueryDomains = new Set([
  'youtube.com',
  'www.youtube.com',
  'm.youtube.com',
  'music.youtube.com',
  'gaming.youtube.com',
]);
const validYoutubePathDomains =
  /^https?:\/\/(youtu\.be\/|(www\.)?youtube\.com\/(embed|v|shorts)\/)/;

export const getYoutubeIdFromLink = (link: string): string => {
  const parsed = new URL(link.trim());
  let id = parsed.searchParams.get('v');
  if (validYoutubePathDomains.test(link.trim()) && !id) {
    const paths = parsed.pathname.split('/');
    id = parsed.host === 'youtu.be' ? paths[1] : paths[2];
  } else if (
    parsed.hostname &&
    !validYoutubeQueryDomains.has(parsed.hostname)
  ) {
    throw Error('Not a YouTube domain');
  }
  if (!id) {
    throw Error(`No video id found: "${link}"`);
  }
  id = id.substring(0, 11);
  if (!validateYoutubeId(id)) {
    throw TypeError(
      `Video id (${id}) does not match expected ` +
        `format (${youtubeIdRegex.toString()})`
    );
  }
  return id;
};

const urlRegex = /^https?:\/\//;
export const getYoutubeVideoId = (str: string): string => {
  if (validateYoutubeId(str)) {
    return str;
  } else if (urlRegex.test(str.trim())) {
    return getYoutubeIdFromLink(str);
  } else {
    throw Error(`No video id found: ${str}`);
  }
};

export const validateYoutubeURL = (string: string): boolean => {
  try {
    getYoutubeVideoId(string);
    return true;
  } catch (e) {
    return false;
  }
};
