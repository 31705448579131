import { useOutletContext } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import useActiveController from '../../../../hook/useActiveController';
import useDeepState from '../../../../hook/useDeepState';
import useTaskQueue from '../../../../hook/useTaskQueue';

import type { OutletContext } from '.';
import type { AssetItem, AssetVersion } from '../../../../modal';

import commonStyles from '../../../common.module.css';
import { toast } from 'react-hot-toast';
import { selectFile, processError, formatSize } from '../../../../utils';
import axios from 'axios';
import styles from './config.module.css';
import { createPortal } from 'react-dom';
import uploadAsset from '../../../../api/uploadAsset';

import Input, { TextArea } from '../../../../Comps/Input';
import Button from '../../../../Comps/Button';
import { Toggle } from '../../../../Comps/Checkbox';
import Select from '../../../../Comps/Select';
import TagInput from '../../../../Comps/TagInput';
import Modal from '../../../../Comps/Modal';

import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../../../assets/icons/hamburgerIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtubeIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as PackageIcon } from '../../../../assets/icons/packageIcon.svg';
import Loader from '../../../../Comps/Loader';

export default function CreationsAssetsAssetManagerConfigPage() {
  const {
    asset,
    getField,
    setUpdateField,
    savingData,
    fetchAsset,
    setSavingData,
  } = useOutletContext<OutletContext>();
  useEffect(() => {
    console.log(asset.depends_on, getField<string[]>('depends_on', []));
  }, [asset.depends_on]);
  const { getDeepState, setDeepState, deleteDeepState } = useDeepState();

  const [filePickerOpen, setFilePickerOpen] = useState(false);
  const filePickerRef = useRef<boolean>(false);
  useEffect(() => {
    filePickerRef.current = filePickerOpen;
  }, [filePickerOpen]);
  useEffect(
    () => () => {
      if (!filePickerRef.current) return;
      setFilePickerOpen(false);
    },
    []
  );
  useEffect(() => {
    window.addEventListener('dragover', (e) => {
      e.preventDefault();
      if (!filePickerRef.current) return;
      setBeaconActive(true);
    });
    window.addEventListener('dragleave', (e) => {
      e.preventDefault();
      setBeaconActive(false);
    });
    window.addEventListener('drop', (e) => {
      e.preventDefault();
      setBeaconActive(false);
      if (!e?.dataTransfer?.files) return;
      if (!filePickerRef.current) return;

      handleUpload(e.dataTransfer.files[0]);
    });
  }, []);
  const [beaconActive, setBeaconActive] = useState(false);
  const beaconController = useActiveController(beaconActive, 0.07 * 1000);

  const { addTask } = useTaskQueue();

  const handleUpload = async (file: File | null | undefined) => {
    if (!file) return;
    /*if (file.type !== 'application/zip')
      return toast.error('Unsupported file type');*/
    setSavingData(true);

    try {
      const uploadRequest = await axios.get(
        `asset/multipart/start/${asset.id}`
      );

      if (uploadRequest.status !== 200) {
        setSavingData(false);
        return toast.error(
          `Failed to start upload: ${processError(uploadRequest)}`
        );
      }
      setFilePickerOpen(false);
      setSavingData(false);
      toast.success('Upload started');
      setDeepState(`config.${asset.id}.uploading`, true);

      const abortController = new AbortController();

      addTask(
        `Uploading ${asset.name}.zip`,
        (setProgress) =>
          uploadAsset(
            asset.id,
            asset.name,
            file,
            uploadRequest.data.payload,
            setProgress,
            abortController.signal
          ),
        () => {
          deleteDeepState(`config.${asset.id}.uploading`);
          fetchAsset();
        },
        () => abortController.abort()
      );
    } catch (e) {
      setSavingData(false);
      return toast.error(`Failed to start upload: ${processError(e)}`);
    }
  };

  return (
    <div className={`${commonStyles.splitContentWrapper}`}>
      {beaconController.view &&
        createPortal(
          <div
            className={`${styles.beacon} ${
              beaconController.animate && styles.beaconActive
            }`}
          />,
          document.body
        )}
      <Modal
        active={filePickerOpen}
        actions={[]}
        title=""
        description=""
        closeable
        escapable
        className={styles.filePickerModal}
        onClose={() => setFilePickerOpen(false)}
      >
        <figure
          className={`${styles.dropZone} ${
            beaconController.animate && styles.dropZoneActive
          }`}
        >
          <figcaption className={styles.dropZoneTitle}>
            Drag & drop a file here
          </figcaption>
          <figure className={styles.dropZoneDivider} />
          <Button
            style={{ width: 'fit-content' }}
            variant="invisible"
            onClick={() => {
              if (savingData) return;

              selectFile('*').then((file) => handleUpload(file));
            }}
            disabled={savingData}
          >
            <UploadIcon /> select a file
          </Button>
        </figure>
      </Modal>
      <div className={commonStyles.splitContentWrapperLimit}>
        <h1 className={commonStyles.splitContentTitle}>Config</h1>
        <figure
          className={commonStyles.splitContentDivider}
          style={{ marginTop: 24 }}
        />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Dependencies
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Asset ID of dependent assets
            </figcaption>
            <TagInput
              value={getField<string[]>('depends_on', []) ?? []}
              className={commonStyles.formGroupInput}
              disabled={savingData}
              onChange={(newValue) => setUpdateField('depends_on', newValue)}
              sanitizeTag={(tag) => tag.toLowerCase().replace(/ /g, '-')}
              theme="blue"
              placeholder="Enter an asset"
              searchSuggestions='assets'
            />
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -22 }}
          >
            Depot
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Asset / package
            </figcaption>

            {!!asset.version && (
              <AssetVersionView version={asset.version} asset={asset} />
            )}

            <Button
              style={{ width: 'fit-content' }}
              disabled={
                savingData ||
                getDeepState<boolean>(`config.${asset.id}.uploading`)
              }
              onClick={async () => {
                if (
                  savingData ||
                  getDeepState<boolean>(`config.${asset.id}.uploading`)
                )
                  return;
                setFilePickerOpen(true);
              }}
            >
              <UploadIcon />
              {!asset.version ? 'upload' : 're-upload'}
            </Button>
          </figure>
        </section>
      </div>
    </div>
  );
}

const STATUS_COLOR: Record<string, [string, string]> = {
  UNKNOWN: ['#EDEDED', '#EDEDED'], // gray
  VALID: ['#99D52A', '#1C1500'], // lime
  INVALID: ['#E54D2E', '#FEEFEC'], // tomato
  PENDING: ['#68DDFD', '#0C1820'], // sky
  VALIDATING: ['#F5D90A', '#1C1500'], // yellow
};

function AssetVersionView({
  version,
  asset,
}: {
  version: AssetVersion;
  asset: AssetItem;
}) {
  return (
    <>
      <figure
        className={styles.versionWrapper}
        style={
          {
            '--primary': (STATUS_COLOR[version.status] ??
              STATUS_COLOR.UNKNOWN)[0],
            '--secondary': (STATUS_COLOR[version.status] ??
              STATUS_COLOR.UNKNOWN)[1],
          } as React.CSSProperties
        }
      >
        <figure className={`${commonStyles.rowFull} ${styles.versionRoot}`}>
          <figure className={styles.versionInfo}>
            <picture className={styles.versionInfoIcon}>
              <PackageIcon />
            </picture>
            <figure className={styles.versionInfoText}>
              <h4 className={styles.versionInfoTitle}>{asset.name}.zip</h4>
              <figcaption className={styles.versionInfoSubtitle}>
                {[
                  formatSize(version.size_in_bytes),
                  !version.toml_version || version.toml_version === ''
                    ? null
                    : `v${version.toml_version}`,
                  !version.updated_at || version.updated_at === ''
                    ? null
                    : `${new Date(
                        version.updated_at
                      ).toLocaleDateString()} · ${new Date(
                        version.updated_at
                      ).toLocaleTimeString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}`,
                ]
                  .filter((e) => e !== null)
                  .join(' · ')}
              </figcaption>
            </figure>
          </figure>
          <figure className={styles.versionText}>
            <span className={styles.versionStatus}>{version.status}</span>
          </figure>
        </figure>
        <figure className={styles.versionStripe} />
      </figure>
      {version.status === 'INVALID' && (
        <figure className={styles.versionError}>
          {version.reason || 'Unknown error validating asset'}
        </figure>
      )}
    </>
  );
}
