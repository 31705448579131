import { useEffect, useState, useRef, useMemo } from 'react';

import styles from './styles.module.css';
import themes from './themes.module.css';

import { randomString } from '../../utils';
import { getAssetSearchSuggestions } from '../../api/asset';

import type { StateExtendable } from '../../types/common';
import type { AssetSearchSuggestion } from '../../api/asset';

import { ReactComponent as CrossIcon } from '../../assets/icons/crossIcon.svg';
import { ReactComponent as AssetIcon } from '../../assets/icons/assetIcon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/checkIcon.svg';

enum POSITION {
  TOP = 0,
  BOTTOM = 1,
}

export default function TagInput({
  disabled = false,
  value = [],
  onChange = () => {},
  className = '',
  theme = '',
  maxTagLength = -1,
  minTagLength = 1,
  maxTags = -1,
  placeholder = 'Enter a tag',
  validateTag = () => true,
  addTagOnBlur = true,
  canDeleteTags = true,
  canAddTags = true,
  uniqueTags = true,
  sanitizeTag = (tag: string) => tag,
  searchSuggestions = 'off',
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
  disabled?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  className?: string;
  theme?: string;
  maxTagLength?: number | -1;
  minTagLength?: number | 1;
  maxTags?: number | -1;
  placeholder?: string;
  validateTag?: (tag: string) => boolean;
  addTagOnBlur?: boolean;
  canDeleteTags?: boolean;
  canAddTags?: boolean;
  uniqueTags?: boolean;
  sanitizeTag?: (tag: string) => string;
  searchSuggestions?: 'assets' | 'off';
}) {
  const themeClassname = themes[theme] || '';

  const [inputValue, setInputValue] = useState('');

  const addTag = (tag: string) => {
    if (maxTags !== -1 && value.length >= maxTags) return;

    if (tag.length === 0 || tag.length < minTagLength) return;

    if (maxTagLength !== -1 && tag.length > maxTagLength) return;

    const sanitizedValue = sanitizeTag(tag);

    if (!validateTag(sanitizedValue)) return;

    if (uniqueTags && value.includes(sanitizedValue)) return;

    onChange([...value, sanitizedValue]);
    return setInputValue('');
  };

  const [modalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    if (disabled) setModalOpen(false);
  }, [disabled]);

  const uniqueID = useMemo(() => randomString(16), []);
  useEffect(() => {
    if (searchSuggestions === 'off') return;
    const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!e || !e.target) return;

      if (!(e.target as any).closest(`#toggle_${uniqueID}`))
        setModalOpen(false);
    };

    document.addEventListener('click', onClick as any);

    return () => document.removeEventListener('click', onClick as any);
  }, [uniqueID, searchSuggestions]);

  const ref = useRef<HTMLDivElement | null>(null);
  const [modalPosition, setModalPosition] = useState<POSITION>(POSITION.BOTTOM);
  const [modalMaxHeight, setModalMaxHeight] = useState(window.innerHeight / 2);

  useEffect(() => {
    if (!ref.current || searchSuggestions === 'off') return;

    const boundingBox = ref.current.getBoundingClientRect();
    if (boundingBox.top + boundingBox.height / 2 > window.innerHeight / 2) {
      setModalPosition(POSITION.TOP);
      setModalMaxHeight(boundingBox.top - 16);
    } else {
      setModalPosition(POSITION.BOTTOM);
      setModalMaxHeight(window.innerHeight - boundingBox.bottom - 16);
    }
  }, [ref.current, searchSuggestions]);

  useEffect(() => {
    if (!ref.current || !modalOpen) return;

    const boundingBox = ref.current.getBoundingClientRect();
    if (boundingBox.top + boundingBox.height / 2 > window.innerHeight / 2) {
      setModalPosition(POSITION.TOP);
      setModalMaxHeight(boundingBox.top - 16 - 72);
    } else {
      setModalPosition(POSITION.BOTTOM);
      setModalMaxHeight(window.innerHeight - boundingBox.bottom - 16);
    }
  }, [ref.current, modalOpen]);

  const [searchSuggestionsState, setSearchSuggestionsState] = useState<
    StateExtendable<{ items: AssetSearchSuggestion[]; type: 'asset' }>
  >({ loading: true });
  useEffect(() => {
    if (searchSuggestions === 'off' || disabled || inputValue === '')
      return setSearchSuggestionsState({ loading: true });
    if (!modalOpen) return;

    const i = setTimeout(() => {
      setSearchSuggestionsState({ loading: true });
      getAssetSearchSuggestions(inputValue).then((result) => {
        if (!result.success)
          return setSearchSuggestionsState({
            loading: false,
            error: true,
            errorMessage: result.error,
          });
        setSearchSuggestionsState({
          loading: false,
          error: false,
          items: result.data,
          type: 'asset',
        });
      });
    }, 200);

    return () => clearTimeout(i);
  }, [inputValue]);

  const modalReadOnly = useMemo(() => {
    if (disabled) return true;
    if (inputValue === '') return true;
    if (maxTags !== -1 && value.length >= maxTags) return true;
    if (!canAddTags) return true;
    return false;
  }, [disabled, inputValue, maxTags, value, canAddTags]);

  return (
    <figure
      className={`${styles.root} ${
        disabled && styles.rootDisabled
      } ${themeClassname} ${className}`}
      data-role="root"
      {...props}
    >
      {value.length === 0 && (!canAddTags || disabled) && (
        <div style={{ height: 24, display: 'block' }} />
      )}
      {value.map((tagValue, i) => (
        <figure className={styles.tag} data-role="tag" key={i}>
          <figcaption className={styles.tagValue}>{tagValue}</figcaption>
          <button
            className={styles.tagDelete}
            data-role="tag-delete"
            disabled={disabled || !canDeleteTags}
            onClick={() => {
              if (disabled || !canDeleteTags) return;

              onChange(value.slice(0, i).concat(value.slice(i + 1)));
            }}
          >
            <CrossIcon />
          </button>
        </figure>
      ))}
      {canAddTags && !disabled && (
        <input
          type="text"
          className={styles.input}
          data-role="input"
          disabled={disabled}
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
          id={`toggle_${uniqueID}`}
          onKeyDown={(e) => {
            if (disabled) return;

            if (e.code === 'Backspace') {
              if (!canDeleteTags || inputValue.length !== 0) return;

              return onChange(value.slice(0, value.length - 1));
            }

            if (e.code === 'Enter') return addTag(inputValue);
          }}
          {...(maxTagLength !== -1 && {
            maxLength: maxTagLength,
          })}
          onBlur={() => {
            if (!addTagOnBlur || searchSuggestions !== 'off') return;

            addTag(inputValue);
          }}
          onFocus={() => {
            if (searchSuggestions === 'off') return;
            setModalOpen(true);
          }}
        />
      )}
      {searchSuggestions === 'assets' && !disabled && (
        <div
          className={`${styles.modal} ${
            modalPosition === POSITION.TOP && styles.modalTop
          } ${modalOpen && styles.modalActive} ${
            modalReadOnly && styles.modalReadOnly
          } ${
            (inputValue === '' ||
              searchSuggestionsState.loading ||
              searchSuggestionsState.error ||
              searchSuggestionsState.items.length === 0) &&
            styles.modalHasText
          }`}
          style={{ maxHeight: modalMaxHeight }}
        >
          {inputValue === '' && (
            <div className={styles.modalText}>
              Start typing to search for assets
            </div>
          )}
          {inputValue !== '' && (
            <>
              {searchSuggestionsState.loading && (
                <div className={styles.modalText}>Loading assets...</div>
              )}
              {!searchSuggestionsState.loading &&
                searchSuggestionsState.error && (
                  <div className={styles.modalText} style={{ color: 'red' }}>
                    {searchSuggestionsState.errorMessage ??
                      'Something went wrong'}
                  </div>
                )}
              {!searchSuggestionsState.loading &&
                !searchSuggestionsState.error &&
                searchSuggestionsState.items.length === 0 && (
                  <div className={styles.modalText}>No results found</div>
                )}
            </>
          )}
          {searchSuggestions === 'assets' &&
            !searchSuggestionsState.loading &&
            !searchSuggestionsState.error &&
            searchSuggestionsState.items.length !== 0 &&
            searchSuggestionsState.items.map((item, i) => (
              <button
                className={`${styles.modalItem} ${styles.asset}`}
                key={i}
                disabled={value.includes(item.name)}
                onClick={() => {
                  if (modalReadOnly || value.includes(item.name)) return;
                  addTag(item.name);
                  setModalOpen(false);
                }}
              >
                <div className={styles.assetWrapper}>
                  <picture
                    className={`${
                      item.icon_url === '' && styles.assetIconEmpty
                    }`}
                    style={{
                      backgroundImage:
                        item.icon_url === ''
                          ? 'none'
                          : `url('${item.icon_url}')`,
                    }}
                  >
                    {item.icon_url === '' && <AssetIcon />}
                  </picture>
                  <figure>
                    <div className={styles.assetTitle}>{item.title}</div>
                    <div className={styles.assetName}>
                      {item.name}
                      {item.created ? (
                        <div className={styles.assetTag}>Created</div>
                      ) : item.owned ? (
                        <div className={styles.assetTag}>Owned</div>
                      ) : /* <div className={styles.assetTag}>Public</div>*/ null}
                    </div>
                  </figure>
                </div>
                {value.includes(item.name) && <CheckIcon />}
              </button>
            ))}
        </div>
      )}
    </figure>
  );
}
