import { useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';

import sharedStyles from './shared.module.css';
import commonStyles from '../../../common.module.css';
import { toast } from 'react-hot-toast'; // eslint-disable-line import/no-extraneous-dependencies
import { processError, selectFile } from '../../../../utils';
import { getWorldTypes } from '../api';

import type { World } from '../../../../type/World';
import type { OutletContext } from '.';

import Input, { TextArea } from '../../../../Comps/Input';
import TagInput from '../../../../Comps/TagInput';
import Select from '../../../../Comps/Select';
import Button from '../../../../Comps/Button';
import { Toggle } from '../../../../Comps/Checkbox';

import { ReactComponent as UploadIcon } from '../../../../assets/icons/uploadIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/checkCircleIcon.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/checkIcon.svg';
import { ReactComponent as DiscordIcon } from '../../../../assets/icons/discordIcon.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/icons/twitterIcon.svg';
import { ReactComponent as YoutubeIcon } from '../../../../assets/icons/youtubeIcon.svg';

export default function WorldManagementDetailsPage() {
  const {
    world,
    getField,
    setUpdateField,
    savingData,
    fetchWorld,
    update,
    setUpdate,
  } = useOutletContext<OutletContext>();

  const getLink = (category: string): string =>
    typeof update.links === 'object' &&
    typeof update.links[category] === 'string'
      ? update.links[category]
      : typeof world.links === 'object' &&
        typeof world.links[category] === 'string'
      ? world.links[category]
      : '';

  const updateLink = (category: string, value: string) =>
    setUpdate((prev: any) => ({
      ...prev,
      links: {
        ...prev.links,
        [category]: value,
      },
    }));

  const [worldTypes, setWorldTypes] = useState<null | Record<number, string>>(
    null
  );
  useEffect(() => {
    const abortController = new AbortController();
    getWorldTypes(abortController.signal).then(({ success, types }) => {
      if (!success || !types) return;

      //console.log(types, types[0]);

      setWorldTypes(types);
    });

    return () => abortController.abort();
  }, []);

  return (
    <div className={`${commonStyles.splitContentWrapper}`}>
      <div className={commonStyles.splitContentWrapperLimit}>
        <h1 className={commonStyles.splitContentTitle}>World Details</h1>
        <h3
          className={commonStyles.formGroupLabel}
          style={{ lineHeight: '20px', margin: '16px 0 32px 0' }}
        >
          General details about your world. This information will be visible to
          everyone if your world is public.
        </h3>
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -16 }}
          >
            General
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              World ID
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={getField('name')}
              onChange={(e) =>
                setUpdateField(
                  'name',
                  e.target.value
                    .toLowerCase()
                    .replace(/ +/g, '-')
                    .replace(/\-+/g, '-')
                    .replace(/[^a-z\-]/g, '')
                )
              }
              disabled
              placeholder="my-cool-world"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Display name
            </figcaption>
            <Input
              className={commonStyles.formGroupInput}
              value={getField('title')}
              onChange={(e) => setUpdateField('title', e.target.value)}
              maxLength={90}
              disabled={savingData}
              placeholder="My Cool World"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Description
            </figcaption>
            <TextArea
              className={commonStyles.formGroupInput}
              value={getField('description') || ''}
              onChange={(e) => setUpdateField('description', e.target.value)}
              maxLength={240}
              disabled={savingData}
              style={{ height: 80 }}
              placeholder="Super awesome experience I made!"
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Category
            </figcaption>
            <Select
              className={commonStyles.formGroupInput}
              value={`${getField('type') || '0'}`}
              onChange={(value) => {
                console.log(value, typeof value);
                setUpdateField('type', value);
              }}
              placeholder='Pick a category for your world'
              disabled={savingData}
              options={
                worldTypes === null
                  ? []
                  : Object.entries(worldTypes)
                      .filter((e) => {
                        console.log(e);
                        return true;
                      })
                      .map(([id, name]) => ({
                        value: id,
                        label: name,
                      }))
              }
              loading={worldTypes === null}
            />
          </figure>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Tags
            </figcaption>
            <TagInput
              className={commonStyles.formGroupInput}
              value={
                getField<string[]>(
                  'tags',
                  world.tags.map((tag: any) => tag.tag)
                ) || []
              }
              onChange={(newValue) => setUpdateField('tags', newValue)}
              sanitizeTag={(tag) => tag.toLowerCase()}
              disabled={savingData}
              theme="yellow"
            />
          </figure>
        </section>
        {/*<figure className={commonStyles.splitContentDivider} />
       
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -16 }}
          >
            Restrictions
          </h3>
          <figure className={commonStyles.formGroup}>
            <figcaption className={commonStyles.formGroupLabel}>
              Allow users to use their own universal items in your world.
              Recommended for Social worlds.
            </figcaption>
            <figure className={commonStyles.toggleGroup}>
              <Toggle checked style={{ width: 32 }} />
              <figcaption className={commonStyles.toggleGroupLabel}>
                Enabled
              </figcaption>
            </figure>
          </figure>
        </section>
        <figure className={commonStyles.splitContentDivider} />
        
       */}
        <figure className={commonStyles.splitContentDivider} />
        <section className={commonStyles.splitContentSection}>
          <h3
            className={commonStyles.splitContentSubtitle}
            style={{ marginBottom: -16 }}
          >
            Social links
          </h3>
          <figure className={commonStyles.formGroup} style={{ gap: 12 }}>
            <figcaption
              className={commonStyles.formGroupLabel}
              style={{ marginBottom: 4 }}
            >
              Useful if you maintain a presence on other platforms.
            </figcaption>
            <Input
              type="url"
              placeholder="Discord Invite URL"
              value={getLink('discord')}
              onChange={(e) => updateLink('discord', e.target.value)}
              className={commonStyles.formGroupInput}
              icon={<DiscordIcon />}
              disabled={savingData}
            />
            <Input
              type="url"
              className={commonStyles.formGroupInput}
              placeholder="YouTube Channel URL"
              value={getLink('youtube')}
              onChange={(e) => updateLink('youtube', e.target.value)}
              icon={<YoutubeIcon />}
              disabled={savingData}
            />
            <Input
              type="url"
              className={commonStyles.formGroupInput}
              placeholder="Twitter Profile URL"
              value={getLink('twitter')}
              onChange={(e) => updateLink('twitter', e.target.value)}
              icon={<TwitterIcon />}
              disabled={savingData}
            />
          </figure>
        </section>
      </div>
    </div>
  );
}
