import axios from 'axios';
import toast from 'react-hot-toast';
import { processError } from '../utils';

export async function serverStatsFetcher(
  surpressErrors: boolean,
  url: string
): Promise<[number | -1, number | -1]> {
  try {
    const result = await axios.get(url);

    if (result.data.code !== 0) {
      if (!surpressErrors) toast.error(processError(result.data));
      return [-1, -1];
    }

    //console.log(result.data.payload);

    const {
      cpu_percentage_history: cpuPercentageHistory,
      memory_percent_history: memoryPercentHistory,
    }: {
      cpu_percentage_history: [string, number][];
      memory_percent_history: [string, number][];
    } = result.data.payload;

    // console.log('ec', cpuPercentageHistory, memoryPercentHistory);

    if (cpuPercentageHistory.length === 0 && memoryPercentHistory.length === 0)
      return [-1, -1];

    const cpuUsage =
      cpuPercentageHistory.length > 0
        ? cpuPercentageHistory[cpuPercentageHistory.length - 1][1]
        : -1;
    const memoryUsage =
      memoryPercentHistory.length > 0
        ? memoryPercentHistory[memoryPercentHistory.length - 1][1]
        : -1;

    return [cpuUsage, memoryUsage];
  } catch (e) {
    //console.error(e);
    if (!surpressErrors) toast.error(processError(e));

    return [-1, -1];
  }
}

export async function serverLogsFetcher(
  surpressErrors: boolean,
  url: string
): Promise<string[]> {
  try {
    const result = await axios.get(url);

    if (result.data.code !== 0) {
      if (!surpressErrors) throw new Error(processError(result.data));
      return [];
    }

    return result.data.payload ?? [];
  } catch (e) {
    if (!surpressErrors) throw new Error(processError(e));
    return [];
  }
}

export async function terminateServer(
  serverIP: string,
  serverPort: number,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.delete('/server', {
      data: {
        ip: serverIP,
        port: serverPort,
      },
      signal,
      timeout: 60 * 1000, // shutting down a server can take a while
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function sendServerCommand(
  serverIP: string,
  serverPort: number,
  command: string,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
    }
  | {
      success: false;
      error: string;
    }
> {
  try {
    const result = await axios.post(
      '/server/cmd',
      {
        ip: serverIP,
        port: serverPort,
        command,
      },
      {
        signal,
        timeout: 60 * 1000, // running a command can take a while
      }
    );

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
      };

    return {
      success: true,
      error: '',
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
    };
  }
}

export async function getServerLogs(
  serverIP: string,
  serverPort: number,
  signal?: AbortSignal
): Promise<
  | {
      success: true;
      error: '';
      logs: string[];
    }
  | {
      success: false;
      error: string;
      logs: null;
    }
> {
  try {
    const result = await axios.get('/server/log/stdout', {
      params: {
        ip: serverIP,
        port: serverPort,
      },
      signal,
      timeout: 60 * 1000, // getting logs can take a while
    });

    if (result.data.code !== 0)
      return {
        success: false,
        error: processError(result.data),
        logs: null,
      };

    return {
      success: true,
      error: '',
      logs: result.data.payload ?? [],
    };
  } catch (e) {
    return {
      success: false,
      error: processError(e),
      logs: null,
    };
  }
}
